import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueCodemirror from 'vue-codemirror'
import {
  CardPlugin,
  ButtonPlugin,
  TablePlugin,
  TabsPlugin,
  InputGroupPlugin,
  FormInputPlugin,
  SpinnerPlugin,
  ModalPlugin,
  ToastPlugin,
  TooltipPlugin,
  PaginationPlugin,
  FormSelectPlugin,
  FormCheckboxPlugin,
  FormDatepickerPlugin,
  FormRadioPlugin,
  FormGroupPlugin,
  ProgressPlugin,
  DropdownPlugin,
  AlertPlugin,
  FormTextareaPlugin,
  FormPlugin,
  FormTimepickerPlugin,
  BadgePlugin,
  CollapsePlugin,
  LayoutPlugin,
  TimePlugin,
  ButtonGroupPlugin
} from 'bootstrap-vue'
import * as ErrorHelper from './components/helpers/ErrorHelper'
import * as AlertHelper from './components/helpers/AlertHelper'
import VuePapaParse from 'vue-papa-parse'
import * as Config from './config'
import Vuelidate from 'vuelidate'
import AuthService from '@/components/helpers/AuthService'
import EventService from '@/components/helpers/EventService'

// Global SASS Import
import './variables.scss'
import './custom.scss'
import './reports.scss'
import 'codemirror/lib/codemirror.css'
import '@jsonforms/vue2-vanilla/vanilla.css'
import MapsService from '@/components/helpers/GoogleMapsHelper'
import DeviceService from '@/components/helpers/DeviceHelper'
import ConfigService from '@/components/helpers/ConfigHelper'
import NotificationService from '@/components/helpers/NotificationService'

Vue.prototype.$config = Config
Vue.prototype.$auth = new AuthService()
Vue.prototype.$eventService = new EventService()
Vue.prototype.$deviceService = new DeviceService()
Vue.prototype.$configService = ConfigService
Vue.prototype.$maps = new MapsService()
Vue.prototype.$notificationService = NotificationService
Vue.config.productionTip = false
// Bootstrap Imports
Vue.use(CardPlugin)
Vue.use(ButtonPlugin)
Vue.use(TablePlugin)
Vue.use(TabsPlugin)
Vue.use(InputGroupPlugin)
Vue.use(FormInputPlugin)
Vue.use(SpinnerPlugin)
Vue.use(ModalPlugin)
Vue.use(ToastPlugin)
Vue.use(TooltipPlugin)
Vue.use(PaginationPlugin)
Vue.use(FormSelectPlugin)
Vue.use(FormCheckboxPlugin)
Vue.use(FormDatepickerPlugin)
Vue.use(FormRadioPlugin)
Vue.use(FormGroupPlugin)
Vue.use(ProgressPlugin)
Vue.use(DropdownPlugin)
Vue.use(AlertPlugin)
Vue.use(FormTextareaPlugin)
Vue.use(FormPlugin)
Vue.use(FormTimepickerPlugin)
Vue.use(BadgePlugin)
Vue.use(CollapsePlugin)
Vue.use(LayoutPlugin)
Vue.use(TimePlugin)
Vue.use(ButtonGroupPlugin)
// CSV Parsing Utility
Vue.use(VuePapaParse)

// Form Validation
Vue.use(Vuelidate)

Vue.use(VueCodemirror, {
  options: { theme: 'base16-dark' }
})

let newVue = new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

ErrorHelper.initialise(newVue.$bvToast, newVue)
AlertHelper.initialise(newVue.$bvToast)
Vue.config.errorHandler = ErrorHelper.handleError
