<template>
  <div class="device-details">
    <loading-box v-bind:loading="loading"></loading-box>
<!--    Load Sub-components AFTER we load the device details-->
    <div v-if="deviceImei">
      <label>{{deviceInternal.name}}</label>
      <div class="green-divider"></div>
      <div class="device-blocks">
        <div class="device-item">
          <p class="device-item-heading">Device Code:
            <span class="device-item-text">{{deviceInternal.device_code}}</span>
          </p>
        </div>
        <div class="device-item">
          <p class="device-item-heading">Plant Name:
            <span class="device-item-text">{{deviceInternal.plant_num}}</span>
          </p>
        </div>
      </div>
      <div v-if="dtcHelper.hasDTCErrors(device)">
        <vehicle-dtc-control :device="deviceInternal"></vehicle-dtc-control>
      </div>
      <div>
        <button class="w-100" @click="clickShowEvents()" id="events-log">Events Log</button>
        <button class="w-100" @click="clickUpdateDevice()" id="view-device" v-if="!enableEdit">
          View Device Details</button>
        <button class="w-100" @click="clickUpdateDevice()" id="update-device" v-if="enableEdit">
          Edit Device Details</button>
        <div class="mt-2">
          <trips-control
                         :device="deviceInternal" :enable-edit="enableEdit" :autoload_route="autoLoadRoute"
                         :event_id="selectedEventId"
                         :start_date="startDate"
                         :end_date="endDate"
                         :trip_id="selectedTripId"
                         v-on:select-event="clickEventMarker"
                         v-on:change="tripsChange"
                         ref="trips_control"></trips-control>
        </div>

      </div>
      <div  class="mt-2">
        <livetrack-control :device_imei="deviceImei" :enableEdit="enableEdit"
                           v-on:change="livetrackChange"
                           ref="livetrack_control" ></livetrack-control>
      </div>
      <div  class="mt-2">
        <geolimit-control :device_imei="deviceImei" :enableEdit="enableEdit"></geolimit-control>
      </div>

      <!--    Events Menu Popout-->
      <PopoutMenu title="Event History" v-model="showEvents">
        <button class="w-100" @click="clickAddEvent()" id="add-event">New Event</button>
        <device-event-control :device_imei="deviceImei" :editable="enableEdit" ref="eventList"
                              @select="selectEvent($event)" :start_date="startDate" :end_date="endDate">
        </device-event-control>
      </PopoutMenu>

      <!--    Subscription Alert Popup-->
      <div v-show="!deviceInternal.has_subscription" class="unpaid-popup">
        <b-alert variant="danger" show dismissible><p>This device currently has an expired subscription,
          which has temporarily paused tracking. To resume tracking, please update your subscription details using the
          <strong>Subscription</strong> tab of the <strong>Preferences page</strong> or by
          <router-link to="/subscribe">clicking here.</router-link></p>
          If you need any assistance, feel free to email our support team at support@protektgps.com or
          call 1300 95 25 30</b-alert>
      </div>

      <!--    Device Edit Modal Template-->
      <b-modal id="modal-1" centered class="modal-content" size="lg" hide-footer title="Edit Device">
        <b-tabs>
          <b-tab title="Properties">
            <edit-device is-modal modal="modal-1" v-bind:device="deviceInternal" v-on:save="onSave()" :editable="enableEdit"></edit-device>
          </b-tab>
          <b-tab title="Status">
            <device-status is-modal modal="modal-1" v-bind:device="deviceInternal" :editable="enableEdit"></device-status>
          </b-tab>
          <b-tab title="Alarms">
            <device-alarms is-modal modal="modal-1" v-bind:device="deviceInternal" v-on:save="onSave()" :editable="enableEdit"></device-alarms>
          </b-tab>
          <b-tab title="Services">
            <device-service-schedule modal="modal-1" v-bind:device="deviceInternal" v-on:save="onSave()" :editable="enableEdit"></device-service-schedule>
          </b-tab>
          <b-tab title="Notifications">
            <edit-user-notifications v-bind:device="deviceInternal"></edit-user-notifications>
          </b-tab>
<!--          <b-tab title="View Schedule">-->
<!--            <device-view-schedule is-modal modal="modal-1" v-bind:device="deviceInternal"  v-on:save="onSave()" :editable="enableEdit"></device-view-schedule>-->
<!--          </b-tab>-->
<!--          <b-tab title="View Exclusions">-->
<!--            <device-view-exclusions is-modal modal="modal-1" v-bind:device="deviceInternal"  v-on:save="onSave()" :editable="enableEdit"></device-view-exclusions>-->
<!--          </b-tab>-->
          <b-tab title="Trip Purpose" v-if="enableEdit" lazy>
            <DeviceTripPurposeSettings :device="deviceInternal" is-modal modal="modal-1" @change="refreshDeviceData"
                                    :editable="enableEdit"></DeviceTripPurposeSettings>
          </b-tab>
          <b-tab title="Advanced" v-if="enableEdit" lazy>
            <DeviceAdvancedSettings :device="deviceInternal" is-modal modal="modal-1" @change="refreshDeviceData"
                                    :editable="enableEdit"></DeviceAdvancedSettings>
          </b-tab>
          <b-tab title="Auditlog">
            <h2>Audit Log</h2>
            <audit-log related_type="device" :related_id="deviceInternal.device_id"></audit-log>
          </b-tab>
          <b-tab title="Integrations">
            <device-integrations-config is-modal modal="modal-1" v-bind:device="deviceInternal"  v-on:save="onSave()" :editable="enableEdit"></device-integrations-config>
          </b-tab>
        </b-tabs>
      </b-modal>
      <!--    Add Event Modal Template-->
      <b-modal id="add-event-modal" centered class="modal-content" size="lg" hide-footer title="Edit Event">
        <device-edit-event :device_imei="deviceImei" :editable="enableEdit" modal="add-event-modal" @save="refreshEvents"
                           :event="selectedEventData"
        ></device-edit-event>
      </b-modal>
    </div>
  </div>
</template>

<script>

import EditDevice from '@/components/device/EditDevice'
import * as DataProvider from '@/components/helpers/DataProvider'
import LoadingBox from '@/components/helpers/LoadingBox'
import * as ErrorHelper from '@/components/helpers/ErrorHelper'
import DeviceEventControl from '@/components/device/DeviceEventControl'
import DeviceEditEvent from '@/components/device/DeviceEditEvent'
import DeviceAlarms from '@/components/device/DeviceAlarms'
import DeviceStatus from '@/components/device/DeviceStatus'
import DeviceServiceSchedule from '@/components/device/DeviceServiceSchedule'
import DeviceViewSchedule from '@/components/device/DeviceViewSchedule'
import EditUserNotifications from '@/components/notifications/EditUserNotifications'
import GeolimitControl from '@/components/device/device_controls/GeolimitControl'
import LivetrackControl from '@/components/device/device_controls/LiveTrackControl'
import TripsControl from '@/components/device/device_controls/TripsControl'
import * as DTCHelper from '@/components/helpers/DTCHelper'
import VehicleDtcControl from '@/components/device/device_controls/VehicleDTCControl'
import DeviceViewExclusions from '@/components/device/DeviceViewExclusions.vue';
import moment from 'moment'
import PopoutMenu from '@/components/shared/PopoutMenu.vue'
import AuditLog from '@/components/shared/AuditLogList.vue'
import DeviceAdvancedSettings from '@/components/device/DeviceAdvancedSettings.vue'
import DeviceTripPurposeSettings from '@/components/device/DeviceTripPurposeSettings.vue'
import DeviceIntegrationsConfig from '@/components/device/DeviceIntegrationsConfig.vue';

export default {
  name: 'device-details',
  components: {
    DeviceTripPurposeSettings,
    DeviceAdvancedSettings,
    DeviceIntegrationsConfig,
    AuditLog,
    PopoutMenu,
    VehicleDtcControl,
    TripsControl,
    LivetrackControl,
    GeolimitControl,
    DeviceEventControl,
    DeviceAlarms,
    DeviceStatus,
    LoadingBox,
    EditDevice,
    DeviceEditEvent,
    DeviceServiceSchedule,
    EditUserNotifications
  },
  props: {
    device: Object,
    enableEdit: Boolean
  },
  data: function () {
    return {
      loading: true,
      deviceImei: '',
      deviceInternal: null,
      showEvents: false,
      mapMarkers: [],
      selectedEventId: null, // Event Id for a selcted event
      selectedEventData: null, // Event Data for modal
      loadEventId: null,
      autoLoadRoute: true,
      dtcHelper: DTCHelper,
      startDate: null,
      endDate: null,
      selectedTripId: null // Initially selected Trip via Query params
    }
  },
  async mounted () {
    this.$maps.clearOverlays()
    if (!this.device) {
      ErrorHelper.displayGeneralErrorToast('No Device Data was Provided.')
    } else {
      this.deviceInternal = this.device
    }
    this.deviceImei = this.device.device_imei
    await this.loadData()
    this.loading = false
  },
  methods: {
    // Refresh/Load All data from the Protekt Server
    loadData: async function () {
      // Load this last, as it's not immediately visible.
      if (Object.keys(this.$route.query).length !== 0) {
        this.autoLoadRoute = false
        await this.parseQueryData(this.$route.query)
      }
      this.loading = false
    },
    // Parse Query Params from URL
    parseQueryData: async function (data) {
      if (data.hasOwnProperty('event_id')) {
        this.selectedEventId = data.event_id
        this.$nextTick(() => {
          this.stopTripsUpdateTimer()
        })
      }

      if (Object.hasOwn(data, 'start_date')) {
        this.startDate = moment(data.start_date)
        if (Object.hasOwn(data, 'end_date')) {
          this.endDate = moment(data.end_date)
        } else {
          console.warn('No end_date param provided, defaulting to 24 hours.')
          this.endDate = moment(data.start_date).add(24, 'hours')
        }
        if (Object.hasOwn(data, 'trip_id')) {
          this.selectedTripId = data.trip_id
        }
      }
    },
    refreshDeviceData: async function () {
      let resp = await DataProvider.getDeviceByIMEI(this.deviceImei)
      if (resp.success) {
        this.deviceInternal = resp.data
      } else {
        throw Error('Failed to fetch device Data.')
      }
    },
    // Event Handler for Livetrack button being toggled.
    livetrackChange: async function (newState) {
      if (newState) {
        this.setTripsUpdateTimer(this.$config.general.livetrackUpdateDelay)
      } else {
        this.setTripsUpdateTimer(this.$config.general.deviceUpdateDelay)
      }
    },
    // Event Handler for when trips interactions happen.
    tripsChange: function (newTimerState) {
      // Close other pop-outs if the trips controller is doing things
      this.livetrackChange(false)
      this.showEvents = false
    },
    setTripsUpdateTimer: function (newVal) {
      this.$refs.trips_control.setUpdateTimer(newVal)
    },
    stopTripsUpdateTimer: function () {
      this.$refs.trips_control.clearUpdateTimer()
    },
    clickAddEvent: function () {
      this.selectedEventData = null
      this.$bvModal.show('add-event-modal')
    },
    clickUpdateDevice: function () {
      this.$bvModal.show('modal-1')
    },
    clickShowEvents: function () {
      this.showEvents = !this.showEvents
    },
    clickHideEventsLog: function () {
      this.showEvents = !this.showEvents
    },
    onSave: async function () {
      await this.refreshDeviceData()
      await this.loadData()
    },
    refreshEvents: function () {
      this.$refs.eventList.refresh()
    },
    selectEvent: function (eventId) {
      this.selectedEventId = eventId
      this.stopTripsUpdateTimer()
    },
    // Event called when an Event Map Marker is clicked.
    // Markers are added by the Trips control
    clickEventMarker: function (eventData) {
      this.selectedEventData = eventData
      this.$bvModal.show('add-event-modal')
    }
  },
  watch: {
    'device': {
      handler: function (newDevice) {
        this.deviceInternal = newDevice
        this.deviceImei = newDevice.device_imei
        this.loadData()
      },
      deep: true
    },
    '$route.query.event_id': {
      handler: function (newEventId) {
        this.selectedEventId = newEventId
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '@/variables';

  .device-item {
    margin-top: 0;
    margin-bottom: 1rem;
    text-align: left;
  }

  .device-item-heading {
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: 'Open Sans', sans-serif;
    color: $text-color-invert;
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
  }

  .device-item-text {
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: 'Open Sans', sans-serif;
    color: $text-color-invert;
    font-size: 15px;
    line-height: 18px;
    font-weight: 400;
  }

  //.button-full{
  //  width: 100%;
  //}

  //.modal-conent {
  //  background-color: #2c3237;
  //  padding-bottom: 100px;
  //}

  //.show {
  //  display: block !important;
  //}
  //
  //.menu-popout{
  //  position: absolute;
  //  left: auto;
  //  z-index: 2;
  //  top: 0%;
  //  right: 310px;
  //  bottom: 0%;
  //  width: 276px;
  //  height: 100vh;
  //  display: none;
  //  -webkit-box-orient: vertical;
  //  -webkit-box-direction: normal;
  //  -webkit-flex-direction: column;
  //  -ms-flex-direction: column;
  //  flex-direction: column;
  //  -webkit-box-pack: end;
  //  -webkit-justify-content: flex-end;
  //  -ms-flex-pack: end;
  //  justify-content: flex-end;
  //  -webkit-box-align: stretch;
  //  -webkit-align-items: stretch;
  //  -ms-flex-align: stretch;
  //  align-items: stretch;
  //  background-color: #2c3237;
  //  box-shadow: 1px 1px 20px -1px rgba(0, 0, 0, 0.51);
  //}
  //
  //.pop-out-heading {
  //  position: relative;
  //  width: 100%;
  //  height: 50px;
  //  background-image: linear-gradient(65deg, $theme-color-primary-3, $theme-color-primary-1);
  //}

  //.pop-out-content {
  //  padding: 1em;
  //}
  //
  //.popout_image {
  //  position: absolute;
  //  right: 0;
  //  top: 0;
  //}

  //.w-col {
  //  position: relative;
  //  float: left;
  //  width: 100%;
  //  min-height: 1px;
  //  padding-left: 10px;
  //  padding-right: 10px;
  //}

  //.progress-bar {
  //  height: 10px;
  //}

  .unpaid-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: calc(100vw - 310px);
    height: 2vh;
  }

  .green-label {
    margin-top: 0;
  }

  @media screen and (max-width: 600px) {
    .menu-popout {
      right: 0;
      width: 100vw;
    }
  }

</style>
