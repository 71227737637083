var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "edit-device" }, [
    !_vm.loading
      ? _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass: "col s4",
              class: { "form-error": _vm.$v.device.device_name.$error }
            },
            [
              _c("label", { attrs: { for: "device_name" } }, [
                _vm._v("Device Name")
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.$v.device.device_name.$model,
                    expression: "$v.device.device_name.$model"
                  }
                ],
                staticClass: "text-field w-input",
                attrs: {
                  type: "text",
                  maxlength: "256",
                  placeholder: "Device Name",
                  id: "device_name",
                  disabled: !_vm.editable
                },
                domProps: { value: _vm.$v.device.device_name.$model },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.$v.device.device_name,
                      "$model",
                      $event.target.value
                    )
                  }
                }
              }),
              !_vm.$v.device.device_name.required
                ? _c("div", { staticClass: "input-error" }, [
                    _vm._v("Field is Required")
                  ])
                : _vm._e(),
              !_vm.$v.device.device_name.minLength
                ? _c("div", { staticClass: "input-error" }, [
                    _vm._v(
                      "Must be at least " +
                        _vm._s(
                          _vm.$v.device.device_name.$params.minLength.min
                        ) +
                        " characters"
                    )
                  ])
                : _vm._e(),
              !_vm.$v.device.device_name.maxLength
                ? _c("div", { staticClass: "input-error" }, [
                    _vm._v(
                      "Must be less than " +
                        _vm._s(
                          _vm.$v.device.device_name.$params.maxLength.max
                        ) +
                        " characters"
                    )
                  ])
                : _vm._e()
            ]
          ),
          _c(
            "div",
            {
              staticClass: "col-4",
              class: { "form-error": _vm.$v.device.plant_num.$error }
            },
            [
              _c("label", { attrs: { for: "plant_properties" } }, [
                _vm._v("Plant Name")
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.$v.device.plant_num.$model,
                    expression: "$v.device.plant_num.$model"
                  }
                ],
                staticClass: "text-field w-input",
                attrs: {
                  type: "text",
                  maxlength: "256",
                  placeholder: "Plant Name",
                  id: "plant_properties",
                  disabled: !_vm.editable
                },
                domProps: { value: _vm.$v.device.plant_num.$model },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.$v.device.plant_num,
                      "$model",
                      $event.target.value
                    )
                  }
                }
              })
            ]
          ),
          _c(
            "div",
            {
              staticClass: "col-4",
              class: { "form-error": _vm.$v.device.registration.$error }
            },
            [
              _c("label", { attrs: { for: "Registration" } }, [
                _vm._v("Registration")
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.$v.device.registration.$model,
                    expression: "$v.device.registration.$model"
                  }
                ],
                staticClass: "text-field w-input",
                attrs: {
                  type: "text",
                  maxlength: "256",
                  name: "Registration",
                  "data-name": "Registration",
                  placeholder: "000 000",
                  id: "Registration",
                  disabled: !_vm.editable
                },
                domProps: { value: _vm.$v.device.registration.$model },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.$v.device.registration,
                      "$model",
                      $event.target.value
                    )
                  }
                }
              })
            ]
          ),
          _vm.deviceFeatures.includes("device_trips")
            ? _c(
                "div",
                {
                  staticClass: "col-4",
                  class: { "form-error": _vm.$v.device.odometer_reading.$error }
                },
                [
                  _c("label", { attrs: { for: "odometer_reading" } }, [
                    _vm._v("Odometer")
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.$v.device.odometer_reading.$model,
                        expression: "$v.device.odometer_reading.$model"
                      }
                    ],
                    staticClass: "text-field w-input",
                    attrs: {
                      type: "text",
                      maxlength: "256",
                      placeholder: "000",
                      id: "odometer_reading",
                      disabled: !_vm.editable
                    },
                    domProps: { value: _vm.$v.device.odometer_reading.$model },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.$v.device.odometer_reading,
                          "$model",
                          $event.target.value
                        )
                      }
                    }
                  }),
                  !_vm.$v.device.overspeed_limit.decimal
                    ? _c("div", { staticClass: "input-error" }, [
                        _vm._v("Must be a number")
                      ])
                    : _vm._e(),
                  !_vm.$v.device.overspeed_limit.minValue
                    ? _c("div", { staticClass: "input-error" }, [
                        _vm._v("Must be 0 or greater ")
                      ])
                    : _vm._e()
                ]
              )
            : _vm._e(),
          _vm.deviceFeatures.includes("device_trips")
            ? _c(
                "div",
                {
                  staticClass: "col-4",
                  class: { "form-error": _vm.$v.device.hours_tracked.$error }
                },
                [
                  _c(
                    "form",
                    {
                      attrs: {
                        id: "hours_tracked_form",
                        name: "hours-form",
                        "data-name": "Email Form"
                      }
                    },
                    [
                      _c("label", { attrs: { for: "overspeed_limit" } }, [
                        _vm._v("Hours Tracked")
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.$v.device.hours_tracked.$model,
                            expression: "$v.device.hours_tracked.$model"
                          }
                        ],
                        staticClass: "text-field w-input",
                        attrs: {
                          type: "text",
                          maxlength: "256",
                          placeholder: "000",
                          id: "hours_tracked",
                          disabled: !_vm.editable
                        },
                        domProps: { value: _vm.$v.device.hours_tracked.$model },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.$v.device.hours_tracked,
                              "$model",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      !_vm.$v.device.hours_tracked.decimal
                        ? _c("div", { staticClass: "input-error" }, [
                            _vm._v("Must be a number")
                          ])
                        : _vm._e(),
                      !_vm.$v.device.hours_tracked.minValue
                        ? _c("div", { staticClass: "input-error" }, [
                            _vm._v("Must be 0 or greater ")
                          ])
                        : _vm._e()
                    ]
                  )
                ]
              )
            : _vm._e(),
          _c("div", { staticClass: "spacing" }),
          _c("div", { staticClass: "mt-2 flex-row" }, [
            _c("div", { staticClass: "col s12" }, [
              _c("label", [_vm._v("Device Icon")]),
              _c("input", {
                attrs: {
                  type: "hidden",
                  name: "icon",
                  id: "icon_properties",
                  value: "Car"
                }
              }),
              !_vm.loading
                ? _c(
                    "div",
                    { staticClass: "flex-row icon-group-properties" },
                    _vm._l(_vm.icons, function(icon) {
                      return _c(
                        "div",
                        {
                          key: icon.name,
                          staticClass: "col",
                          attrs: { id: "icon_list" }
                        },
                        [
                          _c("img", {
                            staticClass: "clickable device-icon",
                            class: {
                              icon_select_color: icon.selected === true
                            },
                            attrs: { src: icon.src },
                            on: {
                              click: function($event) {
                                return _vm.selectIcon(icon)
                              }
                            }
                          })
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e()
            ])
          ]),
          _c("div", { staticClass: "mt-2 col-12 flex-row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "w-100" }, [
                _c("label", [_vm._v("Device Notes")]),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.device.notes.$model,
                      expression: "$v.device.notes.$model"
                    }
                  ],
                  staticClass: "w-100",
                  attrs: {
                    rows: "4",
                    placeholder: "Device notes can be entered here",
                    disabled: !_vm.editable
                  },
                  domProps: { value: _vm.$v.device.notes.$model },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.device.notes,
                        "$model",
                        $event.target.value
                      )
                    }
                  }
                })
              ])
            ])
          ]),
          _c("div", { staticClass: "footer mt-2" }, [
            _c(
              "button",
              {
                staticClass: "button",
                attrs: { id: "button-close" },
                on: {
                  click: function($event) {
                    return _vm.clickClose()
                  }
                }
              },
              [_vm._v("Close")]
            ),
            _vm.editable
              ? _c(
                  "button",
                  {
                    staticClass: "button",
                    attrs: { id: "button-save" },
                    on: {
                      click: function($event) {
                        return _vm.saveChanges()
                      }
                    }
                  },
                  [_vm._v("Save Changes")]
                )
              : _vm._e()
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }