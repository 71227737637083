<template>
  <div class="device-trip-purpose-settings flex-row">
    <loading-box v-bind:loading="loading"></loading-box>
    <div class="flex-column">
      <!--      Default Trip Purpose Times-->
      <div>
        <div class="flex-column">
          <div class="flex-row w-100 justify-content-between align-items-start">
            <div class="flex-column">
              <div class="flex-column mt-2">
                <label>Enable Trip Purpose Times</label>
                <b-checkbox switch v-model="enablePurposeTimes" @input="dirty = true">Enabled</b-checkbox>
              </div>
              <div class="flex-column mt-2">
                <label class="green-label mt-1">Timezone:</label>
                <b-select class="text-input" :disabled="!enablePurposeTimes"  @input="dirty = true"
                          :options="timezoneOptions" v-model="tripPurposeTimezone" required/>
              </div>
            </div>

            <div class="hint-box">
              <h5>Trip Purpose</h5>
              <p>        The 'purpose' of a trip can manually be set from the Device Trip Log or the Vehicle Trips screen for
                fleet users. This can be helpful when working out FBT and other accounting tasks.
              </p>
              <p>
                Enabling 'Trip Purpose Times' will cause trips that start within the times you set to be automatically flagged
                as the 'work' purpose and trips outside that time to be marked as 'personal'.
              </p>
            </div>
          </div>
<!--          <b-select :options="tripCompModes" v-model="tripMode" :disabled="!editable"></b-select>-->
          <WeekTimeSelector :disabled="!enablePurposeTimes" v-model="tripPurposePeriods" @input="dirty = true"
                            emit_on_load
          ></WeekTimeSelector>
        </div>
      </div>
    </div>
    <div class="footer">
      <button v-if="modal" @click="$bvModal.hide(modal)">Close</button>
      <button @click="saveSettings" v-if="dirty">Save Changes</button>
    </div>
  </div>
</template>

<script>

import * as DataProvider from '@/components/helpers/DataProvider'
import LoadingBox from '@/components/helpers/LoadingBox'
import * as ErrorHelper from '@/components/helpers/ErrorHelper'
import * as AlertHelper from '@/components/helpers/AlertHelper'
import WeekTimeSelector from '@/components/shared/WeekTimeSelector.vue'

export default {
  name: 'DeviceTripPurposeSettings',
  components: {
    WeekTimeSelector,
    LoadingBox
  },
  props: {
    device: Object,
    editable: Boolean,
    modal: String
  },
  data: function () {
    return {
      loading: false,
      savedSettings: {

      },
      enablePurposeTimes: false,
      tripPurposePeriods: null,
      tripPurposeTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      dirty: false
    }
  },
  mounted () {
    this.loadSettings(this.device)
  },
  methods: {
    loadSettings(device) {
      if (Object.hasOwn(device.settings, 'enable_trip_purpose_times')) {
        this.enablePurposeTimes = device.settings.enable_trip_purpose_times
      }
      if (Object.hasOwn(device.settings, 'trip_purpose_timezone')) {
        this.tripPurposeTimezone = device.settings.trip_purpose_timezone
      }
      if (Object.hasOwn(device.settings, 'trip_purpose_periods') && device.settings.trip_purpose_periods) {
        this.tripPurposePeriods = device.settings.trip_purpose_periods
      }
      this.updateSavedSettings()
    },
    updateSavedSettings() {
      this.savedSettings.tripMode = this.tripMode
      this.savedSettings.tripMoveTimeLimit = this.tripMoveTimeLimit
    },
    async saveSettings() {
      console.log(this.tripPurposePeriods)
      let resp = await DataProvider.setDeviceProperty(this.device.device_imei, {
        settings: {
          enable_trip_purpose_times: this.enablePurposeTimes,
          trip_purpose_timezone: this.tripPurposeTimezone,
          trip_purpose_periods: this.tripPurposePeriods
        }
      })
      if (resp.success) {
        this.loadSettings(resp.data)
        AlertHelper.successToast('Device Settings Updated!', 'Settings Saved')
        this.$emit('change')
      } else {
        ErrorHelper.displayDataErrorToast(resp)
      }
    }
  },
  computed: {
    isDirty() {
      return Object.keys(this.savedSettings).some(key => this.savedSettings[key] !== this[key])
    },
    timezoneOptions() {
      return Intl.supportedValuesOf('timeZone')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '../../variables';

  .hint-box {
    max-width: 30em;
    font-size: 0.9em;
  }

</style>
