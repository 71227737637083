import { MarkerClusterer } from '@googlemaps/markerclusterer'
import moment from 'moment'
import appRouter from '@/router/index'
import * as config from '@/config'
import * as IconHelper from '@/components/helpers/IconHelper'
import { DateTimeHelper as dt } from '@/components/helpers/DateTimeHelper'
import { rainbow } from '@indot/rainbowvis'

// const API_KEY = 'AIzaSyD65rQa9TxktunWkfcYz5au8tj-LQLSZhM'

export default class MapsService {
  /***
   * Google Maps Service
   * Tired of setting up convoluted dependencies for passing map data between components?
   * Google maps as a Vue Service should make things easier.
   */

  static mapsRequest
  static mapLoadCallbacks = []
  static google
  // Note: we MUST load MarkerWithLabel AFTER google maps is loaded, so despite it being a static dep, it needs to be
  //       loaded dynamically
  static MarkerWithLabel
  static MapPopup

  static icons = IconHelper.OulinedIcons

  // Standard Coloured Map Markers
  static markerColours = {
    red: 'https://maps.google.com/mapfiles/ms/icons/red.png',
    green: 'https://maps.google.com/mapfiles/ms/icons/green.png',
    blue: 'https://maps.google.com/mapfiles/ms/icons/blue.png',
    yellow: 'https://maps.google.com/mapfiles/ms/icons/yellow.png'
  }

  static defaultClusterIconOptions = {
    iconType: 'image',
    icon: require('@/assets/icons/markerclusters/multiple-icon-1.png'),
    labelSuffix: ' Devices'
  }

  static defaultViewport = {
    lat: -37.69,
    lng: 144.76,
    zoom: 2
  }

  constructor () {
    this.viewport = { ...MapsService.defaultViewport }
    this.markers = []
    this.geolimit = null
    this.routeData = []
    this.routeDataSpaced = [] // Cache of route points with the last spacing applied
    this.routePoints = []
    this.routeLines = []
    this.lastOverspeed = null
    this.routePointSpacing = 0
    // Are we currently drawing a route?
    this.drawing = false
    // Last time the PanHandler(lol) was allowed to execute. For dbouncing.
    this.lastPanTime = 0
    this.drawBounds = null
    this.geolimitMarkers = []
    this.mapDrawings = []
    this.mapElement = null
    this.map = null
    this._reizeObserver = null
    this.scriptLoaded = false
    this.updateRouteonPan = true
    this.markerIconWidth = 71
    this.markerIconHeight = 71
    // What zoom level do we use for a single marker?
    this.singleDeviceZoomLevel = 19
    // TODO - Below is part of a dodgy hack to make Zooming/Bounds setting work. If the map element is visible
    //        Gmaps just does nothing when you try to set the zoom or bounds. Triggering from the idle event doesn't,
    //        work, because it only triggers when touch the map and it has to do work...
    this.pendingSetBounds = null
    this.clusterIconConfig = MapsService.defaultClusterIconOptions

    let scriptObj = document.getElementById('google-maps-script')
    // Primary Loading Method: Listen for a 'load' event on the Google Maps script Element.
    // Note: Sometimes this fails, I *think* the browser caches the file in memory and doesn't fire the event.
    try {
      scriptObj.addEventListener('load', () => {
        console.log('Loaded Maps through PRIMARY method')
        // eslint-disable-next-line no-undef
        MapsService.google = google
        this.scriptLoaded = true
        MapsService.mapLoadCallbacks.forEach((callback) => {
          callback()
        })
        this._loadExtras()
      })
    } catch (e) {
      console.log(e)
    }
    // Backup loading!
    // Add an event listener for when the document is ready (i.e. everything is loaded) which checks that the gmaps
    // script has been initialised.
    document.addEventListener('readystatechange', () => {
      if (!this.scriptLoaded) {
        // eslint-disable-next-line no-undef
        MapsService.google = google
        console.log('GOOGLE Loaded through back method!', MapsService.google)
        this.scriptLoaded = true
        MapsService.mapLoadCallbacks.forEach((callback) => {
          callback()
        })
        this._loadExtras()
      }
    })
  }
  _loadExtras () {
    MapsService.MarkerWithLabel = require('@google/markerwithlabel')
    const popups = require('./MapPopup')
    MapsService.MapPopupOverlayClass = popups
  }

  /***
   * Function called by MapClusterer to render Cluster Icons
   * @type {{render({count: *, position: *}, *): *}}
   */
  clusterRenderer = {
    render: ({ count, position }) => {
      let title = `${count} ${this.clusterIconConfig.labelSuffix}`
      let content = document.createElement('div')
      content.classList = 'marker-label-parent'
      if (this.clusterIconConfig.iconType === 'image') {
        content.innerHTML = `<img src=${this.clusterIconConfig.icon} class="marker-label-awesome"/>` +
          `<div class="marker-label">${title}</div>`
      } else if (this.clusterIconConfig.iconType === 'font') {
        let colour = 'rgba(255,255,255,1)'
        content.innerHTML = `<div>
        <i class="${this.clusterIconConfig.icon} marker-label-awesome" style="color:${colour};"></i>
        </div>` +
          `<div class="marker-label">${title}</div>`
      } else {
        throw new Error('Unknown Icon type in ClusterIconOptions!')
      }
      return new MapsService.google.maps.marker.AdvancedMarkerElement({
        position: position,
        title: title,
        zIndex: Number(MapsService.google.maps.Marker.MAX_ZINDEX) - 1,
        content: content
      })
    }
  }

  /***
   * Internal helper function to make sure the maps are loaded.
   * Returns immediately if the map is loaded, otherwise adds a CB to the existing cb chain.
   * @returns {Promise<unknown>}
   * @private
   */
  async _checkMapLoad () {
    return new Promise((resolve, reject) => {
      try {
        if (this.map) {
          resolve(this.map)
        }

        if (!MapsService.google) {
          MapsService.mapLoadCallbacks.push(() => {
            resolve()
          })
        } else {
          resolve()
        }
      } catch (e) {
        console.error('Failed to load map!')
        console.error(e)
        reject(Error('Failed to load Google Maps'))
      }
    })
  }

  async createMap (mapElement) {
    /***
     * Create/Update a new Map Object
     *
     * NOTE: GoogleMaps APPARENTLY does not recommend trying to delete and recreate map elements. For a SPA they
     * suggest reusing the same map for the lifetime of the app... so that's what is is actually doing in the backend
     */
    await this._checkMapLoad()
    this._createMap(mapElement)
    this.mapElement = mapElement

    // TODO - Disabled the 'Zoom in later' workaround for google maps for now. It *seems* to be working OK without it.
    this._reizeObserver = new ResizeObserver((entries) => {
      if (entries[0].contentRect.width > 0 && this.pendingSetBounds !== null) {
        // TODO - This is SUPER hacky, but we need to 'wait' for Google maps to wake up or it will just ignore the call.
        setTimeout(() => {
          if (this.pendingSetBounds) {
            // let bounds = new MapsService.google.maps.LatLngBounds(this.pendingSetBounds)
            this.map.setCenter(this.pendingSetBounds.getCenter())
            this.map.fitBounds(this.pendingSetBounds)
            this.pendingSetBounds = null
          }
        }, 250)
      }
    })
    this._reizeObserver.observe(this.mapElement)
    MapsService.google.maps.event.addListener(this.map, 'zoom_changed', () => this.zoomEventHandler())
    MapsService.MapPopupOverlay = new MapsService.MapPopupOverlayClass.MapPopupOverlay(this.map)
    // MapsService.google.maps.event.addListener(this.map, 'center_changed', () => this.panEventHandler())
  }

  removeMap () {
    this._reizeObserver.disconnect()
  }

  _createMap (mapElement) {
    if (!this.map) {
      // Create a new Map
      this.map = new MapsService.google.maps.Map(mapElement, {
        zoom: this.viewport.zoom,
        center: { lat: this.viewport.lat, lng: this.viewport.lng },
        MapId: '123456'
      })
    } else {
      // Move the existing map to the new display Div
      mapElement.append(this.map.getDiv())
    }
    return this.map
  }

  async setViewport (lat, lng, zoom) {
    /***
     * Set Map Viewport to the provided position and zoom level
     * @type {{lng, zoom, lat}}
     */
    this._checkMapLoad()
    this.viewport = {
      lat: lat,
      lng: lng,
      zoom: zoom
    }

    this.map.setZoom(this.viewport.zoom)
  }

  setCenter (lat, lng, pan = false) {
    if (pan) {
      this.map.panTo({ lat, lng })
    } else {
      this.map.setCenter({ lat, lng })
    }
  }

  setZoomLevel (zoom) {
    /***
     * Set Zoom level to the given number
     */
    this.viewport.zoom = zoom
    this.map.setZoom(this.viewport.zoom)
  }

  _animateZoomTick (current, target) {

  }

  setZoomToMarkers () {
    /***
     * Automatically determine Viewport/Zoom using the currently displayed Map Markers
     */
    let bounds = new MapsService.google.maps.LatLngBounds()
    this.markers.forEach(x => bounds.extend(x.position))
    this.setBounds(bounds)
  }

  setZoomToGeolimit () {
    if (this.geolimitMarkers.length > 0) {
      let circle = this.geolimitMarkers.filter(x => Object.hasOwn(x, 'radius'))
      let bounds = circle[0].getBounds()
      this.setBounds(bounds)
    } else {
      console.log('Unable to zoom to Geolimit as there are no Geolimit markers!')
    }
  }

  drawMarker (marker) {
    let newMarker
    let zIndex = marker.zIndex || 1000
    let alpha = 1.0 // Transparency
    if (Object.hasOwn(marker, 'zIndex')) {
      zIndex = marker.zIndex
    }
    if (Object.hasOwn(marker, 'alpha')) {
      alpha = marker.alpha
    }
    let content = document.createElement('div')
    content.classList = 'marker-label-parent'
    if (marker.icon || marker.awesome_icon) {
      if (marker.icon) {
        let iconPath
        let iconData = MapsService.icons.find(x => x.name === marker.icon)
        if (iconData) {
          iconPath = iconData.src
        } else {
          console.log('WARNING: Invalid MarkerWithLabel Icon provided to Map Display')
          iconPath = MapsService.icons[0].src
        }
        content.innerHTML = `<div>
        <img src="${iconPath}">
        </div>` +
          `<div class="marker-label">${marker.title}</div>`
      } else {
        let colour = 'rgba(255,255,255,1)'
        if (Object.hasOwn(marker, 'awesome_icon_colour')) {
          colour = marker.awesome_icon_colour
        }
        content.innerHTML = `<div>
        <i class="${marker.awesome_icon} marker-label-awesome" style="color:${colour};"></i>
        </div>` +
          `<div class="marker-label">${marker.title}</div>`
      }
    } else if (marker.direction_arrow) {
      let rotation = marker.position.direction / 10
      let icon
      if (Object.hasOwn(IconHelper.directionIcons, marker.direction_arrow)) {
        icon = IconHelper.directionIcons[marker.direction_arrow]
      } else {
        console.error('Directional Arrow ' + marker.direction_arrow + ' not found. Falling back to default!')
        icon = IconHelper.directionIcons.arrowCircle
      }
      content.innerHTML = `
                            <img src=${icon} class="marker-label-direction"
                            style="transform: rotate(${rotation}deg);"
                            />` +
        `<div class="marker-label">${marker.title}</div>`
    } else {
      // TODO - I don't think we use this
    }

    newMarker = new MapsService.google.maps.marker.AdvancedMarkerElement({
      position: marker.position,
      map: this.map,
      content: content,
      zIndex: zIndex
    })

    if (marker.link) {
      newMarker.addListener('click', () => {
        appRouter.push({ path: marker.link })
      })
    }
    if (marker.popup) {
      let popContent = document.createElement('div')
      popContent.innerHTML = marker.popup
      newMarker.popup = MapsService.MapPopupOverlay.createPopup(marker.position, popContent)
      newMarker.addListener('click', (e) => {
        MapsService.MapPopupOverlay.setVisibility(marker.popup, !marker.popup.show)
        e.domEvent.stopPropagation()
      })
    }
    if (marker.onClick) {
      newMarker.addListener('click', () => {
        marker.onClick()
      })
    }
    return newMarker
  }

  drawMarkers (newMarkers, useClustering) {
    // Create a new boundary box to track our viewport
    let bounds = new MapsService.google.maps.LatLngBounds()

    if (!Array.isArray(newMarkers)) {
      console.error('Map Display: Invalid MarkerWithLabel data provided!')
      throw new Error('Invalid MarkerWithLabel Data, expected an Array')
    }
    for (let marker of newMarkers) {
      this.markers.push(this.drawMarker(marker))
      bounds.extend(marker.position)
    }
    if (useClustering) {
      this.clusterer = new MarkerClusterer({
        map: this.map,
        markers: this.markers,
        renderer: this.clusterRenderer,
        zIndex: 2
      })
    }
    return bounds
  }

  drawGeolimit (dragCallback, clickCallback) {
    let geocolor
    if (this.geolimit.active) { // Colour based on activation state
      geocolor = '#25ff24'
    } else {
      geocolor = '#FA8072'
    }
    let circle = new MapsService.google.maps.Circle({
      strokeColor: geocolor,
      strokeOpacity: 0.8,
      strokeWeight: 4,
      fillColor: null,
      fillOpacity: 0,
      map: this.map,
      center: { lat: this.geolimit.lat, lng: this.geolimit.lng },
      radius: this.geolimit.radius
    })
    // Center Marker
    let marker = new MapsService.google.maps.Marker({
      position: { lat: this.geolimit.lat, lng: this.geolimit.lng },
      map: this.map,
      title: 'GeoLimit Center (Drag to move)',
      icon: {
        path: MapsService.google.maps.SymbolPath.CIRCLE,
        scale: 7
      },
      draggable: true
    })
    // Click Center Marker
    MapsService.google.maps.event.addListener(marker, 'click', (e) => {
      clickCallback(e)
    })
    // Drag Center Marker
    MapsService.google.maps.event.addListener(marker, 'drag', () => {
      let pos = { lat: marker.position.lat(), lng: marker.position.lng() }
      radiusMarker.setPosition(this.getRadiusHandleLocation(circle))
      circle.setCenter(pos)
    })
    // Drop Center Marker
    MapsService.google.maps.event.addListener(marker, 'dragend', () => {
      this.geolimit.lat = marker.getPosition().lat()
      this.geolimit.lng = marker.getPosition().lng()
      let newGeolimit = {
        lat: this.geolimit.lat,
        lng: this.geolimit.lng,
        radius: this.geolimit.radius,
        active: this.geolimit.active
      }
      dragCallback(newGeolimit)
    })
    // Radius handle marker
    let radiusMarker = new MapsService.google.maps.Marker({
      position: this.getRadiusHandleLocation(circle),
      map: this.map,
      title: 'GeoLimit Radius (Drag to Resize)',
      icon: {
        path: MapsService.google.maps.SymbolPath.CIRCLE,
        scale: 6
      },
      draggable: true
    })
    // Drag Radius Marker
    MapsService.google.maps.event.addListener(radiusMarker, 'drag', () => {
      let pos = { lat: circle.getCenter().lat(), lng: radiusMarker.position.lng() }
      let centrePos = { lat: circle.getCenter().lat(), lng: circle.getCenter().lng() }
      let radius = this.nearestMultiple(this.haversineDistance(pos, centrePos) * 1000, config.geolimit.precision)
      if (radius <= 10 || radiusMarker.position.lng() > circle.getCenter().lng()) {
        radius = 10
        circle.setRadius(radius)
        radiusMarker.setPosition(this.getRadiusHandleLocation(circle))
      } else {
        radiusMarker.setPosition(pos)
        circle.setRadius(radius)
      }
    })
    // Drop Radius Marker
    MapsService.google.maps.event.addListener(radiusMarker, 'dragend', () => {
      let radius = Math.round(circle.getRadius())
      if (radius <= config.geolimit.min || radiusMarker.position.lng() > circle.getCenter().lng()) {
        radius = config.geolimit.min
        circle.setRadius(radius)
      } else if (radius > config.geolimit.max) {
        radius = config.geolimit.max
        circle.setRadius(radius)
      }
      this.geolimit.radius = radius
      radiusMarker.setPosition(this.getRadiusHandleLocation(circle))
      let newGeolimit = {
        lat: this.geolimit.lat,
        lng: this.geolimit.lng,
        radius: this.geolimit.radius,
        active: this.geolimit.active
      }
      dragCallback(newGeolimit)
    })
    this.geolimitMarkers.push(circle)
    this.geolimitMarkers.push(marker)
    this.geolimitMarkers.push(radiusMarker)
  }

  clearMarkers () {
    if (this.clusterer) {
      this.clusterer.clearMarkers()
    }
    while (this.markers.length > 0) {
      this.markers.pop().setMap(null)
    }
  }

  async clearRoutePoints () {
    while (this.routePoints.length > 0) {
      this.routePoints.pop().setMap(null)
    }
  }

  async clearRouteLines () {
    while (this.routeLines.length > 0) {
      this.routeLines.pop().setMap(null)
    }
  }

  async clearOverlays () {
    await this._checkMapLoad()
    // this.setClusterRendererOptions()
    this.clearMarkers()
    while (this.mapDrawings.length > 0) {
      this.mapDrawings.pop().setMap(null)
    }
    await this.clearRouteLines()
    await this.clearRoutePoints()
    this.routeData = []
    this.routeDataSpaced = []
    this.clearGeolimit()
  }

  clearGeolimit () {
    while (this.geolimitMarkers.length > 0) {
      this.geolimitMarkers.pop().setMap(null)
    }
  }

  _getRouteBounds (routeData) {
    let bounds = new MapsService.google.maps.LatLngBounds()
    routeData.forEach(x => bounds.extend({ lat: x.latitude, lng: x.latitude }))
    return bounds
  }

  _getRouteOutsideBounds (bounds) {
    return this.routeDataSpaced.filter(point => !bounds.contains({ lat: point.latitude, lng: point.longitude }))
  }

  _getRouteInsideBounds (bounds) {
    return this.routeDataSpaced.filter(point => bounds.contains({ lat: point.latitude, lng: point.longitude }))
  }

  getRouteMarkerSpacing (routeData, zoom = null) {
    let bounds = this._getRouteBounds(routeData)
    if (zoom === null) {
      zoom = this.getBoundsZoomLevel(bounds)
    }
    let minSpacing = 1
    // let max_markers = 1000
    // if (routeData.length > max_markers) {
    //   let min_spacing = max_markers / routeData.length
    // }
    let spacing = 1 + (16 - zoom) * 1.5

    return Math.round(Math.max(spacing, minSpacing))
  }

  getBoundsZoomLevel (bounds) {
    let mapDim = { height: this.mapElement.clientHeight, width: this.mapElement.clientWidth }
    // https://stackoverflow.com/questions/6048975/google-maps-v3-how-to-calculate-the-zoom-level-for-a-given-bounds
    let WORLD_DIM = { height: 256, width: 256 }
    let ZOOM_MAX = 21

    function latRad (lat) {
      let sin = Math.sin(lat * Math.PI / 180)
      let radX2 = Math.log((1 + sin) / (1 - sin)) / 2
      return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2
    }

    function zoom (mapPx, worldPx, fraction) {
      return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2)
    }

    let ne = bounds.getNorthEast()
    let sw = bounds.getSouthWest()

    let latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI

    let lngDiff = ne.lng() - sw.lng()
    let lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360

    let latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction)
    let lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction)

    return Math.min(latZoom, lngZoom, ZOOM_MAX)
  }

  async zoomEventHandler () {
    // This event seems to be triggered by ANY zoom change, not just user interactions.
    if (!this.drawing) {
      let start = performance.now()
      if (this.routeData.length) {
        let newSpacing = this.getRouteMarkerSpacing(this.routeData, this.map.getZoom())
        if (this.setRouteSpacing(newSpacing)) {
          await this.clearRoutePoints()
          // await this.drawRoute(this.routeData, this.lastOverspeed, true)
          await this.drawRoutePoints(this.routeDataSpaced, this.lastOverspeed, this.routeData.opts.lineMode, false)
        }
      }
      console.log('Zoom Event Handler Time: ', performance.now() - start, 'ms')
    }
  }

  // Scale a Gmaps Bounds object by the given factor.
  getAdjustedBounds (bounds, factor) {
    let pointSouthWest = bounds.getSouthWest()
    let pointNorthEast = bounds.getNorthEast()
    let latAdjustment = (pointNorthEast.lat() - pointSouthWest.lat()) * (factor - 1)
    let lngAdjustment = (pointNorthEast.lng() - pointSouthWest.lng()) * (factor - 1)
    let newPointNorthEast = new MapsService.google.maps.LatLng(pointNorthEast.lat() + latAdjustment, pointNorthEast.lng() + lngAdjustment)
    let newPointSouthWest = new MapsService.google.maps.LatLng(pointSouthWest.lat() - latAdjustment, pointSouthWest.lng() - lngAdjustment)

    bounds = new MapsService.google.maps.LatLngBounds()
    bounds.extend(newPointNorthEast)
    bounds.extend(newPointSouthWest)
    return bounds
  }

  async panEventHandler () {
    let start = performance.now()
    if (this.updateRouteonPan && !this.drawing && start > (this.lastPanTime + 100)) {
      this.lastPanTime = start
      let mapBounds = this.map.getBounds()
      let drawBounds = this.getAdjustedBounds(mapBounds, 1.2)
      let pointsOutside = this._getRouteOutsideBounds(drawBounds)
      if (pointsOutside.length) {
        let insidePoints = this._getRouteInsideBounds(drawBounds)
        let oldPoints = [...this.routePoints]
        await this.drawRoutePoints(insidePoints, this.lastOverspeed)
        oldPoints.forEach((p) => p.setMap(null))
      }
      console.log('Pan Event Handler Time: ', performance.now() - start, 'ms')
    }
  }

  /***
   * Set the Route spacing to the new value if it's different from the old. Updates route points cache too.
   * @param newSpacing
   * @returns {boolean} True if the value was updated.
   */
  setRouteSpacing (newSpacing, force = false) {
    if (this.routeDataSpaced.length === 0 || newSpacing !== this.routePointSpacing || force) {
      console.log('Updated Space Point Cache')
      this.routePointSpacing = newSpacing
      console.log('ROUTE SPACING: ', newSpacing)
      // NOTE: The below interpolates speed for spaced position colors, it's quite slow though so it's disabled.
      if (newSpacing > 1) {
        // Recalculate avg values based on the hidden points
        // This is a bit rough, but it should good enough
        // for (let i = 0; i < this.routeData.length; i += newSpacing) {
        //   let point = this.routeData[i]
        //   let range = Math.round(newSpacing / 2) // How far to look in each direction
        //   console.log('Range: ', range)
        //   let siblingIdxs = this.range(Math.max(0, i - range), Math.min(i + range, this.routeData.length - 1))
        //   console.log('IDXs:', siblingIdxs)
        //   // Remove anything without a speed
        //   console.log('Objects: ', siblingIdxs.map(sI => this.routeData[sI]))
        //   siblingIdxs = siblingIdxs.filter(sI =>
        //     Object.hasOwn(this.routeData[sI], 'data') && Object.hasOwn(this.routeData[sI].data, 'speed')
        //   )
        //   this.routeData[i].interpolatedSpeed = siblingIdxs.map(sI => this.routeData[sI].data.speed).reduce((sum, a) => sum + a, 0) / siblingIdxs.length
        //   console.log(siblingIdxs)
        // }
      }
      this.routeDataSpaced = this.routeData.filter((x, idx) => idx % newSpacing === 0)
      return true
    }
    console.log('No Change to Spacing: ', newSpacing, this.routePointSpacing)
    return false
  }

  getColorArray (minIdx, maxIdx, colorsArray) {
    return rainbow().overColors(...colorsArray).withRange(minIdx, maxIdx)
  }

  range (start, end) {
    return Array(end - start + 1).fill().map((_, idx) => start + idx)
  }

  async drawRoute (routeData, overspeed, zoomRedraw = false, animateMarkers = true) {
    if (!zoomRedraw) {
      this.routeData = routeData
      this.lastOverspeed = overspeed
      this.routeDataSpaced = []
      this.setRouteSpacing(this.getRouteMarkerSpacing(routeData))
    }
    this.drawing = true
    await this.clearRouteLines()
    await this.clearRoutePoints()
    await this._checkMapLoad()
    console.log(routeData.opts)
    let bounds = await this.drawRoutePoints(this.routeDataSpaced, overspeed, animateMarkers)
    let colorMode = routeData.opts.lineMode
    let colorRange
    if (colorMode === 'idle') {
      colorRange = this.getColorArray(0, 30,
        ['#ff6f1c', '#159a00', '#159a00'])
    } else if (colorMode === 'speed') {
      colorRange = this.getColorArray(0, 120,
        ['#215ad2', '#05b900', '#ffbb2c', '#ec0000'])
    } else {
      colorRange = this.getColorArray(0, routeData.length, ['#06c517', '#1ea0cb', '#0022a9', '#6d17da'])
    }

    // Note: We're currently just drawing ALL of the line points (because we used to draw a single polypath)
    // if we have performance problems we could render fewer lines at higher zoom levels
    let prevPoint = routeData[0]
    for (let idx = 1; idx < routeData.length; idx++) {
      let lineColor
      let opacity = 0.8
      let speed = 5
      let zIndex = 1
      if (Object.hasOwn(routeData[idx], 'speed')) {
        speed = routeData[idx].speed
      } else if (Object.hasOwn(routeData[idx].data, 'speed')) {
        speed = routeData[idx].data.speed
      }
      if (colorMode === 'idle') {
        if (routeData[idx].idleTime > 0) {
          lineColor = '#ff6f1c'
          opacity = 1
        } else {
          lineColor = '#159a00'
          opacity = 0.6
        }
      } else if (colorMode === 'speed') {
        // Note: I eye-balled this equation, it might need fine tuning. The gradients are multi-point, but only linear.
        // Should be red at about 120 and approach it with a quadratic curve.
        lineColor = '#' + colorRange.colorAt(Math.min(0.008 * speed ** 2, 120))
      } else {
        lineColor = '#' + colorRange.colorAt(idx)
      }

      let line = new MapsService.google.maps.Polyline({
        path: [
          { lat: prevPoint.latitude, lng: prevPoint.longitude },
          { lat: routeData[idx].latitude, lng: routeData[idx].longitude }
        ],
        icons: [],
        strokeColor: lineColor,
        strokeOpacity: opacity,
        strokeWeight: 12,
        geodesic: true,
        zIndex: zIndex,
        map: this.map
      })
      this.routeLines.push(line)
      prevPoint = routeData[idx]
    }

    // Check if we received and route drawing options
    if (Object.hasOwn(routeData, 'opts') && !zoomRedraw) {
      if (routeData.opts.showStartMarker) {
        let startPos = routeData[0]
        let startTime = dt.timestampToLocalTime(startPos.timestamp)
        let content = document.createElement('div')
        content.innerHTML = `<div class="map-bubble-parent">
                                <div class="map-bubble green">
                                <div>Trip Start</div>
                                <div class="font-italic">${startTime}</div>
                                </div>
                            </div>`

        this.mapDrawings.push(new MapsService.google.maps.marker.AdvancedMarkerElement({
          position: { lat: startPos.latitude, lng: startPos.longitude },
          map: this.map,
          title: 'Start',
          zIndex: Number(MapsService.google.maps.Marker.MAX_ZINDEX) - 5,
          content: content
        }))
      }
      if (routeData.opts.showEndMarker) {
        let endPos = routeData[routeData.length - 1]
        let endTime = dt.timestampToLocalTime(endPos.timestamp)
        let content = document.createElement('div')
        content.innerHTML = `<div class="map-bubble-parent">
                                <div class="map-bubble red">
                                <div>Trip End</div>
                                <div class="font-italic">${endTime}</div>
                                </div>
                            </div>`

        this.mapDrawings.push(new MapsService.google.maps.marker.AdvancedMarkerElement({
          position: { lat: endPos.latitude, lng: endPos.longitude },
          map: this.map,
          title: 'End',
          zIndex: Number(MapsService.google.maps.Marker.MAX_ZINDEX) - 5,
          content: content
        }))
      }
    }
    this.drawing = false
    if (!zoomRedraw) {
      await this.setBounds(bounds)
      let newSpacing = this.getRouteMarkerSpacing(this.routeData, this.map.getZoom())
      this.setRouteSpacing(newSpacing, true)
      await this.clearRoutePoints()
      await this.drawRoutePoints(this.routeDataSpaced, overspeed, this.routeData.opts.lineMode)
    }
  }

  async drawRoutePoints (routeData, overspeed, colorMode = 'time', animateMarkers = true) {
    let bounds = new MapsService.google.maps.LatLngBounds()
    let n = 0
    let colorRange
    // Set the color palette based on the line mode
    if (colorMode === 'idle') {
      colorRange = this.getColorArray(0, 20,
        // Note: Currently using two solid colors for this function.
        ['#ff6f1c', '#159a00'])
    } else if (colorMode === 'speed') {
      colorRange = this.getColorArray(0, 120,
        ['#133680', '#036200', '#9d6800', '#520000'])
    } else {
      colorRange = this.getColorArray(0, routeData.length, ['#ffae00', '#ffbb2c'])
    }

    for (let idx = 0; idx < routeData.length; idx++) {
      let point = routeData[idx]
      let scale = 1
      let zIndex = 1
      let animModeFactor = 1 // Modify aanimation length, based on the line mode
      let position = { lat: point.latitude, lng: point.longitude }
      n += 1
      // let lat = point.latitude
      let speed = 0
      // if (this.routePointSpacing !== 1 && Object.hasOwn(point, 'interpolatedSpeed')) {
      //   console.log('Point speed using interpolation, ', point.interpolatedSpeed, point.data.speed)
      //   speed = point.interpolatedSpeed
      // } else
      if (Object.hasOwn(point, 'speed')) {
        speed = point.speed
      } else if (Object.hasOwn(point.data, 'speed')) {
        speed = point.data.speed
      }
      let dotColor
      if (colorMode === 'idle') {
        animModeFactor = 0
        // dotColor = '#' + colorRange.colorAt(speed)
        if (routeData[idx].idleTime > 0) {
          dotColor = '#ff6f1c'
          scale = 1.5
          zIndex = 2
        } else {
          dotColor = '#159a00'
        }
      } else if (colorMode === 'speed') {
        // Note: I eye-balled this equation, it might need fine-tuning. The gradients are multi-point, but only linear.
        // Should be red at about 120 and approach it with a quadratic curve.
        dotColor = '#' + colorRange.colorAt(Math.min(0.008 * speed ** 2, 120))
      } else {
        if (speed >= overspeed && overspeed > 0) {
          dotColor = '#e30000'
        } else {
          dotColor = '#' + colorRange.colorAt(idx)
        }
      }
      let animDelay = animateMarkers ? 1000 * (idx / routeData.length) * animModeFactor : 0
      let content = document.createElement('div')
      content.classList = 'route-marker'
      if (Object.hasOwn(point.data, 'direction') && point.data.direction) {
        content.innerHTML = `<div
                               style="
                               width: 1.5em;
                               animation-duration: ${0.5 * animModeFactor}s;
                               transform: rotate(${point.data.direction / 10}deg) scale(${scale});
                                      ">
                                ${IconHelper.getRouteMarker('1.5em', '1.5em', animDelay, dotColor, dotColor)}
                             </div>`
      } else {
        content.innerHTML = `<i class="fa fa-circle"
                               style="
                               transform: scale(${scale});
                               animation-duration: ${0.5 * animModeFactor}s;
                               animation-delay: ${animDelay}ms;
                               color: ${dotColor};
                               font-weight: 600;
                               font-size: 1em;
                                      "></i>`
      }

      let marker = new MapsService.google.maps.marker.AdvancedMarkerElement({
        position: position,
        map: this.map,
        // opacity: opacity,
        title: `${moment.unix(point.timestamp).format('HH:mm DD/MM/YY ')} ${speed}km/h`,
        content: content,
        zIndex: zIndex
      })
      let popContent = document.createElement('div')
      popContent.innerHTML = `<div class="d-flex"> <div class="map-infobox-headings">` +
        `<div>Date:</div>` +
        `<div>Time:</div>` +
        `<div>Speed:</div>` +
        `<div>Idle Time:</div>` +
        // `<div>Idle Speed:</div>` +
        `</div>` +
        `<div class="map-infobox-data mr-1">` +
        `<div>${moment.unix(point.timestamp).format('YYYY-MM-DD')}</div>` +
        `<div>${dt.timestampToLocalTime(point.timestamp, true)}</div>` +
        `<div>${speed} km/h</div>` +
        `<div>${point.idleTime}s</div>` +
        // `<div>${point.idleSpeed}s</div>` +
        `</div></div>`

      // Attach listener to map point
      marker.popup = MapsService.MapPopupOverlay.createPopup(position, popContent)
      marker.addListener('click', (e) => {
        MapsService.MapPopupOverlay.setVisibility(marker.popup, !marker.popup.show)
        console.log(e)
        e.domEvent.stopPropagation()
      })
      this.routePoints.push(marker)
      bounds.extend(position)
    }
    console.log('Drew ', n, ' total points.')
    return bounds
  }

  haversineMeters (lon1, lat1, lon2, lat2) {
    // convert decimal degrees to radians
    lon1 = lon1 * (Math.PI / 180)
    lat1 = lat1 * (Math.PI / 180)
    lon2 = lon2 * (Math.PI / 180)
    lat2 = lat2 * (Math.PI / 180)

    // haversine formula
    let dlon = lon2 - lon1
    let dlat = lat2 - lat1
    let a = Math.sin(dlat / 2) ** 2 + Math.cos(lat1) * Math.cos(lat2) * Math.sin(dlon / 2) ** 2
    let c = 2 * Math.asin(Math.sqrt(a))
    return 6378137 * c
  }

  /***
   * Set the 'pendingSetBounds' prop on the map.
   * This will be picked up by the ResizeEventListener set up during init that watches for non-zero width map resizes.
   *
   * Note - This is a workaround for the fact that Gmaps will NOT resize or move the viewpoint if the map is not visible
   * @param newBounds
   */
  setBounds (newBounds) {
    if (this.mapElement.clientWidth > 0) {
      this.map.setCenter(newBounds.getCenter())
      this.map.fitBounds(newBounds)
    } else {
      this.pendingSetBounds = newBounds
    }
  }

  getMarker (position, content, options) {

  }

  async updateMarkers (newMarkers, replace = false, autoZoom = true, useClustering = true, setBounds = true) {
    /***
     * Update Map Markers
     */
    // this.clearOverlays()
    // if (this.geolimit) {
    //   this.drawGeolimit()
    // }
    await this._checkMapLoad()
    // let useClustering = newMarkers.length > 1
    // useClustering = false

    if (replace) {
      this.clearMarkers()
    }
    let bounds = this.drawMarkers(newMarkers, useClustering)
    if (this.markers.length === 1 && setBounds) {
      // Only one device, so just set the zoom level appropriately
      // this stops the map zooming in too much
      console.log('Setting zoom for single marker')
      this.map.setZoom(this.singleDeviceZoomLevel)
      this.map.setCenter(bounds.getCenter())
    } else if (autoZoom) {
      // // TODO - Workaround to ensure that the zoom/center happens after the map draws (otherwise it doesn't work)
      // //        Keep an eye on this.
      // MapsService.google.maps.event.addListenerOnce(this.map, 'idle', () => {
      //   this.map.setCenter(bounds.getCenter())
      //   this.map.fitBounds(bounds)
      // })
      if (setBounds) {
        this.setBounds(bounds)
      }
    }
  }

  async setGeolimit (newGeolimit, dragCB, clickCB) {
    await this._checkMapLoad()
    if (!Object.hasOwn(newGeolimit, 'active') ||
      !Object.hasOwn(newGeolimit, 'radius') ||
      !Object.hasOwn(newGeolimit, 'lat') ||
      !Object.hasOwn(newGeolimit, 'lng')) {
      console.error('Map Display: Invalid Geolimit data provided!')
      return
    }
    if (typeof newGeolimit.radius !== 'number') {
      newGeolimit.radius = parseInt(newGeolimit.radius)
    }
    this.geolimit = { ...newGeolimit }
    this.clearGeolimit()
    this.drawGeolimit(dragCB, clickCB)
  }

  async updateRoute (newRouteData, overspeed) {
    await this._checkMapLoad()
    // this.clearOverlays()
    this.drawRoute(newRouteData, overspeed)
    // if (newRouteData.hasOwnProperty('parking')) {
    //   this.drawParking(newRouteData.parking)
    // }
  }

  // Return coords for a marker to act as a handle for resizing the Geolimit circle.
  getRadiusHandleLocation (circle) {
    return { lat: circle.getCenter().lat(), lng: circle.getBounds().getSouthWest().lng() }
  }

  nearestMultiple (number, multiple) {
    return Math.round(number / multiple) * multiple
  }

  haversineDistance (coords1, coords2) {
    let lon1 = coords1.lng
    let lat1 = coords1.lat

    let lon2 = coords2.lng
    let lat2 = coords2.lat

    let R = 6371 // km

    let x1 = lat2 - lat1
    let dLat = this.toRad(x1)
    let x2 = lon2 - lon1
    let dLon = this.toRad(x2)
    let a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.toRad(lat1)) *
      Math.cos(this.toRad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    let d = R * c
    return d
  }

  toRad (x) {
    return (x * Math.PI) / 180
  }

  async hideMapControls () {
    await this._checkMapLoad()
    this.map.setOptions({
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      zoomControl: false,
      fullscreenControl: false
    })
  }

  async showMapControls (options = {}) {
    await this._checkMapLoad()
    let controlOptions = {
      mapTypeControl: true,
      scaleControl: true,
      streetViewControl: true,
      rotateControl: true,
      zoomControl: true,
      fullscreenControl: true,
      ...options
    }
    this.map.setOptions(controlOptions)
  }

  /***
   * Set the Cluster Renderer Icon options for the map. If no value is provided then the renderer will be reset
   * to the default type.
   *
   * @param options An Object including the iconType (font or image), icon (img url or font icon css code) and
   * labelSuffix attributes.
   */
  setClusterRendererOptions (options) {
    if (!options) {
      this.clusterIconConfig = MapsService.defaultClusterIconOptions
    } else {
      this.clusterIconConfig = options
    }
  }

  // https://stackoverflow.com/questions/3410600/convert-lat-lon-to-pixels-and-back
  latLngToPixel (lat, lng) {
    let projection = this.map.getProjection()
    let bounds = this.map.getBounds()
    let topRight = projection.fromLatLngToPoint(bounds.getNorthEast())
    let bottomLeft = projection.fromLatLngToPoint(bounds.getSouthWest())
    let scale = Math.pow(2, this.map.getZoom())
    let worldPoint = projection.fromLatLngToPoint({ lat, lng })
    return [Math.floor((worldPoint.x - bottomLeft.x) * scale), Math.floor((worldPoint.y - topRight.y) * scale)]
  }

  addListener (eventName, func) {
    return MapsService.google.maps.event.addListener(this.map, eventName, func)
  }

  removeListener (listener) {
    return MapsService.google.maps.event.removeListener(listener)
  }
}
