<template>
  <div class="device-service-schedule">
    <LoadingBox :loading="loading"></LoadingBox>
    <div class="flex-row flex-nowrap align-items-stretch">
      <div class="event-list">
        <label for="device-event-list">
          Service History (24 Months)

        </label>
        <div class="green-divider"></div>
        <div class="flex-column flex-grow-1 align-items-center">
          <EventList :events="sortedEvents"
                     class="flex-grow-1 event-list-inner"
                     @select="selectEvent"
                     :highlight_func="getEventHighlightClass"
          ></EventList>
          <div class="" v-if="this.events.length < 1">
            Enter your last service to enable estimate for future services.
          </div>
          <div class="event-list-control slim-controls">
            <button class="button" @click="addEvent()" id="button-add-service">Add Service</button>
            <button class="button" @click="refreshEventList()" id="button-add-service">Refresh
              <i :class="$config.icons.general.refresh"
                 v-b-tooltip.hover title="Refresh List"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="flex-column flex-grow-1 align-items-stretch">
        <div class="flex-row flex-nowrap justify-content-stretch p-2">
          <div class="flex-column flex-grow-1 align-items-stretch">
            <div class="flex-grow-1">
              <label for="service_schedule_type" class="device-label">
                Servicing Schedule
                <i class="row-icon-button" :class="$config.icons.general.info"
                   v-b-tooltip.hover title="Determines how servicing is tracked for the vehicle."
                ></i>
              </label>
              <b-select id="service_schedule_mode" v-model="$v.service_settings.service_schedule_mode.$model" :disabled="!editable" class="w-100"
                        :options="service_schedule_options" @change="refreshServiceEstimate">
              </b-select>
            </div>
            <div class="flex-grow-1" v-if="service_settings.service_schedule_mode">
              <div :class="{ 'form-error': $v.service_settings.service_frequency.$error }" class="w-100">
                <label for="servicing_frequency" class="device-label">Service Interval
                  {{service_mode_metrics[service_settings.service_schedule_mode]}}
                  <i class="row-icon-button" :class="$config.icons.general.info"
                     v-b-tooltip.hover title="How long/far between each service?"
                  ></i>
                </label>
                <input type="text" v-model="$v.service_settings.service_frequency.$model" class="w-100"
                       maxlength="256" placeholder="000" id="service_frequency" :disabled="!editable || !service_settings.service_schedule_mode">
                <div class="input-error" v-if="!$v.service_settings.service_frequency.minValue || !$v.service_settings.service_frequency.numeric">
                  Service Interval Must be a positive number!</div>
              </div>
            </div>
            <div class="green-divider"></div>
          </div>
            <div class="hint-box ml-3">
              <h5>Service Scheduling</h5>
              Service Scheduling is used to estimate when a vehicle will next require servicing and to generate
              events/notifications when a service is overdue. Schedules are repeating and can be set in either
                <strong>Distance (KM)</strong> or <strong>Runtime (Hours)</strong>
            </div>
        </div>

        <!--    Distance Mode-->
        <div class="flex-row p-2" v-if="service_settings.service_schedule_mode === 'distance'">
          <div class="ml-3" :class="{ 'form-error': $v.odometer_reading.$error }">
            <label for="service_odometer_reading" class="device-label">Current Odometer</label>
            <input type="text" v-model="$v.odometer_reading.$model" class="text-field w-input"
                   maxlength="256" placeholder="000" id="service_odometer_reading" :disabled="!editable">
            <div class="input-error" v-if="!$v.odometer_reading.minValue || !$v.odometer_reading.minValue">
              Must be a positive number!</div>
          </div>
          <div class="ml-3" :class="{'form-error': !loading && last_service_metric > odometer_reading}">
            <label for="avg_daily_kms" class="device-label">KMs at Last Service Event
              <i class="row-icon-button" :class="$config.icons.general.info"
                 v-b-tooltip.hover
                 title="Change the Odometer reading for your last service event on the left to change this value."
              ></i>
            </label>
            <div class="flex-row position-relative">
              <input type="text" v-model="last_service_metric" class="text-field w-100"
                     maxlength="256" placeholder="000" id="avg_daily_kms" disabled>

            </div>
            <div class="input-error" v-if="!loading && last_service_metric > odometer_reading">
              Hours Tracked at last Service Event should be LOWER than the current reading!
            </div>
          </div>
          <div class="ml-3" :class="{'form-error': !loading && since_last_service < 0}" >
            <label for="avg_daily_kms" class="device-label">KMs Since Last Service</label>
            <input type="text" v-model="since_last_service" class="text-field w-100"
                   maxlength="256" placeholder="-" id="avg_daily_kms" disabled>
            <div class="input-error" v-if="!loading && since_last_service < 0">
              Negative distance since last service means either the current Odometer or the value for the last service is incorrect!
            </div>
          </div>
          <div class="ml-3" >
            <label for="avg_daily_kms" class="device-label">Avg Daily KMs</label>
            <div class="flex-row position-relative">
              <input type="text" :value="serviceMetricMeanText" class="text-field w-input"
                     :class="{'warning': !loading && service_metric_mean === 0}"
                     maxlength="256" placeholder="-" id="avg_daily_kms" disabled>

              <i v-if="!loading && service_metric_mean === 0"
                 class="row-icon-button input-icon fa fa-exclamation-triangle warning-icon"
                 v-b-tooltip.hover title="Not enough activity to estimate."
              ></i>
            </div>

          </div>
          <div class="ml-3" >
            <label for="km_to_next" class="device-label">KMs to next Service</label>
            <input type="text" :value="unitsToNextService" class="text-field w-input"
                   :class="{'warning': !loading && unitsToNextService <= 0}"
                   maxlength="256" placeholder="-" id="km_to_next" disabled>
          </div>
          <div class="ml-3" >
            <label for="est_next_service" class="device-label">Est Next Service
              <i class="row-icon-button" :class="$config.icons.general.info"
                 v-b-tooltip.hover :title="next_service_est_tooltip"
              ></i>
            </label>
            <div class="position-relative flex-row">
              <input type="text" :value="nextServiceText" class="text-field w-100"
                     :class="{'warning': !loading && unitsToNextService <= 0}"
                     maxlength="256" placeholder="Next Service" id="est_next_service" disabled>
              <i v-if="!loading && unitsToNextService <= 0"
                class="row-icon-button input-icon fa fa-exclamation-triangle warning-icon"
                 v-b-tooltip.hover title="Overdue for next service!"
              ></i>
              <i v-if="!loading && next_service_est === null"
                 class="row-icon-button input-icon fa fa-exclamation-triangle warning-icon"
                 v-b-tooltip.hover title="Unable to estimate next service."
              ></i>
            </div>

          </div>
        </div>

<!--      Hours Mode -->
        <div class="flex-row p-2" v-if="service_settings.service_schedule_mode === 'hours'">
          <div class="ml-3" :class="{ 'form-error': $v.hours_tracked.$error }">
            <form id="service_hours_tracked_form" name="hours-form" data-name="Email Form"
                  :class="{ 'form-error': $v.hours_tracked.$error }">
              <label for="service_hours_tracked_form" class="device-label">Current Hours Tracked</label>
              <input type="text" v-model="$v.hours_tracked.$model" class="text-field w-input"
                     maxlength="256" placeholder="-" id="hours_tracked" :disabled="!editable">
              <div class="input-error" v-if="!$v.hours_tracked.minValue">Must be 0 or greater </div>
            </form>
          </div>
          <div class="ml-3" :class="{'form-error': !loading && last_service_metric > hours_tracked}">
            <label for="hour_at_service" class="device-label">Hours at Last Service Event
              <i class="row-icon-button" :class="$config.icons.general.info"
                 v-b-tooltip.hover title="Change the hours in your last service event on hte left to change this value."
              ></i>
            </label>
            <div class="flex-row position-relative">
              <input type="text" v-model="last_service_metric" class="text-field w-100"
                     maxlength="256" placeholder="-" id="hour_at_service" disabled>
              <i class="row-icon-button input-icon fa fa-external-link" @click="openLastService"></i>
            </div>
            <div class="input-error" v-if="!loading && last_service_metric > hours_tracked">
              Hours Tracked at last Service Event should be LOWER than the current reading!
            </div>
          </div>
          <div class="ml-3" :class="{'form-error': !loading && since_last_service < 0}" >
            <label for="hours_since_service" class="device-label">Hours Since Last Service</label>
            <input type="text" v-model="since_last_service" class="text-field w-100"
                   maxlength="256" placeholder="-" id="hours_since_service" disabled>
            <div class="input-error" v-if="!loading && since_last_service < 0">
              Negative hours since last service means either the current Hours Tracked or the value for the last service is incorrect!
            </div>
          </div>
          <div class="ml-3" >
            <label for="avg_daily_hours" class="device-label">Avg Daily Hours</label>
            <div class="flex-row position-relative">
              <input type="text" :value="serviceMetricMeanText" class="text-field w-input"
                     :class="{'warning': !loading && service_metric_mean === 0}"
                     maxlength="256" placeholder="-" id="avg_daily_hours" disabled>

              <i v-if="!loading && service_metric_mean === 0"
                 class="row-icon-button input-icon fa fa-exclamation-triangle warning-icon"
                 v-b-tooltip.hover title="Not enough activity to estimate."
              ></i>
            </div>

          </div>
          <div class="ml-3" >
            <label for="hrs_to_next" class="device-label">Hours to next Service</label>
            <input type="text" :value="unitsToNextService" class="text-field w-input"
                   :class="{'warning': !loading && unitsToNextService <= 0}"
                   maxlength="256" placeholder="-" id="hrs_to_next" disabled>
          </div>
          <div class="ml-3" >
            <label for="est_next_service" class="device-label">Est Next Service
              <i class="row-icon-button" :class="$config.icons.general.info"
                 v-b-tooltip.hover :title="next_service_est_tooltip"
              ></i>
            </label>
            <div class="flex-row position-relative">
              <input type="text" :value="nextServiceText" class="text-field w-input"
                     :class="{'warning': !loading && unitsToNextService <= 0}"
                     maxlength="256" placeholder="Next Service" id="est_next_service" disabled>
              <i v-if="!loading && unitsToNextService <= 0"
                 class="row-icon-button input-icon fa fa-exclamation-triangle warning-icon"
                 v-b-tooltip.hover title="Overdue for next service!"
              ></i>
              <i v-if="!loading && next_service_est === null"
                 class="row-icon-button input-icon fa fa-exclamation-triangle warning-icon"
                 v-b-tooltip.hover title="Unable to estimate next service."
              ></i>
            </div>
          </div>
        </div>
        <div class="flex-row justify-content-end mt-2">
          <button class="button" @click="clickClose()" id="button-close">Close</button>
          <button class="button" @click="saveChanges()" id="button-save" v-if="editable">Save Changes</button>
        </div>
      </div>


    </div>

    <!--    Add Event Modal Template-->
    <b-modal id="add-service-event-modal" centered class="modal-content" size="lg" hide-footer title="Add/Edit Service Event">
      <device-edit-event :device_imei="device.device_imei" :editable="editable" :event="service_event" modal="add-service-event-modal"
                         @save="refreshEventList">
      </device-edit-event>
    </b-modal>
  </div>
</template>

<script>
import * as DataProvider from '../helpers/DataProvider'
import { required, numeric, minValue } from 'vuelidate/lib/validators'
import * as ErrorHelper from '../helpers/ErrorHelper'
import * as AlertHelper from '../helpers/AlertHelper'
import moment from 'moment'
import DeviceEditEvent from '@/components/device/DeviceEditEvent'
import EventList from '@/components/events/EventList.vue'
import LoadingBox from '@/components/helpers/LoadingBox.vue'

export default {
  name: 'device-service-schedule',
  components: {LoadingBox, EventList, DeviceEditEvent },
  props: {
    isModal: Boolean,
    modal: String,
    device: Object,
    editable: Boolean
  },
  data: function () {
    return {
      showButtons: true,
      loading: true,
      displayDevice: null,
      show_event_types: [
        5001
      ],
      service_settings: {
        service_schedule_mode: null,
        service_frequency: null
      },
      service_schedule_options: [
        { value: null, text: 'Disabled', metric: '' },
        { value: 'distance', text: 'Distance', metric: 'KMs' },
        { value: 'hours', text: 'Hours', metric: 'Hours' }
      ],
      service_mode_metrics: {
        null: 'Metric',
        distance: 'KMs',
        hours: 'Hours'
      },
      events: [],
      service_event: null,
      service_event_template: {
        isNew: true,
        event_type: 5001,
        event_data: {
          odometer_reading: '',
          hours_reading: ''
        }
      },
      odometer_reading: 0,
      hours_tracked: 0,
      // avg_daily_kms: 0,
      // avg_daily_hours: 0,
      next_service_est: '',
      next_service_est_tooltip: '',
      last_service_metric: null,
      service_metric_mean: null,
      since_last_service: null,
    }
  },
  validations: {
    service_settings: {
      service_schedule_mode: {
      },
      service_frequency: {
        numeric,
        minValue: minValue(0)
      }
    },
    hours_tracked: {
      numeric,
      required
    },
    odometer_reading: {
      numeric,
      required
    }
  },
  async created () {
    if (this.device.settings.hasOwnProperty('service_settings')) {
      this.service_settings = this.device.settings.service_settings
    }
    // Copy Tracking Values
    this.odometer_reading = this.device.odometer_reading
    this.hours_tracked = this.device.hours_tracked

    // Hide Controls so the parent modal can display them
    if (this.isModal) {
      this.showButtons = false
    }
    await this.refreshEventList()
    // await this.refreshStats()
    await this.refreshServiceEstimate()
    this.loading = false
  },
  methods: {
    loadEvents: async function () {
      let start = moment(new Date()).subtract(24, 'months').toISOString()
      let end = moment(new Date()).toISOString()
      let result = await DataProvider.getDeviceEvents(this.device.device_imei, start, end, this.show_event_types)
      if (result.success) {
        return result.data.events
      } else {
        ErrorHelper.displayDataErrorToast(result)
      }
    },
    refreshEventList: async function () {
      this.events = await this.loadEvents()
    },
    getEventHighlightClass (event) {
      console.log(event)
      if (this.service_settings.service_schedule_mode === 'distance' && event.event_data.odometer_reading) {
        if (event.event_data.odometer_reading > this.odometer_reading && this.sortedEvents[0] === event) {
          return 'danger'
        }
      } else if (this.service_settings.service_schedule_mode === 'hours' && event.event_data.hours_reading) {
        if (event.event_data.hours_reading > this.hours_tracked) {
          return 'event-highlight-up'
        } else if (event.event_data.hours_reading < this.hours_tracked) {
          return 'event-highlight-down'
        }
      }
    },
    openLastService() {
      this.selectEvent(this.sortedEvents[0])
    },
    refreshServiceEstimate: async function () {
      this.loading = true
      // Update Estimates if we have a service mode set
      // Note: We're using the unsaved GUI values intentionally for these estimates
      if (this.service_settings.service_schedule_mode && this.service_settings.service_frequency) {
        let serviceData = await this.getDeviceServiceEstimate(this.service_settings.service_schedule_mode, this.service_settings.service_frequency)
        console.log(serviceData)
        this.last_service_metric = serviceData.last_service_metric
        this.next_service_est = serviceData.est_next_service
        this.service_metric_mean = serviceData.service_metric_mean
        this.since_last_service = serviceData.since_last_service

        this.next_service_est_tooltip = `Based on ${this.service_settings.service_schedule_mode} mode, with
        service freq of ${this.service_settings.service_frequency} ${this.service_mode_metrics[this.service_settings.service_schedule_mode]}.`
      }
      this.loading = false
    },
    getDeviceServiceEstimate: async function (serviceMode, serviceFreq) {
      let result = await DataProvider.getDeviceServiceEst(this.device.device_imei, serviceMode, serviceFreq)
      if (result.success) {
        return result.data
        // if (result.data.hasOwnProperty('est_next_service') && result.data.est_next_service) {
        //   return moment(result.data.est_next_service).format('YYYY/MM/DD')
        // }
      } else {
        ErrorHelper.displayDataErrorToast(result)
      }
    },
    // getDeviceStats: async function () {
    //   let stats = [
    //     'DEVICE_DAILY_MEAN_KMS',
    //     'DEVICE_DAILY_MEAN_HOURS'
    //   ]
    //   let result = await DataProvider.getMostRecentStats(this.device.device_imei, stats)
    //   if (result.success) {
    //     return result.data
    //   } else {
    //     ErrorHelper.displayDataErrorToast(result)
    //   }
    // },
    saveChanges: async function () {
      if (this.$v.$anyError) {
        ErrorHelper.displayGeneralErrorToast('Some Fields Contain Invalid Data. Please fix them.', 'Invalid Fields')
        return
      }
      // Update Selected icon
      try {
        this.service_settings.service_frequency = parseFloat(this.service_settings.service_frequency)
        let res = await DataProvider.updateDeviceServiceSettings(this.device.device_imei,
          this.odometer_reading,
          this.hours_tracked,
          this.service_settings
        )

        if (res.success) {
          this.$v.$reset()
          this.$emit('save') // Emit a save event, since we've just saved
          AlertHelper.successToast('Service Schedule Settings Update!', 'Settings Updated')
          this.clickClose()
        } else {
          this.$bvToast.toast('Uh Oh! Something went wrong saving changes to the server. Please try again later.',
            {
              title: 'Server Communication Error',
              variant: 'danger',
              toaster: 'b-toaster-top-center'
            })
        }
      } catch (err) {
        console.error(err)
        this.$bvToast.toast('An Error Occurred Getting a Device List from the Server.',
          {
            title: 'Server Communication Error',
            variant: 'danger',
            toaster: 'b-toaster-top-center'
          })
      }
    },
    clickClose: async function () {
      if (this.$v.$anyDirty) {
        let res = await this.$bvModal.msgBoxConfirm('You have unsaved changes, are you sure you want to close this window?', {
          title: 'Confirm Discard Changes',
          okVariant: 'warning',
          centered: true
        })
        if (!res) {
          return
        }
      }
      // Check if we've been passed a modal to close.
      if (this.modal) {
        this.$bvModal.hide(this.modal)
      } else {
        this.$router.go(-1)
      }
    },
    addEvent: function () {
      this.service_event = { ...this.service_event_template }
      this.service_event.event_data.odometer_reading = this.odometer_reading
      this.service_event.event_data.hours_reading = this.hours_tracked
      this.service_event.start_time = moment(new Date()).unix()
      this.$bvModal.show('add-service-event-modal')
    },
    selectEvent (event) {
      this.service_event = event
      this.$bvModal.show('add-service-event-modal')
    },
    formatTimestamp: function (timestamp) {
      return moment.unix(timestamp).local().format('Do MMMM, YYYY')
    },
  },
  computed: {
    sortedEvents() {
      if (this.events) {
        let events = [...this.events]
        return events.sort((a, b) => {
          if (a.start_time < b.start_time) {
            return 1
          }
          if (a.start_time > b.start_time) {
            return -1
          }
          return 0
        })
      }
      return []
    },
    unitsToNextService() {
      if (this.service_settings.service_schedule_mode !== null) {
        return this.service_settings.service_frequency - this.since_last_service
      }
      return 0
    },
    nextServiceText() {
      if (moment(this.next_service_est).isValid()) {
        return moment(this.next_service_est).format('YYYY/MM/DD')
      } else {
        return 'Unable to Estimate'
      }
    },
    serviceMetricMeanText() {
      if (!isNaN(parseFloat(this.service_metric_mean))) {
        return parseFloat(this.service_metric_mean).toFixed(2)
      } else {
        return 0
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '../../variables';

  .icon-button {
    margin: 0;
  }

  .device-code{
    color: $text-color-invert;
    margin-left: 5%;
  }

  .footer{
    width: 100%;
    bottom: 2%;
    display: flex;
    justify-content: flex-end;
  }

  .icon_select_color {
    border: solid $theme-color-primary-3;
  }

  .event-list {
    background: $theme-color-background-2;
    display: flex;
    flex-direction: column;
    border-right: 1px solid $theme-color-primary-3;
    justify-content: stretch;
    align-items: stretch;
    min-width: 200px;
    padding-right: 5px;
  }

  .event-list-container {
    max-height: 60vh;
    overflow-y: auto;
    flex-grow: 1;
  }

  .event-list-control {
    display: flex;
    //align-self: end;
    justify-content: flex-end;
  }

  .hint-box {
    max-width: 25em;
    font-size: 0.9em;
  }

  .justify-items-center {
    justify-items: center;
  }

  .event-list-inner {
    justify-items: center;
    max-height: 70vh;
    overflow: auto;
  }

  .input-icon {
    position: absolute;
    right: 1em;
  }

  .input-icon.warning-icon {
    color: rgb(255, 194, 0);
  }

  .warning {
    box-shadow: 0 0 0 0.2rem rgba(255, 194, 0, 0.80);
  }

</style>
