<template>
  <div class="login">
    <div class="login-outer">
      <div>
        <img class="logo" src="../assets/Protekt-logo-medium.png"/>
      </div>

      <div class="login-container justify-content-center">
        <login-box class="login-item"></login-box>
        <div class='fake-link' @click="$bvModal.show('forgotten-password-modal')">Forgotten Password?</div>
        <div class='fake-link'><router-link to="register">Need to Create an Account?</router-link></div>
      </div>
    </div>
<!--    <div class="justify-content-center">-->
<!--      <h2>Don't have a login or device?</h2>-->
<!--      <a href="https://pro-tekt.com.au/"><h3>Protekt</h3></a>-->
<!--    </div>-->
    <div class="footer">
      <router-link to="terms">Terms and Conditions</router-link>
    </div>

    <!--    Forgotten Password Modal Template-->
    <b-modal id="forgotten-password-modal" centered class="modal-content" size="lg" hide-footer title="Forgotten Password">
      <forgotten-password-box modal="forgotten-password-modal"></forgotten-password-box>
    </b-modal>
  </div>
</template>

<script>
import LoginBox from '../components/auth/LoginBox.vue'
import ForgottenPasswordBox from '../components/auth/ForgottenPasswordBox'

export default {
  name: 'login-view',
  components: {
    ForgottenPasswordBox,
    LoginBox
  },
  data: function () {
    return {
      returnUrl: null,
      token: null
    }
  },
  mounted () {
  },
  methods: { }
}
</script>

<style scoped lang="scss">
  @import '@/variables';

  .login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    //background: $default-sidebar-background;
    background: url('../assets/Satellite-View.png');
    background-size: cover;
    background-repeat: no-repeat;
    color: $text-color-invert;
    overflow: auto;
  }

  .logo {
    max-width: 100%;
    max-height: 40vh;
  }

  .login-outer {
    max-width: 650px;
    padding: 20px 15px;
    border-radius: 15px;
    background: $default-sidebar-background;
  }

  .login-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  .login-item {
    width: 100%;
    max-width: 35rem;
  }

  .footer {
    position: absolute;
    left: 50%;
    bottom: 20px;
    transform: translate(-50%, 0);
    margin-top: 5vh;
  }

  @media screen and (max-height: 810px) {
    .footer {
      position: relative;
      width: 100%;
      left: 50%;
      bottom: 20px;
      transform: translate(-50%, 0);
      margin-top: 5vh;
    }
  }
</style>
