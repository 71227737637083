<template>
  <div class="form-edit" >
    <LoadingBox :loading="loading"></LoadingBox>
<!--      <FormTemplateAvatar v-if="template" :template="template" hide_description disabled></FormTemplateAvatar>-->
    <div class="form-create-inner animate-in slide-left">
      <div class="flex-row justify-content-between align-items-stretch">
        <div class="flex-column mr-3" v-if="!isNew">
          <label>User</label>
          <UserAvatar :user="user" v-if="user"></UserAvatar>
        </div>
        <div class="flex-column mr-3" v-if="!isNew">
          <div v-for="(option, idx) of relatedTypeControls" v-bind:key="idx" >
            <label>{{ option.showText }}</label>
            <DeviceAvatar v-if="option.value==='device' && device" :device=device></DeviceAvatar>
            <div v-else-if="option.value==='device'">Device Unavailable</div>
            <!--            Currently we don't show a trip selector here. -->
            <TripAvatar v-if="option.value==='trip' && tripObject" :trip="tripObject" show_tags show_address> </TripAvatar>
          </div>
        </div>
<!--        Template Control-->
        <div class="flex-column flex-grow-1" v-if="isNew">
          <label>Template</label>
          <b-select :disabled="disabled" :options="templateOptions" v-model="templateId" @change="changeTemplate">
          </b-select>
        </div>
<!--        Related Type Control-->
        <div class="flex-column flex-grow-1 mr-3" v-if="isNew">
          <div v-for="(option, idx) of relatedTypeControls" v-bind:key="idx" >
            <label>{{ option.pickText }}</label>
            <DeviceSelector v-if="option.value==='device'" v-model="deviceId" return_prop="device_id"> </DeviceSelector>
<!--            Currently we don't show a trip selector here. -->
            <TripAvatar v-if="option.value==='trip' && tripObject" :trip="tripObject"> </TripAvatar>
            <div v-else-if="option.value==='trip' && tripObject === null">Trip Not Found</div>

          </div>

        </div>
        <div class="flex-column flex-grow-1" v-if="!isNew && this.template">
          <label>Template</label>
          <FormTemplateAvatar :template="template"></FormTemplateAvatar>
        </div>
      </div>
      <div class="green-divider"></div>
      <div v-if="!isNew">
        <label class="">Workflow Progress</label>
        <WorkflowProgressWidget :steps="formSteps" :progress="workflowProgress" class="mt-3"></WorkflowProgressWidget>
        <div class="green-divider"></div>
      </div>
      <div class="template-container">
        <FormTemplate class="form-template"
                      v-if="template" :template="template" :form_data="formData" :disabled="!editMode"
                      @submit="saveForm" @change="formDataChange" ref="jsonForm"></FormTemplate>
      </div>

    </div>
    <div class="flex-row justify-content-end">
      <button class="danger" v-if="!isNew" @click="deleteForm">Delete</button>
      <button class="warning" v-if="!isNew && !editMode" @click="editMode = true">Edit</button>
      <button class="success" v-if="isReviewRequired" @click="markComplete">Mark Complete</button>
      <button class="warning" v-if="isDirty && !isNew" @click="resetForm">Reset</button>
      <button v-if="isDirty && !isNew" @click="saveForm" :disabled="disableSave">Save</button>
      <button v-if="isNew" @click="createForm" :disabled="disableCreate">Create Form</button>
    </div>
  </div>
</template>

<script>
import { DateTimeHelper as dt } from '@/components/helpers/DateTimeHelper'
import * as AlertHelper from '@/components/helpers/AlertHelper'
import * as DataProvider from '@/components/helpers/DataProvider'
import * as ErrorHelper from '@/components/helpers/ErrorHelper'
import FormTemplate from '@/components/forms/FormTemplate.vue'
import LoadingBox from '@/components/helpers/LoadingBox.vue'
import DeviceAvatar from '@/components/device/DeviceAvatar.vue'
import UserAvatar from '@/components/users/UserAvatar.vue'
import DeviceSelector from '@/components/shared/DeviceSelector.vue'
import WorkflowProgressWidget from '@/components/forms/WorkflowProgressWidget.vue'
import TripAvatar from '@/components/trips/TripAvatar.vue'
import FormTemplateAvatar from '@/components/forms/FormTemplateAvatar.vue'

export default {
  name: 'form-edit',
  components: {
    FormTemplateAvatar,
    TripAvatar, WorkflowProgressWidget, DeviceSelector, UserAvatar, DeviceAvatar, LoadingBox, FormTemplate},
  props: {
    disabled: Boolean,
    form_id: [String, Number],
    form: Object,
    device: Object,
    trip: Object,
    create_new: Boolean,
    related_types: Array,
    modal_id: String
  },
  data: function () {
    return {
      loading: true,
      formObject: null,
      tripObject: null,
      template: null,
      formData: null,
      editMode: false,
      savedData: null,
      isDirty: false,
      user: null,
      templates: [],
      templateId: null,
      deviceId: null,
      isNew: false,
      formErrors: [],
      formSteps: {
        new: 'New',
        review_required: 'Review Required',
        complete: 'Complete'
      },
      relatedTypeOptions: [
        {
          value: 'device',
          pickText: 'Select Device',
          showText: 'Device'
        },
        {
          value: 'trip',
          pickText: 'Selected Trip',
          showText: 'Trip'
        },
        {
          value: 'user',
          pickText: 'Select User',
          showText: 'User'
        },
      ]
    }
  },
  async mounted() {
    this.templates = await this.getTemplates()

    if (this.create_new) {
      this.isNew = true
      this.editMode = true
    } else if (this.form) {
      this.formObject = this.form
    } else if (this.form_id) {
      this.formObject = await this.getForm(this.form_id)
    }

    if (this.formObject) {
      this.loadFormData(this.formObject)

      // if this is a trip-related form and we weren't passed a trip, go get it.
      console.log('Trip: ', this.trip)
      if (this.formObject.related_type === 'trip' && !this.trip) {
        this.tripObject = await this.getRelatedTrip()
      }
      // Annoyingly, updating the template trigger it's change event
      this.$nextTick(() => { this.isDirty = false })
    }
    this.loading = false
  },
  methods: {
    loadFormData(formData) {
      this.template = formData.template
      this.formData = formData.data
      this.user = formData.owner
      this.savedData = {...formData.data}
    },
    async getForm(formId) {
      let resp = await DataProvider.getForm(formId)
      if (resp.success) {
        return resp.data.form
      } else {
        ErrorHelper.displayDataErrorToast(resp)
      }
    },
    async getTemplates() {
      let resp = await DataProvider.getTemplates()
      if (resp.success) {
        return resp.data.templates
      } else {
        ErrorHelper.displayDataErrorToast(resp)
      }
    },
    async getRelatedTrip() {
      let result = await DataProvider.getTrip(this.form.related_id)
      console.log('Trip Resulkt: ', result)
      if (result.success) {
        return result.data.trip
      } else {
        ErrorHelper.displayDataErrorToast(result)
      }
    },
    formDataChange(newData) {
      this.isDirty = true
      this.formErrors = newData.errors
      this.formData = newData.data
      console.log(this.formErrors)
    },
    resetForm() {
      this.formData = {...this.savedData}
      this.$nextTick(() => { this.isDirty = false })
    },
    async saveForm() {
      let resp = await DataProvider.updateForm(this.formObject.id, this.formData)
      if (resp.success) {
        AlertHelper.successToast('Form Updated Successfully.', 'Form Saved')
        this.isDirty = false
        this.formObject.data = this.formData
        this.$emit('changed', this.formObject)
      } else {
        ErrorHelper.displayDataErrorToast(resp)
      }
    },
    async createForm() {
      console.log(this.$refs.jsonForm)

      if (this.formErrors.length) {
        console.log('Form Error: ', this.formErrors)
        return
      }

      let resp = await DataProvider.createForm(this.templateId, 'new', this.relatedId, this.relatedType, this.formData)
      if (resp.success) {
        AlertHelper.successToast('Form Created Successfully.', 'Form Created')
        this.formObject = resp.data.form
        this.loadFormData(this.formObject)
        this.isDirty = false
        this.isNew = false
        this.$emit('changed', this.formObject)
        this.$emit('create', this.formObject)
      } else {
        ErrorHelper.displayDataErrorToast(resp)
      }
    },
    changeTemplate () {
      this.template = this.templates.find(t => t.template_id === this.templateId)
    },
    async deleteForm () {
      let confirm = await this.$bvModal.msgBoxConfirm('This action cannot be undone. ' +
          'Are you sure you want to DELETE this form?',
      {title: 'Really Delete Form?', okVariant: 'danger', okTitle: 'DELETE', centered: true})
      if (confirm) {
        let resp = await DataProvider.deleteForm(this.formObject.id)
        if (resp.success) {
          AlertHelper.successToast('Form Deleted Successfully.', 'Form Deleted')
          this.$emit('changed', this.formObject)
          this.$emit('delete', this.formObject)
          if (this.modal_id) {
            this.$bvModal.hide(this.modal_id)
          }
        } else {
          ErrorHelper.displayDataErrorToast(resp)
        }
      }
    },
    async markComplete() {
      let resp = await DataProvider.updateForm(this.formObject.id, null, 'complete')
      if (resp.success) {
        console.log(resp)
        this.formObject = resp.data.form
        this.loadFormData(this.formObject)
        this.isDirty = false
        AlertHelper.successToast('Form Updated Successfully.', 'Form Updated')
        this.$emit('changed', this.formObject)
      } else {
        ErrorHelper.displayDataErrorToast(resp)
      }
    }
  },
  computed: {
    templateOptions () {
      let defaultOptions = [
        {
          text: '<Select Template>',
          value: null
        }
      ]
      let templates = this.templates
      // If we've been passed a related_type prop, filter the available forms to that type only.
      if (this.related_types) {
        templates = templates.filter(t =>
          ((t.valid_related_types && this.related_types.some(type => t.valid_related_types.includes(type))) ||
            (t.valid_related_types === null && this.related_types.includes(null))
          ))
      }
      return defaultOptions.concat(templates.map((t) => { return { value: t.template_id, text: t.name } }))
    },
    relatedTypeControls () {
      console.log('Template: ', this.template)
      if (this.template && this.template.valid_related_types) {
        return this.relatedTypeOptions.filter(item => this.template.valid_related_types.includes(item.value))
      } else {
        return []
      }
    },
    hasRelatedType() {
      console.log('Has Type: ', !!(this.relatedType === 'device' && this.deviceId) || !!(this.relatedType === 'trip' && this.trip) || (this.relatedType === null))
      console.log('Related type: ', this.relatedType)
      return !!(this.relatedType === 'device' && this.deviceId) || !!(this.relatedType === 'trip' && this.trip) || (this.relatedType === null)
    },

    relatedId () {
      if (this.relatedType === 'device') {
        return this.deviceId || this.device.device_id
      } else if (this.relatedType === 'trip') {
        console.log('Reated ID (Trip): ', this.trip.id)
        return this.trip.id
      }
    },
    disableCreate () {
      return !this.template || !this.hasRelatedType
    },
    disableSave () {
      return this.formErrors.length > 0
    },
    isReviewRequired() {
      return this.formObject && Object.hasOwn(this.formObject, 'state') && this.formObject.state === 'review_required'
    },
    workflowProgress() {
      if (this.formObject && Object.hasOwn(this.formObject, 'state')) {
        return Object.keys(this.formSteps).indexOf(this.formObject.state)
      } else {
        return 0
      }
    },
    relatedType () {
      if (this.formObject) {
        return this.formObject.related_type
      } else if (!this.template) {
        return null
      } else if (this.template && this.template.valid_related_types) {
        console.log('Related Type: ', this.template.valid_related_types.split(',')[0])
        return this.template.valid_related_types.split(',')[0]
      } else {
        return null
      }
    }
  },
  watch: {
    form(newForm) {
      console.log('New Form: ', newForm)
      this.formObject = newForm
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/variables';
.event-avatar {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  text-align: center;
  justify-content: space-between;
  background: $theme-color-background-4;
  color: $theme-color-primary-3;
  border-radius: 5px;
  padding: 5px 5px;
  margin: 2px 0px;
  z-index: 0;
  user-select: none;
}

.event-icon {
  color: $text-color-invert;
  font-size: 2.1em;
  line-height: inherit;
  width: 1.2em;
  overflow: hidden;
}

.event-heading {
  flex-grow: 2;
  font-size: 1.3em
}

.event-timestamp {
  font-size: 0.8rem;
  font-style: italic;
}

.event-title-none {
  font-size: 1rem;
  font-style: italic;
  color:$theme-color-primary-3;
  margin-bottom: 10em;
}

.event-avatar:hover {
  color: white;
  background-color: rgba(0,0,0,0.15);
  box-shadow: 0 0 0 0.2rem rgba(56, 212, 41, 0.4);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.event-avatar.selected {
  background-color: rgba(50, 50, 50,0.25);
  box-shadow: 0 0 0 0.2rem rgba(45, 231, 28, 0.75);
}

.form-template {
  width: 80%;
  max-width: 600px;
  background: $theme-color-background-4;
  padding: 10px;
  border-radius: 10px;
}

.template-container {
  display: flex;
  flex-flow: column wrap;
  align-content: center;
}

</style>
