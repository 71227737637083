var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "device-trip-purpose-settings flex-row" },
    [
      _c("loading-box", { attrs: { loading: _vm.loading } }),
      _c("div", { staticClass: "flex-column" }, [
        _c("div", [
          _c(
            "div",
            { staticClass: "flex-column" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "flex-row w-100 justify-content-between align-items-start"
                },
                [
                  _c("div", { staticClass: "flex-column" }, [
                    _c(
                      "div",
                      { staticClass: "flex-column mt-2" },
                      [
                        _c("label", [_vm._v("Enable Trip Purpose Times")]),
                        _c(
                          "b-checkbox",
                          {
                            attrs: { switch: "" },
                            on: {
                              input: function($event) {
                                _vm.dirty = true
                              }
                            },
                            model: {
                              value: _vm.enablePurposeTimes,
                              callback: function($$v) {
                                _vm.enablePurposeTimes = $$v
                              },
                              expression: "enablePurposeTimes"
                            }
                          },
                          [_vm._v("Enabled")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "flex-column mt-2" },
                      [
                        _c("label", { staticClass: "green-label mt-1" }, [
                          _vm._v("Timezone:")
                        ]),
                        _c("b-select", {
                          staticClass: "text-input",
                          attrs: {
                            disabled: !_vm.enablePurposeTimes,
                            options: _vm.timezoneOptions,
                            required: ""
                          },
                          on: {
                            input: function($event) {
                              _vm.dirty = true
                            }
                          },
                          model: {
                            value: _vm.tripPurposeTimezone,
                            callback: function($$v) {
                              _vm.tripPurposeTimezone = $$v
                            },
                            expression: "tripPurposeTimezone"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._m(0)
                ]
              ),
              _c("WeekTimeSelector", {
                attrs: { disabled: !_vm.enablePurposeTimes, emit_on_load: "" },
                on: {
                  input: function($event) {
                    _vm.dirty = true
                  }
                },
                model: {
                  value: _vm.tripPurposePeriods,
                  callback: function($$v) {
                    _vm.tripPurposePeriods = $$v
                  },
                  expression: "tripPurposePeriods"
                }
              })
            ],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "footer" }, [
        _vm.modal
          ? _c(
              "button",
              {
                on: {
                  click: function($event) {
                    return _vm.$bvModal.hide(_vm.modal)
                  }
                }
              },
              [_vm._v("Close")]
            )
          : _vm._e(),
        _vm.dirty
          ? _c("button", { on: { click: _vm.saveSettings } }, [
              _vm._v("Save Changes")
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hint-box" }, [
      _c("h5", [_vm._v("Trip Purpose")]),
      _c("p", [
        _vm._v(
          " The 'purpose' of a trip can manually be set from the Device Trip Log or the Vehicle Trips screen for fleet users. This can be helpful when working out FBT and other accounting tasks. "
        )
      ]),
      _c("p", [
        _vm._v(
          " Enabling 'Trip Purpose Times' will cause trips that start within the times you set to be automatically flagged as the 'work' purpose and trips outside that time to be marked as 'personal'. "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }