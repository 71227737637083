<template>
  <div class="trips-control">
    <loading-box v-bind:loading="loading" v-if="!showPopout"></loading-box>
    <label>Trips</label>
    <div class="green-divider"></div>
    <button class="button w-100" @click="clickShowCurrent()" id="current-location">Go to Current Location</button>
    <button class="button w-100" @click="clickShowTripHistory()" id="route -history">Trip History</button>

    <!--    Popout Menu for Date Selection-->
    <PopoutMenu title="Trip History" v-model="showPopout" v-if="this.deviceImei">
      <loading-box :loading="loading"></loading-box>
      <label>Date Selection</label>
      <DateSelector class="px-2 w-100" v-model="selectedDateRange" :default_filter_mode="defaultDateMode"
                    :default_date="start_date"></DateSelector>
      <div class="green-divider"></div>
      <label>Route Highlighting</label>
      <div class="px-2 flex-column">
        <b-form-radio-group
          id="btn-radios-1"
          v-model="routeLineMode"
          :options="routeLineModeOptions"
          aria-describedby="Route Line Mode"
          name="radios-btn-default"
          buttons
          button-variant="success"
          @change="changeRouteLineMode"
        ></b-form-radio-group>
        <div class="w-100" v-if="routeLineMode==='idle'">
          <label>Idle Threshold: <span class="text-white">{{idleThresholdText}}s</span></label>
          <input type="range" class="idle-range-input" :min="minIdleTime" :max="maxIdleTime" v-model="idleThreshold"
                 @change="changeRouteLineMode" />
        </div>

      </div>

      <div class="green-divider"></div>
      <div class="flex-row slim-controls">
        <button @click="selectAll">All</button>
        <button @click="selectNone">Clear</button>
        <label>Auto Select</label>
        <input type="checkbox" v-model="selectOnLoad" @change="changeSelectOnLoad">
      </div>
      <div class="trips-list-container" ref="tripsListContainer">
        <ScrollTrigger @scroll_threshold="onScrollTrips" :threshold="loadThreshold"></ScrollTrigger>
        <TripList :trips="trips" :show_addresses="showAddresses" show_tags
                  ref="tripsListComponent" select_multiple :select_on_load="selectOnLoad"
                  @input="onTripSelect"></TripList>
      </div>
      <div class="flex-row slim-controls justify-content-between" v-if="maxPage > 1">
        <span class="trip-counter-text">Loaded Trips: {{ trips.length }}/{{ maxTrips }}</span>
      </div>
    </PopoutMenu>

    <!--    Screen Refresh Timer Take 2 -->
    <div class="map-button flex-column refresh-timer clickable animate-in fade-in delay-05"
         @click="clickToggleTimer">
      <div class="position-relative">
        <div class="progress-circle" :class="{'inactive': !updateTimerOn}"
             :style="refreshProgressVar"
        ></div>
      </div>

      <div class="refresh-timer-text">
        <i class="flex-row fa fa-refresh mr-1"></i> {{ updateTimerSeconds }}
      </div>
    </div>

    <!--    Route Legend-->
    <div class="map-button flex-row route-legend animate-in fade-in delay-05" v-if="selectedTrips.length > 0">
      <div class="flex-column justify-content-between legend-text-container">
        <div v-for="(label, idx) of legendLabels" v-bind:key="idx" class="mr-1">
          {{label}}
        </div>

      </div>
      <div class="legend-gradient" :style="legendVars">
      </div>
    </div>

    <div class="map-button direction-icon-button" @click="toggleDirectionIcons" id="toggle-direction-control"
         :class="{disabled: !useDirectionIcons}">
      <i class="fa fa-location-arrow" v-b-tooltip title="Toggle Direction Icons"></i>
    </div>

    <div class="selected-trip-summary-bar flex-row">
      <PopoverElement direction="top" :value="selectedTrips.length > 0">
        <div class="selected-trip-summary-inner flex-column">
          <h5 class="text-center">Selected Trips Statistics</h5>
          <div class="green-divider"></div>
          <div class="flex-row" v-if="selectedTrips.length">
            <div class="flex-row">
              <label># Trips: </label>
              {{ routeSummary.totalTrips }}
            </div>
            <div class="flex-row">
              <label>Distance: </label>
              {{ routeSummary.distance }}
            </div>
            <div class="flex-row">
              <label>Duration: </label>
              {{ routeSummary.duration }}
            </div>
            <div class="flex-row">
              <label>Fuel: </label>
              {{ routeSummary.fuelUsed }}
            </div>
            <div class="flex-row">
              <label>Avg Speed: </label>
              {{ routeSummary.averageSpeed }} Kph
            </div>
            <div class="flex-row">
              <label># Events: </label>
              {{ routeSummary.totalEvents }}
            </div>
          </div>
        </div>
        <div class="selected-trip-summary-close clickable" @click="selectNone">
          <i class="fa fa-times"></i>
        </div>
      </PopoverElement>
    </div>

    <!-- Individual Trips Controls-->
    <div class="selected-trip-edit-bar flex-row">
      <PopoverElement direction="bottom" :value="selectedTrips.length > 0" class="selected-trip-edit-inner flex-row">
        <div class="device-icon flex-column justify-content-center ">
          <img :src="deviceIcon">
        </div>
        <div class="flex-column ml-2 align-items-stretch">
          <div class="vertical-tab flex-grow-1" :class="{'active': popoutControlMode==='play'}"
            @click="popoutControlMode='play'"
          >
            <i :class="$config.icons.playback.play"></i>
          </div>
          <div class="vertical-tab flex-grow-1" :class="{'active': popoutControlMode==='edit'}"
               @click="popoutControlMode='edit'"
          >
            <i :class="$config.icons.general.edit"></i>
          </div>
        </div>
        <div v-if="popoutControlMode==='play'"
             class="flex-row flex-grow-1 flex-nowrap w-100 vertical-tab-body">
          <TripsPlaybackControl :device="device" :routeData="routeData" :routeMode="routeLineMode"></TripsPlaybackControl>

        </div>
        <div v-if="popoutControlMode==='edit' && selectedTrips.length === 1"
             class="flex-row align-items-stretch flex-grow-1 flex-nowrap w-100 vertical-tab-body">
          <div class="flex-column justify-content-between trip-notes flex-grow-0 p-1">
            <label class="label-underline">Work Use</label>
             <b-checkbox class="trip-purpose-checkbox" size="lg" v-model="selectedTrips[0].work_use" switch
                         @change="changeTripPurpose"></b-checkbox>
          </div>
          <div class="flex-column justify-content-between trip-notes flex-grow-1 p-1">
            <label class="label-underline">Trip Notes</label>
            <b-input-group class="flex-row w-100">
              <input v-model="tempNotes" placeholder="Enter Trip Notes" type="text" class="flex-grow-1"/>

              <b-input-group-append v-if="tripNotesDirty">
                <b-button variant="success" class="edit-notes-button" @click="saveTrip">
                  <i class="fa fa-check"></i>
                </b-button>
                <b-button variant="danger" class="edit-notes-button" @click="clearTempNotes">
                  <i class="fa fa-times"></i>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
          <div class="flex-column justify-content-between trip-forms flex-grow-1 p-1 align-content-xl-stretch"
               v-if="isFormsUser">
            <label class="text-nowrap label-underline">Attached Forms</label>
            <FormListInline v-model="selectedTrips[0]" :editable="enableEdit" @change="onFormChange"
                            class="flex-grow-1"></FormListInline>
          </div>
          <div class="flex-column justify-content-between trips-tags flex-grow-1 p-1">
            <label class="label-underline">Trip Tags</label>
            <b-input-group class="flex-row w-100">
              <TripTags :editable="enableEdit" v-model="tempTags"></TripTags>

              <b-input-group-append v-if="tripTagsDirty">
                <b-button variant="success" class="edit-notes-button" @click="saveTrip">
                  <i class="fa fa-check"></i>
                </b-button>
                <b-button variant="danger" class="edit-notes-button" @click="clearTempTags">
                  <i class="fa fa-times"></i>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>
        <div v-if="popoutControlMode === 'edit' && selectedTrips.length > 1"
             class="selected-trip-edit-inner flex-column vertical-tab-body align-content-xl-stretch flex-grow-1">
          <h6 class="text-center mr-3">Select a single Trip to Edit</h6>
        </div>
<!--        <div class="selected-trip-summary-close clickable" @click="selectNone">-->
<!--          <i class="fa fa-times"></i>-->
<!--        </div>-->
      </PopoverElement>
    </div>
  </div>
</template>

<script>

import * as DataProvider from '@/components/helpers/DataProvider'
import LoadingBox from '@/components/helpers/LoadingBox'
import * as ErrorHelper from '@/components/helpers/ErrorHelper'
import moment from 'moment'
import PopoutMenu from '@/components/shared/PopoutMenu.vue'
import {MetricFormatter as mf} from '@/components/helpers/MetricFormatter'
import {DateTimeHelper as dt} from '@/components/helpers/DateTimeHelper'
import TripList from '@/components/trips/TripList.vue'
import ScrollTrigger from '@/components/shared/ScrollTrigger.vue'
import PopoverElement from '@/components/shared/PopoverElement.vue'
import DateSelector from '@/components/shared/DateSelector.vue'
import TripTags from '@/components/trips/TripTags.vue'
import FormListInline from '@/components/forms/FromListInline.vue'
import * as AlertHelper from '@/components/helpers/AlertHelper'
import * as config from '@/config'
import * as IconHelper from '@/components/helpers/IconHelper'
import TripsPlaybackControl from '@/components/device/device_controls/TripPlayerbackControl.vue'

export default {
  name: 'trips-control',
  components: {
    TripsPlaybackControl,
    FormListInline, TripTags, DateSelector, PopoverElement, ScrollTrigger, TripList, PopoutMenu, LoadingBox},
  props: {
    device: Object,
    device_imei: String,
    enableEdit: Boolean,
    autoload_route: Boolean,
    event_id: [String, Number],
    start_date: [String, Object],
    end_date: [String, Object],
    trip_id: [String, Number]
  },
  data: function () {
    return {
      loading: true,
      deviceImei: null,
      showPopout: false,
      ignoreDateChanges: false, // Ignore Date Changes. Needed for some query param loads.
      routeData: [], // Locations to be displayed
      routeSummary: {
        averageSpeed: 0,
        distance: 0,
        duration: 0,
        parked: 0,
        fuelUsed: '0.00L',
        totalEvents: 0,
        totalTrips: 0,
        isFormsUser: false
      },
      mouseDownState: false,
      updateProgressValue: 0,
      updateTimerSeconds: 30,
      updateTimerOn: true,
      updateInterval: null,
      mapMarkers: [],
      trips: [], // Trip Objects
      tripClusterIconOptions: {
        iconType: 'font',
        icon: 'fa fa-cog',
        labelSuffix: ' events'
      },
      useDirectionIcons: false,
      defaultDateMode: 'today',
      selectedDateRange: null,
      // Data Page tracking
      currentPage: 1,
      maxPage: 1,
      maxTrips: 0,
      pageSize: null,
      //
      loadThreshold: 100, // Trigger Threshold for the Scroll Event Comp
      selectOnLoad: true, // Toggle automatically displaying trips when they are loaded.
      selectedTrips: [],  // Currently selected Trips
      tempNotes: null,
      tempTags: null,
      hasAddressesRole: false,
      showParkedLocations: false,
      routeLineModeOptions: [
        {
          text: 'Time',
          value: 'time'
        },
        {
          text: 'Speed',
          value: 'speed'
        },
        {
          text: 'Idle',
          value: 'idle'
        },
        // {
        //   text: 'State',
        //   value: 'state'
        // }
      ],
      routeLineMode: 'time',
      popoutControlMode: 'play',
      legends: {
        'time': {
          'labels': ['Start', 'End'],
          'cssVars': '--legend-gradient: linear-gradient(#06c517, #1ea0cb, #0022a9, #6d17da);'
        },
        'speed': {
          'labels': ['0 km/h', '60', '80', '120'],
          'cssVars': '--legend-gradient: linear-gradient(#215ad2, #05b900, #ffbb2c, #ec0000);'
        },
        'idle': {
          'labels': ['Idle', 'Moving'],
          'cssVars': '--legend-gradient: linear-gradient(#ff6f1c, #159a00);'
        },
      },
      minIdleTime: 20,
      maxIdleTime: 600,
      idleThreshold: 60
    }
  },
  async mounted() {
    this.isFormsUser = await this.$auth.roleIn(config.roles.forms)
    this.hasAddressesRole = await this.$auth.hasRole('trip_addresses')
    // Setup the clustering to show events rather than vehicles!
    await this.$maps.setClusterRendererOptions(this.tripClusterIconOptions)
    window.addEventListener('mousedown', this.onMouseDown)
    window.addEventListener('mouseup', this.onMouseUp)
    await this.loadUserSettings()
    // Parse Device Props
    if (this.device) {
      this.deviceImei = this.device.device_imei
    } else if (this.device_imei) {
      this.deviceImei = this.device_imei
    } else {
      console.error('TripsControl did not receive a device IMEI!')
    }

    // The next section handles various query parameters that can be passed by the parent.
    // Start Date (end_date and trip_id) sets the controls to a given date and selects a specific trip.
    if (this.start_date) {
      this.ignoreDateChanges = true
      // Setting a date range will prevent the DateRangeSelector from emitting a date the way it normally would.
      this.selectedDateRange = {
        dateMode: 'custom',
        start: this.start_date,
        end: this.end_date
      }
      this.showPopout = true
      await this.getTrips(this.start_date, this.end_date)
      // TODO - Make Trip ID selection work without setting dates.(Needs a backend change to add get trip by id to the API)
      if (this.trip_id) {
        this.$nextTick(() => {
          this.$refs.tripsListComponent.selectByTripId(this.trip_id)
          this.ignoreDateChanges = false
        })
      }
      // event_id fetches the trip which corresponds to the specified event and displays that trip only.
    } else if (this.event_id) {
      await this.getEvent(this.event_id)
      // Otherwise load the current location, the DateRangeSelector should also emit a date that causes today's trips
      // to be loaded automatically.
    }
    if (!this.selectedTrips.length && this.deviceImei) {
      await this.getCurrentLocation()
      this.resumeUpdateTimer()
    }
    this.ignoreDateChanges = false
    this.loading = false
  },
  beforeDestroy() {
    this.$maps.setClusterRendererOptions()
    window.removeEventListener('mousedown', this.onMouseDown)
    window.removeEventListener('mouseup', this.onMouseUp)
    this.clearUpdateTimer()
  },
  methods: {
    async changeRouteLineMode() {
      this.loading = true
      console.log(this.selectedTrips)
      await this.onTripSelect(this.selectedTrips)
      this.loading = false
    },
    async changeSelectOnLoad() {
      await this.$auth.setUserSetting('trip_control_select_on_load', this.selectOnLoad)
    },
    selectAll() {
      this.$refs.tripsListComponent.selectAll()
    },
    selectNone() {
      this.$refs.tripsListComponent.selectNone()
      this.selectedTrips = []
    },
    async loadUserSettings() {
      // TODO - Make a neater way to load/save user settings
      this.showParkedLocations = await this.$auth.getUserSetting('showParkedLocations', false)
      this.selectOnLoad = await this.$auth.getUserSetting('trip_control_select_on_load')
      this.useDirectionIcons = await this.$auth.getUserSetting('deviceListUseDirectionIcons')
    },
    onMouseDown: function () {
      this.mouseDownState = true
    },
    onMouseUp: function () {
      this.mouseDownState = false
    },
    /****
     * Store New Trips, discarding those which aren't appropriate for display.
     * @param tripData
     * @param appendData
     */
    loadTripData: function (tripData, appendData = false) {
      this.loading = true
      let filteredTrips = this.filterTrips(tripData.trips)
      if (appendData) {
        this.trips = this.trips.concat(filteredTrips)
      } else {
        this.trips = filteredTrips
      }
      this.loading = false
    },
    /****
     * Load Route/Location data from the provided trips and display it on the map.
     * @param selectedTrips {Array[Object]} Array of Trips to be displayed.
     * @param appendData {Boolean} If true the existing route data will be added to, rather than replaced.
     */
    loadRouteData(selectedTrips, appendData = false) {
      let routeTrips = selectedTrips.filter(t => t.state === 'MOVING')
      let parkedTrips = selectedTrips.filter(t => t.state === 'PARKED')
      console.log('Show Parked? ', this.showParkedLocations)
      if (this.showParkedLocations) {
        routeTrips = selectedTrips
      } else {
        parkedTrips.forEach(parkedTrip => {
          let startPos = parkedTrip.trip_data[0]
          let title = `Parked<br> ${dt.timestampToLocalTime(parkedTrip.start_time)} to ${dt.timestampToLocalTime(parkedTrip.end_time)}`
          let popupContent = `<div class="map-infobox-col">` +
            `<div> Location Timestamp: ${dt.timestampToLocalTime(parkedTrip.trip_data[0].timestamp)}</div>` +
            `</div>`

          this.createMarker(startPos.latitude, startPos.longitude, startPos.timestamp, title, null, popupContent)
        })
      }
      let newRouteData = routeTrips.reduce((accData, currentTrip) => accData.concat(currentTrip.trip_data), [])

      // How long stationary before it's considered an idle period?
      let idleThresholdTime = this.idleThreshold
      let idleThresholdSpeed = 5
      let tempIdlePositions = [] // Store a run of idle positions here until we reach a non-idle position

      for (let i = 1; i < newRouteData.length; i++) {
        let position = newRouteData[i]
        position.idleTime = 0
        let prevPosition = newRouteData[i - 1]
        let duration = position.timestamp - prevPosition.timestamp // in seconds
        // Speed for each position is a point-in-time reading and isn't trustworthy when going very slowly.
        let distance = this.$maps.haversineDistance({
          lat: position.latitude,
          lng: position.longitude,
        }, {
          lat: prevPosition.latitude,
          lng: prevPosition.longitude
        })
        if (distance > 0 && duration > 0) {
          position.idleSpeed = distance / (duration / 3600) // kph
        } else {
          position.idleSpeed = 0
        }

        // If the engine is on and we're going slow enough, mark this position as idle.
        if (position.data.acc_on && position.idleSpeed <= idleThresholdSpeed && prevPosition.data.acc_on === true) {
          position.idleTime = Math.max(duration, 1) // So it's true for zero time positions
          tempIdlePositions.push(i)
        } else if (tempIdlePositions.length) {
          // This position ISN'T an idle position, so check our temp store of idle position to see if there are enough
          // to consider an idle event
          let totalIdleTime = tempIdlePositions.reduce((acc, curIdx) => acc += newRouteData[curIdx].idleTime, 0)

          if (totalIdleTime < idleThresholdTime) {
            // If the total idle streak is too short, remove all the time time for each location
            tempIdlePositions.forEach((pIdx) => { newRouteData[pIdx].idleTime = 0})
          }
          tempIdlePositions = []
        }
      }


      if (appendData) {
        this.routeData = this.routeData.concat(newRouteData)
      } else {
        this.routeData = newRouteData
      }
      if (selectedTrips.length) {
        this.routeSummary = this.calculateStats(selectedTrips)
      }
    },
    /***
     * Filter our trips which should not be shown
     * @param tripsData
     */
    filterTrips: function (tripsData) {
      return tripsData // tripsData.filter(x => x.time_taken > 0 || x.kms_travelled > 0)
    },
    /***
     * Calculate Total Stats for the Trips selected.
     * @param rawTrips
     * @returns {{duration: string, parked: string, distance: string, averageSpeed: string}}
     */
    calculateStats: function (rawTrips) {
      // Most of our stats are based on moving trips (except 'Parked', which we possibly should remove)
      let trips = rawTrips.filter(trip => trip.state === 'MOVING')
      // Dump all the data points into a single array for processing
      let data = trips.reduce((accData, currentTrip) => accData.concat(currentTrip.trip_data), [])
      let routeSummary = {
        averageSpeed: parseInt(data.reduce((sum, loc) => sum + loc.data.speed, 0) / data.length, 0).toString(),
        distance: mf.kmsFormat(trips.reduce((sum, trip) => sum + trip.kms_travelled, 0)).toString(),
        duration: this.formatDuration(trips.reduce((sum, trip) => sum + trip.time_taken, 0)),
        parked: this.formatDuration( // Filter to parked events then sum the time_taken
          rawTrips.filter(x => x.state === 'PARKED').reduce((sum, trip) => sum + trip.time_taken, 0)
        ),
        fuelUsed: mf.fuelFormat(trips.reduce((sum, trip) => sum + trip.fuel_consumed, 0)),
        totalTrips: rawTrips.length,
        totalEvents: trips.reduce((sum, trip) => sum + trip.events.length, 0)
      }
      if (isNaN(routeSummary.averageSpeed)) {
        routeSummary.averageSpeed = 0
      }
      return routeSummary
    },
    /*****
     * Fetch a list of trips from the server based on the provided parameters
     * @param startDate {Date} Start Date for the Trips
     * @param endDate {Date} End Date for the Trips
     * @param pageSize {Number} Max number of trips to return
     * @param offset {Number} Offset/Start for the query (for pagination)
     * @return {Promise<void>}
     */
    getTrips: async function (startDate, endDate, pageSize = null, offset = null) {
      console.log('Load Route History: ', pageSize, offset)
      this.loading = true
      // TODO - Query times must be in UTC... maybe add conversion to DataProvider?
      let startUTC = moment(moment(startDate).format('YYYY-MM-DD') + ' 00:00').utc()
      let endUTC = moment(moment(endDate).format('YYYY-MM-DD') + ' 23:59').utc()

      let routeData = await DataProvider.getDeviceTrips(this.deviceImei, startUTC, endUTC, null,
        true, pageSize, offset, true, true)

      if (!routeData.success) {
        this.loading = false
        this.$bvToast.toast(routeData.error.action,
          {
            title: routeData.error.message,
            variant: 'danger',
            toaster: 'b-toaster-top-center'
          })
        return
      }
      if (Object.hasOwn(routeData.data, 'total_trips')) {
        this.maxPage = Math.ceil(routeData.data.total_trips / this.pageSize)
        this.maxTrips = routeData.data.total_trips
      }
      this.loadTripData(routeData.data, !!pageSize && this.currentPage > 1)
    },
    /***
     * Fetch the Device's current location and display it on the map.
     * @return {Promise<void>}
     */
    getCurrentLocation: async function () {
      this.loading = true
      // this.clearMapMarkers()
      let locationData = await DataProvider.getDeviceLocation(this.deviceImei)
      if (!locationData.success) {
        ErrorHelper.displayDataErrorToast(locationData)
        return
      }
      this.currentPos = {
        lat: locationData.data.latitude,
        lng: locationData.data.longitude,
        speed: locationData.data.speed,
        timestamp: locationData.data.timestamp,
        streetAddress: locationData.data.location_address
      }
      console.log('Location Data: ', locationData)
      if (Object.hasOwn(locationData.data.data, 'direction')) {
        this.currentPos.direction = locationData.data.data.direction
      }
      if (Object.hasOwn(locationData.data.data, 'acc_on')) {
        this.device.acc_on = locationData.data.data.acc_on
      }
      this.updateLocationMarker()
      this.loading = false
    },
    /***
     * Download an event and display the resulting event on the map.  Also triggers loading related Trips and locations.
     * @param eventId {Number} Event ID to be loaded
     * @return {Promise<void>}
     */
    getEvent: async function (eventId) {
      let event = await this.$eventService.getEventById(eventId)
      let markerAdded = false
      let icon = 'fa fa-info-circle'
      if (event.hasOwnProperty('event_link_options')) {
        // Custom Icon config for Event Locations
        if (event.event_link_options.hasOwnProperty('location_icon')) {
          icon = event.event_link_options.location_icon
        }
        // Show Trips that have a timestamp inside the events
        if (event.event_link_options.hasOwnProperty('show_trips') && event.event_link_options.show_trips === true) {
          // Get Trip data for this event and load it up
          let res = await DataProvider.getEventTrip(eventId)
          if (res.success) {
            await this.loadTripData(res.data)
            await this.onTripSelect(res.data.trips)
            markerAdded = true
          }
        }
      }
      let position
      // if the event has location data we can display an icon in that location
      if (event.event_data.hasOwnProperty('location')) {
        // If we haven't drawn the marker (from show_trips) then draw it now.
        position = {
          lat: event.event_data.location.latitude,
          lng: event.event_data.location.longitude
        }
      }
      if (event.event_data.hasOwnProperty('latitude') && event.event_data.hasOwnProperty('longitude')) {
        position = {
          lat: event.event_data.latitude,
          lng: event.event_data.longitude
        }
      }
      if (position) {
        // If the marker hasn't been implicitly loaded when we loaded trip data, do it now.
        if (!markerAdded) {
          let marker = await this.getEventMarker(event)
          this.addMapMarkers([marker])
        } else {
          // This is setup so when an event is selected we load the trip, then pan to the event and zoom in.
          // Google maps will only do an animated zoom if the zoom-level is within 2 though (for performance reasons)
          setTimeout(() => {
            this.$maps.setCenter(position.lat, position.lng, true)
          }, 150)
          setTimeout(() => {
            this.$maps.setZoomLevel(this.$config.general.defaultZoomLevel + 2)
          }, 500)
        }
      } else {
        this.selectedEvent = event
        // await this.clickShowCurrent()
        this.$bvModal.show('add-event-modal')
      }
    },
    /***
     * Draw a Vehicle Map Marker for the current vehicle locations (currentPos)
     */
    updateLocationMarker: function () {
      let timestampString = moment.unix(this.currentPos.timestamp).local().format('HH:mm DD-MM-YYYY')
      let popupContent = `<div class="map-infobox-col">` +
        `<div> No Stats for Today Yet... </div>` +
        `<div> Last Update: ${timestampString}</div>` +
        `</div>`
      if (this.routeSummary) {
        popupContent = `<div class="map-infobox-col">` +
          `<div class="map-infobox-row">` +
          `<div class="map-infobox-headings">Current Speed:</div>` +
          `<div class="map-infobox-data mr-1">${this.currentPos.speed}</div>` +
          `</div>` +
          `<div class="map-infobox-row">` +
          `<div class="map-infobox-headings">Avg Speed:</div>` +
          `<div class="map-infobox-data mr-1">${this.routeSummary.averageSpeed}km/h</div>` +
          `</div>` +
          `<div class="map-infobox-row">` +
          `<div class="map-infobox-headings">Distance:</div>` +
          `<div class="map-infobox-data mr-1">${this.routeSummary.distance}km</div>` +
          `</div>` +
          `<div class="map-infobox-row">` +
          `<div class="map-infobox-headings">Fuel Used:</div>` +
          `<div class="map-infobox-data mr-1">${this.routeSummary.fuelUsed}</div>` +
          `</div>`

        if (this.currentPos.streetAddress) {
          popupContent = popupContent + `<div class="map-infobox-row">` +
            `<div class="map-infobox-headings">Street Address:</div>` +
            `<div class="map-infobox-data mr-1">${this.currentPos.streetAddress.data.address_text}</div>` +
            `</div>`
        }

        popupContent = popupContent + `<div class="map-infobox-row">` +
          `<div class="map-infobox-headings">Last Update:</div>` +
          `<div class="map-infobox-data mr-1">${timestampString}</div>` +
          `</div>` +
          `</div>`
      }

      let marker = {
        id: 'current_position',
        title: this.device.name,
        position: this.currentPos,
        popup: popupContent
      }

      if (this.useDirectionIcons && Object.hasOwn(this.currentPos, 'direction')) {
        marker.direction_arrow = this.device.acc_on ? 'arrowCircleOn' : 'arrowCircle'
        marker.direction_arrow_colour = this.device.acc_on ? 'rgb(5,192,5)' : 'rgba(255,255,255, 1)'
        marker.position.direction = this.currentPos.direction
      } else {
        marker.icon = this.device.icon
      }
      // this.$maps.setClusterRendererOptions(this.tripClusterIconOptions)
      this.updateMapMarker(marker)
    },
    createMarker(lat, lng, timestamp, title, icon = null, popupContent = null, replaceExisting = false) {
      if (popupContent === null) {
        let timestampString = moment.unix(timestamp).local().format('HH:mm DD-MM-YYYY')
        popupContent = `<div class="map-infobox-col">` +
          `<div> Last Update: ${timestampString}</div>` +
          `</div>`
      }

      let marker = {
        id: 'current_position',
        title: title || this.device.name,
        position: {
          lat: lat,
          lng: lng
        },
        popup: popupContent,
        icon: icon || this.device.icon
      }

      // this.$maps.setClusterRendererOptions(this.tripClusterIconOptions)
      if (replaceExisting) {
        this.mapMarkers = [marker]
      } else {
        this.mapMarkers.push(marker)
      }
      this.updateMapMarkers()
    },
    async clearMapMarkers(autozoom = true) {
      this.mapMarkers = []
      this.updateMapMarkers(autozoom)
      await this.$maps.clearOverlays()
      // await this.$maps.setClusterRendererOptions(this.tripClusterIconOptions)
    },
    addMapMarkers: function (markers, autoZoom = true) {
      markers.forEach((item) => this.mapMarkers.push(item))
      this.updateMapMarkers(autoZoom)
    },
    updateMapMarker: function (marker) {
      let oldMarkerIdx = this.mapMarkers.findIndex((i) => marker.id === i.id)
      if (oldMarkerIdx !== -1) {
        this.mapMarkers[oldMarkerIdx] = marker
      } else {
        this.mapMarkers.push(marker)
      }
      this.updateMapMarkers()
    },
    updateMapMarkers: function (autoZoom = true) {
      this.$maps.updateMarkers(this.mapMarkers, true, autoZoom)
    },
    /****
     * Load Event data to be displayed on the map.
     * Takes an Array of Event objects and displays them on the map.
     * @param events {Array[Object]} Array of Event Objects
     * @param auto_zoom {Boolean} Determines if the map viewport will be resized when the operation is over.
     * @return {Promise<void>}
     */
    loadEventsData: async function (events, auto_zoom = false) {
      let markers = []
      for (let event of events) {
        let marker = await this.getEventMarker(event)
        if (marker) {
          markers.push(marker)
        }
      }
      this.addMapMarkers(markers, auto_zoom)
    },
    /****
     * Generate a Marker based on event data.
     * @param event
     * @return {Promise<{awesome_icon: string, onClick: marker.onClick, id: string, position: {lng: (number|null|number|*), lat: (number|null|number|*)}, title: string}|null>}
     */
    getEventMarker: async function (event) {
      let icon = 'fa fa-info-circle'
      let config = await this.$eventService.getEventTypeConfig(event.event_type)
      if (config.hasOwnProperty('event_link_options')) {
        // Custom Icon config for Event Locations
        if (config.event_link_options.hasOwnProperty('location_icon')) {
          icon = config.event_link_options.location_icon
        }
      }
      let position
      // if the event has location data we can display an icon in that location
      if (event.event_data.hasOwnProperty('location')) {
        // If we haven't drawn the marker (from show_trips) then draw it now.
        position = {
          lat: event.event_data.location.latitude,
          lng: event.event_data.location.longitude
        }
      }
      if (event.event_data.hasOwnProperty('latitude') && event.event_data.hasOwnProperty('longitude')) {
        position = {
          lat: event.event_data.latitude,
          lng: event.event_data.longitude
        }
      }
      // if the event has location data we can display an icon in that location
      if (position) {
        let marker = {
          id: `event_${event.event_id}`,
          title: `${config.name}`,
          position: position,
          // popup: popupContent,
          awesome_icon: icon,
          onClick: () => {
            this.$emit('select-event', event)
            // this.selectedEvent = event
            // this.$bvModal.show('add-event-modal')
          }
        }
        return marker
      } else {
        return null
      }
    },
    /*****
     * Click Handler for 'Show Current Location' button.
     * Also called by some of hte other event handler if they need to go back to the default view state.
     * @return {Promise<void>}
     */
    clickShowCurrent: async function () {
      this.loading = true
      await this.clearMapMarkers()
      await this.getCurrentLocation()
      this.setUpdateTimer(this.$config.general.deviceUpdateDelay)
      await this.$maps.setViewport(this.currentPos.lat, this.currentPos.lng, this.$config.general.defaultZoomLevel)
      this.$emit('change', true)
      this.loading = false
    },
    /****
     * Click Event Handler for the 'Trip History' Button.
     * @return {Promise<void>}
     */
    clickShowTripHistory: async function () {
      this.clearUpdateTimer()
      this.togglePopoutMenu()
    },
    /****
     * Toggles the Popout Menu
     */
    togglePopoutMenu: function () {
      this.showPopout = !this.showPopout
      this.showEvents = false
    },
    /*****
     *  Trip Selection Event Handler.
     *
     *   Called when the selected trips in the Trip List changes.
     * @param trips
     * @return {Promise<void>}
     */
    onTripSelect: async function (trips) {
      // this.loadTripData({trips}, this.currentPage > 1)
      console.log('Selected ' + trips.length + ' Trips.')
      if (trips.length) {
        await this.$maps.clearOverlays()
        this.clearUpdateTimer()
        this.mapMarkers = []
        this.loadRouteData(trips, this.currentPage > 1 && trips.length)
        let overspeed = this.device.overspeed_limit ? this.device.overspeed_limit : 0
        console.log('New Route Data: ', this.routeData)
        this.selectedTrips = trips
        if (trips.length && this.routeData.length) {
          if (this.selectedTrips.length === 1) {
            this.routeData.opts = {
              showStartMarker: true,
              showEndMarker: true
            }
          } else {
            this.routeData.opts = {}
          }
          this.routeData.opts.lineMode = this.routeLineMode

          this.updateMapMarkers(false)
          let eventData = this.selectedTrips.reduce((sum, trip) => sum.concat(trip.events), [])
          await this.loadEventsData(eventData, false)
          await this.$maps.updateRoute(this.routeData, overspeed)
        }
        if (trips.length === 1) {
          this.tempNotes = this.selectedTrips[0].annotation
          this.tempTags = this.selectedTrips[0].tags
        }
      } else {
        await this.$maps.clearOverlays()
        this.clickShowCurrent()
      }
      this.loading = false
    },
    // Set the update Timer which handles automatic location updates
    setUpdateTimer: function (updateTicks) {
      this.updateTimerOn = true
      clearInterval(this.updateInterval)
      this.updateInterval = setTimeout(() => {
        this.tickUpdateTimer(0, updateTicks)
      }, 1000)
    },
    resumeUpdateTimer: function () {
      this.updateTimerOn = true
      let max
      if (this.livetrackState) {
        max = 5
      } else {
        max = 30
      }
      this.updateInterval = setTimeout(() => {
        this.tickUpdateTimer(max - this.updateTimerSeconds, max)
      }, 1000)
    },
    tickUpdateTimer: function (tick, maxTicks) {
      this.updateTimerSeconds = maxTicks - tick
      if (tick === maxTicks) {
        if (!this.mouseDownState) {
          this.getCurrentLocation().then(() => {
            if (this.currentPos) {
              this.$maps.setViewport(this.currentPos.lat, this.currentPos.lng, this.$config.general.defaultZoomLevel)
            }
            // this.$emit('view-change', { zoom: this.$config.general.defaultZoomLevel, lat: this.currentPos.lat, lng: this.currentPos.lng })
          })
        }
        tick = 0
      }
      tick++
      this.updateProgressValue = Math.round((tick / maxTicks) * 100)
      this.updateInterval = setTimeout(() => {
        this.tickUpdateTimer(tick, maxTicks)
      }, 1000)
    },
    // Stop/Clear the Update Timer
    clearUpdateTimer: function () {
      this.updateTimerOn = false
      clearTimeout(this.updateInterval)
    },
    clickToggleTimer: function () {
      if (this.updateTimerOn) {
        this.clearUpdateTimer()
      } else {
        this.resumeUpdateTimer()
      }
    },
    formatTimestamp: function (timestamp) {
      return moment.unix(timestamp).local().format('HH:mm')
    },
    formatDuration: function (timestamp) {
      return moment.unix(timestamp).utc().format('HH:mm')
    },
    formatKmh: function (value) {
      return Math.round(value) + ' km/h'
    },
    formatKm: function (value) {
      return Math.round(value) + ' km'
    },
    formatFuel: function (value) {
      return (value * 0.01).toFixed(2) + 'l'
    },
    async toggleDirectionIcons() {
      this.useDirectionIcons = !this.useDirectionIcons
      // The API endpoint for changing user settings is non-destructive so we can send just the single prop to change
      let resp = await DataProvider.setUserSettings({deviceListUseDirectionIcons: this.useDirectionIcons})
      if (!resp.success) {
        console.error('Failed to save user settings!')
      } else {
        this.$auth.expireUserData()
      }
      this.updateLocationMarker()
    },
    async onScrollTrips() {
      if (!this.loading && this.currentPage < this.maxPage) {
        console.log('Loading more Trips...')
        let offset = this.currentPage * this.pageSize
        this.currentPage += 1
        await this.getTrips(this.selectedDateRange.start, this.selectedDateRange.end, this.pageSize, offset)
      }
    },
    async loadAllForDate() {
      await this.getTrips(this.selectedDateRange.start, this.selectedDateRange.end, null, 0)
      this.currentPage = this.maxPage
    },
    onFormChange(data) {
      console.log('data', data)
    },
    clearTempNotes() {
      this.tempNotes = this.selectedTrips[0].annotation
    },
    clearTempTags() {
      this.tempTags = this.selectedTrips[0].tags
    },
    changeTripPurpose(val) {
      this.selectedTrips[0].work_use = val
      this.saveTrip()
    },
    async saveTrip() {
      this.selectedTrips[0].annotation = this.tempNotes
      this.selectedTrips[0].tags = this.tempTags
      let result = await DataProvider.updateDeviceTrips(this.device.device_imei, [this.selectedTrips[0]])
      if (result.success) {
        AlertHelper.successToast('Trip Updated Successfully.', 'Trip Changes Saved.')
      } else {
        ErrorHelper.displayDataErrorToast(result)
      }
    }
  },
  computed: {
    showAddresses() {
      return !!this.hasAddressesRole
    },
    tripNotesDirty() {
      return this.selectedTrips.length === 1 && this.tempNotes !== this.selectedTrips[0].annotation
    },
    tripTagsDirty() {
      return this.selectedTrips.length === 1 && this.tempTags !== this.selectedTrips[0].tags
    },
    deviceIcon() {
      return IconHelper.getIcon(this.device.icon)
    },
    refreshProgressVar () {
      return `--refresh-progress: ${this.updateProgressValue}%; --refresh-rotation: ${(this.updateProgressValue * 3.6) - 80}deg;`
    },
    legendVars () {
      return this.legends[this.routeLineMode].cssVars
    },
    legendLabels () {
      return this.legends[this.routeLineMode].labels
    },
    idleThresholdText () {
      return dt.secondsToTime(this.idleThreshold, 'mm:ss')
    }
  },
  watch: {
    device_imei: async function (newVal) {
      if (!this.deviceImei) {
        this.deviceImei = newVal
        if (this.autoload_route && this.deviceImei) {
          await this.getCurrentLocation()
          // await this.loadTodaysRoute()
        }
      }
    },
    device: async function (newVal) {
      if (!this.device) {
        this.device = newVal
        this.deviceImei = this.device.device_imei
        if (this.autoload_route && this.deviceImei) {
          await this.getCurrentLocation()
          // await this.loadTodaysRoute()
        }
      }
    },
    event_id: async function (newEventId) {
      await this.clearMapMarkers(false)
      await this.getEvent(newEventId)
    },
    trip_data: function (newTripData) {
      this.loadTripData(newTripData)
      this.onTripSelect(newTripData.data.trips)
    },
    selectedDateRange(newVal) {
      console.log('New Date Selection: ', newVal)
      if (!this.ignoreDateChanges && Object.hasOwn(newVal, 'start') && Object.hasOwn(newVal, 'end')) {
        let start = moment(newVal.start).format('YYYY-MM-DD')
        console.log('New Start: ', start)
        history.replaceState({}, null, this.$route.path + '?start_date=' + start)
        // let newQuery = {...this.$route.query, start}
        //
        // this.$router.replace({ query: newQuery })
        this.clearUpdateTimer()
        this.$refs.tripsListContainer.scrollTo(0, 0)
        this.currentPage = 1
        this.maxPage = 1
        this.getTrips(newVal.start, newVal.end)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '@/variables';
@import '@/animations';

.trips-control {
  margin-top: 0;
  margin-bottom: 1rem;
  text-align: left;
}

.refresh-timer {
  position: fixed;
  top: 85px;
  left: 0;
  width: auto;
  height: auto;
  align-items: stretch;
  color: black;
  font-size: 0.85em;
  text-align: center;
  padding: 5px;
  //line-height: 1.1;
  z-index: 10;
}

.progress-circle {
  position: relative;
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  opacity: 1;
  background: conic-gradient(transparent var(--refresh-progress), white 0);
  transition: --refresh-progress 1s linear;
}

.progress-circle::after {
  // Progress Icon
  content: "\f01e";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(var(--refresh-rotation));
  transition: transform 1s linear;
  font: normal normal normal 14px / 1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-size: 44px;
  z-index: -1;
}

.progress-circle.inactive {
  color: rgba(0, 0, 0, 0.25);
  z-index: 1;
}

.refresh-timer-text {
  display: flex;
  font-size: 1rem;
  color: black;
  position: relative;
  border-top: 1px solid #c2c2c2;
  justify-content: center;
  font-weight: 600;
}

.refresh-timer-hint {
  font-size: 2rem;
  color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 22px;
  width: 75px;
}

.route-legend {
  position: fixed;
  bottom: 25px;
  left: 0;
  width: auto;
  height: auto;
  align-items: stretch;
  color: black;
  font-size: 0.85em;
  text-align: center;
  padding: 5px;
  //line-height: 1.1;
  z-index: 10;
}

.legend-gradient {
  width: 15px;
  height: 150px;
  background: var(--legend-gradient);
}

.trips-list-container {
  overflow: auto;
}

.trip-counter-text {
  margin-left: 1em;
  color: white;
  font-weight: 600;
}

/****
  Styling for the Top Popout Stats Box
 */
.selected-trip-summary-bar {
  position: fixed;
  top: 0;
  left: 0;
  height: 0;
  width: calc(100% - 310px);
  background: transparent;
  z-index: 10;
  justify-content: center;
}

.selected-trip-summary-inner {
  padding: 10px 10px;
  background: $theme-color-background-3;
  color: white;
  border-radius: 0 0 10px 10px;
  align-items: stretch;

  h5 {
    margin-bottom: 0;
  }

  .green-divider {
    margin: 2px;
  }
}

.selected-trip-summary-close {
  position: absolute;
  right: 10px;
  top: 0;
  font-size: 1.2em;
  color: $theme-color-primary-3
}

.selected-trip-summary-close:hover {
  color: white;
}

/****
    Styling for the Bottom Popout Edit Box
   */
.selected-trip-edit-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 0;
  width: 100%; // calc(100% - 310px);
  max-width: calc(100vw - 360px);
  background: transparent;
  z-index: 5;
  justify-content: center;
}

.selected-trip-edit-inner {
  padding: 2px;
  min-width: 80%;
  background: $theme-color-background-3;
  color: white;
  border-radius: 10px 10px 0 0;
  align-items: stretch;
  flex-wrap: nowrap;

  h5, h6 {
    margin-bottom: 0;
  }

  .green-divider {
    margin: 2px;
  }

  .trip-forms {
    flex-grow: 1;
    //min-width: 150px;
    //max-width: calc(40vw - 360px);
  }

  .trip-tags {
    flex-grow: 1;
    //min-width: 150px;
    //max-width: calc(32vw - 360px);
  }

  .trip-notes {
    flex-grow: 1;
    //min-width: 150px;
    //max-width: calc(32vw - 360px);
  }

  .label-underline {
    width: 100%;
    border-bottom: 1px solid green;
    padding-bottom: 0;
    margin-bottom: 5px;
  }
}

.edit-notes-button {
  i {
    font-size: 1em;
  }
}

.device-icon {
  background: $theme-color-background-4;
  border: 1px solid grey;
  border-radius: 10px;
}

.vertical-tab {
  display: flex;
  text-align: center;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  color: #6c757d;
  text-decoration: none;
  padding: 5px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  background: black;
  background: linear-gradient(-90deg, #2a2a2a 0%, #2a2a2a 60%, #707070 100%);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.vertical-tab.active {
  color: #38d429 !important;
  border-color: #38d429 transparent #38d429 #38d429 !important;
}

.vertical-tab-body {
  border: 1px solid #38d429;
  border-radius: 0 10px 0 0;
}

.time-control-container {
  background: $theme-color-background-4;
  border-bottom: 1px solid grey;
}
.time-control {
  padding: 0;

}
.time-control:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

//input[type='range']::-webkit-slider-thumb.time-control {
//  width: 10px;
//  -webkit-appearance: none;
//  height: 10px;
//  cursor: ew-resize;
//  background: #434343;
//  box-shadow: -80px 0 0 80px #43e5f7;
//}




datalist {
  user-select: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
  font-weight: 100;
  padding: 0;
  font-size: 0.8em;
  margin-top: -5px;
}

option {
  padding: 0
}

option:hover {
  background-color: transparent;
}

.idle-range-input {
  width: 100%;
  padding: 0 5px;
}

.trip-purpose-checkbox {
  margin-left: 10px;
  height: 2em;
  width: 2em;
}

</style>
