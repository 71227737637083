<template>
  <div class="device-details">
    <loading-box v-bind:loading="loading"></loading-box>
<!--    Load Sub-components AFTER we load the device details-->
    <div v-if="deviceImei">
      <label>{{device.device_name}}</label>
      <div class="green-divider"></div>
      <div class="device-blocks">
        <div class="device-item">
          <p class="device-item-heading">Device Code:
            <span class="device-item-text">{{device.device_code}}</span>
          </p>
        </div>
        <div class="device-item">
          <p class="device-item-heading">Plant Name:
            <span class="device-item-text">{{device.plant_num}}</span>
          </p>
        </div>
      </div>
      <div>
        <button class="w-100" @click="clickShowEvents()" id="events-log">Events Log</button>
        <button class="w-100" @click="clickUpdateDevice()" id="view-device" v-if="!enableEdit">
          View Device Properties</button>
        <button class="w-100" @click="clickUpdateDevice()" id="update-device" v-if="enableEdit">
          Edit Device Details</button>
        <locations-control :device="device" :enable-edit="enableEdit" :autoload_route="autoLoadRoute"
                       :event_id="selectedEventId"
                       v-on:select-event="clickEventMarker"
                       v-on:change="tripsChange"
                       ref="trips_control"></locations-control>
      </div>

      <div>
        <geolimit-control :device_imei="deviceImei" :enableEdit="enableEdit"></geolimit-control>
      </div>

      <!--    Events Menu Popout-->
      <PopoutMenu v-model="showEvents" title="Event History">
        <button class="w-100" @click="clickAddEvent()" id="add-event">New Event</button>
        <device-event-control :device_imei="deviceImei" :editable="enableEdit" ref="eventList" @select="selectEvent($event)">
        </device-event-control>
      </PopoutMenu>

      <!--    Subscription Alert Popup-->
      <div v-show="!device.has_subscription" class="unpaid-popup">
        <b-alert variant="danger" show dismissible><p>This device currently has an expired subscription,
          which has temporarily paused tracking. To resume tracking, please update your subscription details using the
          <strong>Subscription</strong> tab of the <strong>Preferences page</strong> or by
          <router-link to="/subscribe">clicking here.</router-link></p>
          If you need any assistance, feel free to email our support team at support@protektgps.com or
          call 1300 95 25 30</b-alert>
      </div>

      <!--    Device Edit Modal Template-->
      <b-modal id="modal-1" centered class="modal-content" size="lg" hide-footer title="Edit Device">
        <b-tabs>
          <b-tab title="Properties">
            <edit-device is-modal modal="modal-1" v-bind:device="device" v-on:save="onSave()" :editable="enableEdit"></edit-device>
          </b-tab>
          <b-tab title="Status">
            <device-status is-modal modal="modal-1" v-bind:device="device" :editable="enableEdit"></device-status>
          </b-tab>
          <b-tab title="Upload Schedule" v-if="deviceFeatures.includes('scheduled_uploads')">
            <device-upload-schedule is-modal modal="modal-1" v-bind:device="device" :editable="enableEdit">
            </device-upload-schedule>
          </b-tab>
          <b-tab title="Notifications">
            <edit-user-notifications v-bind:device="device"></edit-user-notifications>
          </b-tab>
<!--          <b-tab title="View Schedule">-->
<!--            <device-view-schedule is-modal modal="modal-1" v-bind:device="device"  v-on:save="onSave()" :editable="enableEdit"></device-view-schedule>-->
<!--          </b-tab>-->
<!--          <b-tab title="View Exclusions">-->
<!--            <device-view-exclusions is-modal modal="modal-1" v-bind:device="device"  v-on:save="onSave()" :editable="enableEdit"></device-view-exclusions>-->
<!--          </b-tab>-->
          <b-tab title="Auditlog">
            <h2>Audit Log</h2>
            <audit-log related_type="device" :related_id="device.device_id"></audit-log>
          </b-tab>
        </b-tabs>
      </b-modal>
      <!--    Add Event Modal Template-->
      <b-modal id="add-event-modal" centered class="modal-content" size="lg" hide-footer title="Edit Event">
        <device-edit-event :device_imei="deviceImei" :editable="enableEdit" modal="add-event-modal" @save="refreshEvents"
                           :event="selectedEventData"
        ></device-edit-event>
      </b-modal>
    </div>
  </div>
</template>

<script>

import EditDevice from '@/components/device/EditDevice'
import * as DataProvider from '@/components/helpers/DataProvider'
import LoadingBox from '@/components/helpers/LoadingBox'
import moment from 'moment'
import * as ErrorHelper from '@/components/helpers/ErrorHelper'
import DeviceStatus from '@/components/device/DeviceStatus'
import DeviceEventControl from '@/components/device/DeviceEventControl'
import DeviceEditEvent from '@/components/device/DeviceEditEvent'
import EditUserNotifications from '@/components/notifications/EditUserNotifications'
import DeviceViewSchedule from '@/components/device/DeviceViewSchedule'
import GeolimitControl from '@/components/device/device_controls/GeolimitControl'
import LocationsControl from '@/components/device/device_controls/LocationsControl'
import DeviceUploadSchedule from '@/components/device/device_controls/DeviceUploadSchedule'
import DeviceViewExclusions from '@/components/device/DeviceViewExclusions.vue'
import PopoutMenu from '@/components/shared/PopoutMenu.vue'
import AuditLog from '@/components/shared/AuditLogList.vue'

export default {
  name: 'device-details',
  components: {
    AuditLog,
    PopoutMenu,
    DeviceViewExclusions,
    DeviceUploadSchedule,
    GeolimitControl,
    DeviceEventControl,
    LoadingBox,
    EditDevice,
    DeviceStatus,
    DeviceEditEvent,
    EditUserNotifications,
    LocationsControl,
    DeviceViewSchedule
  },
  props: {
    device: Object,
    enableEdit: Boolean
  },
  data: function () {
    return {
      loading: true,
      deviceImei: '',
      showEvents: false,
      mapMarkers: [],
      selectedEventId: null, // Event Id for a selcted event
      selectedEventData: null, // Event Data for modal
      loadEventId: null,
      autoLoadRoute: true,
      deviceFeatures: []
    }
  },
  async mounted () {
    this.$maps.clearOverlays()
    if (!this.device) {
      ErrorHelper.displayGeneralErrorToast('No Device Data was Provided.')
    }
    this.deviceImei = this.device.device_imei
    this.deviceFeatures = await this.$deviceService.getDeviceFeatures(this.device.device_type)
    await this.loadData()
    this.loading = false
  },
  methods: {
    // Refresh/Load All data from the Protekt Server
    loadData: async function () {
      // Load this last, as it's not immediately visible.
      if (Object.keys(this.$route.query).length !== 0) {
        this.autoLoadRoute = false
        await this.parseQueryData(this.$route.query)
      }
      this.loading = false
    },
    // Parse Query Params from URL
    parseQueryData: async function (data) {
      if (data.hasOwnProperty('event_id')) {
        this.selectedEventId = data.event_id
      }
    },
    // Event Handler for when trips interactions happen.
    tripsChange: function (newTimerState) {
      // Close other pop-outs if the trips controller is doing things
      this.showEvents = false
    },
    clickAddEvent: function () {
      this.selectedEventData = null
      this.$bvModal.show('add-event-modal')
    },
    clickUpdateDevice: function () {
      this.$bvModal.show('modal-1')
    },
    clickShowEvents: function () {
      this.showEvents = !this.showEvents
    },
    clickHideEventsLog: function () {
      this.showEvents = !this.showEvents
    },
    onSave: function () {
      this.loadData()
    },
    refreshEvents: function () {
      this.$refs.eventList.refresh()
    },
    selectEvent: function (eventId) {
      this.selectedEventId = eventId
    },
    // Event called when an Event Map Marker is clicked.
    // Markers are added by the Trips control
    clickEventMarker: function (eventData) {
      this.selectedEventData = eventData
      this.$bvModal.show('add-event-modal')
    }
  },
  computed: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '@/variables';

  .device-item{
    margin-top: 0;
    margin-bottom: 1rem;
    text-align: left;
  }

  .device-item-heading{
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: 'Open Sans', sans-serif;
    color: $text-color-invert;
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
  }

  .device-item-text{
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: 'Open Sans', sans-serif;
    color: $text-color-invert;
    font-size: 15px;
    line-height: 18px;
    font-weight: 400;
  }

  .button-full{
    width: 100%;
  }

  .modal-conent {
    background-color: #2c3237;
    padding-bottom: 100px;
  }

  .show {
    display: block !important;
  }

  .menu-popout{
    position: absolute;
    left: auto;
    z-index: 2;
    top: 0%;
    right: 310px;
    bottom: 0%;
    width: 276px;
    height: 100vh;
    display: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    background-color: #2c3237;
    box-shadow: 1px 1px 20px -1px rgba(0, 0, 0, 0.51);
  }

  .pop-out-heading {
    position: relative;
    min-width: 160px;
    padding-bottom: 90px;
    display: block;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    background-image: linear-gradient(65deg, $theme-color-primary-3, $theme-color-primary-1);
  }

  .pop-out-content {
    padding: 1em;
  }

  .popout_image {
    position: absolute;
    right: 0;
    top: 0;
  }

  .w-col {
    position: relative;
    float: left;
    width: 100%;
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .progress-bar {
    height: 10px;
  }

  .unpaid-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: calc(100vw - 310px);
    height: 2vh;
  }

  @media screen and (max-width: 600px) {
    .menu-popout {
      right: 0;
      width: 100vw;
    }
  }

</style>
