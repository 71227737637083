var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex-row inline-forms" },
    [
      _vm.editable
        ? _c(
            "button",
            {
              staticClass: "inlineform-dropdown",
              on: { click: _vm.createForm }
            },
            [_vm._v(" + ")]
          )
        : _vm._e(),
      _vm._l(_vm.formObjects, function(form, idx) {
        return _c("FormDataAvatar", {
          key: "tag-" + idx,
          staticClass: "trip-tag",
          attrs: {
            size: "small",
            variant: "outline",
            form: form,
            editable: _vm.editable,
            show_remove_icon: ""
          },
          on: {
            click: function($event) {
              return _vm.selectForm(form)
            }
          }
        })
      }),
      _c(
        "b-modal",
        {
          staticClass: "modal-content",
          attrs: {
            id: _vm.modalId,
            centered: "",
            size: "xl",
            "hide-footer": "",
            title: _vm.isNew ? "Create Form" : "Edit Form"
          }
        },
        [
          _c(
            "b-tabs",
            [
              _c(
                "b-tab",
                { attrs: { title: "Form" } },
                [
                  _c("FormEdit", {
                    attrs: {
                      form: _vm.selectedForm,
                      related_type: "trip",
                      trip: _vm.value,
                      create_new: _vm.isNew,
                      modal_id: _vm.modalId
                    },
                    on: {
                      delete: _vm.deletedForm,
                      create: _vm.createdForm,
                      changed: _vm.selectedFormChange
                    }
                  })
                ],
                1
              ),
              !_vm.isNew
                ? _c(
                    "b-tab",
                    { attrs: { title: "Auditlog" } },
                    [
                      _vm.selectedForm
                        ? _c("AuditLog", {
                            attrs: {
                              related_type: "form",
                              related_id: _vm.selectedForm.id
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }