<template>
  <div class="notification-subscription-list">
    <loading-box v-bind:loading="loading"></loading-box>
    <!--    Bootstrap Table-->
    <b-table hover :items="notificationSubscriptions" :fields="fields" select-mode="single" selected-variant="success"
             th-class="bv-table-header" responsive="true"
             class="bv-table">
      <!--Event Type Row Element-->
      <template v-slot:cell(event_type)="row">
        <b-select v-model="row.item.event_type" v-on:change="fieldChange(row.item, $event)"
                        :disabled="row.item.disable_edit_event_type" :options="eventTypes"
                        placeholder="Select Event Type"
        ></b-select>
      </template>
      <!--Notification Type Row Element-->
      <template v-slot:cell(notification_type)="row">
        <b-form-select v-model="row.item.notification_type" v-on:change="fieldChange(row.item, $event)"
                       :disabled="row.item.disable_edit_notification_type" :options="notificationTypes"
                       aria-placeholder="Select Notification Type"
        ></b-form-select>
      </template>
      <template v-slot:cell(actions)="row">
        <div class="flex-row">
          <button class="" v-if="row.item.id === null" @click="clickSave(row.item)">Save</button>
          <i class="icon-button danger" :class="$config.icons.general.remove" v-b-tooltip.hover title="Remove/Reset"
             v-if="!row.item.disable_delete" @click="removeItem(row.item)"></i>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import LoadingBox from '../helpers/LoadingBox'
import * as DataProvider from '../helpers/DataProvider'
import * as ErrorHelper from '../helpers/ErrorHelper'
import * as AlertHelper from '../helpers/AlertHelper'

export default {
  name: 'notification-subscription-list',
  components: {
    LoadingBox
  },
  props: {
    data: Array,
    showFields: Array,
    user: Object
  },
  data: function () {
    return {
      loading: false,
      dirty: false,
      fields: [],
      masterFields: [
        { key: 'device_name',
          label: 'Vehicle',
          sortable: true
        },
        { key: 'event_type',
          label: 'Event Type',
          sortable: true
        },
        { key: 'notification_type',
          label: 'Notification Type',
          sortable: false
        },
        { key: 'actions',
          label: 'Actions',
          sortable: false
        }
      ],
      notificationSubscriptions: [],
      notificationTypes: [],
      eventTypes: []
    }
  },
  async created () {
    if (this.showFields) {
      this.updateFields(this.showFields)
    } else {
      this.fields = this.masterFields
    }
    if (this.data) {
      this.notificationSubscriptions = this.data
    } else {
      console.error('Subscription List was not given any data!')
    }
    this.loadConfig()
  },
  methods: {
    loadConfig: async function () {
      await this.populateNotificationTypes()
      // Populate Event Types
      await this.populateEventTypes()
    },
    populateNotificationTypes: async function () {
      // Populate Notification Types
      this.notificationTypes = [{
        value: null,
        text: 'Select Notification Type',
        disabled: true
      }]
      this.notificationTypes = this.notificationTypes.concat(await this.$eventService.getNotificationTypeOpts())
    },
    // Generate a grouped list of all known event types.
    populateEventTypes: async function () {
      this.eventTypes = [
        {
          value: null,
          text: 'Select Event Type',
          disabled: true
        },
        {
          value: 'all',
          text: 'All Event Types',
          disabled: true
        }
      ]
      this.eventTypes = this.eventTypes.concat(await this.$eventService.getEventTypeOpts())
      this.updateAvailableEventTypes()
    },
    updateAvailableEventTypes: function () {
      let usedEventTypes = this.notificationSubscriptions.map(x => x.event_type)
      // Set the 'disabled' prop on each of the event type options, based on if it's been used.
      this.eventTypes.forEach(x => {
        x.disabled = usedEventTypes.includes(x.value)
        if (x.hasOwnProperty('options')) {
          x.options.forEach(subX => { subX.disabled = usedEventTypes.includes(subX.value) })
        }
      })
    },
    updateFields: function (fieldList) {
      this.rolesFields = this.masterFields.filter(field => fieldList.includes(field.key))
    },
    fieldChange: async function (item, event) {
      // If the NotificationSubscription has an ID it exists on the server so we can update it.
      // Since we address the subscription by id, this endpoint works for users and admins (it's authed)
      if (item.id && event !== null) {
        let resp = await DataProvider.updateNotificationSubscription(item.id, item.event_type, item.notification_type)
        if (resp.success) {
          AlertHelper.successToast('Notification Rule Updated', 'Update Successful')
        } else {
          ErrorHelper.displayDataErrorToast(resp)
        }
      }
      this.updateAvailableEventTypes()
      this.$emit('change', this.notificationSubscriptions)
    },
    // Create a new Item
    clickSave: async function (item) {
      if (item.event_type && item.notification_type) {
        let resp
        if (this.user) {
          resp = await DataProvider.adminAddNotificationSubscription(this.user.email,
            item.device_imei, item.event_type, item.notification_type)
        } else {
          resp = await DataProvider.addNotificationSubscription(item.device_imei, item.event_type, item.notification_type)
        }
        if (resp.success) {
          item.id = resp.data.id
          AlertHelper.successToast('Notification Rule Saved', 'Creation Successful')
        } else {
          ErrorHelper.displayDataErrorToast(resp)
        }
      } else {
        ErrorHelper.displayGeneralWarningToast('Please select both an Event Type and a Notifiation Type to ' +
          'complete this rule.', 'Incomplete Rule')
      }
    },
    removeItem: async function (item) {
      if (item.id) {
        // Since we address the subscription by id, this endpoint works for users and admins (it's authed)
        let resp = await DataProvider.removeNotificationSubscription(item.id)
        if (resp.success) {
          AlertHelper.successToast('Notification Rule Successfully Deleted', 'Successful Removal')
        } else {
          ErrorHelper.displayDataErrorToast(resp)
          return
        }
      }
      this.notificationSubscriptions = this.notificationSubscriptions.filter(x => x !== item)
      this.updateAvailableEventTypes()
      this.$emit('change', this.notificationSubscriptions)
    }
  },
  watch: {
    data: function (newval) {
      this.notificationSubscriptions = newval
      this.loadConfig()
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '../../variables';

  .notification-subscription-list{
    position: relative;
    width: 100%;
  }

  .filter-bar {
    display: flex;
    flex-direction: row;
  }

  .page-bar {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .page-select {
    width: 10%;
    min-width: 50px;
  }

  .page-buttons {
    width: 25%;
    min-width: 150px;
  }

  //.row-action {
  //  font-size: 1.5rem;
  //  margin-right: 0.5rem;
  //}

  .child-card {
    background: $theme-color-background-4;
    padding: 10px 20px;
    border: 2px solid $theme-color-primary-3;
  }

  .advanced-filter{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 5px;
    padding: 3px;
    background: $theme-color-background-1;
    border: 1px solid $theme-color-primary-3;
  }

  .filter-label {
    font-family: 'Open Sans', sans-serif;
    color: $theme-color-primary-3;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 1px;
    margin: 0px 5px;
  }

  .sm {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    height: calc(1.5em + 0.5rem + 2px);
  }
</style>
