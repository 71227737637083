<template>
  <div class="locations-control">
    <loading-box v-bind:loading="loading"></loading-box>
      <label>Locations</label>
      <div class="green-divider"></div>
      <button class="button w-100" @click="clickShowCurrent()" id="current-location">Go to Current Location</button>
      <button class="button w-100" @click="clickShowLocationHistory()" id="route -history">Locations History</button>

    <!--    Popout Menu for Date Selection-->
    <PopoutMenu title="Location History" v-model="showPopout">
      <div class="flex-column overflow-hidden">
<!--        <DateRangeSelector class="w-100" v-model="selectedDateRange" disable_years-->
<!--                           :default_filter_mode="defaultDateMode"-->
<!--                           :default_start_date="start_date"-->
<!--                           :default_end_date="end_date"-->
<!--        >-->
<!--        </DateRangeSelector>-->
        <DateSelector class="w-100" v-model="selectedDateRange" :default_filter_mode="defaultDateMode"
                      :default_date="start_date"></DateSelector>
        <div class="green-divider"></div>
        <div class="flex-row slim-controls">
          <button @click="selectAll">All</button>
          <button @click="selectNone">Clear</button>
          <label>Auto Select</label>
          <input type="checkbox" v-model="selectOnLoad">
        </div>
        <div class="trips-list-container" ref="tripsListContainer">
          <TripList :trips="trips" locations
                    ref="tripsListComponent" select_multiple :select_on_load="selectOnLoad" @input="onTripSelect"></TripList>
        </div>
      </div>
    </PopoutMenu>
  </div>
</template>

<script>

import * as DataProvider from '@/components/helpers/DataProvider'
import LoadingBox from '@/components/helpers/LoadingBox'
import * as ErrorHelper from '@/components/helpers/ErrorHelper'
import moment from 'moment'
import PopoutMenu from '@/components/shared/PopoutMenu.vue'
import DateRangeSelector from '@/components/shared/DateRangeSelector.vue'
import TripList from '@/components/trips/TripList.vue'
import {DateTimeHelper as dt} from '@/components/helpers/DateTimeHelper'
import DateSelector from '@/components/shared/DateSelector.vue'

export default {
  name: 'locations-control',
  components: {DateSelector, TripList, DateRangeSelector, PopoutMenu, LoadingBox },
  props: {
    device: Object,
    device_imei: String,
    enableEdit: Boolean,
    autoload_route: Boolean,
    event_id: [String, Number],
    start_date: [String, Object],
    end_date: [String, Object],
    trip_id: [String, Number]
  },
  data: function () {
    return {
      loading: true,
      deviceImei: null,
      // Placeholder for device info
      deviceInternal: {
      },
      showLogs: false,
      showPopout: false,
      dateFilter: moment(new Date()).format('YYYY-MM-DD'),
      tripData: [],
      locations: [],
      mouseDownState: false,
      mapMarkers: [],
      trips: [],
      locationClusterIconOptions: {
        iconType: 'font',
        icon: 'fa fa-cog',
        labelSuffix: ' locations'
      },
      selectedDateRange: null,
      defaultDateMode: 'today',
      selectOnLoad: true,
      selectedLocations: []
    }
  },
  async mounted () {
    window.addEventListener('mousedown', this.onMouseDown)
    window.addEventListener('mouseup', this.onMouseUp)
    if (this.device) {
      this.deviceImei = this.device.device_imei
    } else if (this.device_imei) {
      this.deviceImei = this.device_imei
    }
    // The next section handles various query parameters that can be passed by the parent.
    // Start Date (end_date and trip_id) sets the controls to a given date and selects a specific trip.
    if (this.start_date) {
      this.ignoreDateChanges = true
      this.selectOnLoad = false
      // Setting a date range will prevent the DateRangeSelector from emitting a date the way it normally would.
      this.selectedDateRange = {
        dateMode: 'custom',
        start: this.start_date,
        end: this.end_date
      }
      this.showPopout = true
      await this.getTrips(this.start_date, this.end_date)
      this.loadedDefaults = true
      // TODO - Make Trip ID selection work without setting dates.(Needs a backend change to add get trip by id to the API)
      if (this.trip_id) {
        this.$nextTick(() => {
          this.$refs.tripsListComponent.selectByTripId(this.trip_id)
          this.ignoreDateChanges = false
        })
      }
      // event_id fetches the trip which corresponds to the specified event and displays that trip only.

      // Load Event Data if an event id was provided
    } else if (this.event_id) {
      await this.loadEvent(this.event_id)
    // Load today's data if we have the device IMEI
    } else if (this.autoload_route && this.deviceImei) {
      await this.loadCurrentLocation()
    }

    this.loading = false
  },
  beforeDestroy () {
    this.$maps.setClusterRendererOptions()
    window.removeEventListener('mousedown', this.onMouseDown)
    window.removeEventListener('mouseup', this.onMouseUp)
  },
  methods: {
    selectAll() {
      this.$refs.tripsListComponent.selectAll()
    },
    selectNone() {
      this.$refs.tripsListComponent.selectNone()
    },
    onMouseDown: function () {
      this.mouseDownState = true
    },
    onMouseUp: function () {
      this.mouseDownState = false
    },
    loadTrips: function (trips) {
      this.loading = true
      this.trips = trips
      // this.displayLocations(trips)
      this.loading = false
    },
    displayLocations: function (trips) {
      // Add the 'trip_id' to each location, so if they are selected we can back-ref them accurately.
      trips.forEach(trip => {
        trip.trip_data.forEach(location => {
          location.trip_id = trip.id
        })
      })
      this.locations = trips.reduce((accData, currentTrip) => accData.concat(currentTrip.trip_data), [])
    },
    // // Load today's route data and use it to fuel our click-popup
    // loadTodaysRoute: async function () {
    //   this.loading = true
    //   let startUTC = moment(moment(new Date()).format('YYYY-MM-DD') + ' 00:00').utc()
    //   let endUTC = moment(moment(new Date()).format('YYYY-MM-DD') + ' 23:59').utc()
    //
    //   let response = await DataProvider.getDeviceHistory(this.deviceImei, startUTC, endUTC)
    //   if (response.success) {
    //     this.loadTrips(response.data.trips)
    //     this.updateLocationMarker()
    //   }
    //   this.loading = false
    // },
    getTrips: async function (startDate, endDate) {
      this.loading = true
      // TODO - Query times must be in UTC... maybe add conversion to DataProvider?
      let startUTC = moment(moment(startDate).format('YYYY-MM-DD') + ' 00:00').utc()
      let endUTC = moment(moment(endDate).format('YYYY-MM-DD') + ' 23:59').utc()

      let routeData = await DataProvider.getDeviceTrips(this.deviceImei, startUTC, endUTC)

      if (!routeData.success) {
        this.loading = false
        this.$bvToast.toast(routeData.error.action,
          {
            title: routeData.error.message,
            variant: 'danger',
            toaster: 'b-toaster-top-center'
          })
        return
      }
      this.loadTrips(routeData.data.trips)
      // this.$maps.updateRoute(this.routeData)
      this.drawHistoryLocations(this.locations)
      this.loading = false
    },
    drawHistoryLocations: function (locationData) {
      this.clearMapMarkers()
      let markers = locationData.map(location => {
        return this.createLocationMarker(
          location.trip_id, { lat: location.latitude, lng: location.longitude },
          location.timestamp)
      })
      this.addMapMarkers(markers)
    },
    // Refresh/Load the current location and update the map.
    loadCurrentLocation: async function () {
      this.loading = true
      this.clearMapMarkers()
      let locationData = await DataProvider.getDeviceLocation(this.deviceImei)
      if (!locationData.success) {
        ErrorHelper.displayDataErrorToast(locationData)
        this.loading = false
        return
      }
      this.currentPos = {
        lat: locationData.data.latitude,
        lng: locationData.data.longitude,
        speed: locationData.data.speed,
        timestamp: locationData.data.timestamp
      }
      this.updateLocationMarker()
      this.loading = false
    },
    // Load a specific Event, usually by route query or via the Event List
    loadEvent: async function (eventId) {
      let event = await this.$eventService.getEventById(eventId)
      let markerAdded = false
      let icon = 'fa fa-info-circle'
      if (event.hasOwnProperty('event_link_options')) {
        // Custom Icon config for Event Locations
        if (event.event_link_options.hasOwnProperty('location_icon')) {
          icon = event.event_link_options.location_icon
        }
        // Show Trips that have a timestamp inside the events
        if (event.event_link_options.hasOwnProperty('show_trips') && event.event_link_options.show_trips === true) {
          // Get Trip data for this event and load it up
          let res = await DataProvider.getEventTrip(eventId)
          if (res.success) {
            await this.loadTrips(res.data.trips)
            await this.onTripSelect(res.data.trips)
            markerAdded = true
          }
        }
      }
      let position
      // if the event has location data we can display an icon in that location
      if (event.event_data.hasOwnProperty('location')) {
        // If we haven't drawn the marker (from show_trips) then draw it now.
        position = {
          lat: event.event_data.location.latitude,
          lng: event.event_data.location.longitude
        }
      }
      if (event.event_data.hasOwnProperty('latitude') && event.event_data.hasOwnProperty('longitude')) {
        position = {
          lat: event.event_data.latitude,
          lng: event.event_data.longitude
        }
      }
      if (position) {
        if (!markerAdded) {
          let marker = await this.getEventMarker(event)
          this.addMapMarkers([marker])
        }
        this.$maps.setViewport(position.lat, position.lng, this.$config.general.defaultZoomLevel + 2)
      } else {
        this.selectedEvent = event
        // await this.clickShowCurrent()
        this.$bvModal.show('add-event-modal')
      }
    },
    // Emit an update event for the map marker
    updateLocationMarker: function () {
      let timestampString = moment.unix(this.currentPos.timestamp).local().format('HH:mm DD-MM-YYYY')
      let popupContent = `<div class="map-infobox-col">` +
        `<div> Last Update: ${timestampString}</div>` +
        `</div>`

      let marker = {
        id: 'current_position',
        title: this.device.name,
        position: this.currentPos,
        popup: popupContent,
        icon: this.device.icon
      }
      this.updateMapMarker(marker)
    },
    // Create a location Marker for use with Location history
    createLocationMarker: function (tripId, position, timestamp) {
      let zIndex = 1000
      let alpha = 1.0

      let timestampString = moment.unix(timestamp).local().format('HH:mm DD-MM-YYYY')
      let popupContent = `<div class="map-infobox-col">` +
        `<div> Location: ${timestampString}</div>` +
        `</div>`

      let marker = {
        id: 'position_' + tripId,
        title: dt.timestampToLocalDateTime(timestamp),
        position: position,
        popup: popupContent,
        icon: this.device.icon,
        alpha: alpha,
        zIndex: zIndex
      }
      return marker
    },
    clearMapMarkers: function () {
      this.mapMarkers = []
      this.updateMapMarkers()
      this.$maps.clearOverlays()
      this.$maps.setClusterRendererOptions(this.locationClusterIconOptions)
    },
    addMapMarkers: function (markers) {
      markers.forEach((item) => this.mapMarkers.push(item))
      this.updateMapMarkers()
    },
    updateMapMarker: function (marker) {
      let oldMarkerIdx = this.mapMarkers.findIndex((i) => marker.id === i.id)
      if (oldMarkerIdx !== -1) {
        this.mapMarkers[oldMarkerIdx] = marker
      } else {
        this.mapMarkers.push(marker)
      }
      this.updateMapMarkers()
    },
    updateMapMarkers: function () {
      this.$maps.updateMarkers(this.mapMarkers, true)
    },
    // Load and display an array of events (usually from a trip)
    loadEventsData: async function (trip) {
      let resp = await DataProvider.getTripsEvents(trip.id)
      if (resp.success) {
        let markers = []
        for (let event of resp.data.events) {
          let marker = await this.getEventMarker(event)
          if (marker) {
            markers.push(marker)
          }
        }
        this.addMapMarkers(markers)
      }
    },
    // Create a Marker for an Event
    getEventMarker: async function (event) {
      let icon = 'fa fa-info-circle'
      let config = await this.$eventService.getEventTypeConfig(event.event_type)
      if (config.hasOwnProperty('event_link_options')) {
        // Custom Icon config for Event Locations
        if (config.event_link_options.hasOwnProperty('location_icon')) {
          icon = config.event_link_options.location_icon
        }
      }
      let position
      // if the event has location data we can display an icon in that location
      if (event.event_data.hasOwnProperty('location')) {
        // If we haven't drawn the marker (from show_trips) then draw it now.
        position = {
          lat: event.event_data.location.latitude,
          lng: event.event_data.location.longitude
        }
      }
      if (event.event_data.hasOwnProperty('latitude') && event.event_data.hasOwnProperty('longitude')) {
        position = {
          lat: event.event_data.latitude,
          lng: event.event_data.longitude
        }
      }
      // if the event has location data we can display an icon in that location
      if (position) {
        let marker = {
          id: `event_${event.event_id}`,
          title: `${config.name}`,
          position: position,
          // popup: popupContent,
          awesome_icon: icon,
          onClick: () => {
            this.$emit('select-event', event)
            // this.selectedEvent = event
            // this.$bvModal.show('add-event-modal')
          }
        }
        return marker
      } else {
        return null
      }
    },
    clickShowCurrent: async function () {
      this.loading = true
      // this.clearMapMarkers()
      await this.loadCurrentLocation()
      if (this.currentPos) {
        this.$maps.setViewport(this.currentPos.lat, this.currentPos.lng, this.$config.general.defaultZoomLevel)
      }
      this.$emit('change', true)
      this.loading = false
    },
    clickShowLocationHistory: async function () {
      this.loading = true
      this.clickShowPopout()
      this.$emit('change', false)
      this.loading = false
    },
    clickShowPopout: function () {
      this.showPopout = !this.showPopout
      this.showEvents = false
    },
    onTripSelect: async function (selectedTrips) {
      this.clearMapMarkers()
      this.selectedLocations = selectedTrips
      this.displayLocations(selectedTrips)
      if (selectedTrips.length) {
        this.drawHistoryLocations(this.locations)
      } else {
        this.clickShowCurrent()
      }
    },
    formatTimestamp: function (timestamp) {
      return moment.unix(timestamp).local().format('HH:mm')
    }
  },
  watch: {
    selectedDateRange (newVal) {
      if (!this.ignoreDateChanges && Object.hasOwn(newVal, 'start') && Object.hasOwn(newVal, 'end')) {
        this.$refs.tripsListContainer.scrollTo(0, 0)
        this.getTrips(newVal.start, newVal.end)
      }
    },
    device_imei: async function (newVal) {
      if (!this.deviceImei) {
        this.deviceImei = newVal
        if (this.autoload_route && this.deviceImei) {
          await this.loadCurrentLocation()
          await this.getTrips(this.selectedDateRange.start, this.selectedDateRange.end)
        }
      }
    },
    device: async function (newVal) {
      if (!this.device) {
        this.device = newVal
        this.deviceImei = this.device.device_imei
        if (this.autoload_route && this.deviceImei) {
          await this.loadCurrentLocation()
          await this.getTrips(this.selectedDateRange.start, this.selectedDateRange.end)
        }
      }
    },
    event_id: function (newEventId) {
      this.loadEvent(newEventId)
    },
    trip_data: function (newTripData) {
      this.loadTrips(newTripData.trips)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '@/variables';
  @import '@/animations';

  .locations-control{
    margin-top: 0;
    margin-bottom: 1rem;
    text-align: left;
    align-items: stretch;
  }

  .flex-column {
    align-items: stretch;
  }

  .trips-list-container {
    overflow: auto;
  }
  //
  //.trips-indicator {
  //  position: fixed;
  //  bottom: 10px;
  //  left: 10px;
  //  width: 100px;
  //  border-radius: 10px;
  //  border: 1px solid $theme-color-primary-3;
  //  color: $theme-color-primary-3;
  //  background: $theme-color-background-4;
  //  font-weight: 600;
  //  text-align: center;
  //  font-size: 1em;
  //}
  //
  //.trips-indicator i {
  //  font-size: 2em;
  //}
  //
  //.trips-indicator:hover{
  //  background: $theme-color-background-1;
  //  color: $theme-color-primary-2;
  //}
  //
  //.trips-indicator-active {
  //  background: $theme-color-background-1;
  //  color: $theme-color-primary-2;
  //}

  //.refresh-timer {
  //  position: absolute;
  //  top: 7vh;
  //  left: 20px;
  //  width: 75px;
  //  height: 75px;
  //  color: rgba(0, 0, 0, 0.1);
  //  font-size: 4.5em;
  //  text-align: center;
  //  line-height: 1.1;
  //}
  //
  //.refresh-timer-active {
  //  color: rgba(0, 0, 0, 0.25);
  //}
  //
  //.refresh-timer-text {
  //  font-size: 2rem;
  //  color: rgba( 0, 0, 0, 1);
  //  position: relative;
  //  display: inherit;
  //  top: -58px;
  //}


  @media screen and (max-width: 600px) {
    .menu-popout {
      right: 0;
      width: 100vw;
    }
  }

</style>
