var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c("div", { staticClass: "generic-feature-control" }, [
        _c(
          "label",
          {
            staticClass: "green-label feature-label",
            attrs: { for: "feature_control_" + _vm.feature_code }
          },
          [_vm._v(_vm._s(_vm.config.title))]
        ),
        _c(
          "div",
          {
            staticClass: "feature_control_form",
            class: { "form-error": _vm.$v.value.$error },
            attrs: { id: "form_" + _vm.feature_code }
          },
          [
            ["text", "textarea", "number", "range"].includes(_vm.config.control)
              ? _c("b-form-checkbox", {
                  directives: [{ name: "b-tooltip", rawName: "v-b-tooltip" }],
                  attrs: {
                    size: "lg",
                    name: "check-button",
                    switch: "",
                    id: "feature_control_" + _vm.feature_code + "_enable",
                    disabled: !_vm.editable || _vm.disableControl,
                    title: "Toggle Alarm"
                  },
                  on: {
                    change: function($event) {
                      return _vm.onToggleEnable($event)
                    }
                  },
                  model: {
                    value: _vm.enableFeature,
                    callback: function($$v) {
                      _vm.enableFeature = $$v
                    },
                    expression: "enableFeature"
                  }
                })
              : _vm._e(),
            _vm.config.control === "checkbox"
              ? _c("b-form-checkbox", {
                  directives: [{ name: "b-tooltip", rawName: "v-b-tooltip" }],
                  attrs: {
                    size: "lg",
                    name: "check-button",
                    switch: "",
                    id: "feature_control_" + _vm.feature_code,
                    disabled: !_vm.editable || _vm.disableControl,
                    title: _vm.description
                  },
                  on: {
                    change: function($event) {
                      return _vm.onValueChange($event)
                    }
                  },
                  model: {
                    value: _vm.value,
                    callback: function($$v) {
                      _vm.value = $$v
                    },
                    expression: "value"
                  }
                })
              : _vm._e(),
            ["text", "textarea", "number"].includes(_vm.config.control)
              ? _c(
                  "b-input-group",
                  {
                    attrs: {
                      prepend: _vm.fieldPrepend,
                      append: _vm.fieldAppend
                    }
                  },
                  [
                    _c("b-form-input", {
                      directives: [
                        { name: "b-tooltip", rawName: "v-b-tooltip" }
                      ],
                      staticClass: "text-field w-100 w-input input",
                      attrs: {
                        type: _vm.config.control,
                        maxlength: "256",
                        placeholder: _vm.config.placeholder,
                        id: "feature_control_" + _vm.feature_code,
                        disabled:
                          !_vm.editable ||
                          _vm.disableControl ||
                          !_vm.enableFeature,
                        title: _vm.description
                      },
                      on: { change: _vm.onUnsavedSave },
                      model: {
                        value: _vm.$v.value.$model,
                        callback: function($$v) {
                          _vm.$set(_vm.$v.value, "$model", $$v)
                        },
                        expression: "$v.value.$model"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            ["range"].includes(_vm.config.control)
              ? _c(
                  "b-input-group",
                  {
                    attrs: {
                      prepend: _vm.fieldPrepend,
                      append: _vm.fieldAppend
                    }
                  },
                  [
                    ["range"].includes(_vm.config.control)
                      ? _c("b-form-input", {
                          directives: [
                            { name: "b-tooltip", rawName: "v-b-tooltip" }
                          ],
                          staticClass: "text-field",
                          attrs: {
                            type: _vm.config.control,
                            maxlength: "256",
                            placeholder: _vm.config.placeholder,
                            id: "feature_control_" + _vm.feature_code,
                            disabled:
                              !_vm.editable ||
                              _vm.disableControl ||
                              !_vm.enableFeature,
                            title: "Sensitivity " + _vm.value + "%"
                          },
                          on: { change: _vm.onUnsavedSave },
                          model: {
                            value: _vm.$v.value.$model,
                            callback: function($$v) {
                              _vm.$set(_vm.$v.value, "$model", $$v)
                            },
                            expression: "$v.value.$model"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e(),
            _c("b-input-group-append", [
              _vm.featureState === true
                ? _c("i", {
                    directives: [{ name: "b-tooltip", rawName: "v-b-tooltip" }],
                    staticClass: "control-indicator",
                    class: _vm.$config.icons.feature_controls.confirmed,
                    attrs: { title: "Confirmed " + _vm.timestampText }
                  })
                : _vm._e(),
              _vm.featureState === false
                ? _c("i", {
                    directives: [{ name: "b-tooltip", rawName: "v-b-tooltip" }],
                    staticClass: "control-indicator pending",
                    class: _vm.$config.icons.feature_controls.sent,
                    attrs: { title: "Pending Device " + _vm.timestampText }
                  })
                : _vm._e(),
              _vm.featureState === null
                ? _c("i", {
                    directives: [{ name: "b-tooltip", rawName: "v-b-tooltip" }],
                    staticClass: "control-indicator failed",
                    class: _vm.$config.icons.feature_controls.failed,
                    attrs: { title: "No Data " + _vm.timestampText }
                  })
                : _vm._e()
            ]),
            _vm.$v.value.hasOwnProperty("minValue") && !_vm.$v.value.minValue
              ? _c("div", { staticClass: "input-error" }, [
                  _vm._v(
                    " Must be " +
                      _vm._s(_vm.$v.value.$params.minValue.min) +
                      " or greater "
                  )
                ])
              : _vm._e(),
            _vm.$v.value.hasOwnProperty("maxValue") && !_vm.$v.value.maxValue
              ? _c("div", { staticClass: "input-error" }, [
                  _vm._v(
                    " Must be " +
                      _vm._s(_vm.$v.value.$params.maxValue.max) +
                      " or less "
                  )
                ])
              : _vm._e(),
            _vm.$v.value.hasOwnProperty("decimal") && !_vm.$v.value.decimal
              ? _c("div", { staticClass: "input-error" }, [
                  _vm._v(" Must be a decimal value. ")
                ])
              : _vm._e(),
            _vm.$v.value.hasOwnProperty("integer") && !_vm.$v.value.integer
              ? _c("div", { staticClass: "input-error" }, [
                  _vm._v(" Must be a whole number value. ")
                ])
              : _vm._e()
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }