var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "auditlog-inner" },
      [
        _vm._l(_vm.log, function(entry, idx) {
          return _c("div", { key: idx, staticClass: "mt-1" }, [
            _c("span", { staticClass: "auditlog-timestamp" }, [
              _vm._v(_vm._s(_vm.dt.toLocalDateTime(entry.timestamp)))
            ]),
            _vm._v(" "),
            _c("i", { staticClass: "fa fa-arrow-right" }),
            _vm._v(" " + _vm._s(entry.message) + " ")
          ])
        }),
        !_vm.log || _vm.log.length === 0
          ? _c("div", { staticClass: "auditlog-empty" }, [
              _vm._v(" No Audit log entries found. ")
            ])
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }