<template>
<div>
  <div class="auditlog-inner">
    <div v-for="(entry, idx) of log" v-bind:key="idx" class="mt-1">
      <span class="auditlog-timestamp">{{dt.toLocalDateTime(entry.timestamp)}}</span> <i class="fa fa-arrow-right"></i> {{entry.message}}
    </div>
    <div class="auditlog-empty" v-if="!log || log.length === 0">
      No Audit log entries found.
    </div>
  </div>
</div>
</template>

<script>
import { DateTimeHelper as dt } from '@/components/helpers/DateTimeHelper'
import * as DataProvider from '@/components/helpers/DataProvider'
import * as ErrorHelper from '@/components/helpers/ErrorHelper'
export default {
  name: 'audit-log',
  props: {
    related_id: [Number, String],
    related_type: String
  },
  data: function () {
    return {
      dt: dt,
      log: null
    }
  },
  async mounted() {
    this.log = await this.getAuditLog()
    console.log('Log: ', this.log)
  },
  methods: {
    async getAuditLog() {
      let resp = await DataProvider.getAuditLog(this.related_id, this.related_type)
      if (resp.success) {
        return resp.data.audit_log
      } else {
        ErrorHelper.displayDataErrorToast(resp)
      }
    }
  },
  computed: {
    userIcon () {
      return 'fa fa-user'
    },
    username () {
      if (this.user && this.user.name && this.user.name !== '') {
        return this.user.name
      } else {
        return 'User #' + this.user.user_id
      }
    },
    email () {
      return this.user.email || ''
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
@import '@/variables';
.user-avatar {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  background: $theme-color-background-4;
  color: $theme-color-primary-3;
  border-radius: 5px;
  padding: 5px 5px;
  margin: 2px 0px;
  z-index: 0;
  user-select: none;
}

.user-icon {
  color: $text-color-invert;
  font-size: 2.1em;
  line-height: inherit;
  width: 1.2em;
  overflow: hidden;
}

.user-heading {
  flex-grow: 2;
  font-size: 1.3em
}

.detail-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  font-size: 0.7em;
  color: $theme-color-primary-3;
  font-style: italic;
}
.user-avatar-hover:hover {
  color: white;
  background-color: rgba(0,0,0,0.15);
  box-shadow: 0 0 0 0.2rem rgba(56, 212, 41, 0.4);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.user-avatar.selected {
  background-color: rgba(50, 50, 50,0.25);
  box-shadow: 0 0 0 0.2rem rgba(45, 231, 28, 0.75);
}

.auditlog-empty {
  color: white;
  font-size: 1.2em;
}

.auditlog-timestamp {
  color: $theme-color-primary-3;
  font-weight: 500;
}

</style>
