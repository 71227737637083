// Authentication and User Data Service. Manages user data and authentication
// Hopefully minimises the number of calls made to the server to auth data
// We keep a timestamp with the last refresh time in local storage, this should reduce duplication of data requests
// TODO - Consider making this a proper Singleton Class?

import * as DataProvider from '@/components/helpers/DataProvider'
import moment from 'moment'
import * as config from '../../config'
import * as ErrorHelper from '@/components/helpers/ErrorHelper'
import router from '../../router/index'

export default class AuthService {
  static refreshing = false
  static refreshCallbacks = []

  // delete all cached items (e.g. for logout)
  purgeCache () {
    localStorage.removeItem('protekt-user-settings')
    localStorage.removeItem('token')
    localStorage.removeItem('protekt-roles')
    localStorage.removeItem('protekt-user-data')
    localStorage.removeItem('protekt-subscriptions')
    localStorage.removeItem('protekt-userdata-timestamp')
    localStorage.removeItem('userid')
  }

  // Are we logged in?
  isLoggedIn () {
    return !!localStorage.getItem('userid')
  }

  // Return an Array of roles for the current user.
  async getRoles () {
    if (this.isRefreshTime()) {
      await this.refreshUserData()
    }
    try {
      return JSON.parse(localStorage.getItem('protekt-roles'))
    } catch (e) {
      console.log('Auth Service - WARNING: Failed to parse Roles data from cache')
      return []
    }
  }

  // Get User Settings data
  async getUserSettings () {
    if (this.isRefreshTime() || !JSON.parse(localStorage.getItem('protekt-user-settings'))) {
      await this.refreshUserData()
    }
    try {
      return JSON.parse(localStorage.getItem('protekt-user-settings'))
    } catch (e) {
      console.log('Auth Service - WARNING: Failed to parse User Settings data from cache')
      return { error: true }
    }
  }

  /****
   * Get a Specific user settings from the saved settings data
   * @param propName
   * @param defaultValue
   * @return {Promise<*|null>}
   */
  async getUserSetting (propName, defaultValue = null) {
    let settings = await this.getUserSettings()
    if (Object.hasOwn(settings, propName)) {
      return settings[propName]
    } else {
      return defaultValue
    }
  }

  /****
   * Set a specific user setting value
   * @param propName
   * @param value
   * @return {Promise<void>}
   */
  async setUserSetting (propName, value) {
    let settings = await this.getUserSettings()
    settings[propName] = value
    await this.setUserSettings(settings)
  }

  // Update User Settings, save to server and update local cache
  async setUserSettings (newData) {
    let resp = await DataProvider.setUserSettings(newData)
    if (resp.success) {
      localStorage.setItem('protekt-user-settings', JSON.stringify(resp.data.settings))
    } else {
      ErrorHelper.displayDataErrorToast(resp)
      return { error: true }
    }
  }

  // Update User settings cache, no call to the server
  async _setUserSettingsCache (newData) {
    if (newData === null) {
      newData = {}
    }
    localStorage.setItem('protekt-user-settings', JSON.stringify(newData))
  }

  // Get the user's token
  getToken () {
    return localStorage.getItem('token')
  }

  // TODO - Add some token management. Do they even expire???
  setToken (newVal) {
    localStorage.setItem('token', newVal)
  }

  /***
   * Find the User ID associated with the current Token and save it for future use.
   * This is mostly for facilitating smooth inter-app communication when a token is exchanged.
   * @returns {Promise<void>}
   */
  async queryUserId () {
    let resp = await DataProvider.getUserByToken()
    console.log('User Id Response: ', resp)
    if (resp.success && Object.hasOwn(resp.data, 'user_id')) {
      localStorage.setItem('userid', resp.data.user_id)
      await this.getUserData(true)
    } else {
      throw Error('Unable to Retrieve User Id.')
    }
  }

  // Set Roles to the new value
  setRoles (newVal) {
    return localStorage.setItem('protekt-roles', JSON.stringify(newVal))
  }

  // Set Subscription Data to the new value
  setSubscriptions (newVal) {
    return localStorage.setItem('protekt-subscriptions', JSON.stringify(newVal))
  }

  // Get the current user's subscription data
  async getSubscriptions () {
    if (this.isRefreshTime()) {
      await this.refreshUserData()
    }
    try {
      return JSON.parse(localStorage.getItem('protekt-subscriptions'))
    } catch (e) {
      console.log('Auth Service - WARNING: Failed to parse subscription data from cache')
      return []
    }
  }

  async getUserData (force = false) {
    if (this.isRefreshTime() || force) {
      await this.refreshUserData()
    }
    try {
      return JSON.parse(localStorage.getItem('protekt-user-data'))
    } catch (e) {
      console.log('Auth Service - WARNING: Failed to parse User data from cache')
      return []
    }
  }

  _setUserData (data) {
    localStorage.setItem('protekt-user-data', JSON.stringify(data))
  }

  // Does the user meet the criteria to use the system, based on subscription and device ownership?
  async meetsSubscriptionRequirement () {
    let userData = await this.getUserData()
    if (userData) {
      return userData.meets_subscription_reqs
    } else {
      return false
    }
  }

  // Get the current user's subscription status
  async hasActiveSubscription () {
    let subscriptions = await this.getSubscriptions()
    return subscriptions.some(x => config.auth.activeSubscriptionStates.includes(x.status))
  }

  // Does the user have any subscriptions which can be 'fixed'
  // e.g. Subscription in past_due or incomplete.
  async hasRecoverableSubscriptions () {
    let subscriptions = await this.getSubscriptions()
    return subscriptions.some(x => config.auth.recoverableSubscriptionStates.includes(x.status))
  }

  // Does the user have more than one subscription? (usually that's bad)
  async hasMultipleSubscriptions () {
    let subscriptions = this.getSubscriptions()
    return subscriptions.length > 1
  }

  // Check if data cache should be refreshed
  isRefreshTime () {
    let lastUpdate = localStorage.getItem('protekt-userdata-timestamp')
    let updateThreshold = moment().subtract(config.auth.userDataCacheSeconds, 'seconds')
    return (!lastUpdate || !moment(lastUpdate).isSameOrAfter(updateThreshold))
  }

  /***
   * Set the current user data to be expired (useful if we know the data has been changed)
   */
  expireUserData () {
    localStorage.setItem('protekt-userdata-timestamp', '0')
  }

  // Determine if a user has a role
  async hasRole (role) {
    if (this.isLoggedIn()) {
      let roles = await this.getRoles()
      return roles.includes(role)
    } else {
      return false
    }
  }

  // Determine if a user has at least one of the supplied roles
  async roleIn (requiredRoleIds) {
    if (this.isLoggedIn()) {
      let roles = await this.getRoles()
      if (roles) {
        return roles.some(x => requiredRoleIds.includes(x))
      } else {
        return false
      }
    } else {
      return false
    }
  }

  // Download new user data, if there's already a request for new data pending, return a promise that will be
  // resolved when the data has been loaded.
  async refreshUserData () {
    if (AuthService.refreshing) {
      return new Promise(resolve => {
        AuthService.refreshCallbacks.push(resolve)
      })
    } else {
      AuthService.refreshing = true
      console.log('Refreshing User Data....')
      let resp = await DataProvider.getUserProfile()
      if (resp.success) {
        localStorage.setItem('protekt-userdata-timestamp', moment().toISOString())
        if (resp.data.hasOwnProperty('roles')) {
          let roles = resp.data.roles.map(x => x.name)
          this.setRoles(roles)
        }
        if (resp.data.hasOwnProperty('admin_roles')) {
          let roles = resp.data.admin_roles.filter(x => // Filter to handle extra data provided to Admin users
            !x.hasOwnProperty('is_member') || (x.hasOwnProperty('is_member') && x.is_member === true)
          ).map(x => x.name)
          this.setRoles(roles)
        }
        if (resp.data.hasOwnProperty('subscriptions')) {
          this.setSubscriptions(resp.data.subscriptions)
        }
        if (resp.data.hasOwnProperty('settings')) {
          this._setUserSettingsCache(resp.data.settings)
        }
        this._setUserData(resp.data)
      } else {
        console.log('Failed to get user Data. Keeping Stale Data')
        // Check for logout command from server...
        if (resp.error.logout_user === true) {
          router.push({ path: '/logout' })
        }
      }
      AuthService.refreshing = false
      AuthService.refreshCallbacks.forEach((resolve) => {
        resolve()
      })
      AuthService.refreshCallbacks = []
    }
  }
}
