var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-check" }, [
    _c("input", {
      staticClass: "form-check-input mt-0",
      attrs: {
        id: _vm.control.id + "-input",
        name: "check-button",
        type: "checkbox",
        disabled: !_vm.control.enabled
      },
      domProps: { checked: !!_vm.control.data },
      on: {
        change: function($event) {
          return _vm.handleChange(_vm.control.path, !_vm.control.data)
        },
        focus: function($event) {
          _vm.isFocused = true
        },
        blur: function($event) {
          _vm.isFocused = false
        }
      }
    }),
    _c(
      "label",
      {
        staticClass: "ml-2",
        class: _vm.labelClass,
        on: {
          click: function($event) {
            return _vm.handleChange(_vm.control.path, !_vm.control.data)
          }
        }
      },
      [_vm._v(_vm._s(_vm.control.label))]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }