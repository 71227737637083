<template>

  <div class="form-check">
    <input  :id="control.id + '-input'"
            class="form-check-input mt-0"
            name="check-button"
            type="checkbox"
            :checked="!!control.data"
            :disabled="!control.enabled"
            @change="handleChange(control.path, !control.data)"
            @focus="isFocused = true"
            @blur="isFocused = false"
    >
    <label class="ml-2" @click="handleChange(control.path, !control.data)" :class="labelClass">{{control.label}}</label>
  </div>

</template>

<script>

import {
  rendererProps,
  useJsonFormsControl,
} from '@jsonforms/vue2'
import {isBooleanControl, rankWith} from '@jsonforms/core'


const renderer = {
  name: 'BooleanControlRendererNew',
  components: {},
  props: {
    ...rendererProps(),
  },
  setup(props) {
    return useJsonFormsControl(props)
  },
  mounted () {
  },
  computed: {
    labelClass() {
      if (Object.hasOwn(this.uischema, 'options')) {
        if (Object.hasOwn(this.uischema.options, 'true_variant') && this.control.data) {
          console.log('TRUE!')
          return this.uischema.options['true_variant']
        } else if (Object.hasOwn(this.uischema.options, 'false_variant') && !this.control.data) {
          return this.uischema.options['false_variant']
        }
      } else {
        return ''
      }
    }
  }
}
export default renderer

export const booleanRendererentry = {
  renderer: renderer,
  tester: rankWith(1, isBooleanControl),
}

</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped >

.form-check {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.form-check-input {
  width: 1.6em;
  height: 1.6em;
}

.form-check label {
  //font-size: 1.6em;
}

.warning {
  color: #e5bf00;
}

.danger {
  color: #ff4848;
}

</style>
