<template>
  <div class="week-time-selector flex-column" ref="parent">
    <div>
      Legend
      <div class="seg-personal px-2">Personal</div>
      <div class="seg-work px-2">Work</div>
    </div>

    <div class="week-time-container flex-column" :class="{'disabled': disabled}">
      <div class="flex-row time-header">
        <div class="day-header"></div>
        <div class="flex-row" ref="timeHeader" :class="{'disabled': disabled}">
          <div class="time-slot" v-for="(step, idx) of timeSteps" v-bind:key="'time-header-'+idx">
            {{ step }}
          </div>
        </div>

        <div class="day-controls"></div>
      </div>
      <div class="">
        <div class="flex-row day-height day-row" v-for="(day, idx) of Object.values(dayValues)"
             :class="{'disabled': disabled}"
             v-bind:key="'day-header-'+idx">
          <div class="day-header">
            {{ day.text }}
          </div>
          <div class="position-relative flex-grow-1 flex-row seg-personal row-segment-height" v-if="!loading"
               @click="clickDayRow(day, $event)">
            <div v-for="(seg, segIdx) of daySegments[idx]" v-bind:key="`day-${idx}-seg-${segIdx}`"
                 class="row-segment row-segment-height seg-work"
                 :class="{'disabled': disabled,
                           'selected': selectedSegment && selectedSegment.day === seg.day && selectedSegment.index === seg.index}"
                 :style="`width: ${seg.width}px; left: ${seg.offset}px;`"
                 @click="selectSegment(seg, $event)"
                 @mousedown="dragSegmentStart('move', seg, $event)"
            >
              <div class="font-weight-bold">Work</div>
              <div class="row-segment-times">{{ dt.toLocalTime(seg.start) }} -> {{ dt.toLocalTime(seg.end) }}</div>
              <div class="row-segment-handle start" @mousedown="dragSegmentStart('start', seg, $event)"
                   :class="{'selected': selectedSegment && selectedSegment.day === seg.day && selectedSegment.index === seg.index}"
              ></div>
              <div class="row-segment-handle end" @mousedown="dragSegmentStart('end', seg, $event)"
                   :class="{'selected': selectedSegment && selectedSegment.day === seg.day && selectedSegment.index === seg.index}"
              ></div>
            </div>
          </div>
          <div class="flex-row day-controls">
            <button @click="resetDay(day)" :disabled="disabled" class="slim-controls" v-b-tooltip title="Reset">
              <i class="fa fa-undo"></i>
            </button>
            <button @click="clearDay(day)" :disabled="disabled" class="slim-controls" v-b-tooltip title="Clear">
              <i :class="$config.icons.general.clearClipboard"></i>
            </button>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>
<script>

import moment from 'moment'
import {DateTimeHelper as dt} from '@/components/helpers/DateTimeHelper'

export default {
  name: 'WeekTimeSelector',
  props: {
    value: Object,
    disabled: Boolean,
    emit_on_load: Boolean
  },
  emits: [
    'input',
    'cancel'
  ],
  data() {
    return {
      dt: dt,
      loading: true,
      timeStart: '00:00:00',
      timeStepUnit: 'hours',
      timeStepValue: 1,
      timeStepCount: 24,
      days: [
        'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'
      ],
      defaultPeriod: {
        start: moment('1970/1/1 09:00'),
        end: moment('1970/1/1 17:00'),
      },
      dayValues: {
        0: {
          text: 'Monday',
          periods: [
            {
              start: moment('1970/1/1 09:00'),
              end: moment('1970/1/1 17:00'),
            }
          ]
        },
        1: {
          text: 'Tuesday',
          periods: [
            {
              start: moment('1970/1/1 09:00'),
              end: moment('1970/1/1 17:00'),
            }
          ]
        },
        2: {
          text: 'Wednesday',
          periods: [
            {
              start: moment('1970/1/1 09:00'),
              end: moment('1970/1/1 17:00'),
            }
          ]
        },
        3: {
          text: 'Thursday',
          periods: [
            {
              start: moment('1970/1/1 09:00'),
              end: moment('1970/1/1 17:00'),
            }
          ]
        },
        4: {
          text: 'Friday',
          periods: [
            {
              start: moment('1970/1/1 09:00'),
              end: moment('1970/1/1 17:00'),
            }
          ]
        },
        5: {
          text: 'Saturday',
          periods: []
        },
        6: {
          text: 'Sunday',
          periods: []
        },
      },
      selectedSegment: null,
      draggingSegment: false,
      dragStartX: null,
      dragStartValues: {
        start: null,
        end: null
      },
      dragMode: 'move',
      lastMouseDown: null,
      wibble: 0,
      dirty: false
    }
  },
  async mounted() {
    if (!this.disabled) {
      this.addListeners()
    }
    if (this.value) {
      this.loadValues(this.value)
    }
    if (this.emit_on_load) {
      this.emitValue()
    }
    this.loading = false
  },
  beforeDestroy() {
    this.removeListeners()
  },
  methods: {
    addListeners() {
      document.addEventListener('mousemove', this.dragSegment)
      document.addEventListener('mouseup', this.dragSegmentEnd)
      document.addEventListener('mousedown', this.mouseDownHandler)
      document.addEventListener('keypress', this.keyPressHandler)
    },
    removeListeners() {
      document.removeEventListener('mousemove', this.dragSegment)
      document.removeEventListener('mouseup', this.dragSegmentEnd)
      document.removeEventListener('keypress', this.keyPressHandler)
      document.removeEventListener('mousedown', this.mouseDownHandler)
    },
    loadValues(newValue) {
      /**
       * Load DayValues from a backend list of periods for each day
       * @type {{}}
       */
      let dayValues = {}
      this.days.forEach((day, i) => {
        dayValues[i] = {
          text: day,
          periods: newValue[i].map(period => {
            return {
              start: moment('1/1/1970 ' + period.start),
              end: moment('1/1/1970 ' + period.end)
            }
          })
        }
      })
      this.dayValues = dayValues
    },
    clearDay(day) {
      day.periods = []
      this.dirty = true
      this.emitValue()
    },
    resetDay(day) {
      day.periods = [{
        start: this.defaultPeriod.start.clone(),
        end: this.defaultPeriod.end.clone()
      }]
      this.emitValue()
    },
    keyPressHandler(event) {
      if (event.key === 'Delete' && this.selectedSegment) {
        let day = this.dayValues[this.selectedSegment.day]
        day.periods = day.periods.filter((_, i) => i !== this.selectedSegment.index)
      }
    },
    mouseDownHandler(e) {
      this.lastMouseDown = event.timeStamp
    },
    clickDayRow(day, event) {
      if (this.disabled) {
        return
      }
      // This prevents weirdness when drags are released *just* outside the segment div.
      if (!day || event.timeStamp - this.lastMouseDown > 300) {
        return
      }
      let pixelsPerSeconds = this.$refs.timeHeader.clientWidth / (24 * 60 * 60)
      let clickTime = moment('1970/1/1 00:00').add(event.offsetX / pixelsPerSeconds, 'seconds')
      let startTime = this.clampTime(clickTime.subtract(30, 'minute'))
      let endTime = this.clampTime(clickTime).clone().add(1, 'hour')
      if (!this.getOverlaps(startTime, endTime, day)) {
        day.periods.push({
          start: startTime,
          end: endTime
        })
        day.periods.sort((a, b) => a.start - b.start)
      }
    },
    selectSegment(segment, event) {
      if (this.disabled) {
        return
      }
      event.stopPropagation()
      this.selectedSegment = segment
    },
    dragSegmentStart(mode, segment, event) {
      if (this.disabled) {
        return
      }
      event.preventDefault()
      if (this.selectedSegment !== segment) {
        this.selectedSegment = segment
      }
      this.dragMode = mode
      this.dragStartX = event.clientX
      this.draggingSegment = true
      this.dragStartValues.start = segment.start
      this.dragStartValues.end = segment.end
      event.stopPropagation()
    },
    dragSegmentEnd(segment, event) {
      this.draggingSegment = false
      if (this.selectedSegment) {
        this.joinPeriods(this.dayValues[this.selectedSegment.day])
        this.dirty = true
        this.emitValue()
      }
    },
    dragSegment(event) {
      event.preventDefault()
      if (this.draggingSegment) {
        let pixelsPerMinute = this.$refs.timeHeader.clientWidth / (24 * 60)
        let timeDelta = (event.clientX - this.dragStartX) / pixelsPerMinute
        let dayPeriod = this.dayValues[this.selectedSegment.day].periods[this.selectedSegment.index]
        // Clone the start and end times from when we started dragging
        let newStartTime = this.dragStartValues.start.clone()
        let newEndTime = this.dragStartValues.end.clone()
        if (this.dragMode === 'move') {
          newStartTime = this.clampTime(newStartTime.add(timeDelta, 'minutes'))
          newEndTime = this.clampTime(newEndTime.add(timeDelta, 'minutes'))
        } else if (this.dragMode === 'start') {
          newStartTime = this.clampTime(newStartTime.add(timeDelta, 'minutes'))
        } else if (this.dragMode === 'end') {
          newEndTime = this.clampTime(newEndTime.add(timeDelta, 'minutes'))
        }
        if (!this.getOverlaps(newStartTime, newEndTime, this.dayValues[this.selectedSegment.day])) {
          dayPeriod.start = newStartTime
          dayPeriod.end = newEndTime
        }
        // this.dragStartX = event.clientX
        this.wibble += 1
      }
    },
    clampTime(time) {
      // Clamp time within a 24 hour period.
      let start = moment('1970/1/1 00:00')
      let end = moment('1970/1/1 23:59')
      time = moment.unix((Math.round(time.unix() / 1800)) * 1800)
      // Round to the nearest half hour (1800 seconds).
      // Moment doesn't have a tool for this, so we convert to a TS and back
      return time.min(start).max(end)
    },
    getOverlaps(start, end, day) {
      /**
       * Check if any of the segments in a given day overlap the provided times.
       * @type {*|number}
       */
      // If there's a selected segment, also ensure it's not conflicting with itself.
      let segmentIdx = this.selectedSegment ? this.selectedSegment.index : -1
      let overlaps = day.periods.filter(
        (period, idx) => idx !== segmentIdx && (
          (period.end > start && period.start < start) || (end > period.start && period.end > end) ||
          (end >= period.end && start <= period.start)
        ))
      return overlaps.length > 0
    },
    joinPeriods(day) {
      let joinedPeriods = []
      for (let idx = 0; idx < day.periods.length - 1; idx++) {
        let period = day.periods[idx]
        let next = day.periods[idx + 1]
        if (next && next.start.unix() === period.end.unix()) {
          next.start = period.start
          joinedPeriods.push(idx)
        }
      }
      day.periods = day.periods.filter((p, i) => !joinedPeriods.includes(i))
    },
    emitValue() {
      let values = {}
      Object.keys(this.dayValues).forEach(key => {
        values[key] = this.dayValues[key].periods.map((period) => {
          return {
            start: period.start.format('HH:mm'),
            end: period.end.format('HH:mm')
          }
        })
      })
      this.$emit('input', values)
    }
  },
  computed: {
    timeSteps() {
      return Array(this.timeStepCount).fill().map((_, i) =>
        moment('1970/1/1 ' + this.timeStart).add(this.timeStepValue * i, this.timeStepUnit).format('HH:mm'))
    },
    daySegments() {
      let segments = {}
      if (!this.loading) {
        let hourWidth = this.$refs.timeHeader.clientWidth / 24
        let startOfDay = moment('1970/1/1 00:00')
        // Each Day/Row
        Object.keys(this.dayValues).forEach((key) => {
          segments[key] = this.dayValues[key].periods.map((period, idx) => {
            // let start = moment('1970/1/1 ' + period.start)
            // let end = moment('1970/1/1 ' + period.end)
            return {
              day: key,
              index: idx,
              class: 'seg-work',
              start: period.start,
              end: period.end,
              width: period.end.diff(period.start, 'hour', true) * hourWidth,
              offset: startOfDay.diff(period.start, 'hour', true) * hourWidth * -1
            }
          })
        })
      }
      return segments
    }
  },
  watch: {
    disabled(newVal) {
      this.draggingSegment = false
      this.selectedSegment = null
      if (newVal) {
        this.removeListeners()
      } else {
        this.addListeners()
      }
    },
    value (newVal) {
      this.loadValues(newVal)
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../variables';

.week-time-selector {
  font-size: 0.8em;
}

.week-time-container {
  position: relative;
  justify-content: center;
  align-items: stretch;
}

.header-spacing {
  height: 2em;
}

.time-header {
  flex-wrap: nowrap;
  background: $theme-color-background-4;
}

.time-slot {
  padding: 5px;
  border: solid 1px grey;
}

.day-header {
  //background: $theme-color-background-4;
  width: 10em;
  border-right: 1px solid grey;
  text-transform: capitalize;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.day-height {
  height: 3.4em;
}

.day-slot {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.day-controls {
  flex-wrap: nowrap;
  width: 10em;

  input {
    padding: 0 10px;
  }
}

.day-row {
  border: 1px solid grey;
  background: $theme-color-background-4;
  align-items: center;
  align-content: center;
}

.row-segment-height {
  height: 3.0em;
}

.row-segment {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  overflow: hidden;
  text-align: center;
}

.row-segment-times {
  font-size: 0.8em;
  font-style: italic;
}

.seg-personal {
  color: black;
  background: #2f69ad;
}

.seg-work {
  color: black;
  background: #ff953c;
}

.seg-work.disabled {
  color: darkgrey;
  background: #c57c41;
}

.row-segment.selected {
  outline: 2px solid white;
  cursor: grab;
}

.row-segment-handle.selected {
  position: absolute;
  content: "";
  cursor: col-resize;
  width: 1em;
  height: 3.1em;
  background: #00000066;
  z-index: 2;
}

.row-segment-handle.start {
  left: 0;
}

.row-segment-handle.end {
  right: 0;
}

.disabled {
  background: #2d2d2d;
  color: darkgrey;
}

.disabled:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  z-index: 100;
}

//.row-segment.selected:after {
//  position: absolute;
//  right: 0;
//  content:"";
//  cursor: col-resize;
//  width: 1em;
//  height: 2.2em;
//  background: #00000066;
//  z-index: 2;
//}

</style>
