<template>
  <div class="edit-device">
    <div class="row" v-if="!loading">
      <div class=" col s4" :class="{ 'form-error': $v.device.device_name.$error }">
        <label for="device_name" class="">Device Name</label>
        <input type="text" v-model="$v.device.device_name.$model" class="text-field w-input"
               maxlength="256" placeholder="Device Name" id="device_name" :disabled="!editable">
        <div class="input-error" v-if="!$v.device.device_name.required">Field is Required</div>
        <div class="input-error" v-if="!$v.device.device_name.minLength">Must be at least {{$v.device.device_name.$params.minLength.min}} characters</div>
        <div class="input-error" v-if="!$v.device.device_name.maxLength">Must be less than {{$v.device.device_name.$params.maxLength.max}} characters</div>
      </div>

      <div class="col-4" :class="{ 'form-error': $v.device.plant_num.$error }">
        <label for="plant_properties" class="">Plant Name</label>
        <input type="text" v-model="$v.device.plant_num.$model" class="text-field w-input"
               maxlength="256" placeholder="Plant Name" id="plant_properties" :disabled="!editable">
      </div>

      <div class="col-4" :class="{ 'form-error': $v.device.registration.$error }">
        <label for="Registration" class="">Registration</label>
        <input type="text" v-model="$v.device.registration.$model" class="text-field w-input"
               maxlength="256" name="Registration" data-name="Registration" placeholder="000 000" id="Registration"
               :disabled="!editable">
      </div>

      <div v-if="deviceFeatures.includes('device_trips')"
        class="col-4" :class="{ 'form-error': $v.device.odometer_reading.$error }">
        <label for="odometer_reading" class="">Odometer</label>
        <input type="text" v-model="$v.device.odometer_reading.$model" class="text-field w-input"
               maxlength="256" placeholder="000" id="odometer_reading" :disabled="!editable">
        <div class="input-error" v-if="!$v.device.overspeed_limit.decimal">Must be a number</div>
        <div class="input-error" v-if="!$v.device.overspeed_limit.minValue">Must be 0 or greater </div>
      </div>

<!--      <div v-if="deviceFeatures.includes('device_trips')"-->
<!--        class="col-4" :class="{ 'form-error': $v.device.overspeed_limit.$error }">-->
<!--        <form id="placeholder_values" name="email-form" data-name="Email Form">-->
<!--          <label for="overspeed_limit" class="device-label">Overspeed (0–disable)</label>-->
<!--          <input type="text" v-model="$v.device.overspeed_limit.$model" class="text-field w-input"-->
<!--                 maxlength="256" placeholder="000" id="overspeed_limit" :disabled="!editable">-->
<!--          <div class="input-error" v-if="!$v.device.overspeed_limit.required">Field is Required</div>-->
<!--          <div class="input-error" v-if="!$v.device.overspeed_limit.minValue">Must be 0 or greater </div>-->
<!--        </form>-->
<!--      </div>-->

      <div v-if="deviceFeatures.includes('device_trips')"
        class="col-4" :class="{ 'form-error': $v.device.hours_tracked.$error }">
        <form id="hours_tracked_form" name="hours-form" data-name="Email Form">
          <label for="overspeed_limit" class="">Hours Tracked</label>
          <input type="text" v-model="$v.device.hours_tracked.$model" class="text-field w-input"
                 maxlength="256" placeholder="000" id="hours_tracked" :disabled="!editable">
          <div class="input-error" v-if="!$v.device.hours_tracked.decimal">Must be a number</div>
          <div class="input-error" v-if="!$v.device.hours_tracked.minValue">Must be 0 or greater </div>
        </form>
      </div>

      <div class="spacing"></div>

      <div class="mt-2 flex-row">
        <div class="col s12">
          <label>Device Icon</label>
          <input type="hidden" name="icon" id="icon_properties" value="Car">
          <div class="flex-row icon-group-properties" v-if="!loading">
            <div v-for="icon of icons" v-bind:key="icon.name" id="icon_list" class="col" >
              <img class="clickable device-icon" v-bind:class="{icon_select_color: icon.selected === true}" :src="icon.src"
              @click="selectIcon(icon)">
            </div>
          </div>
        </div>
      </div>
      <div class="mt-2 col-12 flex-row">
        <div class="col-12">
          <div class="w-100">
            <label>Device Notes</label>
            <textarea rows="4" class="w-100" placeholder="Device notes can be entered here"
                      v-model="$v.device.notes.$model" :disabled="!editable"></textarea>
          </div>
        </div>
      </div>
      <div class="footer mt-2">
        <button class="button" @click="clickClose()" id="button-close">Close</button>
        <button class="button" @click="saveChanges()" id="button-save" v-if="editable">Save Changes</button>
      </div>
    </div>
  </div>
</template>

<script>
import * as DataProvider from '../helpers/DataProvider'
import { minLength, required, decimal, integer, minValue, maxLength } from 'vuelidate/lib/validators'
import * as ErrorHelper from '../helpers/ErrorHelper'
import * as IconHelper from '@/components/helpers/IconHelper'
import moment from 'moment'

export default {
  name: 'edit-device',
  props: {
    isModal: Boolean,
    modal: String,
    device: Object,
    editable: Boolean
  },
  data: function () {
    return {
      showButtons: true,
      loading: true,
      // We're going to modify the Icons, so take a *deep* copy of them.
      icons: IconHelper.Icons.map(x => {
        return {
          name: x.name,
          src: x.src,
          selected: false
        }
      }),
      deviceFeatures: [],
      displayDevice: null,
      service_schedule_options: [
        { value: 'distance', text: 'Distance' },
        { value: 'hours', text: 'Hours' }
      ]
    }
  },
  validations: {
    device: {
      device_name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(22)
      },
      plant_num: {
        maxLength: maxLength(22)
      },
      registration: {
      },
      overspeed_limit: {
        integer,
        minValue: minValue(0)
      },
      hours_tracked: {
        decimal,
        minValue: minValue(0)
      },
      odometer_reading: {
        decimal,
        minValue: minValue(0)
      },
      notes: {}
    }
  },
  async mounted () {
    this.loadDevice()
    this.loading = false
  },
  methods: {
    loadDevice: async function () {
      this.displayDevice = {
      }
      if (this.device.icon !== undefined && this.device.icon !== '') {
        let selectedIcon = this.icons.find(x => x.name === this.device.icon)
        if (selectedIcon === undefined) {
          selectedIcon = this.icons[0]
        }
        selectedIcon.selected = true
      }

      // Hide Controls so the parent modal can display them
      if (this.isModal) {
        this.showButtons = false
      }
      console.log('Loaded Device: ', this.device)
      this.deviceFeatures = await this.$deviceService.getDeviceFeatures(this.device.device_type)
      this.loading = false
    },
    selectIcon: function (icon) {
      if (this.editable) {
        this.icons.forEach(x => { x.selected = false })
        icon.selected = true
      }
    },
    getSelectedIcon: function () {
      return this.icons.find(x => x.selected).name
    },
    saveChanges: async function () {
      if (this.$v.$anyError) {
        ErrorHelper.displayGeneralErrorToast('Some Fields Contain Invalid Data. Please fix them.', 'Invalid Fields')
        return
      }
      // Update Selected icon
      this.device.icon = this.getSelectedIcon()
      try {
        let res = await DataProvider.updateDeviceDetails(
          this.device.device_imei,
          this.device.device_name,
          this.device.registration,
          this.device.plant_num,
          this.device.icon,
          this.device.overspeed_limit,
          this.device.odometer_reading,
          this.device.hours_tracked,
          this.device.service_schedule_mode,
          this.device.service_frequency,
          this.device.notes
        )

        if (res.success) {
          this.$v.$reset()
          this.$emit('save') // Emit a save event, since we've just saved
          this.clickClose()
        } else {
          this.$bvToast.toast('Uh Oh! Something went wrong saving changes to the server. Please try again later.',
            {
              title: 'Server Communication Error',
              variant: 'danger',
              toaster: 'b-toaster-top-center'
            })
        }
      } catch (err) {
        console.error(err)
        this.$bvToast.toast('An Error Occurrred Getting a Device List from the Server.',
          {
            title: 'Server Communication Error',
            variant: 'danger',
            toaster: 'b-toaster-top-center'
          })
      }
    },
    clickClose: async function () {
      if (this.$v.$anyDirty) {
        let res = await this.$bvModal.msgBoxConfirm('You have unsaved changes, are you sure you want to close this window?', {
          title: 'Confirm Discard Changes',
          okVariant: 'warning',
          centered: true
        })
        if (!res) {
          return
        }
      }
      // Check if we've been passed a modal to close.
      if (this.modal) {
        this.$bvModal.hide(this.modal)
      } else {
        this.$router.go(-1)
      }
    },

    formatTimestamp: function (timestamp) {
      return moment.unix(timestamp).local().format('Do MMMM, YYYY')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '../../variables';

  .device-label{
    font-family: 'Open Sans', sans-serif;
    color: $theme-color-primary-3;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
  }

  .device-code{
    color: $text-color-invert;
    margin-left: 5%;
  }

  .footer{
    width: 100%;
    bottom: 2%;
    display: flex;
    justify-content: flex-end;
    margin: 0 1% 0 1%;
  }

  .device-icon {
    background: $theme-color-background-4;
    border: 2px inset #999999;
    border-radius: 4px;
  }

  .icon_select_color {
    border: 2px solid $theme-color-primary-3;
  }

</style>
