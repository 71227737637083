var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "device-view-schedule" }, [
    !_vm.loading
      ? _c("div", { staticClass: "flex-row" }, [
          _c(
            "div",
            { staticClass: "col-8" },
            [
              _c(
                "b-check",
                {
                  staticClass: "device-label",
                  attrs: { "button-variant": "success" },
                  model: {
                    value: _vm.view_schedule.enabled,
                    callback: function($$v) {
                      _vm.$set(_vm.view_schedule, "enabled", $$v)
                    },
                    expression: "view_schedule.enabled"
                  }
                },
                [_vm._v("Enable View Schedule? ")]
              )
            ],
            1
          )
        ])
      : _vm._e(),
    _c("div", { staticClass: "green-divider" }),
    _c("div", { staticClass: "flex-row flex-nowrap" }, [
      _vm.view_schedule.enabled
        ? _c(
            "div",
            { staticClass: "col-4" },
            [
              _c(
                "label",
                {
                  staticClass: "device-label",
                  attrs: { for: "timezone-select" }
                },
                [
                  _vm._v("Timezone: "),
                  _c("i", {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover",
                        modifiers: { hover: true }
                      }
                    ],
                    staticClass: "row-icon-button",
                    class: _vm.$config.icons.general.info,
                    attrs: {
                      title: "Which timezone is the schedule applicable for?"
                    }
                  })
                ]
              ),
              _c("b-form-select", {
                attrs: { options: _vm.timezones },
                model: {
                  value: _vm.view_schedule.effective_time_zone,
                  callback: function($$v) {
                    _vm.$set(_vm.view_schedule, "effective_time_zone", $$v)
                  },
                  expression: "view_schedule.effective_time_zone"
                }
              }),
              _c(
                "button",
                {
                  staticClass: "button",
                  attrs: { id: "button-close" },
                  on: {
                    click: function($event) {
                      return _vm.setTimezoneToCurrent()
                    }
                  }
                },
                [_vm._v("Current")]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._m(0)
    ]),
    !_vm.loading && _vm.view_schedule.enabled
      ? _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c(
              "table",
              [
                _vm._m(1),
                _vm._l(_vm.daysOfWeek, function(day) {
                  return _c("tr", { key: day }, [
                    _c("th", [_vm._v(_vm._s(day))]),
                    _c(
                      "td",
                      { staticStyle: { "z-index": "2" } },
                      [
                        _c(
                          "b-input-group",
                          { staticClass: "mb-2" },
                          [
                            _c(
                              "b-form-input",
                              {
                                model: {
                                  value: _vm.view_schedule[day].start_time,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.view_schedule[day],
                                      "start_time",
                                      $$v
                                    )
                                  },
                                  expression: "view_schedule[day].start_time"
                                }
                              },
                              [_vm._v("Text")]
                            ),
                            _c(
                              "b-input-group-append",
                              { staticStyle: { "margin-top": "-2px" } },
                              [
                                _c("b-form-timepicker", {
                                  attrs: {
                                    dropup: "",
                                    "button-only": "",
                                    locale: "en"
                                  },
                                  model: {
                                    value: _vm.view_schedule[day].start_time,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.view_schedule[day],
                                        "start_time",
                                        $$v
                                      )
                                    },
                                    expression: "view_schedule[day].start_time"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "td",
                      { staticStyle: { "z-index": "1" } },
                      [
                        _c(
                          "b-input-group",
                          { staticClass: "mb-2" },
                          [
                            _c(
                              "b-form-input",
                              {
                                model: {
                                  value: _vm.view_schedule[day].end_time,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.view_schedule[day],
                                      "end_time",
                                      $$v
                                    )
                                  },
                                  expression: "view_schedule[day].end_time"
                                }
                              },
                              [_vm._v("Text")]
                            ),
                            _c(
                              "b-input-group-append",
                              { staticStyle: { "margin-top": "-2px" } },
                              [
                                _c("b-form-timepicker", {
                                  staticClass: "text-field w-input",
                                  attrs: {
                                    dropup: "",
                                    "button-only": "",
                                    locale: "en"
                                  },
                                  model: {
                                    value: _vm.view_schedule[day].end_time,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.view_schedule[day],
                                        "end_time",
                                        $$v
                                      )
                                    },
                                    expression: "view_schedule[day].end_time"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                })
              ],
              2
            ),
            _c("div")
          ])
        ])
      : _vm._e(),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 footer mt-2" }, [
        _vm.editable
          ? _c(
              "button",
              {
                staticClass: "button",
                attrs: { id: "button-save" },
                on: {
                  click: function($event) {
                    return _vm.saveChanges()
                  }
                }
              },
              [_vm._v("Save Changes")]
            )
          : _vm._e(),
        _c(
          "button",
          {
            staticClass: "button",
            attrs: { id: "button-close" },
            on: {
              click: function($event) {
                return _vm.clickClose()
              }
            }
          },
          [_vm._v("Close")]
        ),
        _vm.view_schedule.enabled
          ? _c(
              "button",
              {
                staticClass: "button",
                attrs: { id: "button-default" },
                on: {
                  click: function($event) {
                    return _vm.setDefaultViewSchedule()
                  }
                }
              },
              [_vm._v("Default")]
            )
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hint-box" }, [
      _c("h5", [_vm._v("View Schedule")]),
      _c("p", [
        _vm._v(
          " A View Schedule denotes which trips a vehicle takes can be seen by those with read-only access. Only trips that fall entirely within the View Schedule can be seen by those people. If someone has a Device Share with write access or higher, they can see all trips. If the View Schedule is not enabled, then someone with read-only access can view all trips. "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("Day")]),
      _c("th", [_vm._v("Start")]),
      _c("th", [_vm._v("End")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }