var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c(
        "div",
        { staticClass: "device-upload-schedule" },
        [
          _c(
            "b-alert",
            {
              attrs: { show: _vm.uploadSchedule.length > 1, variant: "warning" }
            },
            [
              _c("strong", [_vm._v("Warning:")]),
              _vm._v(
                " Multiple upload times will decrease the battery life of your tracker. The projected battery life is based on a single upload time each day. "
              )
            ]
          ),
          _c(
            "div",
            { staticClass: "d-flex flex-row justify-content-between w-100" },
            [
              _c(
                "div",
                { staticClass: "upload-times" },
                [
                  _c(
                    "label",
                    { staticClass: "green-label feature-label p-1" },
                    [
                      _vm._v(" Upload Times "),
                      _vm.featureState === true
                        ? _c("i", {
                            directives: [
                              { name: "b-tooltip", rawName: "v-b-tooltip" }
                            ],
                            staticClass: "control-indicator",
                            class: _vm.$config.icons.feature_controls.confirmed,
                            attrs: {
                              title: "Device Synced @ " + _vm.timestampText
                            }
                          })
                        : _vm._e(),
                      _vm.featureState === false
                        ? _c("i", {
                            directives: [
                              { name: "b-tooltip", rawName: "v-b-tooltip" }
                            ],
                            staticClass: "control-indicator pending",
                            class: _vm.$config.icons.feature_controls.sent,
                            attrs: {
                              title:
                                "Pending Device Sync @ " + _vm.timestampText
                            }
                          })
                        : _vm._e(),
                      _vm.featureState === null
                        ? _c("i", {
                            directives: [
                              { name: "b-tooltip", rawName: "v-b-tooltip" }
                            ],
                            staticClass: "control-indicator failed",
                            class: _vm.$config.icons.feature_controls.failed,
                            attrs: { title: "No Data " + _vm.timestampText }
                          })
                        : _vm._e()
                    ]
                  ),
                  _c("b-select", {
                    staticClass: "text-input",
                    attrs: {
                      options: _vm.uploadScheduleSorted,
                      "select-size": 8
                    },
                    on: { change: _vm.selectUploadEntry },
                    model: {
                      value: _vm.selectedValue,
                      callback: function($$v) {
                        _vm.selectedValue = $$v
                      },
                      expression: "selectedValue"
                    }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "control-panel" }, [
                _c("label", { staticClass: "green-label feature-label" }, [
                  _vm._v("Edit Time")
                ]),
                _c("p", { staticClass: "desc-text" }, [
                  _vm._v(
                    "Set the times that the tracking device will upload it's location to the server. The device schedule will be uploaded to the device when it next connected to the server. The 'Upload Times' box to the left contains the schedule, use the time control and the add/update/remove buttons below to modify the schedule. "
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "flex-grow-1" },
                  [
                    _c("b-timepicker", {
                      staticClass: "text-input",
                      attrs: { "close-button-variant": "danger" },
                      model: {
                        value: _vm.selectedTime,
                        callback: function($$v) {
                          _vm.selectedTime = $$v
                        },
                        expression: "selectedTime"
                      }
                    }),
                    _c(
                      "div",
                      { staticClass: "button-row" },
                      [
                        _c(
                          "b-button",
                          { attrs: { pill: "" }, on: { click: _vm.addEntry } },
                          [_vm._v("Add")]
                        ),
                        _c(
                          "b-button",
                          {
                            attrs: { pill: "", disabled: !_vm.selectedValue },
                            on: { click: _vm.editSelected }
                          },
                          [_vm._v("Update")]
                        ),
                        _c(
                          "b-button",
                          {
                            attrs: { pill: "", disabled: !_vm.selectedValue },
                            on: { click: _vm.removeEntry }
                          },
                          [_vm._v("Remove")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ]
          ),
          _c(
            "div",
            { staticClass: "footer mt-2" },
            [
              _c(
                "b-button",
                {
                  staticClass: "button",
                  attrs: { id: "button-close" },
                  on: {
                    click: function($event) {
                      return _vm.$bvModal.hide(_vm.modal)
                    }
                  }
                },
                [_vm._v("Close")]
              ),
              _vm.editable
                ? _c(
                    "b-button",
                    {
                      staticClass: "button",
                      attrs: { id: "button-save", disabled: !_vm.dirty },
                      on: {
                        click: function($event) {
                          return _vm.saveChanges()
                        }
                      }
                    },
                    [_vm._v("Save Changes")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }