import * as config from '../../config'
import Vue from 'vue'
import DisconnectPopup from './componenets/DisconnectPopup.vue'
/*
    Helper Class for Handling Errors.
 */

// TODO - Turn this into a class.

let toastr
let connectivityComponent
let showConnectivityAlarm = true


// Anti-spam timer for error messages. NOTE: Toastr doesn't provide a view of the toast queue or any sort of handle when
// toast is generated, so this is a bit of a hacky workaround.
let lastErrorTimestamp

// Setup Error Handler. Called Globally on initialisation in Main.js
export function initialise (toastrHandle) {
  toastr = toastrHandle
  // Event Listener for de-spamming, but the bvEvent doesn't seem to contain a reference to the toast.
  // toastr._root.$on('bv::toast:show', (bvEvent) => {
  //   console.log('Toast is about to be shown', bvEvent)
  //   toastr.hide()
  // })
}

// Display Error Toast to User, based on Data Provider & API standard responses.
export function displayDataErrorToast (responseData) {
  // Check if the server provided a detailed error.
  if (responseData.error.hasOwnProperty('action')) {
    this.displayGeneralErrorToast(responseData.error.action, responseData.error.message)
    // Otherwise use our Data Provider Message
  } else if (responseData.hasOwnProperty('message')) {
    this.displayGeneralErrorToast(responseData.message, 'An Error Occurred')
  }
}

// Display Error Toast to User, based on Data Provider & API standard responses.
export function displayGeneralErrorToast (message, title, timeout = 5000) {
  // Anti-Spam
  let now = new Date().getTime()
  console.log(toastr)
  if (!lastErrorTimestamp || (lastErrorTimestamp + config.general.minErrorAlertTime) <= now) {
    lastErrorTimestamp = now
    let noAutoHide = (timeout === 0)
    toastr.show()
    toastr.toast(message,
      {
        title: title,
        variant: 'danger',
        toaster: 'b-toaster-top-center',
        autoHideDelay: timeout,
        noAutoHide: noAutoHide
      })
  } else {
    console.log('Error Helper: Suppressed Popup Alerts based on MinErrorAlertTime config.')
  }
}

// Display Error Toast to User, based on Data Provider & API standard responses.
export function displayGeneralWarningToast (message, title) {
  // Check if the server provided a detailed error.
  toastr.toast(message,
    {
      title: title,
      variant: 'warning',
      toaster: 'b-toaster-top-center'
    })
}

// Global Exception Handler. Plugged into Vue config to capture unhandled errors.
// TODO - Consider error logging rather than user alerting?
export function handleError (err, vm, info) {
  toastr.toast('An Application Error Occurred. Check Console logs for details.',
    {
      title: 'Something went wrong!',
      variant: 'danger',
      toaster: 'b-toaster-top-center'
    })
  console.error('Unhandled Error: ', err)
}

function createConnectivityComponent () {
  let ComponentClass = Vue.extend(DisconnectPopup)
  let instance = new ComponentClass({
    propsData: {
      show: showConnectivityAlarm
    }
  })
  instance.$mount('#connectivity-alarms')
  return instance
}

export function showDisconnectPopup () {
  if (!connectivityComponent) {
    connectivityComponent = createConnectivityComponent()
  } else {
    connectivityComponent.show = true
    showConnectivityAlarm = true
  }
}

export function hideDisconnectPopup () {
  if (connectivityComponent) {
    connectivityComponent.show = false
    showConnectivityAlarm = false
  }
}
