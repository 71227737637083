var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.eventList
      ? _c(
          "div",
          { staticClass: "event-container" },
          _vm._l(_vm.eventList, function(event, idx) {
            return _c("EventAvatar", {
              key: idx,
              class: _vm.applyHighlightFunc(event),
              attrs: {
                event: event,
                selected: _vm.selectedEvents.includes(event)
              },
              on: {
                click: function($event) {
                  return _vm.clickEvent(event, $event)
                }
              }
            })
          }),
          1
        )
      : _vm._e(),
    !_vm.eventList || _vm.eventList.length === 0
      ? _c("div", { staticClass: "empty-text" }, [_vm._v(" No Events ")])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }