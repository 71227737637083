<template>
  <div class="device-upload-schedule" v-if="!loading">
    <b-alert :show="uploadSchedule.length > 1" variant="warning">
      <strong>Warning:</strong> Multiple upload times will decrease the battery life of your tracker. The projected battery life is based
      on a single upload time each day.
    </b-alert>
    <div class="d-flex flex-row justify-content-between w-100">
      <div class="upload-times">
        <label class="green-label feature-label p-1"> Upload Times
          <i v-if="featureState === true"
             class="control-indicator" :class="$config.icons.feature_controls.confirmed"
             v-b-tooltip :title="'Device Synced @ '+timestampText"></i>
          <i v-if="featureState === false"
             class="control-indicator pending" :class="$config.icons.feature_controls.sent"
             v-b-tooltip :title="'Pending Device Sync @ '+timestampText"></i>
          <i v-if="featureState === null"
             class="control-indicator failed" :class="$config.icons.feature_controls.failed"
             v-b-tooltip :title="'No Data '+timestampText"></i>
        </label>
        <b-select :options="uploadScheduleSorted" :select-size="8" v-model="selectedValue" @change="selectUploadEntry"
                  class="text-input"></b-select>
      </div>

      <div class="control-panel">
        <label class="green-label feature-label">Edit Time</label>
        <p class="desc-text">Set the times that the tracking device will upload it's location to the server.
          The device schedule will be uploaded to the device when it next connected to the server. The 'Upload Times'
        box to the left contains the schedule, use the time control and the add/update/remove buttons below to modify
        the schedule. </p>
        <div class="flex-grow-1">
          <b-timepicker v-model="selectedTime" class="text-input" close-button-variant="danger">
          </b-timepicker>
          <div class="button-row">
            <b-button pill v-on:click="addEntry">Add</b-button>
            <b-button pill v-on:click="editSelected" :disabled="!selectedValue">Update</b-button>
            <b-button pill v-on:click="removeEntry" :disabled="!selectedValue">Remove</b-button>
          </div>
        </div>
      </div>
    </div>
    <div class="footer mt-2">
      <b-button class="button" @click="$bvModal.hide(modal)" id="button-close">Close</b-button>
      <b-button class="button" @click="saveChanges()" id="button-save" v-if="editable" :disabled="!dirty">Save Changes</b-button>
    </div>
  </div>
</template>

<script>
import * as DataProvider from '@/components/helpers/DataProvider'
import * as ErrorHelper from '@/components/helpers/ErrorHelper'
import * as AlertHelper from '@/components/helpers/AlertHelper'
import moment from 'moment'

export default {
  name: 'device-upload-schedule',
  props: {
    device: Object,
    editable: Boolean,
    modal: String
  },
  data: function () {
    return {
      showButtons: true,
      loading: true,
      displayDevice: null,
      uploadSchedule: [
      ],
      selectedValue: '',
      selectedTime: '00:00:00',
      featureState: null,
      timestampText: '',
      dirty: false
    }
  },
  async mounted () {
    if (this.device.hasOwnProperty('scheduled_uploads')) {
      this.parseUploadScheduleData(this.device.scheduled_uploads)
    } else {
      this.parseUploadScheduleData(await this.getUploadSchedule())
    }
    // this.featureState = featureValue.from_device
    this.loading = false
  },
  methods: {
    parseUploadScheduleData (scheduleData) {
      console.log(scheduleData)
      if (scheduleData.hasOwnProperty('from_device')) {
        this.featureState = scheduleData.from_device
      }
      if (scheduleData.hasOwnProperty('timestamp')) {
        this.timestampText = this.formatTimestamp(scheduleData.timestamp)
      }
      if (scheduleData.hasOwnProperty('value')) {
        if (scheduleData.value) {
          this.uploadSchedule = scheduleData.value.map(utcTime => {
            console.log(moment.utc(utcTime.toString().padStart(4, '0')))
            return moment.utc(utcTime.toString().padStart(4, '0'), 'HHmm').local().format('HHmm')
          })
        } else {
          this.uploadSchedule = []
        }
      }
    },
    /***
     * Fetch the Upload Schedule from the device
     */
    getUploadSchedule: async function () {
      let resp = await DataProvider.getDeviceProperty(this.device.device_imei, 'scheduled_uploads')
      if (resp.success) {
        // Check to see if the server returned the prop wee asked for. If not return null (better than an empty object)
        if (resp.data.hasOwnProperty('scheduled_uploads')) {
          console.log(resp.data.scheduled_uploads)
          return resp.data.scheduled_uploads
        } else {
          return null
        }
      } else {
        ErrorHelper.displayGeneralErrorToast(resp)
      }
    },
    /***
     * Set the upload schedule for a Device.
     * @returns {Promise<null|*>}
     */
    setUploadSchedule: async function (newSchedule) {
      let utcTimes = newSchedule.map(timeString => {
        return parseInt(moment(timeString, 'HHmm').utc().format('HHmm'))
      })
      // let scheduleInts = newSchedule.map(x => parseInt(x))
      console.log(utcTimes)
      let resp = await DataProvider.setDeviceProperty(this.device.device_imei, {
        scheduled_uploads: utcTimes
      })
      if (resp.success) {
        // Check to see if the server returned the prop wee asked for. If not return null (better than an empty object)
        AlertHelper.successToast('Upload Schedule Saved', 'Success')
        this.featureState = false
      } else {
        ErrorHelper.displayGeneralErrorToast(resp)
      }
    },
    async saveChanges () {
      if (this.uploadSchedule.length < 1) {
        ErrorHelper.displayGeneralErrorToast('Upload schedule is empty. You need at least one upload time.',
          'No Upload Times')
        return
      }
      if (this.uploadSchedule.length > 1) {
        let confirm = await this.$bvModal.msgBoxConfirm('Multiple Upload times will reduce the service life of this device. ' +
          'By clicking OK you confirm that you understand and will accept that the device will need to be replaced sooner ' +
          'due to the additional battery usage.', {
          title: 'Confirm Upload Schedule Changes',
          okVariant: 'warning',
          centered: true
        })
        if (!confirm) {
          return
        }
      }
      await this.setUploadSchedule(this.uploadSchedule)
    },
    /***
     * Return a formatted Timestamp String or an empty string
     * @param timestamp Unix Timestamp
     * @returns {string}
     */
    formatTimestamp: function (timestamp) {
      if (timestamp) {
        return moment.unix(timestamp).local().format('YYYY/MM/DD HH:mm')
      } else {
        return ''
      }
    },
    convertNumericHoursToTime (numericTime) {
      console.log(`${numericTime.substring(0, 2)}:${numericTime.substring(2, 4)}:00`)
      return `${numericTime.substring(0, 2)}:${numericTime.substring(2, 4)}:00`
    },
    convertTimeToNumericHours (timeString) {
      console.log(`${timeString.substring(0, 2)}${timeString.substring(3, 5)}`)
      return `${timeString.substring(0, 2)}${timeString.substring(3, 5)}`
    },
    selectUploadEntry (x) {
      if (x) {
        this.selectedTime = this.convertNumericHoursToTime(x)
      }
    },
    addEntry () {
      this.dirty = true
      let timeValue = this.convertTimeToNumericHours(this.selectedTime)
      if (this.uploadSchedule.includes(timeValue)) {
        ErrorHelper.displayGeneralWarningToast('That time is already in the upload schedule.')
      } else {
        this.uploadSchedule.push(timeValue)
        this.selectedValue = timeValue
      }
    },
    removeEntry () {
      this.dirty = true
      this.uploadSchedule = this.uploadSchedule.filter(x => x !== this.selectedValue)
      this.selectedValue = null
    },
    editSelected () {
      this.dirty = true
      this.removeEntry()
      this.addEntry()
    }
  },
  computed: {
    uploadScheduleSorted () {
      let tempSchedule = [...this.uploadSchedule]
      return tempSchedule.sort()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '@/variables';

  .device-upload-schedule{
    font-family: 'Open Sans', sans-serif;
    color: $theme-color-primary-3;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .control-panel {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex-grow: 1;
  }

  //.button {
  //  display: flex;
  //  flex-direction: row;
  //  flex-grow: 2;
  //  align-items: center;
  //  background: $theme-color-background-5;
  //  border-radius: 5px;
  //  padding: 5px;
  //  width: 100%;
  //}

  .control-indicator {
    font-size: 24px;
    align-self: center;
    margin-left: 1em;
  }

  .feature-label {
    margin: 5px 5px 0 5px;
  }

  input {
    margin-bottom: 0
  }

  .footer{
    width: 100%;
    bottom: 2%;
    display: flex;
    justify-content: flex-end;
    margin: 0 1% 0 1%;
  }

  .desc-text {
    color: $text-color-invert;
    flex-grow: 2;
    margin: 10px 0 0 0;
    flex-wrap: wrap;
    padding: 10px;
  }

  .upload-times {
    min-width: 180px;
  }

</style>
