<template>
  <div class="form-container" v-if="template">
    <json-forms v-if="customAjv"
      :schema="schema"
      :uischema="uiSchema"
      :data="data"
      :renderers="renderers"
      :ajv="customAjv"
      @change="onChange"
      ref="jsonForm"
      :readonly="isReadOnly"
    ></json-forms>

<!--    <div class="form-footer">-->
<!--      <button class="btn btn-success w-100" @click="submitTemplate" v-if="!disabled">Save</button>-->
<!--    </div>-->

  </div>
</template>

<script>

import {JsonForms} from '@jsonforms/vue2'
import {vanillaRenderers, defaultStyles, mergeStyles} from '@jsonforms/vue2-vanilla'
import {booleanRendererentry, default as boolRenderer} from '@/components/forms/form_renderers/BooleanControlRenderer.vue'
import {textareaRendererEntry, default as textareaRenderer} from '@/components/forms/form_renderers/TextAreaRenderer.vue'
import {enumRendererEntry, default as enumRenderer} from '@/components/forms/form_renderers/EnumControlRenderer.vue'
import {createAjv} from '@jsonforms/core'


const myStyles = mergeStyles(defaultStyles, { control: { label: 'mylabel' } })
const renderers = [
  booleanRendererentry,
  textareaRendererEntry,
  enumRendererEntry,
  ...vanillaRenderers
  // here you can add custom renderers
]

export default {
  name: 'FormTemplate',
  // eslint-disable-next-line vue/no-unused-components
  components: {JsonForms, boolRenderer, textareaRenderer, enumRenderer},
  props: {
    template: Object,
    form_data: Object,
    disabled: Boolean
  },
  provide() {
    return {
      styles: myStyles
    }
  },
  data () {
    return {
      renderers: Object.freeze(renderers),
      data: {},
      formOutput: {},
      customAjv: null,
      ignoredFirst: false
    }
  },
  async mounted () {
    this.customAjv = this.getAJV()
    if (this.form_data) {
      console.log('Loading With Form Data; ', this.form_data)
      this.data = this.form_data
    }
  },
  methods: {
    getAJV() {
      let ajv = createAjv({
        useDefaults: true
      })
      console.log(ajv)
      // ajv.addMetaSchema(ajv2019)
      return ajv
    },
    submitTemplate() {
      console.log('Data: ', {...this.formOutput})
    },
    onChange (c) {
      // The JSON forms object emits a change when it first loads with a blank Data object, even if it's given a
      // template and form data. This filters out that erroneous data.
      if (!this.ignoredFirst) {
        this.ignoredFirst = true
        return
      }
      this.formOutput = c.data
      this.$emit('change', c)
    }
  },
  computed: {
    schema () {
      console.log(this.template)
      if (this.template && Object.hasOwn(this.template.schema, 'json_schema')) {
        console.log('Found JSON Template! ', this.template.schema.json_schema)
        return this.template.schema.json_schema
      } else {
        return {}
      }
    },
    uiSchema () {
      if (this.template && Object.hasOwn(this.template.schema, 'ui_schema')) {
        console.log('Found UI Template! ', this.template.schema.ui_schema)
        return this.template.schema.ui_schema
      } else {
        return {}
      }
    },
    isReadOnly() {
      return this.disabled
    }
  },
  watch: {
    template () {
      console.log('Template Change!')
    },
    form_data (x) {
      console.log('FormData Changed: ', x)
      this.data = this.form_data
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../variables';

.form-container {
  display: flex;
  flex-flow: column wrap;
}

.form-footer {
  display: flex;
  flex-flow: row nowrap;
  .btn {
    margin: 1em 1em;
  }
}


.device-image img {
  width: 100%;
}

h3 {
  font-weight: 600;
  color: $theme-color-primary-3;
  font-size: 24px;
}


/* Very Narrow Screens */
@media screen and (max-width: 300px) {
  .device-container {
    padding: 5px 5px;
  }

  .status-icons {
    display: none;
  }
}
</style>
