var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edit-user-notifications" },
    [
      _c(
        "div",
        [
          _vm._v(" Please make sure you have read the "),
          _c(
            "router-link",
            { attrs: { to: { path: "/preferences", query: { tab: "help" } } } },
            [_vm._v(" Help Section on Notification Rules")]
          ),
          _vm._v(" before you use this feature. ")
        ],
        1
      ),
      _c("LoadingBox", { attrs: { loading: _vm.loading } }),
      _c("div", { staticClass: "flex-row justify-content-end slim-controls" }, [
        !_vm.device
          ? _c(
              "button",
              { staticClass: "danger", on: { click: _vm.resetRules } },
              [_vm._v("Reset All")]
            )
          : _vm._e()
      ]),
      _c(
        "div",
        { staticClass: "main-container" },
        _vm._l(_vm.categories, function(category, idx) {
          return _c("div", { key: idx, staticClass: "category-container" }, [
            _c("div", { staticClass: "category-title-row" }, [
              _c("div", { staticClass: "sub-item-name" }, [
                !category.expanded
                  ? _c("i", {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true }
                        }
                      ],
                      staticClass: "icon-button",
                      class: _vm.$config.icons.twisties.collapsed_before,
                      attrs: { title: "Show Rules" },
                      on: {
                        click: function($event) {
                          category.expanded = true
                        }
                      }
                    })
                  : _vm._e(),
                category.expanded
                  ? _c("i", {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true }
                        }
                      ],
                      staticClass: "icon-button",
                      class: _vm.$config.icons.twisties.expanded,
                      attrs: { title: "Hide Rules" },
                      on: {
                        click: function($event) {
                          category.expanded = false
                        }
                      }
                    })
                  : _vm._e(),
                _c(
                  "strong",
                  {
                    on: {
                      click: function($event) {
                        category.expanded = !category.expanded
                      }
                    }
                  },
                  [_vm._v(_vm._s(category.name))]
                ),
                _vm._v(" Rules "),
                _c("i", {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      modifiers: { hover: true }
                    }
                  ],
                  staticClass: "icon-button",
                  class: _vm.$config.icons.general.info,
                  attrs: { title: category.help }
                })
              ]),
              _c("i", {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover",
                    modifiers: { hover: true }
                  }
                ],
                staticClass: "icon-button",
                class: _vm.$config.icons.general.add,
                attrs: { title: "Add Rule" },
                on: {
                  click: function($event) {
                    return _vm.addRule(category)
                  }
                }
              })
            ]),
            _c("div", { staticClass: "green-divider" }),
            category.expanded
              ? _c(
                  "div",
                  { staticClass: "category-rules-container" },
                  [
                    _c("notification-subscription-list", {
                      attrs: { data: category.children, user: _vm.user },
                      on: {
                        change: function($event) {
                          return _vm.categoryChanged(category, $event)
                        }
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }