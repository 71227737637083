<template>
  <div class="search-filters">
    <div  class="filter-label" v-for="(filter, idx) of filtersInternal" :key="filter.fieldName">
<!--      String Search Filter-->
      <div class="filter-label" v-if="filter.type === 'string'">{{filter.label}}</div>
      <b-form-input v-if="filter.type === 'string'" @change="dataChange(idx, $event)"
        v-model="filterValues[idx]"
        type="search"
        :placeholder="filter.placeholder"
        class="text-input sm"
        size="sm"
        @keypress="inputPress(filter, $event)"
      ></b-form-input>
<!--      Select Box Filter -->
      <div class="filter-label" v-if="filter.type === 'select'">{{filter.label}}</div>
      <b-form-select v-if="filter.type === 'select'"
                     v-model="filterValues[idx]"
                     :options="filter.options"
                     :placeholder="filter.placeholder"
                     class="text-input sm"
                     @change="dataChange(idx, $event)"
                   ></b-form-select>
<!--    Boolean Search Filter-->
    <b-form-checkbox class="filter-label" v-if="filter.type === 'boolean'" @change="dataChange(idx, $event)"
                       v-model="filterValues[idx]">{{filter.label}}</b-form-checkbox>
      <!--    Date Search Filter-->
      <div class="filter-label" v-if="filter.type === 'date'">{{filter.label}}</div>
      <input type="date"  v-if="filter.type === 'date'" @input="dataChange(idx, $event)"
                          :placeholder="filter.placeholder"
        v-model="filterValues[idx]" class="" size="sm"
                          maxlength="256"
                          :date-format-options="{ 'year': 'numeric', 'month': 'numeric', 'day': 'numeric', 'weekday': 'short' }"

      ></input>
   </div>
  </div>
</template>
<script>

import moment from 'moment'

export default {
  name: 'search-filters',
  components: {},
  props: {
    filters: Array,
    defaultSettings: Object // Prop to set initial filter values (e.g. to make 'archived' default to true)
  },
  data: function () {
    return {
      // Default Values by Type
      defaultValues: {
        string: '',
        boolean: false,
        select: '',
        date: ''
      },
      // Holds the definition of each filter
      filtersInternal: [
        {
          type: '',
          value: '',
          fieldName: '',
          label: ''
        }
      ],
      // Holds the currently set values for each Filter
      filterValues: {
      }
    }
  },
  created () {
    this.filtersInternal = [...this.filters]
    this.filtersInternal.forEach((x, idx) => {
      //
      this.$set(this.filterValues, idx, this.defaultValues[x.type])
    })
  },
  mounted () {
    // TODO - Refactor this component to NOT use index values in arrays.
    if (this.defaultSettings) {
      Object.keys(this.defaultSettings).forEach(x => {
        let iFilter = this.filtersInternal.findIndex(f => f.fieldName === x)
        if (iFilter > -1) {
          this.filterValues[iFilter] = this.defaultSettings[x]
        } else {
          console.error('Unable to set Default Filter value, unknown filter: ' + x)
        }
      })
    }
  },
  methods: {
    dataChange: function (idx, value) {
      this.$set(this.filterValues, idx, value)
      this.$emit('change', this.getFilterObj())
    },
    inputPress: function (filter, event) {
      if (event.charCode === 13) {
        filter.value = event.target.value
        this.$emit('change', this.getFilterObj())
        this.$emit('press-enter')
        event.stopPropagation()
      }
    },
    getFilterObj: function () {
      let filterOutput = {}
      this.filtersInternal.forEach((filter, idx) => {
        if ((filter.type === 'string' || filter.type === 'select') && this.filterValues[idx].length > 0) {
          filterOutput[filter.fieldName] = this.filterValues[idx]
        } else if (filter.type === 'date' && this.filterValues[idx].length > 0) {
          // If it's a date, convert to GMT for the server
          filterOutput[filter.fieldName] = moment(this.filterValues[idx]).utc().toISOString()
        } else if (filter.type === 'boolean' && this.filterValues[idx]) {
          filterOutput[filter.fieldName] = this.filterValues[idx]
        }
      })
      return filterOutput
    },
    reset: function () {
      for (let i in this.filtersInternal) {
        this.$set(this.filterValues, i, this.defaultValues[this.filtersInternal[i].type])
      }
      this.$emit('change', this.getFilterObj())
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '../../variables';

  .search-filters{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 5px;
    padding: 3px;
    background: $theme-color-background-1;
    border: 1px solid $theme-color-primary-3;
  }

  .filter-label {
    font-family: 'Open Sans', sans-serif;
    color: $theme-color-primary-3;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 1px;
    margin: 0px 5px;
  }

  .sm {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    height: calc(1.5em + 0.5rem + 2px);
  }
</style>
