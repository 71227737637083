var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "edit-event" }, [
    !_vm.loading ? _c("div", {}) : _vm._e(),
    _c("div", { staticClass: "flex-row" }, [
      _c("i", { staticClass: "event-icon", class: _vm.eventIcon }),
      _c(
        "div",
        { staticClass: "flex-column flex-grow-1" },
        [
          _c(
            "label",
            { staticClass: "device-label", attrs: { for: "event_type" } },
            [_vm._v("Event Type")]
          ),
          _vm.enableEdit
            ? _c("b-select", {
                attrs: {
                  type: "text",
                  options: _vm.creatableEventTypes,
                  maxlength: "256",
                  placeholder: "Select Event Type",
                  id: "event_type",
                  disabled: !_vm.enableEdit
                },
                on: {
                  change: function($event) {
                    return _vm.changeEventType($event)
                  }
                },
                model: {
                  value: _vm.eventInternal.event_type,
                  callback: function($$v) {
                    _vm.$set(_vm.eventInternal, "event_type", $$v)
                  },
                  expression: "eventInternal.event_type"
                }
              })
            : _c("div", [_c("h4", [_vm._v(_vm._s(_vm.eventConfig.name))])])
        ],
        1
      )
    ]),
    _vm.eventInternal.event_type !== null
      ? _c(
          "div",
          { staticClass: "event-prop w-100 z-1" },
          [
            _c("label", { staticClass: "device-label" }, [
              _vm._v("Event Start")
            ]),
            _vm.enableEdit
              ? _c("b-input-group", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.eventInternal.startDate,
                        expression: "eventInternal.startDate"
                      }
                    ],
                    attrs: {
                      type: "date",
                      size: "sm",
                      maxlength: "256",
                      placeholder: "Start Date",
                      id: "event_start_date",
                      disabled: !_vm.enableEdit
                    },
                    domProps: { value: _vm.eventInternal.startDate },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.eventInternal,
                          "startDate",
                          $event.target.value
                        )
                      }
                    }
                  }),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.eventInternal.startTime,
                        expression: "eventInternal.startTime"
                      }
                    ],
                    attrs: {
                      type: "time",
                      size: "sm",
                      maxlength: "256",
                      placeholder: "Start Time",
                      id: "event_start_time",
                      disabled: !_vm.enableEdit
                    },
                    domProps: { value: _vm.eventInternal.startTime },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.eventInternal,
                          "startTime",
                          $event.target.value
                        )
                      }
                    }
                  })
                ])
              : _c("div", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.eventInternal.startDate) +
                      " - " +
                      _vm._s(_vm.eventInternal.startTime) +
                      " "
                  )
                ])
          ],
          1
        )
      : _vm._e(),
    _vm.eventConfig !== null
      ? _c(
          "div",
          { staticClass: "event-props-container z-0" },
          _vm._l(_vm.eventConfig.event_props, function(eventProp, idx) {
            return _c(
              "div",
              {
                key: idx,
                staticClass: "event-prop",
                class: { "w-100": eventProp.fullWidth }
              },
              [
                _c(
                  "label",
                  { staticClass: "device-label", attrs: { for: "prop" + idx } },
                  [_vm._v(_vm._s(eventProp.title))]
                ),
                eventProp.control === "textarea"
                  ? _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.eventData[eventProp.propName],
                          expression: "eventData[eventProp.propName]"
                        }
                      ],
                      staticClass: "text-field w-input",
                      class: { "w-100": eventProp.fullWidth },
                      attrs: {
                        type: "textarea",
                        maxlength: "256",
                        id: "prop" + idx,
                        placeholder: eventProp.placeholder,
                        disabled: !_vm.enableEdit,
                        rows: eventProp.rows
                      },
                      domProps: { value: _vm.eventData[eventProp.propName] },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.eventData,
                            eventProp.propName,
                            $event.target.value
                          )
                        }
                      }
                    })
                  : eventProp.control === "select"
                  ? _c("b-select", {
                      staticClass: "text-field w-input",
                      class: { "w-100": eventProp.fullWidth },
                      attrs: {
                        type: "text",
                        options: eventProp.options,
                        maxlength: "256",
                        placeholder: eventProp.placeholder,
                        id: "event_type",
                        disabled: !_vm.enableEdit
                      },
                      model: {
                        value: _vm.eventData[eventProp.propName],
                        callback: function($$v) {
                          _vm.$set(_vm.eventData, eventProp.propName, $$v)
                        },
                        expression: "eventData[eventProp.propName]"
                      }
                    })
                  : eventProp.control === "checkbox"
                  ? _c(
                      "b-checkbox",
                      {
                        staticClass: "text-field w-input z-2",
                        attrs: {
                          type: "text",
                          maxlength: "256",
                          placeholder: eventProp.placeholder,
                          id: "event_type",
                          disabled: !_vm.enableEdit
                        },
                        model: {
                          value: _vm.eventData[eventProp.propName],
                          callback: function($$v) {
                            _vm.$set(_vm.eventData, eventProp.propName, $$v)
                          },
                          expression: "eventData[eventProp.propName]"
                        }
                      },
                      [_vm._v(_vm._s(eventProp.placeholder))]
                    )
                  : eventProp.control === "datepicker"
                  ? _c("b-form-datepicker", {
                      staticClass: "text-input date-picker",
                      attrs: {
                        type: "text",
                        size: "sm",
                        maxlength: "256",
                        placeholder: eventProp.placeholder,
                        id: "event_type",
                        disabled: !_vm.enableEdit
                      },
                      model: {
                        value: _vm.eventData[eventProp.propName],
                        callback: function($$v) {
                          _vm.$set(_vm.eventData, eventProp.propName, $$v)
                        },
                        expression: "eventData[eventProp.propName]"
                      }
                    })
                  : eventProp.control === "number"
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.eventData[eventProp.propName],
                          expression: "eventData[eventProp.propName]"
                        }
                      ],
                      staticClass: "text-field w-input",
                      class: { "w-100": eventProp.fullWidth },
                      attrs: {
                        type: "number",
                        step: "any",
                        id: "prop" + idx,
                        maxlength: "256",
                        placeholder: eventProp.placeholder,
                        disabled: !_vm.enableEdit
                      },
                      domProps: { value: _vm.eventData[eventProp.propName] },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.eventData,
                            eventProp.propName,
                            $event.target.value
                          )
                        }
                      }
                    })
                  : _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.eventData[eventProp.propName],
                          expression: "eventData[eventProp.propName]"
                        }
                      ],
                      staticClass: "text-field w-input",
                      class: { "w-100": eventProp.fullWidth },
                      attrs: {
                        type: "text",
                        id: "prop" + idx,
                        maxlength: "256",
                        placeholder: eventProp.placeholder,
                        disabled: !_vm.enableEdit
                      },
                      domProps: { value: _vm.eventData[eventProp.propName] },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.eventData,
                            eventProp.propName,
                            $event.target.value
                          )
                        }
                      }
                    })
              ],
              1
            )
          }),
          0
        )
      : _vm._e(),
    _c("div", { staticClass: "event-update-timestamp" }, [
      _vm._v(" " + _vm._s(_vm.updateText) + " ")
    ]),
    _c("div", { staticClass: "w-100 flex-row justify-content-end mt-2" }, [
      _vm.enableEdit
        ? _c(
            "button",
            {
              staticClass: "button",
              attrs: { id: "button-save" },
              on: {
                click: function($event) {
                  return _vm.saveChanges()
                }
              }
            },
            [_vm._v("Save Changes")]
          )
        : _vm._e(),
      _vm.hasLocation
        ? _c(
            "button",
            {
              staticClass: "button",
              attrs: { id: "button-show" },
              on: {
                click: function($event) {
                  return _vm.clickShow()
                }
              }
            },
            [_vm._v("Show Location")]
          )
        : _vm._e(),
      _vm.hasLink
        ? _c(
            "button",
            {
              staticClass: "button",
              attrs: { id: "button-show" },
              on: {
                click: function($event) {
                  return _vm.clickLink()
                }
              }
            },
            [_vm._v("Show Related")]
          )
        : _vm._e(),
      _c(
        "button",
        {
          staticClass: "button",
          attrs: { id: "button-close" },
          on: {
            click: function($event) {
              return _vm.clickClose()
            }
          }
        },
        [_vm._v("Close")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }