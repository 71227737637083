<template>
  <div class="navigationbar">
    <notification-agent @update="updateUnreadBadge"></notification-agent>
      <router-link class="nav-item-container navbar-fleet"   to="/"  v-if="isFleetUser">
        <div class="nav-icon-outer">
            <i  class="menu-item nav-icon" :class="$config.icons.nav.fleet"></i>
        </div>
        <div class="nav-item-heading">
          Fleet Screen
        </div>
      </router-link>

    <router-link class="nav-item-container navbar-forms" to="/forms" v-if="isFormsUser">
      <div class="nav-icon-outer">
        <i class="menu-item nav-icon" :class="$config.icons.nav.forms"></i>
      </div>
      <div class="nav-item-heading">
        Prestarts/Forms
      </div>
    </router-link>

    <div class="nav-item-container navbar-manage" v-if="isFleetUser" @click="expandManagement=!expandManagement">
      <div class="nav-icon-outer">
        <i class="menu-item nav-icon" :class="$config.icons.nav.fleetManager"></i>
      </div>
      <div class="nav-item-heading">
        Manage Fleet
        <i class="expand_icon" :class="$config.icons.twisties.collapsed_before" v-if="!expandManagement"></i>
        <i class="expand_icon" :class="$config.icons.twisties.expanded" v-else></i>
      </div>
    </div>
    <div class="sub-menu-container" v-show="expandManagement">
      <router-link class="nav-item-container navbar-trips" to="/trips" v-if="isFleetUser">
        <div class="nav-icon-outer">
          <i class="menu-item nav-icon" :class="$config.icons.nav.trips"></i>
        </div>
        <div class="nav-item-heading">
          Vehicle Trips
        </div>
      </router-link>

      <router-link class="nav-item-container navbar-fm"  to="/fleet-manager"  v-if="isFleetUser">
        <div class="nav-icon-outer">
          <i class="menu-item nav-icon" :class="$config.icons.nav.fleetManager"></i>
        </div>
        <div class="nav-item-heading">
          Fleet Manager
        </div>
      </router-link>
    </div>

    <div class="nav-item-container navbar-reports-menu" @click="expandReports=!expandReports">
      <div class="nav-icon-outer">
        <i class="menu-item nav-icon" :class="$config.icons.nav.reports"></i>
      </div>
      <div class="nav-item-heading">
        Reports Menu <i class="expand_icon" :class="$config.icons.twisties.collapsed_before" v-if="!expandReports"></i>
        <i class="expand_icon" :class="$config.icons.twisties.expanded" v-else></i>
      </div>
    </div>
    <div class="sub-menu-container" v-show="expandReports">
      <router-link class="nav-item-container navbar-dashboard" to="/dashboard" v-if="isFleetUser">
        <div class="nav-icon-outer">
          <i class="menu-item nav-icon" :class="$config.icons.nav.dashboard"></i>
        </div>
        <div class="nav-item-heading">
          Dashboard
        </div>
      </router-link>

      <router-link class="nav-item-container navbar-reports" to="/reports" v-if="isPowerUser">
        <div class="nav-icon-outer">
          <i class="menu-item nav-icon" :class="$config.icons.nav.reports"></i>
        </div>
        <div class="nav-item-heading">
          Reports
        </div>
      </router-link>
      <router-link class="nav-item-container navbar-stats" to="/stats" v-if="isFleetUser">
        <div class="nav-icon-outer">
          <i class="menu-item nav-icon" :class="$config.icons.nav.stats"></i>
        </div>
        <div class="nav-item-heading">
          Legacy Stats
        </div>
      </router-link>
    </div>

    <router-link class="nav-item-container navbar-preferences" to="/preferences">
      <div class="nav-icon-outer">
        <i class="menu-item nav-icon" :class="$config.icons.nav.preferences"></i>
      </div>
      <div class="nav-item-heading">
        Preferences
      </div>
    </router-link>

    <div class="nav-item-container navbar-notifications clickable" v-if="isFleetUser" v-b-modal.modal-notifications>
      <div class="nav-icon-outer">
        <i :class="$config.icons.nav.notifications" class="menu-item nav-icon"> <b-badge
            pill class="unread-badge">{{unreadCount | limitUnreadBadge}}
        </b-badge></i>
      </div>
      <div class="nav-item-heading">
        Notifications
      </div>
    </div>

    <router-link class="nav-item-container navbar-admin" to="/admin" v-if="isAdmin">
      <div class="nav-icon-outer">
        <i class="menu-item nav-icon" :class="$config.icons.nav.admin"></i>
      </div>
      <div class="nav-item-heading">
        Admin
      </div>
    </router-link>

    <router-link class="nav-item-container navbar-warboard" to="/warboard" v-if="isPowerUser">
      <div class="nav-icon-outer">
        <i class="menu-item nav-icon" :class="$config.icons.nav.warboard"></i>
      </div>
      <div class="nav-item-heading">
        Warboard
      </div>
    </router-link>

    <router-link class="nav-item-container navbar-logout" to="/logout">
      <div class="nav-icon-outer">
        <i class="menu-item nav-icon" :class="$config.icons.nav.signout"></i>
      </div>
      <div class="nav-item-heading">
        Sign-Out
      </div>
    </router-link>

    <!--    Notifications Modal Template-->
    <b-modal id="modal-notifications" centered class="modal-content" size="lg" hide-footer title="Notifications">
      <notification-list></notification-list>
    </b-modal>
  </div>

</template>

<script>

import NotificationList from './notifications/NotificationList'
import NotificationAgent from './helpers/NotificationAgent'
import * as config from '../config'

export default {
  name: 'nav-bar',
  components: { NotificationList, NotificationAgent },
  props: {
    msg: String
  },
  data: function () {
    return {
      isAdmin: false,
      isFleetUser: false,
      isPowerUser: false,
      isFormsUser: false,
      unreadCount: 0,
      expandReports: false,
      expandManagement: false,
      reportsPages: [
        'dashboard',
        'reports',
        'reports_view',
        'reports_edit',
        'stats'
      ],
      fleetManagementPages: [
        'fleet-manager',
        'trips'
      ]
    }
  },
  filters: {
    limitUnreadBadge: function (value) {
      if (value > 99) {
        return '99+'
      } else {
        return value
      }
    }
  },
  async mounted () {
    this.isAdmin = await this.$auth.roleIn(config.roles.admin)
    this.isFleetUser = await this.$auth.roleIn(config.roles.fleetscreen)
    this.isPowerUser = await this.$auth.roleIn(config.roles.power_user)
    this.isFormsUser = await this.$auth.roleIn(config.roles.forms)

    if (this.reportsPages.includes(this.$route.name)) {
      this.expandReports = true
    }
    if (this.fleetManagementPages.includes(this.$route.name)) {
      this.expandManagement = true
    }
    console.log(this.$route)
  },
  methods: {
    updateUnreadBadge: function (count) {
      this.unreadCount = count
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '../variables';

  .navigationbar {
    margin-left: auto;
    margin-right: auto;
    //max-width: 940px;
    position: fixed;
    height: 100%;
    width: 200px;
    right: -140px;
    flex-direction: column;
    display: flex;
    flex-shrink: 0;
    overflow: hidden;
    justify-content: center;
    align-items: stretch;
    background-color: $default-nav-background;
    z-index: 200;
    transition: right 0.15s ease-in-out;
    box-shadow: 1px 1px 20px -1px rgba(0, 0, 0, 1);
  }

  .navigationbar:hover{
    right: 0;
  }

  .nav-item-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    text-decoration: none;
    .nav-item-heading {
      transition: background 0.25s ease-in-out;
    }
    i {
      transition: opacity 0.25s ease-in-out;
    }
  }

  .nav-item-container:hover {
    i {
      opacity:1;
    }
  }

  .nav-item-heading {
    display: flex;
    flex-flow: row wrap;
    align-content: center;
    flex-grow: 1;
    font-size: 1em;
    color: white;
    font-weight: 600;
    background: $theme-color-background-3;
    margin-top: 5px;
    border-radius: 0 25px 25px 0;
    padding: 5px;
    align-items: center;
  }

  .nav-icon-outer {
    width: 60px;
  }

  .navbar-fleet {
    .nav-item-heading {
      background: #18fc0099;
    }
    .nav-icon-outer i {
      color: #18fc00;
    }
  }

  .navbar-fleet:hover {
    .nav-item-heading {
      background: #18fc00DD;
    }
  }

  .navbar-manage {
    .nav-item-heading {
      background: #2fa0d099;
    }
    .nav-icon-outer i {
      color: #2fa0d0;
    }
  }

  .navbar-manage:hover {
    .nav-item-heading {
      background: #2fa0d0DD;
    }
  }

  .navbar-trips {
    .nav-item-heading {
      background: #2fa0d099;
    }
    .nav-icon-outer i {
      color: #2fa0d0;
    }
  }

  .navbar-trips:hover {
    .nav-item-heading {
      background: #2fa0d0DD;
    }
  }

  .navbar-fm {
    .nav-item-heading {
      background: #53cce399;
    }
    .nav-icon-outer i {
      color: #53cce3;
    }
  }

  .navbar-fm:hover {
    .nav-item-heading {
      background: #53cce3DD;
    }
  }

  .navbar-forms {
    .nav-item-heading {
      background: #00750999;
    }
    .nav-icon-outer i {
      color: #007509;
    }
  }

  .navbar-forms:hover {
    .nav-item-heading {
      background: #007509DD;
    }
  }

  .navbar-warboard {
    .nav-item-heading {
      background: #e0383899;
    }
    .nav-icon-outer i {
      color: #e03838;
    }
  }

  .navbar-warboard:hover {
    .nav-item-heading {
      background: #e03838DD;
    }
  }

  .navbar-reports-menu {
    .nav-item-heading {
      background: #0056b399;
    }
    .nav-icon-outer i {
      color: #0056b3;
    }
  }

  .navbar-reports-menu:hover {
    .nav-item-heading {
      background: #0056b3DD;
    }
  }

  .navbar-dashboard {
    .nav-item-heading {
      background: #0056b399;
    }
    i {
      color: #0056b3;
    }
  }

  .navbar-dashboard:hover {
    .nav-item-heading {
      background: #0056b3DD;
    }
  }

  .navbar-reports {
    .nav-item-heading {
      background: #2f69ad99;
    }
    i {
      color: #2f69ad;
    }
  }

  .navbar-reports:hover {
    .nav-item-heading {
      background: #2f69adDD;
    }
  }

  .navbar-stats {
    .nav-item-heading {
      background: #6597d099;
    }
    .nav-icon-outer i {
      color: #6597d0;
    }
  }

  .navbar-stats:hover {
    .nav-item-heading {
      background: #6597d0DD;
    }
  }

  .navbar-notifications{
    position: relative;
    .nav-item-heading {
      background: #fff50099;
    }
    i {
      color: #fff500;
    }
  }

  .navbar-notifications:hover {
    .nav-item-heading {
      background: #fff500DD;
    }
  }

  .navbar-preferences{
    .nav-item-heading {
      background: #FFFFFF99;
    }
    i {
      color: #FFFFFF;
    }
  }

  .navbar-preferences:hover {
    .nav-item-heading {
      background: #FFFFFFDD;
    }
  }

  .navbar-admin{
    .nav-item-heading {
      background: #FFA50099;
    }
    i {
      color: #FFA500;
    }
  }

  .navbar-admin:hover {
    .nav-item-heading {
      background: #FFA500DD;
    }
  }

  .navbar-logout{
    .nav-item-heading {
      background: #f1000099;
    }
    i {
      color: #f10000;
    }
  }

  .navbar-logout:hover {
    .nav-item-heading {
      background: #f10000DD;
    }
  }

  .expand_icon {
    color: white;
    margin-left: 0.5em;
  }

  .menu-item {
    width: 45px;
    //height: 45px;
    font-size: 35px;
    flex: 0 0 auto;
    margin: 5px 0;
  }

  .nav-icon {
    opacity: 0.7;
  }

  // Notifications
  .notifications-icon {
    color: #fff500;
    opacity: 0.7;
    flex: 0 0 auto;
    width: 45px;
    height: 45px;
    i {
      width: 45px;
      height: 45px;
      font-size: 35px;
    }
  }

  .unread-badge {
    font-size: 0.75rem;
    position: absolute;
    left: 0.5rem;
    background: rgba(150, 150, 150, 0.7);
  }

  .sub-menu-container {

    .nav-item-heading {
      margin-left: 1em;
    }
  }

  @media print {
    .navigationbar {
      display: none;
    }
  }

</style>
