/*
    Helper Class for Providing Consistent User Alerts (Mostly Toast)
 */

var toastr

// Setup Error Handler. Called Globally on initialisation in Main.js
export function initialise (toastrHandle) {
  toastr = toastrHandle
}

// Display a General Success Toast Message
export function successToast (message, title) {
  toastr.toast(message,
    {
      title: title,
      variant: 'success',
      toaster: 'b-toaster-top-center'
    })
}
