var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "notification-list" }, [
    _c("div", { staticClass: "flex-row justify-content-end" }, [
      _c(
        "button",
        {
          directives: [
            {
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover.left",
              modifiers: { hover: true, left: true }
            }
          ],
          attrs: { title: "Refresh List" },
          on: { click: _vm.refreshNotifications }
        },
        [_vm._v("Refresh")]
      )
    ]),
    _vm.noData
      ? _c("div", { staticClass: "no-data-message" }, [
          _vm._v("Nothing to show right now.")
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "notification-table" },
      [
        !_vm.hideFilter
          ? _c("div", { staticClass: "filter-bar" }, [
              _c("div", { staticClass: "slim-controls" }, [
                _c(
                  "button",
                  {
                    on: {
                      click: function($event) {
                        _vm.showAdvancedFilters = !_vm.showAdvancedFilters
                      }
                    }
                  },
                  [_vm._v("Adv Filters")]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showAdvancedFilters,
                        expression: "showAdvancedFilters"
                      }
                    ]
                  },
                  [
                    _c("search-filters", {
                      ref: "searchFilters",
                      attrs: { filters: _vm.filters },
                      on: {
                        change: _vm.changeFilter,
                        "press-enter": _vm.applyFilter
                      }
                    }),
                    _c("div", { staticClass: "flex-row slim-controls" }, [
                      _c("button", { on: { click: _vm.resetFilters } }, [
                        _vm._v("Clear")
                      ]),
                      _vm.filterMethod === "remote"
                        ? _c(
                            "button",
                            {
                              staticClass: "success",
                              on: { click: _vm.applyFilter }
                            },
                            [_vm._v("Apply")]
                          )
                        : _vm._e()
                    ])
                  ],
                  1
                )
              ])
            ])
          : _vm._e(),
        _c("b-table", {
          ref: "notificationTable",
          attrs: {
            striped: "",
            hover: "",
            items: _vm.tableDataSource,
            fields: _vm.notificationFields,
            "select-mode": "single",
            "selected-variant": "success",
            "current-page": _vm.currentPage,
            "per-page": _vm.perPage,
            "td-class": "admin-device-table-data",
            "th-class": "admin-device-table-header",
            "sort-desc": true,
            "sort-by": "id"
          },
          scopedSlots: _vm._u([
            {
              key: "head(selected)",
              fn: function(row) {
                return undefined
              }
            },
            {
              key: "cell(selected)",
              fn: function(row) {
                return [
                  !row.item.read
                    ? _c(
                        "b-badge",
                        { attrs: { pill: "", variant: "warning" } },
                        [_vm._v("New")]
                      )
                    : _vm._e()
                ]
              }
            },
            {
              key: "head(action)",
              fn: function(row) {
                return undefined
              }
            },
            {
              key: "cell(action)",
              fn: function(row) {
                return [
                  row.item.link
                    ? _c("i", {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true }
                          }
                        ],
                        staticClass: "fa fa-external-link row-icon-button",
                        attrs: { title: "View Event" },
                        on: {
                          click: function($event) {
                            return _vm.clickLink(row.item)
                          }
                        }
                      })
                    : _vm._e()
                ]
              }
            },
            {
              key: "cell(alert_type)",
              fn: function(row) {
                return [
                  _c(
                    "b-badge",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true }
                        }
                      ],
                      staticClass: "type",
                      attrs: {
                        pill: "",
                        variant: "info",
                        title: _vm.alertTypeTooltips[row.item.alert_type]
                      }
                    },
                    [_vm._v(_vm._s(row.item.alert_type))]
                  )
                ]
              }
            }
          ])
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "page-bar" },
      [
        _c("b-form-select", {
          staticClass: "page-select",
          attrs: { id: "perPageSelect", size: "sm", options: _vm.pageOptions },
          model: {
            value: _vm.perPage,
            callback: function($$v) {
              _vm.perPage = $$v
            },
            expression: "perPage"
          }
        }),
        _c("b-pagination", {
          staticClass: "page-buttons",
          attrs: {
            "total-rows": _vm.totalRows,
            "per-page": _vm.perPage,
            align: "fill",
            size: "sm"
          },
          model: {
            value: _vm.currentPage,
            callback: function($$v) {
              _vm.currentPage = $$v
            },
            expression: "currentPage"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }