var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "forgotten-password-box" }, [
    _vm.mode === "sendLink"
      ? _c(
          "div",
          [
            _c("div", { staticClass: "property-item" }, [
              _c(
                "label",
                { staticClass: "device-label", attrs: { for: "user_email" } },
                [
                  _vm._v(
                    "Enter your Email Address or Mobile Number and we'll send you a code"
                  )
                ]
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.identifier,
                    expression: "identifier"
                  }
                ],
                staticClass: "w-100",
                attrs: {
                  type: "text",
                  maxlength: "256",
                  placeholder: "Email or Mobile Number",
                  id: "user_email"
                },
                domProps: { value: _vm.identifier },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.identifier = $event.target.value
                  }
                }
              })
            ]),
            _c(
              "div",
              {
                staticClass: "fake-link",
                on: {
                  click: function($event) {
                    _vm.mode = "enterCode"
                  }
                }
              },
              [_vm._v("I already have a reset or activation code")]
            ),
            _c(
              "b-button",
              {
                staticClass: "button",
                on: {
                  click: function($event) {
                    return _vm.clickReset()
                  }
                }
              },
              [_vm._v("Send")]
            )
          ],
          1
        )
      : _vm._e(),
    _vm.mode === "enterCode"
      ? _c("div", [
          _c("div", { staticClass: "property-item" }, [
            _c(
              "label",
              { staticClass: "device-label", attrs: { for: "reset-code" } },
              [_vm._v("Enter the Reset Code from your email or SMS:")]
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.resetCode,
                  expression: "resetCode"
                }
              ],
              staticClass: "w-100",
              attrs: {
                type: "text",
                maxlength: "256",
                placeholder: "Enter Reset Code from Email or SMS",
                id: "reset-code"
              },
              domProps: { value: _vm.resetCode },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.resetCode = $event.target.value
                }
              }
            })
          ]),
          _c("div", { staticClass: "property-item" }, [
            _c(
              "label",
              { staticClass: "device-label", attrs: { for: "password" } },
              [_vm._v("Enter a New Password:")]
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.password,
                  expression: "password"
                }
              ],
              staticClass: "w-100",
              attrs: {
                type: "password",
                maxlength: "256",
                placeholder: "New Password",
                id: "password"
              },
              domProps: { value: _vm.password },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.password = $event.target.value
                }
              }
            })
          ]),
          _c("div", { staticClass: "property-item" }, [
            _c(
              "label",
              {
                staticClass: "device-label",
                attrs: { for: "confirm-password" }
              },
              [_vm._v("Retype New Password:")]
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.passwordConfirm,
                  expression: "passwordConfirm"
                }
              ],
              staticClass: "w-100",
              attrs: {
                type: "password",
                maxlength: "256",
                placeholder: "Confirm Password",
                id: "confirm-password"
              },
              domProps: { value: _vm.passwordConfirm },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.passwordConfirm = $event.target.value
                }
              }
            })
          ]),
          _c(
            "button",
            {
              on: {
                click: function($event) {
                  return _vm.clickChangePassword()
                }
              }
            },
            [_vm._v("Change Password")]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }