<template>
  <div class="flex-row inline-forms">
<!--    Control to Add new Tags-->
    <button class="inlineform-dropdown" v-if="editable" @click="createForm">
        +
    </button>

    <FormDataAvatar class="trip-tag" size="small" variant="outline"
              v-for="(form, idx) of formObjects" v-bind:key="'tag-' + idx" :form="form" :editable="editable" show_remove_icon
              @click="selectForm(form)"
    ></FormDataAvatar>

    <b-modal :id="modalId" centered class="modal-content" size="xl" hide-footer :title="isNew ? 'Create Form': 'Edit Form'">
      <b-tabs>
        <b-tab title="Form">
          <FormEdit :form="selectedForm" related_type="trip" :trip="value" :create_new="isNew" :modal_id="modalId"
                    @delete="deletedForm"
                    @create="createdForm"
                    @changed="selectedFormChange"></FormEdit>
        </b-tab>
        <b-tab title="Auditlog" v-if="!isNew">
          <AuditLog v-if="selectedForm" related_type="form" :related_id="selectedForm.id"></AuditLog>
        </b-tab>
      </b-tabs>

    </b-modal>

  </div>
</template>
<script>

/****
 *
 *  Inline Forms List,
 *
 *   A bit like Trip Tags... but forms.
 *
 *
 */

import FormDataAvatar from '@/components/forms/FormDataAvatar.vue'
import AuditLog from '@/components/shared/AuditLogList.vue'
import FormEdit from '@/components/forms/FormEdit.vue'

export default {
  name: 'FormListInline',
  components: {FormEdit, AuditLog, FormDataAvatar},
  props: {
    editable: Boolean,
    value: Object, // Expecting the Trip Object.
    related_type: String
  },
  data () {
    return {
      customTagText: '',
      selectedForm: null,
      isNew: false
    }
  },
  async mounted () {
  },
  methods: {
    createForm() {
      this.isNew = true
      this.selectedForm = null
      this.$bvModal.show(this.modalId)
    },
    selectForm(form) {
      console.log('Click Form: ', form)
      this.selectedForm = form
      this.isNew = false
      this.$bvModal.show(this.modalId)
    },
    selectedFormChange() {
      let tempTrip = { ...this.value }
      this.$emit('input', tempTrip)
    },
    /**
     * Event Handler for the 'delete' event from FormEdit.
     * Since our v-model is on the trip, we need to send the whole trip back with updated forms.
     * @param form
     */
    deletedForm(form) {
      let tempTrip = { ...this.value }
      tempTrip.trip_forms = tempTrip.trip_forms.filter(f => f.id !== form.id)
      this.$emit('input', tempTrip)
    },
    /**
     * Event handler for the 'create' event from FormEdit
     * Since our v-model is on the trip, we need to send the whole trip back with updated forms.
     * @param form
     */
    createdForm(form) {
      let tempTrip = { ...this.value }
      tempTrip.trip_forms.push(form)
      this.$emit('input', tempTrip)
    }
  },
  computed: {
    modalId () {
      console.log(`edit-form-modal-${this._uid}`)
      return `edit-form-modal-${this._uid}`
    },
    formObjects () {
      if (this.value && Object.hasOwn(this.value, 'trip_forms') && Array.isArray(this.value.trip_forms)) {
        return this.value.trip_forms
      } else {
        return []
      }
    }
  },
  watch: {
    value (v) {
      console.log('New Trip Forms Value: ', v)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/variables';
.inline-forms  {
  div:not(:first-child) {
    margin-left: 1px;
  }
}

.add-form-button {
  color: #2f69ad;
}
</style>
