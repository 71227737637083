<template>

  <div class="control" v-if="control.visible" :class="{'form-error': control.errors}">
    <label @click="handleChange(control.path, !control.data)" :class="labelClass">{{labelText}}</label>
    <textarea  :id="control.id + '-input'"
            :rows="computedRows"
            v-model="control.data"
            :disabled="!control.enabled"
            @change="handleChange(control.path, control.data)"
            @focus="isFocused = true"
            @blur="isFocused = false"
            :placeholder="placeholderText"
            :required="isRequired"
    />
    <div class="error" v-if="control.errors">
      {{ errorMessages }}
    </div>
  </div>

</template>

<script>

import {
  rendererProps,
  useJsonFormsControl,
} from '@jsonforms/vue2'
import {and, isMultiLineControl, isStringControl, rankWith} from '@jsonforms/core'


const renderer = {
  name: 'TextareaRenderer',
  components: {},
  props: {
    ...rendererProps(),
  },
  setup(props) {
    return useJsonFormsControl(props)
  },
  mounted () {
  },
  computed: {
    labelText() {
      let suffix = this.control.required ? '*' : ''
      return `${this.control.label} ${suffix}`
    },
    computedRows () {
      return this.uischema.options.rows || 3
    },
    placeholderText () {
      return this.uischema.placeholder || 'Enter Text...'
    },
    isRequired() {
      return this.control.required && this.control.visible
    },
    errorMessages() {
      console.log('Errors: ', this.control.errors)

      return this.control.label + ' ' + this.control.errors || ''
    },
    labelClass() {
      if (Object.hasOwn(this.uischema, 'options')) {
        if (Object.hasOwn(this.uischema.options, 'true-variant') && this.control.data) {
          return this.uischema.options['true_variant']
        } else if (Object.hasOwn(this.uischema.options, 'false-variant') && !this.control.data) {
          return this.uischema.options['false_variant']
        }
      } else {
        return ''
      }
    }
  }
}
export default renderer

export const textareaRendererEntry = {
  renderer: renderer,
  tester: rankWith(3, and(isStringControl, isMultiLineControl)),
}

</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped >

.form-check {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.form-check-input {
  width: 1.6em;
  height: 1.6em;
}

.form-check label {
  font-size: 1.6em;
}

.warning {
  color: red;
}

</style>
