var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return this.user
    ? _c(
        "div",
        {
          staticClass: "user-avatar",
          class: {
            selected: _vm.selected === true,
            "user-avatar-hover": !_vm.disabled,
            "user-avatar-disabled": _vm.disabled
          },
          on: { click: _vm.onClick }
        },
        [
          _c("i", { staticClass: "user-icon", class: _vm.userIcon }),
          _c(
            "div",
            {
              staticClass:
                "flex-column flex-wrap flex-grow-1 align-items-center"
            },
            [
              _c("div", { staticClass: "user-heading" }, [
                _vm._v(_vm._s(_vm.username))
              ]),
              _c("div", { staticClass: "detail-text" }, [
                _vm._v(_vm._s(_vm.email))
              ])
            ]
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }