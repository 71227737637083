var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "geolimit-control" },
    [
      _c("loading-box", { attrs: { loading: _vm.loading } }),
      _c("label", [_vm._v("Geolimit")]),
      _c("div", { staticClass: "green-divider" }),
      _c(
        "div",
        {},
        [
          _c(
            "b-form-checkbox",
            {
              attrs: {
                size: "md",
                name: "check-button",
                switch: "",
                id: "geo-limit-switch",
                disabled: !_vm.geolimitControlEnabled
              },
              on: { change: _vm.clickGeolimitActive },
              model: {
                value: _vm.geolimitData.active,
                callback: function($$v) {
                  _vm.$set(_vm.geolimitData, "active", $$v)
                },
                expression: "geolimitData.active"
              }
            },
            [_vm._v(" Enable Geolimit ")]
          ),
          _c(
            "b-tooltip",
            {
              attrs: {
                target: "geo-limit-switch",
                noninteractive: "",
                variant: "success",
                placement: "left",
                delay: {
                  show: _vm.$config.general.tooltipShowDelay,
                  hide: _vm.$config.general.tooltipHideDelay
                }
              }
            },
            [_vm._v(" Toggle Geolimit Alarm ")]
          )
        ],
        1
      ),
      _c("label", [_vm._v("Geolimit Radius")]),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.inputRadius,
            expression: "inputRadius"
          }
        ],
        staticClass: "w-100 mt-2",
        attrs: {
          type: "number",
          id: "geo-limit-input",
          min: _vm.$config.geolimit.min,
          max: _vm.$config.geolimit.max,
          step: _vm.$config.geolimit.precision,
          disabled: !_vm.geolimitControlEnabled
        },
        domProps: { value: _vm.inputRadius },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.inputRadius = $event.target.value
          }
        }
      }),
      _c(
        "b-tooltip",
        {
          attrs: {
            target: "geo-limit-input",
            noninteractive: "",
            variant: "success",
            placement: "left",
            delay: {
              show: _vm.$config.general.tooltipShowDelay,
              hide: _vm.$config.general.tooltipHideDelay
            }
          }
        },
        [_vm._v(" Geolimit Radius in Meters ")]
      ),
      _c(
        "button",
        {
          staticClass: "w-100",
          attrs: {
            id: "set-geolimit-radius",
            disabled: !_vm.geolimitControlEnabled
          },
          on: { click: _vm.clickSetGeolimitRadius }
        },
        [_vm._v("Set with Radius")]
      ),
      _c(
        "button",
        {
          staticClass: "w-100",
          attrs: {
            id: "set-geolimit-current",
            disabled: !_vm.geolimitControlEnabled
          },
          on: { click: _vm.clickSetGeolimitLocation }
        },
        [_vm._v("Set to Current")]
      ),
      _c(
        "button",
        {
          staticClass: "w-100",
          attrs: { id: "goto-geolimit", disabled: !_vm.geolimitControlEnabled },
          on: { click: _vm.clickViewGeolimit }
        },
        [_vm._v("Go to Geolimit")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }