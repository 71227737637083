<template>
  <div class="device-container-outer" v-if="device && deviceFeatures"
       :class="{
          'selected': selected === true,
       }"
        @click="clickDevice"
  >
  <div class="device-container-inner"
       :class="{
            'invalid-subscription': (showIndicators && !device.has_subscription),
            'clickable': enable_link || !disabled,
            'selected': selected === true,
            'device-avatar-hover': !disabled,
            'disabled': disabled
        }">

    <div class="device-image">
      <img :src="iconImage" :alt="device.icon">
      <!--      <span v-if="showIndicators && errorCount"-->
      <!--            class="error-badge badge rounded-pill bg-danger badge-error">!-->
      <!--      </span>-->
      <!--      ACC Indicators -->
      <span v-if="showIndicators && deviceFeatures.includes('device_trips')"
            v-b-tooltip :title="accTooltip"
            class="error-badge badge rounded-pill badge-acc"
            :class="accOnBadgeVariant">
          <i class="fa fa-power-off"></i>
      </span>
      <!--        Battery Level Indicator -->
      <span v-if="showIndicators && deviceFeatures.includes('device_battery_level') && batteryIcon"
            v-b-tooltip :title="device.device_battery_level + '%'"
            :class="batteryBadgeVariant"
            class="error-badge badge rounded-pill badge-acc">
          <i class="battery-icon fa" :class="batteryIcon"></i>
      </span>
    </div>
    <div class="device-inner">
      <div class="device-name">
        {{device.device_name}}
      </div>
      <div class="detail-text">
        <div>{{device.device_code}}</div>
        <!--        <div class="status-icons " v-if="showIndicators && device.indicators.length">-->
        <!--          <i v-for="(indicator, idx) of device.indicators" v-bind:key="idx" :class="indicator"></i>-->
        <!--        </div>-->
      </div>
    </div>
  </div>

  </div>
</template>
<script>
import * as IconHelper from '@/components/helpers/IconHelper'

export default {
  name: 'DeviceAvatar',
  props: {
    device: Object,
    showIndicators: Boolean,
    selected: Boolean,
    disabled: Boolean,
    enable_link: Boolean
  },
  data () {
    return {
      deviceFeatures: null
    }
  },
  async mounted () {
    this.deviceFeatures = await this.$deviceService.getDeviceFeatures(this.device.device_type)
  },
  methods: {
    /***
     * Function to determine if the Vehicle is On or Off.
     * @param device
     * @returns {boolean|*}
     */
    power_on (device) {
      if (device.location) {
        return device.location.data.acc_on
      } else {
        return false
      }
    },
    /***
     * Function to determine if the device has a low battery
     * @param device
     * @returns {boolean}
     */
    battery_low (device) {
      return Object.hasOwn(device, 'battery')
    },
    clickDevice() {
      if (this.enable_link) {
        if (this.device.has_subscription ||
          this.$auth.roleIn(this.$config.roles.admin)
        ) {
          this.$router.push({ path: 'device/' + this.device.device_imei })
        } else {
          this.$bvModal.show('modal-no-sub')
        }
      }
    }
  },
  computed: {
    errorCount () {
      if (!this.device) {
        return 0
      } else {
        return this.$deviceService.getErrorMessages(this.device).length
      }
    },
    accOn () {
      if (this.deviceFeatures && this.deviceFeatures.includes('device_trips')) {
        return this.power_on(this.device)
      } else {
        return false
      }
    },
    accTooltip () {
      if (this.deviceFeatures && this.deviceFeatures.includes('device_trips')) {
        if (this.power_on(this.device)) {
          return 'ACC ON'
        } else {
          return 'ACC OFF'
        }
      } else {
        return null
      }
    },
    accOnBadgeVariant () {
      if (this.deviceFeatures && this.deviceFeatures.includes('device_trips')) {
        if (this.power_on(this.device)) {
          return 'bg-success'
        } else {
          return 'bg-danger'
        }
      } else {
        return null
      }
    },
    batteryIcon () {
      if (Object.hasOwn(this.device, 'device_battery_level')) {
        if (this.device.device_battery_level > 90) {
          return 'fa-battery-full bg-success'
        } else if (this.device.device_battery_level <= 90 && this.device.device_battery_level > 50) {
          return 'fa-battery-three-quarters  bg-success'
        } else if (this.device.device_battery_level <= 50 && this.device.device_battery_level > 25) {
          return 'fa-battery-half bg-success'
        } else if (this.device.device_battery_level <= 25 && this.device.device_battery_level > 10) {
          return 'fa-battery-quarter bg-warning'
        } else if (this.device.device_battery_level <= 10) {
          return 'fa-battery-empty bg-danger'
        }
      } else {
        return null
      }
    },
    batteryBadgeVariant () {
      if (Object.hasOwn(this.device, 'device_battery_level')) {
        if (this.device.device_battery_level > 25) {
          return 'bg-success'
        } else if (this.device.device_battery_level <= 25 && this.device.device_battery_level > 10) {
          return 'bg-warning'
        } else if (this.device.device_battery_level <= 10) {
          return 'bg-danger'
        }
      } else {
        return null
      }
    },
    indicators () {
      let indicators = []
      if (this.device) {
        if (this.$deviceService.hasError(this.device)) {
          indicators.push('fa fa-exclamation-circle icon-danger')
        }
        if (this.deviceFeatures && this.deviceFeatures.includes('device_trips')) {
          if (this.power_on(this.device)) {
            indicators.push('fa fa-power-off icon-okay')
          } else {
            indicators.push('fa fa-power-off icon-danger')
          }
        }

        if (this.deviceFeatures && this.deviceFeatures.includes('device_battery_level') && this.battery_low(this.device)) {
          indicators.push('fa fa-battery-quarter icon-warning')
        }
      }
      return indicators
    },
    iconImage () {
      return IconHelper.getIcon(this.device.icon)
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../variables';

.device-container-outer {
  width: 100%;
  max-width: 400px;
  border-radius: 5px;
  user-select: none;
}

.device-container-inner {
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  background: $theme-color-background-4;
  padding: 5px;
}

.invalid-subscription {
  background: #800000;
}

.device-image {
  position: relative;
  width: 50px;
  height: 50px;
  //max-width: 25%;
  background: $theme-color-background-1;
  border-radius: 5px;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
}

.device-image img {
  width: 100%;

}

.device-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  flex-grow: 1;
  color: $theme-color-primary-3;
}

.device-name {
  align-items: center;
  font-weight: 500;
  font-size: 1.2em;
  text-align: center;

}

.detail-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: space-around;
  font-size: 0.7em;
  font-style: italic;
}

.badge {
  position: absolute;
  height: 18px;
  width: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.badge-error {
  right: -1px;
  top: -1px;
}

.badge-acc {
  left: -1px;
  top: -2px;
}

.battery-icon {
  transform: rotate(-90deg);
}

.device-avatar-hover:hover {
  color: white!important;
  background-color: rgba(0,0,0,0.15);
  box-shadow: 0 0 0 0.2rem rgba(56, 212, 41, 0.4);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  .device-inner {
    color: white;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  }
}

.device-avatar.selected {

}

.device-container-outer.selected{
  box-shadow: 0 0 0 0.2rem rgba(45, 231, 28, 0.75);
  .device-container-inner {
    background-color: rgba(50, 50, 50,0.25);
  }
}

.device-container-inner.disabled {
  color: $theme-color-primary-4;
  background-color: rgba(0,0,0,0.3);
  .device-inner {
    color: $theme-color-primary-4;
  }
  .device-image {
    filter: brightness(50%);
  }
}
</style>
