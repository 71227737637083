<template>
  <div class="flex-column trip-avatar"
       :class="{
                selected: selected === true,
                hover: !disabled,
                disabled: disabled
               }"
       @click="clickTrip">
    <div class="flex-row flex-grow-1 align-items-stretch">
      <i class="trip-icon trip-icon-border" :class="$config.icons.general.locations" v-if="location"></i>
      <img class="trip-icon" :src="icon" v-else>
      <div class="flex-column align-items-sm-center flex-grow-1" >
        <div class="flex-row trip-avatar-main" v-if="!location">
          <div>{{stateText}}</div>
          <div>{{dt.timestampToLocalTime(trip.start_time)}}</div>
          <div><i class="fa fa-arrow-right"></i></div>
          <div>{{dt.timestampToLocalTime(trip.end_time)}}</div>
        </div>
        <div class="flex-row trip-avatar-main" v-else>
          <div>Location</div>
          <div>@</div>
          <div>{{dt.timestampToLocalTime(trip.start_time)}}</div>
        </div>
        <div class="trip-avatar-date" v-if="location">
          {{dt.timestampToLocalDateTime(trip.start_time)}}
        </div>
        <div class="trip-avatar-date" v-else>
          {{dt.timestampToLocalDateTime(trip.start_time)}} to {{dt.timestampToLocalDateTime(trip.end_time)}}
        </div>
      </div>

      <div v-if="indicatorIcons.length" class="trip-indicators ml-1">
        <i class="indicator-icon" v-for="(indicator, idx) of indicatorIcons" v-bind:key="idx" :class="indicator.icon"
           v-b-tooltip :title="indicator.text"
        ></i>
      </div>
    </div>
    <div class="flex-column trips-addresses align-items-md-stretch" v-if="show_address && hasAddressData">
      <div class="flex-row justify-content-between">
        <div class="trips-addresses-label">From:</div>
        <b-tooltip :target="'trip-' + trip.id + '-startAddress'" noninteractive :title="startAddressFull"></b-tooltip>
        <div :id="'trip-' + trip.id + '-startAddress'" v-b-tooltip >{{ startAddressTruncated }}</div>
      </div>
      <div class="flex-row justify-content-between">
        <div class="trips-addresses-label ">To:</div>
        <b-tooltip :target="'trip-' + trip.id + '-endAddress'" noninteractive :title="startAddressFull"></b-tooltip>
        <div :id="'trip-' + trip.id + '-endAddress'"> {{endAddressTruncated}} </div>
      </div>
    </div>
    <div class="trip-section" v-if="show_tags && hasTags">
      <TripTags :value="trip.tags"></TripTags>
    </div>
  </div>
</template>
<script>
import * as IconHelper from '@/components/helpers/IconHelper'
import {DateTimeHelper as dt} from '@/components/helpers/DateTimeHelper'
import TripTags from '@/components/trips/TripTags.vue'

export default {
  name: 'TripAvatar',
  components: {TripTags},
  props: {
    trip: Object,
    selected: Boolean,
    show_address: Boolean,
    disabled: Boolean,
    location: Boolean, // Treat as a location (a trip with a single point for Object Trackers)
    show_tags: Boolean
  },
  data () {
    return {
      stateStrings: {
        MOVING: 'Driving',
        PARKED: 'Parked'
      }
    }
  },
  async mounted () {
  },
  methods: {
    clickTrip(e) {
      this.$emit('click', e)
    },
    getStartAddress() {
      if (this.trip.start_location_address && Object.hasOwn(this.trip.start_location_address, 'data')) {
        return this.trip.start_location_address.data.address_text
      } else {
        return 'None'
      }
    },
    getEndAddress() {
      if (this.trip.end_location_address && Object.hasOwn(this.trip.end_location_address, 'data')) {
        return this.trip.end_location_address.data.address_text
      } else {
        return 'None'
      }
    }
  },
  computed: {
    dt() {
      return dt
    },
    tripId () {
      return this.trip ? this.trip.id : ''
    },
    stateText () {
      if (this.trip && Object.hasOwn(this.stateStrings, this.trip.state)) {
        return this.stateStrings[this.trip.state]
      } else {
        return 'Unknown State'
      }
    },
    icon () {
      if (this.trip && Object.hasOwn(IconHelper.tripIcons, this.trip.state)) {
        return IconHelper.tripIcons[this.trip.state]
      } else {
        return ''
      }
    },
    hasAddressData() {
      return this.trip.start_location_address || this.trip.end_location_address
    },
    startAddressTruncated () {
      if (this.startAddressFull.length > 32) {
        return this.startAddressFull.substring(0, 29) + '...'
      } else {
        return this.startAddressFull
      }
    },
    startAddressFull () {
      return this.getStartAddress()
    },
    endAddressTruncated () {
      if (this.endAddressFull.length > 32) {
        return this.endAddressFull.substring(0, 29) + '...'
      } else {
        return this.endAddressFull
      }
    },
    endAddressFull () {
      return this.getEndAddress()
    },
    indicatorIcons () {
      let indicators = []
      if (this.trip.annotation !== null) {
        indicators.push({
          text: `Trip Annotation: ${this.trip.annotation}`,
          icon: 'fa fa-pencil-square-o'
        })
      }
      console.log(this.trip.trip_forms)
      if (this.trip && this.trip.trip_forms && this.trip.trip_forms.length) {
        indicators.push({
          text: `Trip has ${this.trip.trip_forms.length} Attached Form(s).`,
          icon: 'fa fa-file-text-o'
        })
      }
      console.log(indicators)
      return indicators
    },
    hasTags () {
      if (this.trip && Object.hasOwn(this.trip, 'tags')) {
        return Object.keys(this.trip.tags).length
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/variables';

.trip-avatar {
  background: $theme-color-background-4;
  color: $theme-color-primary-3;
  border-radius: 5px;
  padding: 5px 5px;
  margin: 2px 0;
  align-items: stretch;
  user-select: none;
}

.trip-avatar.hover:hover {
  color: white;
  background-color: rgba(0,0,0,0.15);
  box-shadow: 0 0 0 0.2rem rgba(56, 212, 41, 0.4);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  .trip-avatar-main {
    color: white;
    transition: color 0.15s ease-in-out;
  }
  .trip-indicators {
    color: white;
    transition: color 0.15s ease-in-out;
  }
}
.trip-avatar.selected {
  background-color: rgba(50, 50, 50,0.25);
  box-shadow: 0 0 0 0.2rem rgba(45, 231, 28, 0.75);
  .trip-indicators {
    color: white;
    transition: color 0.15s ease-in-out;
  }
}

.trip-avatar.disabled {
  color: $theme-color-primary-4;
  background-color: rgba(0,0,0,0.3);
  .trip-indicators {
    filter: brightness(50%);
  }
  .trip-icon {
    filter: brightness(50%);
  }
}

.trip-avatar-date {
  font-size: 12px;
  font-style: italic;
  font-weight: 500;
}

.trip-avatar-main {
  flex-grow: 1;
  color: grey;
  font-size: 18px;
  font-weight: 500;
  div {
    margin-left: 0.8rem;
  }
}

.trip-avatar.selected .trip-avatar-main{
  color: white;
}

.trips-addresses {
  border-top: 1px solid $theme-color-primary-3
}

.trips-addresses-label {
  font-weight: 600;
}

.trip-icon {
  width: 38px;
  color: white;
  font-size: 36px;
  text-align: center;
}

// Add a border to match the images
.trip-icon-border {
  border: 1px solid #DDDDDD;
  border-radius: 3px;
}

.trip-indicators {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: space-around;
  color: #dedede;
}

.trip-section {
  border-top: 1px solid var(--theme-color-primary-3);
  padding: 5px;
}


</style>
