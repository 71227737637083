var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "device-status" }, [
    !_vm.loading
      ? _c(
          "div",
          { staticClass: "widget-container" },
          _vm._l(_vm.status_widgets, function(feature, idx) {
            return _c("GenericStatusWidget", {
              key: idx,
              ref: feature,
              refInFor: true,
              staticClass: "col-6",
              attrs: {
                device: _vm.device,
                feature_code: feature,
                editable: _vm.editable
              },
              on: {
                change: function($event) {
                  _vm.unsavedChanges = true
                }
              }
            })
          }),
          1
        )
      : _vm._e(),
    _c("div", { staticClass: "spacing" }),
    _c("div", { staticClass: "footer mt-2" }, [
      _c(
        "button",
        {
          staticClass: "button",
          attrs: { id: "button-close" },
          on: { click: _vm.clickClose }
        },
        [_vm._v("Close")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }