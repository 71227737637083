var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "parent", staticClass: "week-time-selector flex-column" },
    [
      _vm._m(0),
      _c(
        "div",
        {
          staticClass: "week-time-container flex-column",
          class: { disabled: _vm.disabled }
        },
        [
          _c("div", { staticClass: "flex-row time-header" }, [
            _c("div", { staticClass: "day-header" }),
            _c(
              "div",
              {
                ref: "timeHeader",
                staticClass: "flex-row",
                class: { disabled: _vm.disabled }
              },
              _vm._l(_vm.timeSteps, function(step, idx) {
                return _c(
                  "div",
                  { key: "time-header-" + idx, staticClass: "time-slot" },
                  [_vm._v(" " + _vm._s(step) + " ")]
                )
              }),
              0
            ),
            _c("div", { staticClass: "day-controls" })
          ]),
          _c(
            "div",
            {},
            _vm._l(Object.values(_vm.dayValues), function(day, idx) {
              return _c(
                "div",
                {
                  key: "day-header-" + idx,
                  staticClass: "flex-row day-height day-row",
                  class: { disabled: _vm.disabled }
                },
                [
                  _c("div", { staticClass: "day-header" }, [
                    _vm._v(" " + _vm._s(day.text) + " ")
                  ]),
                  !_vm.loading
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "position-relative flex-grow-1 flex-row seg-personal row-segment-height",
                          on: {
                            click: function($event) {
                              return _vm.clickDayRow(day, $event)
                            }
                          }
                        },
                        _vm._l(_vm.daySegments[idx], function(seg, segIdx) {
                          return _c(
                            "div",
                            {
                              key: "day-" + idx + "-seg-" + segIdx,
                              staticClass:
                                "row-segment row-segment-height seg-work",
                              class: {
                                disabled: _vm.disabled,
                                selected:
                                  _vm.selectedSegment &&
                                  _vm.selectedSegment.day === seg.day &&
                                  _vm.selectedSegment.index === seg.index
                              },
                              style:
                                "width: " +
                                seg.width +
                                "px; left: " +
                                seg.offset +
                                "px;",
                              on: {
                                click: function($event) {
                                  return _vm.selectSegment(seg, $event)
                                },
                                mousedown: function($event) {
                                  return _vm.dragSegmentStart(
                                    "move",
                                    seg,
                                    $event
                                  )
                                }
                              }
                            },
                            [
                              _c("div", { staticClass: "font-weight-bold" }, [
                                _vm._v("Work")
                              ]),
                              _c("div", { staticClass: "row-segment-times" }, [
                                _vm._v(
                                  _vm._s(_vm.dt.toLocalTime(seg.start)) +
                                    " -> " +
                                    _vm._s(_vm.dt.toLocalTime(seg.end))
                                )
                              ]),
                              _c("div", {
                                staticClass: "row-segment-handle start",
                                class: {
                                  selected:
                                    _vm.selectedSegment &&
                                    _vm.selectedSegment.day === seg.day &&
                                    _vm.selectedSegment.index === seg.index
                                },
                                on: {
                                  mousedown: function($event) {
                                    return _vm.dragSegmentStart(
                                      "start",
                                      seg,
                                      $event
                                    )
                                  }
                                }
                              }),
                              _c("div", {
                                staticClass: "row-segment-handle end",
                                class: {
                                  selected:
                                    _vm.selectedSegment &&
                                    _vm.selectedSegment.day === seg.day &&
                                    _vm.selectedSegment.index === seg.index
                                },
                                on: {
                                  mousedown: function($event) {
                                    return _vm.dragSegmentStart(
                                      "end",
                                      seg,
                                      $event
                                    )
                                  }
                                }
                              })
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "flex-row day-controls" }, [
                    _c(
                      "button",
                      {
                        directives: [
                          { name: "b-tooltip", rawName: "v-b-tooltip" }
                        ],
                        staticClass: "slim-controls",
                        attrs: { disabled: _vm.disabled, title: "Reset" },
                        on: {
                          click: function($event) {
                            return _vm.resetDay(day)
                          }
                        }
                      },
                      [_c("i", { staticClass: "fa fa-undo" })]
                    ),
                    _c(
                      "button",
                      {
                        directives: [
                          { name: "b-tooltip", rawName: "v-b-tooltip" }
                        ],
                        staticClass: "slim-controls",
                        attrs: { disabled: _vm.disabled, title: "Clear" },
                        on: {
                          click: function($event) {
                            return _vm.clearDay(day)
                          }
                        }
                      },
                      [
                        _c("i", {
                          class: _vm.$config.icons.general.clearClipboard
                        })
                      ]
                    )
                  ])
                ]
              )
            }),
            0
          )
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _vm._v(" Legend "),
      _c("div", { staticClass: "seg-personal px-2" }, [_vm._v("Personal")]),
      _c("div", { staticClass: "seg-work px-2" }, [_vm._v("Work")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }