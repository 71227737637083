import axios from 'axios/index'
import moment from 'moment'
import {isNull} from 'bootstrap-vue/esm/utils/inspect'
import router from '@/router'
import * as ErrorHelper from '@/components/helpers/ErrorHelper'
import * as AlertHelper from '@/components/helpers/AlertHelper'


// Helper for interfacing with Protekt API
// https://dev.pro-tekt.com.au/api/v1/heartbeat

export function getAPIUrl(frontEndURL = null, environmentName = null, ignoreStaticURL=false) {
  let url
  if (!frontEndURL) {
    frontEndURL = window.location.hostname
  }
  if (!environmentName) {
    environmentName = process.env.VUE_APP_ENVIRONMENT_NAME
  }
  // Static URL set via Environmental Variable.
  if (process.env.VUE_APP_API_URL && !ignoreStaticURL) {
    url = process.env.VUE_APP_API_URL
  } else if (environmentName) {
    if (environmentName === 'production') {
      url = `https://api.${frontEndURL.split('.').slice(1, frontEndURL.length).join('.')}/api/v1`
    } else if (frontEndURL.includes('pro-tekt.com.au')) {
      // TODO - Remove this when we get control of the old domains and change the subdomain layout
      url = `https://${environmentName}.pro-tekt.com.au/api/v1`
    } else if (frontEndURL.includes('protektgps.com')) {
      url = `https://api.${environmentName}.protektgps.com/api/v1`
    } else {
      console.error('Unknown Front End Hostname. Falling back to protektgps.com formatting...')
      url = `https://api.${environmentName}.${frontEndURL.split('.').slice(1, frontEndURL.length).join('.')}/api/v1`
    }
  } else {
    console.error('Unable to determine API URL! Neither VUE_APP_API_URL nor VUE_APP_ENVIRONMENT_NAME was set!')
  }
  console.log('Using API URL: ', url)
  return url
}

export const API_URL = getAPIUrl()

const api = axios.create({
  baseURL: API_URL,
  timeout: 20000
  // headers:
  //   { 'Content-Type': 'multipart/form-data' }
})

function addInterceptors() {
  api.interceptors.request.use(authIntercept)
  api.interceptors.response.use(logoutInterceptor, errorInterceptor)
}

addInterceptors()

// Auth Interceptor - Injects our authentication token (if present) into HTTP requests
export function authIntercept(request) {
  let token = localStorage.getItem('token')
  if (token !== null) {
    // // Does the request already contain form data?
    // if (request.data instanceof FormData) {
    //   request.data.set('token', token)
    //
    // // Blank request. Attach token anyway!
    // } else if (request.data === undefined) {
    //   let data = new FormData()
    //   data.set('token', token)
    //   request.data = data
    // } else {
    //   console.log('AUTH INTERCEPTOR WARNING: Unknown request payload. Token not attached.')
    // }
    if (request.hasOwnProperty('data')) {
      request.data.token = token
    } else {
      request.data = {
        token: token
      }
    }
  }
  return request
}

// Handles successful (2xx) responses from API
export function logoutInterceptor(response) {
  if (response.data.logout_user) {
    return router.push({path: '/logout'})
  }

  return response
}

// Handles error (non 2xx) responses from API
export function errorInterceptor(error) {
  if (error.hasOwnProperty('response') && error.response) {
    // Check if this is an invalid token error from our server...
    if (
      error.response.hasOwnProperty('data') &&
      error.response.data.hasOwnProperty('message') &&
      error.response.data.message.toLowerCase() === 'invalid token'
    ) {
      console.log('Invalid Token!')
      try {
        router.push({path: '/logout'})
      } catch (e) {
        console.error('Error redirecting to logout page.')
      }
    }
  }
  throw error
}

// // Helper function to convert an object and it's properties into form data
// export function objectToFormData (sourceObj) {
//   let formData = new FormData()
//   for (let prop in sourceObj) {
//     formData.set(prop, sourceObj[prop])
//   }
//
//   return formData
// }

// Helper function to send data, catch errors and return them gracefully.
async function sendAPICall(data) {
  try {
    let result = await api(data)
    ErrorHelper.hideDisconnectPopup()
    return {success: true, data: result.data}
  } catch (err) {
    console.error(err.message)
    console.error(err)
    if (err.message === 'Network Error') {
      ErrorHelper.displayGeneralErrorToast(
        'Uh oh, we can\'t connect to the Pro-Tekt Server! Please refresh the page to try again.',
        'Network Connection Error',
        10000
      )
      ErrorHelper.showDisconnectPopup()

      return {
        success: false,
        message: 'Network Connection Error',
        error: err
      }
    } else if (err.response.data) {
      if (err.response.data.logout_user) {
        router.push({path: '/logout'})
      }
      return {
        success: false,
        message: 'Error getting Data from Protekt server!',
        error: err.response.data
      }
    } else {
      ErrorHelper.displayGeneralErrorToast(
        'Uh oh, we can\'t there was an unexpected error! Please refresh the page to try again.',
        'Unexpected Error',
        0
      )
    }
  }
}

// Helper function to convert a dict-like object into an array.
export function objToArray(obj) {
  let array = []
  for (let prop in obj) {
    obj[prop].id = prop // Add the User ID to the User Object, as it's not in the data
    array.push(obj[prop])
  }
  return array
}

function formatUserData(responseData) {
  if (responseData.success) {
    responseData.data.devices = objToArray(responseData.data.devices)
    responseData.data.shared_devices = objToArray(
      responseData.data.shared_devices
    )
  }

  return responseData
}

// Request server Heartbeat
export async function heartbeat() {
  const postConfig = {
    method: 'get',
    url: '/heartbeat'
  }

  try {
    let result = await api(postConfig)
    return {success: true, data: result.data}
  } catch (err) {
    console.error(err)
    return {success: false, message: 'Heartbeat Failed'}
  }
}

/* -------------------------------------------------------------- */
/*                        Auth Functions                          */
/* -------------------------------------------------------------- */

// Register a new user.
export async function registerUser(fullname, email, mobile) {
  const postConfig = {
    method: 'post',
    url: '/user/register',
    data: {
      fullname: fullname,
      email: email,
      mobile_phone: mobile
    }
  }

  return sendAPICall(postConfig)
}

// Attempt to log in with a given username and password
export async function sendLogin(identifier, password) {
  let data = {password}
  // TODO - This probably should be a better check for email vs phone number
  if (identifier.includes('@')) {
    data.email = identifier
  } else {
    data.mobile_number = identifier
  }

  const postConfig = {
    method: 'post',
    url: '/login',
    data: data
  }

  try {
    let result = await api(postConfig)
    localStorage.setItem('token', result.data.token)
    localStorage.setItem('userid', result.data.user_id)
    console.log('Login', result)
    return {success: true, message: 'Success'}
  } catch (err) {
    console.error(err)
    if (err.response.status === 403) {
      return {
        success: false,
        message: 'Incorrect username or password. Please Try Again.'
      }
    } else {
      return {
        success: false,
        message:
          'Error communicating with Protekt Server. Please try again Later.',
        error: err.response.data
      }
    }
  }
}

// Log Out
export async function logOut() {
  const postConfig = {
    method: 'post',
    url: '/user/logout'
  }
  // Remove Tokens stored locally, regardless of result.
  let result = await sendAPICall(postConfig)

  return result
}

// Forgotten Password - Provide email and a reset link is sent via email
export async function forgottenPasswordEmail(emailAddress) {
  const postConfig = {
    method: 'post',
    url: '/user/forgot_password/email',
    data: {
      email: emailAddress
    }
  }

  return sendAPICall(postConfig)
}

// Forgotten Password - Provide mobile number and a reset link is sent via email
export async function forgottenPasswordMobile(mobileNumber) {
  const postConfig = {
    method: 'post',
    url: '/user/forgot_password/sms',
    data: {
      mobile_number: mobileNumber
    }
  }

  return sendAPICall(postConfig)
}

// Forgotten Password - Provide email and have a SMS sent to unlock
export async function forgottenPasswordSMS(emailAddress) {
  const postConfig = {
    method: 'post',
    url: '/user/forgot_password/sms',
    data: {
      email: emailAddress
    }
  }

  return sendAPICall(postConfig)
}

// Forgotten Password - Provide email and have a SMS sent to unlock
export async function forgottenPasswordSMSMobile(mobileNumber) {
  const postConfig = {
    method: 'post',
    url: '/user/forgot_password/sms',
    data: {
      mobile_number: mobileNumber
    }
  }

  return sendAPICall(postConfig)
}

// Forgotten Password Change - Use the supplied token/code to reset password
export async function forgottenPasswordChange(resetCode, password) {
  const postConfig = {
    method: 'post',
    url: '/user/forgot_password/change',
    data: {
      reset_code: resetCode,
      password: password
    }
  }

  return sendAPICall(postConfig)
}

// Change password of current user
export async function changePassword(password, confirmPassword) {
  const postConfig = {
    method: 'post',
    url: '/user/change_password',
    data: {
      password,
      verify_password: confirmPassword
    }
  }

  return sendAPICall(postConfig)
}

// Change password of current user
export async function adminSetPassword(userId, password) {
  const postConfig = {
    method: 'post',
    url: '/users/set_password',
    data: {
      user_id: userId,
      password
    }
  }

  return sendAPICall(postConfig)
}

// Get profile details for user (Users can only see their own profile)
export async function getUserProfile() {
  let userId = localStorage.getItem('userid')
  const postConfig = {
    method: 'post',
    url: `/user/${userId}`
  }

  let response = await sendAPICall(postConfig)
  if (response.success) {
    try {
      // response.data.settings = JSON.parse(response.data.settings)
    } catch (err) {
      console.log('Failed to parse settings JSON.')
    }
  }

  return response
}

// Get profile details for user (Users can only see their own profile)
export async function getUserByToken() {
  const postConfig = {
    method: 'post',
    url: `/users/me`
  }

  let response = await sendAPICall(postConfig)
  if (response.success) {
    try {
      // response.data.settings = JSON.parse(response.data.settings)
    } catch (err) {
      console.log('Failed to parse settings JSON.')
    }
  }

  return response
}

// Update User Profile
// NOTE: The API treats User settings as a dict, not a string. Omitting entries to be saved will NOT overwrite them
//       in the way that it would if the data was stored as stringified JSON.
export async function setUserProfile(name, email, mobile, settings) {
  let userId = localStorage.getItem('userid')

  const postConfig = {
    method: 'post',
    url: `/user/${userId}`,
    data: {
      name,
      email,
      mobile,
      settings
    }
  }

  return sendAPICall(postConfig)
}

// Update the Current User's payment information in Stripe
export async function setPaymentMethod(paymentId) {
  const postConfig = {
    method: 'post',
    url: `/user/updatePaymentMethod`,
    data: {
      payment_id: paymentId
    }
  }

  return sendAPICall(postConfig)
}

// Update the Current User's subscription details (creates one if there is no subscription)
export async function updateSubscription (productIds = null, paymentId = null) {
  let data = {}
  if (productIds) {
    data.product_ids = productIds
  }
  if (paymentId) {
    data.payment_id = paymentId
  }
  const postConfig = {
    method: 'post',
    url: `/user/subscription/edit`,
    data: data
  }

  return sendAPICall(postConfig)
}

// Get the current user's subscription detail
export async function getSubscription() {
  const postConfig = {
    method: 'post',
    url: `/user/subscription`,
    data: {}
  }

  return sendAPICall(postConfig)
}

// Delete a subscription
export async function adminDeleteSubscription(subscriptionId) {
  const postConfig = {
    method: 'post',
    url: `/user/subscription/delete`,
    data: {
      subscription_id: subscriptionId
    }
  }

  return sendAPICall(postConfig)
}

// Get a session URL for the Stripe Billing Portal
export async function getBillingPortalSession() {
  const postConfig = {
    method: 'post',
    url: `/user/getPortalSession`,
    data: {}
  }

  return sendAPICall(postConfig)
}

// Return only the settings JSON object from the server
// NOTE: The API treats User settings as a dict, not a string. Omitting entries to be saved will NOT overwrite them
//       in the way that it would if the data was stored as stringified JSON.
export async function getUserSettings() {
  let userId = localStorage.getItem('userid')
  const postConfig = {
    method: 'post',
    url: `/user/${userId}`
  }

  let response = await sendAPICall(postConfig)
  if (response.success) {
    if (isNull(response.data.settings)) {
      // New Users have their settings data set as Null. Provide a blank Object instead.
      response.data.settings = {}
    } else {
      try {
        // response.data = JSON.parse(response.data.settings)
      } catch (err) {
        console.log('Warning: Failed to parse Settings JSON.')
        response.data.settings = {}
      }
    }
  }
  // We're only returning the settings data
  return {success: response.success, data: response.data.settings}
}

// NOTE: The API treats User settings as a dict, not a string. Omitting entries to be saved will NOT overwrite them
//       in the way that it would if the data was stored as stringified JSON.
export async function setUserSettings(settings) {
  let userId = localStorage.getItem('userid')

  const postConfig = {
    method: 'post',
    url: `/user/${userId}`,
    data: {
      settings
    }
  }

  return sendAPICall(postConfig)
}

// Get profile details for user (Users can only see their own profile)
export async function getProducts() {
  const postConfig = {
    method: 'post',
    url: `/products`
  }

  return sendAPICall(postConfig)
}

/* -------------------------------------------------------------- */
/*                   User Device Functions                        */
/* -------------------------------------------------------------- */

// Get a list of devices visible to the current user
export async function getDeviceList(whitelist = null) {
  const postConfig = {
    method: 'post',
    url: '/devices',
    data: {}
  }
  if (whitelist) {
    postConfig.data.whitelist = whitelist
  }

  return sendAPICall(postConfig)
}

// Get device List for Admins
export async function getFilteredDeviceList(
  textFilter,
  searchOptions,
  whitelist = null
) {
  // Valid Search attributes: device_id, device_code, device_password, device_name, device_phone, device_imei, registration, plant_num, icon, user
  // alarm_status (bool), geolimit_status (bool), livetrack_status (bool), vibration_alarm_active (bool), overspeed_limit {min/max}, odometer_reading {min/max},
  // voltage_level {min/max}, hours_tracked {min/max}
  let data = {}
  if (searchOptions) {
    data = searchOptions
  }
  if (textFilter) {
    data.anywhere = textFilter
  }
  if (whitelist) {
    data.whitelist = whitelist
  }
  const postConfig = {
    method: 'post',
    url: '/devices',
    data: data
  }

  let responseData = await sendAPICall(postConfig)
  if (responseData.success) {
    responseData.data = objToArray(responseData.data)
    return responseData
  } else {
    return responseData
  }
}

// Claim Ownership of a Device
export async function claimDevice(deviceCode, deviceName) {
  const postConfig = {
    method: 'post',
    url: '/devices/claim_device',
    data: {
      device_code: deviceCode,
      device_name: deviceName
    }
  }

  return sendAPICall(postConfig)
}

// Get device details for a given device
export async function getDeviceByIMEI(deviceIMEI, whitelist = null) {
  let data = {}
  if (whitelist) {
    data.whitelist = whitelist
  }
  const postConfig = {
    method: 'post',
    url: '/devices/' + deviceIMEI,
    data: data
  }

  return sendAPICall(postConfig)
}

// Update the device details on the server side
export async function updateDeviceDetails(
  deviceIMEI,
  deviceName,
  registration,
  plantNum,
  icon,
  overspeed,
  odometer,
  hoursTracked,
  serviceScheduleMode,
  serviceFrequency,
  notes
) {
  const postConfig = {
    method: 'post',
    url: '/devices/' + deviceIMEI + '/edit',
    data: {
      device_name: deviceName,
      registration: registration,
      plant_num: plantNum,
      icon: icon,
      overspeed_limit: overspeed,
      odometer_reading: odometer,
      hours_tracked: hoursTracked,
      service_schedule_mode: serviceScheduleMode,
      service_frequency: serviceFrequency,
      notes: notes
    }
  }

  return sendAPICall(postConfig)
}

// Update the device details on the server side
export async function updateDeviceProperty(deviceIMEI, propName, value) {
  let postConfig = {
    method: 'post',
    url: '/devices/' + deviceIMEI + '/edit',
    data: {}
  }
  postConfig.data[propName] = value

  return sendAPICall(postConfig)
}

// Update the device details on the server side
export async function updateDeviceProperties(deviceIMEI, updateData) {
  let postConfig = {
    method: 'post',
    url: '/devices/' + deviceIMEI + '/edit',
    data: updateData
  }

  return sendAPICall(postConfig)
}

// Get the Geolimit for a given IMEI
export async function getDeviceGeolimit(deviceIMEI) {
  const postConfig = {
    method: 'post',
    url: '/geolimit',
    data: {
      device_imei: deviceIMEI
    }
  }

  return sendAPICall(postConfig)
}

// Get integrations for a given IMEI
export async function getDeviceIntegrations (deviceIMEI) {
  const postConfig = {
    method: 'post',
    url: '/integrations/device/' + deviceIMEI
  }

  return sendAPICall(postConfig)
}

export async function getIntegrationTypes () {
  const postConfig = {
    method: 'post',
    url: '/integrations/types'
  }

  return sendAPICall(postConfig)
}

export async function deleteIntegration (integrationId) {
  const postConfig = {
    method: 'post',
    url: '/integrations/delete',
    data: {
      id: integrationId
    }
  }

  return sendAPICall(postConfig)
}

export async function addIntegration (integration) {
  const postConfig = {
    method: 'post',
    url: '/integrations/update',
    data: integration
  }

  return sendAPICall(postConfig)
}

// Set the Geolimit for a given IMEI
export async function setDeviceGeolimit(deviceIMEI, lat, lng, radius, state) {
  const postConfig = {
    method: 'post',
    url: '/geolimit',
    data: {
      device_imei: deviceIMEI,
      latitude: lat,
      longitude: lng,
      radius_meters: radius,
      geolimit_status: state
    }
  }

  return sendAPICall(postConfig)
}

// Get the Livetrack State for a given IMEI
export async function getDeviceLivetrack(deviceIMEI) {
  const postConfig = {
    method: 'post',
    url: '/devices/' + deviceIMEI + '/livetrack'
  }

  return sendAPICall(postConfig)
}

// Get the Livetrack State for a given IMEI
export async function setDeviceLivetrack(deviceIMEI, newState) {
  const postConfig = {
    method: 'post',
    url: '/devices/' + deviceIMEI + '/livetrack',
    data: {
      livetrack_status: newState
    }
  }

  return sendAPICall(postConfig)
}

// Get the most recent location for a device
export async function getDeviceLocation(deviceIMEI) {
  const postConfig = {
    method: 'post',
    url: '/location/' + deviceIMEI
  }

  return sendAPICall(postConfig)
}

// Get the most recent location for a device
export async function getDeviceRecentLocations(deviceIMEI) {
  const postConfig = {
    method: 'post',
    url: '/location/most_recent/' + deviceIMEI
  }

  return sendAPICall(postConfig)
}

// Get device history
export async function getDeviceTrips(
  deviceIMEI,
  startDate,
  endDate,
  validState = null,
  getTripData = true,
  limit = null,
  offset = null,
  getEvents = false,
  getForms = false
) {
  // TODO - Test for timezone formatting
  let startDateUTC = moment(startDate)
    .utc()
    .toISOString()
  let endDateUTC = moment(endDate)
    .utc()
    .toISOString()

  const postConfig = {
    method: 'post',
    url: '/location/trips/' + deviceIMEI,
    data: {
      start_date: startDateUTC,
      end_date: endDateUTC,
      timezone: new Date().getTimezoneOffset(),
      valid_state: validState,
      get_trip_data: getTripData,
      limit: limit,
      offset: offset,
      trip_events: getEvents,
      get_trip_forms: getForms
    }
  }

  return sendAPICall(postConfig)
}

export async function getTrips(
  deviceImeis,
  startDate,
  endDate,
  validState = null,
  getTripData = true,
  limit = null,
  offset = null,
  getEvents = false,
  getForms = false
) {
  let startDateUTC = moment(startDate)
    .utc()
    .toISOString()
  let endDateUTC = moment(endDate)
    .utc()
    .toISOString()

  const postConfig = {
    method: 'post',
    url: '/location/trips',
    data: {
      device_imeis: deviceImeis,
      start_date: startDateUTC,
      end_date: endDateUTC,
      timezone: new Date().getTimezoneOffset(),
      valid_state: validState,
      get_trip_data: getTripData,
      limit: limit,
      offset: offset,
      trip_events: getEvents,
      get_trip_forms: getForms
    }
  }

  return sendAPICall(postConfig)
}

export async function getTrip(tripId, getTripData = false, getEvents = false, getForms = false) {
  const postConfig = {
    method: 'post',
    url: '/location/trip/' + tripId,
    data: {
      get_trip_data: getTripData,
      trip_events: getEvents,
      get_trip_forms: getForms
    }
  }

  return sendAPICall(postConfig)
}

// Get device statistics
export async function getStatistics(
  selection,
  startDate,
  endDate,
  tripPurpose,
  tagFilter
) {
  // TODO - Test for timezone formatting
  let startDateUTC = moment(startDate)
    .utc()
    .toISOString()
  let endDateUTC = moment(endDate)
    .utc()
    .toISOString()

  const postConfig = {
    method: 'post',
    url: '/statistics',
    data: {
      device_imei: selection,
      start_date: startDateUTC,
      end_date: endDateUTC,
      trip_purpose: tripPurpose,
      tag_filter: tagFilter
    }
  }

  return sendAPICall(postConfig)
}

/* -------------------------------------------------------------- */
/*                Notification (Alert) Functions                  */
/* -------------------------------------------------------------- */

// Get a list of notifications from the server
export async function getNotifications(excludeIds, filters) {
  const postConfig = {
    method: 'post',
    url: '/notifications',
    data: {
      exclude_ids: excludeIds,
      filters: filters
    }
  }

  return sendAPICall(postConfig)
}

// Get a paginated list of Notifications
export async function getNotificationsPage(
  pageSize,
  offset,
  sortBy,
  sortDesc,
  filters
) {
  const postConfig = {
    method: 'post',
    url: '/notifications',
    data: {
      limit: pageSize,
      offset: offset,
      sort_by: sortBy,
      sort_desc: sortDesc,
      filters: filters
    }
  }

  return sendAPICall(postConfig)
}

// Get a count of all available notifications
export async function getNotificationCount(filters) {
  const postConfig = {
    method: 'post',
    url: '/notifications/count',
    data: {
      filters: filters
    }
  }

  return sendAPICall(postConfig)
}

// Clear a specific notification
export async function markNotificationRead(notificationId) {
  const postConfig = {
    method: 'post',
    url: '/notifications/mark_read',
    data: {
      notification_id: notificationId
    }
  }

  return sendAPICall(postConfig)
}

// Clear a specific notification
export async function markAllNotificationsRead() {
  const postConfig = {
    method: 'post',
    url: '/notifications/mark_read_all'
  }

  return sendAPICall(postConfig)
}

// Send a Test Notification to a user
export async function sendTestNotificationToUser(userEmail) {
  const postConfig = {
    method: 'post',
    url: '/send_notification',
    data: {
      email: userEmail
    }
  }
  return sendTestNotification(postConfig)
}

// Send a Test Notification to a device
export async function sendTestNotificationToDevice(imei) {
  const postConfig = {
    method: 'post',
    url: '/send_notification',
    data: {
      imei: imei
    }
  }
  return sendTestNotification(postConfig)
}

async function sendTestNotification(data) {
  const postConfig = {
    method: 'post',
    url: '/send_notification',
    data: data
  }

  return sendAPICall(postConfig)
}

/* -------------------------------------------------------------- */
/*                Sharing (and Caring) Functions                  */
/* -------------------------------------------------------------- */

// Get a list of all devices that are shared and the current permissions and users.
export async function getShareList() {
  const postConfig = {
    method: 'post',
    url: '/share/list'
  }

  return sendAPICall(postConfig)
}

// Add a share
// NOTE: Endpoint accepts 'all' as a valid IMEI reference to share all a user's devices.
export async function addShare(imei, email, permissionLevel) {
  const postConfig = {
    method: 'post',
    url: '/share/add',
    data: {
      device_imei: imei,
      email: email,
      permission_level: permissionLevel
    }
  }

  return sendAPICall(postConfig)
}

// Remove a share
export async function removeShare(imei, email) {
  const postConfig = {
    method: 'post',
    url: '/share/remove',
    data: {
      device_imei: imei,
      email: email
    }
  }

  return sendAPICall(postConfig)
}

// Get Device Events
export async function getDeviceEvents(imei, startDate, endDate, eventType = null) {
  let startDateUTC = moment(startDate)
    .utc()
    .toISOString()
  let endDateUTC = moment(endDate)
    .utc()
    .toISOString()

  const postConfig = {
    method: 'post',
    url: `/devices/${imei}/events`,
    data: {
      start_time: startDateUTC,
      end_time: endDateUTC,
      event_type: eventType
    }
  }

  return sendAPICall(postConfig)
}

export async function addDeviceExclusion(
  deviceIMEI, exclusion
) {
  const postConfig = {
    method: 'post',
    url: `/devices/${deviceIMEI}/exclusions/add`,
    data: exclusion
  }

  return sendAPICall(postConfig)
}

export async function deleteExclusion(exclusionID) {
  const postConfig = {
    method: 'post',
    url: `/devices/exclusions/delete`,
    data: {
      exclusion_id: exclusionID,
      action: 'delete'
    }
  }

  return sendAPICall(postConfig)
}

// Add Device Event
export async function addDeviceEvent(
  imei,
  startDate,
  endDate,
  eventType,
  eventSubType,
  eventData
) {

  const postConfig = {
    method: 'post',
    url: `/devices/${imei}/events/add`,
    data: {
      device_imei: imei,
      start_time: startDate,
      end_time: endDate,
      event_type: eventType,
      event_subtype: eventSubType,
      event_data: eventData
    }
  }

  return sendAPICall(postConfig)
}

// Edit Device Event
export async function editDeviceEvent(
  imei,
  eventId,
  startDate,
  endDate,
  eventType,
  eventSubType,
  eventData
) {
  const postConfig = {
    method: 'post',
    url: `/devices/${imei}/events/edit`,
    data: {
      device_imei: imei,
      event_id: eventId,
      start_time: startDate,
      end_time: endDate,
      event_type: eventType,
      event_subtype: eventSubType,
      event_data: eventData
    }
  }

  return sendAPICall(postConfig)
}

/* -------------------------------------------------------------- */
/*                     Admin Functions                            */
/* -------------------------------------------------------------- */

// Get Queue Health
export async function adminGetQueueHealth(queueName) {
  const postConfig = {
    method: 'post',
    url: `/admin/queue/${queueName}/health`
  }

  return sendAPICall(postConfig)
}

// Get a user List. Accept filtering options
export async function adminGetUserList(textFilter, searchOptions, whitelist = null) {
  // Valid Search attributes: email, name, mobile_number, admin (bool), activated (bool)
  let data = {}
  if (searchOptions) {
    data = searchOptions
  }
  if (whitelist) {
    data.whitelist = whitelist
  }
  if (textFilter) {
    data.anywhere = textFilter
  }
  const postConfig = {
    method: 'post',
    url: '/users',
    data: data
  }

  return sendAPICall(postConfig)
}

// Get device List for Admins - paginated
export async function getAdminUserListPaginated(pagingOptions) {
  // Valid paging options: start: int = 0, page_size: int = 10
  let data = {}
  if (pagingOptions) {
    data = pagingOptions
  }
  const postConfig = {
    method: 'post',
    url: '/users/page',
    data: data
  }

  let responseData = await sendAPICall(postConfig)
  // TODO - Make a cleaner user-data formatting function when sub_exempt requirement is gone.
  if (responseData.success) {
    responseData.data.users = objToArray(responseData.data.users)
    responseData.data.users.forEach(user => {
      user.devices = objToArray(user.devices)
      user.shared_devices = objToArray(user.shared_devices)
      user.is_subscription_exempt = user.admin_roles.some(
        role => role.name === 'subscription_exempt' && role.is_member === true
      )
    })

    return responseData
  } else {
    return responseData
  }
}

// Get device Count
export async function getAdminUserCount() {
  // Valid paging options: start: int = 0, page_size: int = 10
  const postConfig = {
    method: 'post',
    url: '/users/count',
    data: {}
  }

  return sendAPICall(postConfig)
}

// Admin Update User Profile
export async function adminGetUser(userId) {
  const postConfig = {
    method: 'post',
    url: `/user/${userId}`,
    data: {}
  }

  return formatUserData(await sendAPICall(postConfig))
}

// Admin Update User Profile
export async function adminUpdateUser(userId, name, email, mobile, admin) {
  const postConfig = {
    method: 'post',
    url: `/user/${userId}`,
    data: {
      name,
      email,
      mobile,
      admin
    }
  }

  return formatUserData(await sendAPICall(postConfig))
}

// Admin DEACTIVATE a User
export async function adminDeactivateUser(userId) {
  const postConfig = {
    method: 'post',
    url: `/users/deactivate`,
    data: {
      user_id: userId
    }
  }

  return formatUserData(await sendAPICall(postConfig))
}

// Admin Update User Roles
export async function adminUpdateUserRoles(userId, roleIds) {
  const postConfig = {
    method: 'post',
    url: `/user/${userId}`,
    data: {
      role_ids: roleIds
    }
  }

  return formatUserData(await sendAPICall(postConfig))
}

// Admin get User Entitlements
export async function adminGetUserEntitlements(userId) {
  const postConfig = {
    method: 'post',
    url: `/users/${userId}/entitlements`,
    data: {
      user_id: userId
    }
  }

  return formatUserData(await sendAPICall(postConfig))
}

// Admin get User Entitlements
export async function adminUpdateEntitlement(
  entitlementId,
  status,
  couponCode
) {
  const postConfig = {
    method: 'post',
    url: `/entitlements/update`,
    data: {
      entitlement_id: entitlementId,
      status: status,
      coupon_code: couponCode
    }
  }

  return formatUserData(await sendAPICall(postConfig))
}

// Admin get User Entitlements
export async function adminCreateEntitlement(userId, status, couponCode) {
  const postConfig = {
    method: 'post',
    url: `/entitlements/create`,
    data: {
      user_id: userId,
      status: status,
      coupon_code: couponCode
    }
  }

  return formatUserData(await sendAPICall(postConfig))
}

// Reset a User's roles to only those granted by products
export async function adminResetUserRoles(userId) {
  const postConfig = {
    method: 'post',
    url: `/users/reset-roles`,
    data: {
      user_id: userId
    }
  }

  return sendAPICall(postConfig)
}

// Admin Create User
// NOTE: Settings is a JSON string holding user profile/settings data
// TODO - Test this... not sure I have the endpoint logic correct
export async function adminCreateUser(name, email, mobile, settings, admin) {
  const postConfig = {
    method: 'post',
    url: `/user/new`,
    data: {
      create_user: true,
      name,
      email,
      mobile,
      settings,
      admin
    }
  }

  return formatUserData(await sendAPICall(postConfig))
}

// Get Notifications for a specific User
export async function adminGetUserNotifications(email) {
  const postConfig = {
    method: 'post',
    url: '/notifications',
    data: {
      email: email
    }
  }

  return sendAPICall(postConfig)
}

// Get Notifications for a specific User
export async function adminClearAllUserNotifications(email) {
  const postConfig = {
    method: 'post',
    url: '/notifications/clear_all',
    data: {
      email: email
    }
  }

  return sendAPICall(postConfig)
}

// Get device List for Admins
export async function getAdminDeviceList(
  textFilter,
  searchOptions,
  whitelist = null
) {
  // Valid Search attributes: device_id, device_code, device_password, device_name, device_phone, device_imei, registration, plant_num, icon, user
  // alarm_status (bool), geolimit_status (bool), livetrack_status (bool), vibration_alarm_active (bool), overspeed_limit {min/max}, odometer_reading {min/max},
  // voltage_level {min/max}, hours_tracked {min/max}
  let data = {}
  if (searchOptions) {
    data = {...searchOptions}
  }
  if (textFilter) {
    data.anywhere = textFilter
  }
  if (whitelist) {
    data.whitelist = whitelist
  }
  const postConfig = {
    method: 'post',
    url: '/admin/devices',
    data: data
  }

  let responseData = await sendAPICall(postConfig)
  if (responseData.success) {
    responseData.data = objToArray(responseData.data)
    return responseData
  } else {
    return responseData
  }
}

// Get device List for Admins - paginated
export async function getAdminDeviceListPaginated(pagingOptions) {
  // Valid paging options: start: int = 0, page_size: int = 10
  let data = {}
  if (pagingOptions) {
    data = pagingOptions
  }
  const postConfig = {
    method: 'post',
    url: '/admin/devices/page',
    data: data
  }

  let responseData = await sendAPICall(postConfig)
  if (responseData.success) {
    responseData.data.devices = objToArray(responseData.data.devices)
    return responseData
  } else {
    return responseData
  }
}

// Get device Count
export async function getAdminDeviceCount(archived = false) {
  // Valid paging options: start: int = 0, page_size: int = 10
  const postConfig = {
    method: 'post',
    url: '/devices/count',
    data: {
      archived: archived
    }
  }

  return sendAPICall(postConfig)
}

// Create a new Device
export async function adminCreateDevice(deviceFields) {
  if (!deviceFields.hasOwnProperty('create_device')) {
    deviceFields.create_device = true
  }
  const postConfig = {
    method: 'post',
    url: '/devices/add',
    data: deviceFields
  }

  return sendAPICall(postConfig)
}

// Update a Device's Details
export async function adminUpdateDevice(deviceIMEI, updateFields) {
  // Make sure we don't try and send any objects accidentally.
  for (let field in updateFields) {
    if (typeof field === 'object') {
      field = JSON.stringify(field)
    }
    // Strip out the device_phone field. It should only be updated explicitly.
    if (field === 'device_phone') {
      delete updateFields[field]
    }
  }

  const postConfig = {
    method: 'post',
    url: `/devices/${deviceIMEI}/edit`,
    data: updateFields
  }
  return sendAPICall(postConfig)
}

// Update a Device's Details
export async function adminUpdateDeviceType(deviceIMEI, newDeviceType) {
  const postConfig = {
    method: 'post',
    url: `/devices/${deviceIMEI}/edit`,
    data: {
      device_type: newDeviceType
    }
  }
  return sendAPICall(postConfig)
}

// Assign a Device to a User by Email address
export async function adminAssignDevice(deviceIMEI, emailAddress) {
  const postConfig = {
    method: 'post',
    url: `/devices/${deviceIMEI}/assign`,
    data: {
      assign: true,
      new_owner_email: emailAddress
    }
  }

  return sendAPICall(postConfig)
}

// Remove Ownership of a Device
export async function adminUnAssignDevice(deviceIMEI) {
  const postConfig = {
    method: 'post',
    url: `/devices/${deviceIMEI}/assign`,
    data: {
      unassign: true
    }
  }

  return sendAPICall(postConfig)
}

// Update a device's phone number
export async function adminUpdateDevicePhone(deviceIMEI, newPhone) {
  const postConfig = {
    method: 'post',
    url: `/devices/${deviceIMEI}/edit`,
    data: {
      yes_really_set_device_phone: true,
      device_phone: newPhone
    }
  }

  return sendAPICall(postConfig)
}

// Get a list of notifications from the server for a given user (By Email)
export async function adminGetNotifications(emailAddress) {
  const postConfig = {
    method: 'post',
    url: '/notifications',
    data: {
      email: emailAddress
    }
  }

  return sendAPICall(postConfig)
}

// Get Command History
export async function getDeviceCommands(deviceIMEI, offset = 0, limit = 50) {
  const postConfig = {
    method: 'post',
    url: `/devices/${deviceIMEI}/command_history`,
    data: {
      offset: offset,
      limit: limit
    }
  }

  return sendAPICall(postConfig)
}

// Get Command History
export async function getDeviceCommandQueue(deviceIMEI) {
  const postConfig = {
    method: 'post',
    url: `/devices/${deviceIMEI}/get_command_queue`,
    data: {}
  }

  return sendAPICall(postConfig)
}

// Send Command to device
export async function sendDeviceCommands(
  deviceIMEI,
  command,
  sendingMethod,
  commandText
) {
  const postConfig = {
    method: 'post',
    url: `/devices/${deviceIMEI}/send_command`,
    data: {
      command: command,
      sending_method: sendingMethod,
      command_text: commandText
    }
  }

  return sendAPICall(postConfig)
}

// Gets the health status for a device
export async function getHealthStatus(deviceIMEI) {
  const postConfig = {
    method: 'post',
    url: `/devices/${deviceIMEI}/health`,
    data: {}
  }

  return sendAPICall(postConfig)
}

// Archive (faux-delete) a device.
export async function adminArchiveDevice(deviceIMEI) {
  const postConfig = {
    method: 'post',
    url: `/devices/${deviceIMEI}/archive`,
    data: {}
  }

  return sendAPICall(postConfig)
}

// Get a list of All products
export async function adminGetProducts() {
  const postConfig = {
    method: 'post',
    url: '/products',
    data: {}
  }
  let data = await sendAPICall(postConfig)
  for (let product of data.data) {
    product.roles = objToArray(product.roles)
  }
  return data
}

// Update a product's details
export async function adminUpdateProduct (id, description, published, productType, billableUnit, roleIds, data) {
  const postConfig = {
    method: 'post',
    url: '/products/update',
    data: {
      id: id,
      description,
      published,
      product_type: productType,
      billable_unit: billableUnit,
      role_ids: roleIds,
      data
    }
  }

  return sendAPICall(postConfig)
}

// Admin get ShopProducts
export async function adminGetShopProducts() {
  const postConfig = {
    method: 'post',
    url: `/shop_products`,
    data: {}
  }

  return sendAPICall(postConfig)
}

// Admin get ShopProducts
export async function adminGetShopProduct(productId) {
  const postConfig = {
    method: 'post',
    url: `/shop_product/` + productId,
    data: {}
  }

  return sendAPICall(postConfig)
}

// Admin Update Shop product
export async function adminUpdateShopProduct(productId, actionData) {
  const postConfig = {
    method: 'post',
    url: `/shop_products/update`,
    data: {
      id: productId,
      product_actions: actionData
    }
  }

  return sendAPICall(postConfig)
}

// Admin get ShopOrders
export async function adminGetShopOrders() {
  const postConfig = {
    method: 'post',
    url: `/shop_orders`,
    data: {}
  }

  return formatUserData(await sendAPICall(postConfig))
}

// Admin get ShopOrders
export async function adminGetShopOrder(orderId) {
  const postConfig = {
    method: 'post',
    url: `/shop_order/` + orderId,
    data: {}
  }

  return formatUserData(await sendAPICall(postConfig))
}

// Admin get Coupon Codes
export async function adminGetCoupons() {
  const postConfig = {
    method: 'post',
    url: `/coupons`,
    data: {}
  }

  return sendAPICall(postConfig)
}

export async function getSetupIntent() {
  const postConfig = {
    method: 'post',
    url: '/user/getPaymentIntent',
    data: {}
  }

  return sendAPICall(postConfig)
}

// Get performance metrics
export async function getAdminPerfStats(environment, metric, pagingOptions) {
  // Valid paging options: start_key: record, page_size: int = 10
  let data = {}
  if (pagingOptions) {
    data = pagingOptions
  }

  data.environment = environment
  data.metric_name = metric

  const postConfig = {
    method: 'post',
    url: '/admin/perf_metrics/page',
    data: data
  }

  return sendAPICall(postConfig)
}

// Get performance metrics
export async function isValidUser(email) {
  const postConfig = {
    method: 'post',
    url: '/user/exists',
    data: {
      email: email
    }
  }

  return sendAPICall(postConfig)
}

// Get performance metrics with date restrictions
export async function getAdminPerfStatsByDate(
  environment,
  metric,
  startDate,
  endDate,
  pagingOptions
) {
  // Valid paging options: start_key: record, page_size: int = 10
  let data = {}
  if (pagingOptions) {
    data = pagingOptions
  }

  data.environment = environment
  data.metric_name = metric
  data.start_date = startDate
  data.end_date = endDate

  const postConfig = {
    method: 'post',
    url: '/admin/perf_metrics/page',
    data: data
  }

  return sendAPICall(postConfig)
}

export async function updateDeviceTrips(imei, deviceTrips) {
  const postConfig = {
    method: 'post',
    url: `/devices/${imei}/trips/update`,
    data: {
      device_trips: deviceTrips
    }
  }

  return sendAPICall(postConfig)
}

// Notification Subscriptions

export async function getNotificationSubscriptions() {
  const postConfig = {
    method: 'post',
    url: `/notifications/subscriptions`,
    data: {}
  }

  return sendAPICall(postConfig)
}

export async function addNotificationSubscription(
  deviceImei,
  eventType,
  notificationType
) {
  const postConfig = {
    method: 'post',
    url: `/notifications/subscriptions/add`,
    data: {
      device_imei: deviceImei,
      event_type: eventType,
      notification_type: notificationType
    }
  }

  return sendAPICall(postConfig)
}

export async function removeNotificationSubscription(id) {
  const postConfig = {
    method: 'post',
    url: `/notifications/subscriptions/remove`,
    data: {
      id: id
    }
  }

  return sendAPICall(postConfig)
}

export async function updateNotificationSubscription(
  id,
  eventType,
  notificationType
) {
  const postConfig = {
    method: 'post',
    url: `/notifications/subscriptions/update`,
    data: {
      id: id,
      event_type: eventType,
      notification_type: notificationType
    }
  }

  return sendAPICall(postConfig)
}

export async function setDefaultNotificationSubscription(notificationType) {
  const postConfig = {
    method: 'post',
    url: `/notifications/subscriptions/update_default`,
    data: {
      notification_type: notificationType
    }
  }

  return sendAPICall(postConfig)
}

// Resets the user's notification rules to defaults
export async function resetAllNotificationSubscriptions() {
  const postConfig = {
    method: 'post',
    url: `/notifications/subscriptions/reset_all`,
    data: {}
  }

  return sendAPICall(postConfig)
}

// Get Notification/Event Config
export async function getConfig (configPath) {
  const postConfig = {
    method: 'post',
    url: `/config/` + configPath,
    data: {}
  }

  return sendAPICall(postConfig)
}

//
// // Get Notification/Event Config
// export async function getConfigEvents () {
//   const postConfig = {
//     method: 'post',
//     url: `/config/events`,
//     data: {
//     }
//   }
//
//   return sendAPICall(postConfig)
// }

// Reset ALL User Notification Subscriptions
export async function adminResetAllUserNotificationRules() {
  const postConfig = {
    method: 'post',
    url: `/admin/reset_all_user_notification_subscriptions`,
    data: {}
  }

  return sendAPICall(postConfig)
}

// Deduplication Locations within a given time period.
export async function adminDedupeTrips(deviceImei, startTime, endTime) {
  const postConfig = {
    method: 'post',
    url: `/admin/dedupe_trips`,
    data: {
      device_imei: deviceImei,
      start_time: startTime,
      end_time: endTime
    },
    timeout: 180000
  }

  return sendAPICall(postConfig)
}

// Defragment/Rebuild Locations within a given time period.
export async function adminDefragTrips(deviceImei, startTime, endTime) {
  const postConfig = {
    method: 'post',
    url: `/admin/defrag_trips`,
    data: {
      device_imei: deviceImei,
      start_time: startTime,
      end_time: endTime
    },
    timeout: 180000
  }

  return sendAPICall(postConfig)
}

// Check Trips in the given period for inconsistencies
export async function adminCheckTrips(deviceImei, startTime, endTime) {
  const postConfig = {
    method: 'post',
    url: `/admin/check_trips`,
    data: {
      device_imei: deviceImei,
      start_time: startTime,
      end_time: endTime
    },
    timeout: 180000
  }

  return sendAPICall(postConfig)
}

export async function adminGetNotificationSubscriptions(userEmail) {
  const postConfig = {
    method: 'post',
    url: `/notifications/subscriptions`,
    data: {
      email: userEmail
    }
  }

  return sendAPICall(postConfig)
}

// Resets the user's notification rules to defaults
export async function adminResetAllNotificationSubscriptions(userEmail) {
  const postConfig = {
    method: 'post',
    url: `/notifications/subscriptions/reset_all`,
    data: {
      email: userEmail
    }
  }

  return sendAPICall(postConfig)
}

export async function adminAddNotificationSubscription(
  userEmail,
  deviceImei,
  eventType,
  notificationType
) {
  const postConfig = {
    method: 'post',
    url: `/notifications/subscriptions/add`,
    data: {
      email: userEmail,
      device_imei: deviceImei,
      event_type: eventType,
      notification_type: notificationType
    }
  }

  return sendAPICall(postConfig)
}

export async function getEvent(eventId) {
  const postConfig = {
    method: 'post',
    url: `/events/${eventId}`,
    data: {}
  }

  return sendAPICall(postConfig)
}

// Get Trips for an Event
export async function getEventTrip(eventId) {
  const postConfig = {
    method: 'post',
    url: '/location/history/event/' + eventId,
    data: {}
  }

  return sendAPICall(postConfig)
}

// Get Trips for an Event
export async function getTripsEvents(tripId) {
  const postConfig = {
    method: 'post',
    url: '/location/trip_events/' + tripId,
    data: {}
  }

  return sendAPICall(postConfig)
}

// Update the device details on the server side
export async function updateDeviceServiceSettings(
  deviceIMEI,
  odometer,
  hoursTracked,
  serviceSettings
) {
  const postConfig = {
    method: 'post',
    url: '/devices/' + deviceIMEI + '/edit',
    data: {
      odometer_reading: odometer,
      hours_tracked: hoursTracked,
      settings: {
        service_settings: serviceSettings
      }
    }
  }

  return sendAPICall(postConfig)
}


export async function updateDeviceViewSchedule(
  deviceIMEI,
  viewSchedule
) {
  const postConfig = {
    method: 'post',
    url: '/devices/' + deviceIMEI + '/edit',
    data: {
      'view_schedule': viewSchedule
    }
  }
  return sendAPICall(postConfig)

}

// Get stats Data (temporary implementation)
export async function getMostRecentStats(deviceIMEIs, statTypes) {
  const postConfig = {
    method: 'post',
    url: '/stats',
    data: {
      device_imei: deviceIMEIs,
      most_recent: true,
      stat_types: statTypes
    }
  }

  return sendAPICall(postConfig)
}

// Get the view exclusions for a given device
export async function getDeviceExclusions(deviceIMEI) {
  const postConfig = {
    method: 'post',
    url: `/devices/${deviceIMEI}/view_exclusions`
  }

  return sendAPICall(postConfig)
}

// Get service estimate from device
export async function getDeviceServiceEst(
  deviceIMEI,
  serviceMode,
  serviceFreq
) {
  const postConfig = {
    method: 'post',
    url: `/devices/${deviceIMEI}/service`,
    data: {
      service_mode: serviceMode,
      service_freq: serviceFreq
    }
  }

  return sendAPICall(postConfig)
}

/***
 * Get a list of NotificationSubscriptions for the current user that relate to the specified device.
 * @param deviceImei
 * @returns {Promise<{data: any, success: boolean}|{success: boolean, message: string, error: *}|{success: boolean, message: string, error: T}|undefined>}
 */
export async function getNotificationSubscriptionsForDevice(deviceImei) {
  const postConfig = {
    method: 'post',
    url: `/notifications/subscriptions`,
    data: {
      device_imei: deviceImei
    }
  }

  return sendAPICall(postConfig)
}

export async function getDeviceProperty(deviceImei, propName) {
  const postConfig = {
    method: 'post',
    url: `/devices/${deviceImei}`,
    data: {
      whitelist: [propName]
    }
  }

  return sendAPICall(postConfig)
}

/***
 * Send a Property/Parameter update request for a specific device. This is basically calling the server-side
 * update_parameters function using the provided data (via the API of course).
 * @param deviceImei  Device IMEI
 * @param propData  Object containing property names and values
 * @returns {Promise<{data: any, success: boolean}|{success: boolean, message: string, error: *}|{success: boolean, message: string, error: T}|undefined>}
 */
export async function setDeviceProperty(deviceImei, propData) {
  const postConfig = {
    method: 'post',
    url: `/devices/${deviceImei}/edit`,
    data: propData
  }

  return sendAPICall(postConfig)
}

// Get device List for Users - paginated
export async function getDeviceListPaginated(pagingOptions) {
  // Valid paging options: start: int = 0, page_size: int = 10
  let data = {}
  if (pagingOptions) {
    data = pagingOptions
  }
  const postConfig = {
    method: 'post',
    url: '/devices/page',
    data: data
  }

  let responseData = await sendAPICall(postConfig)
  if (responseData.success) {
    responseData.data.devices = objToArray(responseData.data.devices)
    return responseData
  } else {
    return responseData
  }
}

// Get device Count
export async function getDeviceCount() {
  // Valid paging options: start: int = 0, page_size: int = 10
  const postConfig = {
    method: 'get',
    url: '/devices/count',
    data: {}
  }

  return sendAPICall(postConfig)
}

// Get a paginated list of Notifications
export async function adminGetNotificationsPage(
  userEmail,
  pageSize,
  offset,
  sortBy,
  sortDesc,
  filters
) {
  const postConfig = {
    method: 'post',
    url: '/notifications',
    data: {
      email: userEmail,
      limit: pageSize,
      offset: offset,
      sort_by: sortBy,
      sort_desc: sortDesc,
      filters: filters
    }
  }

  return sendAPICall(postConfig)
}

// Get a count of all available notifications
export async function adminGetNotificationCount(userEmail, filters) {
  const postConfig = {
    method: 'post',
    url: '/notifications/count',
    data: {
      email: userEmail,
      filters: filters
    }
  }

  return sendAPICall(postConfig)
}

// User Client API and webhooks
export async function getUserAPIClients() {
  const postConfig = {
    method: 'post',
    url: `/auth/clients`
  }

  return sendAPICall(postConfig)
}

export async function addUserAPIClient(newClientID) {
  const postConfig = {
    method: 'post',
    url: `/auth/add`,
    data: {
      client_id: newClientID
    }
  }

  return sendAPICall(postConfig)
}

export async function revokeUserAPIClient(clientSecret) {
  const postConfig = {
    method: 'post',
    url: `/auth/revoke`,
    data: {
      client_secret: clientSecret
    }
  }

  return sendAPICall(postConfig)
}

export async function getUserWebhooks() {
  const postConfig = {
    method: 'post',
    url: `/webhooks/list`
  }

  return sendAPICall(postConfig)
}

export async function addWebhook(
  newWebhookURL,
  newWebhookType,
  newWebhookHeaders,
  blacklistDevices,
  blacklistEventTypes,
  status
) {
  if (newWebhookHeaders) {
    newWebhookHeaders = JSON.parse(newWebhookHeaders)
  }

  const postConfig = {
    method: 'post',
    url: `/webhooks/add`,
    data: {
      webhook_url: newWebhookURL,
      webhook_type: newWebhookType,
      headers: newWebhookHeaders,
      blacklist_event_types: blacklistEventTypes,
      blacklist_device_imeis: blacklistDevices,
      status: status
    }
  }

  return sendAPICall(postConfig)
}

export async function updateWebhook(
  webhookID,
  newWebhookURL,
  newWebhookType,
  newWebhookHeaders,
  blacklistDevices,
  blacklistEventTypes,
  status
) {
  if (newWebhookHeaders) {
    newWebhookHeaders = JSON.parse(newWebhookHeaders)
  }
  const postConfig = {
    method: 'post',
    url: `/webhooks/update`,
    data: {
      webhook_id: webhookID,
      webhook_url: newWebhookURL,
      webhook_type: newWebhookType,
      headers: newWebhookHeaders,
      blacklist_event_types: blacklistEventTypes,
      blacklist_device_imeis: blacklistDevices,
      status: status
    }
  }

  return sendAPICall(postConfig)
}

export async function sendTestWebhook(webhookID) {
  const postConfig = {
    method: 'post',
    url: `/webhooks/send_test`,
    data: {
      webhook_id: webhookID
    }
  }

  return sendAPICall(postConfig)
}

export async function deleteWebhook(webhookID) {
  const postConfig = {
    method: 'post',
    url: `/webhooks/revoke`,
    data: {
      webhook_id: webhookID
    }
  }

  return sendAPICall(postConfig)
}

/* -------------------------------------------------------------- */
/*                Admin Sharing (and Caring) Functions            */
/* -------------------------------------------------------------- */

// Get a list of all the device shares this user owns
export async function adminGetUserShareList(userEmail) {
  const postConfig = {
    method: 'post',
    url: '/share/list',
    data: {
      email: userEmail
    }
  }

  return sendAPICall(postConfig)
}

// Get a list of all the device shares this user owns
export async function adminGetDeviceShareList(deviceImei) {
  const postConfig = {
    method: 'post',
    url: '/share/list',
    data: {
      device_imei: deviceImei
    }
  }

  return sendAPICall(postConfig)
}

// Add a share
// NOTE: Endpoint accepts 'all' as a valid IMEI reference to share all a user's devices.
export async function adminAddShare(
  imei,
  email,
  permissionLevel,
  permissionGiver
) {
  const postConfig = {
    method: 'post',
    url: '/share/add',
    data: {
      device_imei: imei,
      email: email,
      permission_level: permissionLevel,
      permission_giver: permissionGiver
    }
  }

  return sendAPICall(postConfig)
}

export async function adminGetSIMs(
  pageSize,
  offset,
  sortBy,
  sortDesc,
  filters
) {
  const postConfig = {
    method: 'post',
    url: '/admin/sims',
    data: {
      limit: pageSize,
      offset: offset,
      sort_by: sortBy,
      sort_desc: sortDesc,
      filters: filters
    }
  }

  return sendAPICall(postConfig)
}

export async function adminGetSIMByICCID(iccid) {
  const postConfig = {
    method: 'post',
    url: '/admin/sims/find',
    data: {
      iccid: iccid
    }
  }

  return sendAPICall(postConfig)
}

export async function adminGetSIMByMSISDN(msisdn) {
  const postConfig = {
    method: 'post',
    url: '/admin/sims/find',
    data: {
      msisdn: msisdn
    }
  }

  return sendAPICall(postConfig)
}

export async function adminUpdateSIM(simId, status, deviceIMEI) {
  const postConfig = {
    method: 'post',
    url: '/admin/sims/update',
    data: {
      sim_id: simId,
      device_imei: deviceIMEI
    }
  }
  if (status !== null) {
    postConfig.data.status = status
  }
  if (deviceIMEI !== null) {
    postConfig.data.device_imei = deviceIMEI
  }

  return sendAPICall(postConfig)
}

export async function adminUnassignSIM(simId) {
  const postConfig = {
    method: 'post',
    url: '/admin/sims/update',
    data: {
      sim_id: simId,
      unassign: true
    }
  }

  return sendAPICall(postConfig)
}

export async function adminForceSIMSync(providerId) {
  const postConfig = {
    method: 'post',
    url: '/admin/sims/force_sync',
    data: {
      sim_provider_config_id: providerId
    }
  }

  return sendAPICall(postConfig)
}

export async function adminUsageStatistics() {
  const postConfig = {
    method: 'post',
    url: '/admin/statistics/usage',
    data: {}
  }

  return sendAPICall(postConfig)
}


export async function getReportList() {
  const postConfig = {
    method: 'post',
    url: '/stats/reports',
    data: {}
  }

  return sendAPICall(postConfig)
}

export async function getReport(reportId) {
  const postConfig = {
    method: 'post',
    url: `/stats/reports/${reportId}`,
    data: {}
  }

  return sendAPICall(postConfig)
}

export async function copyReport(reportId, newReportName) {
  const postConfig = {
    method: 'post',
    url: '/stats/reports/add',
    data: {
      parent_id: reportId,
      name: newReportName
    }
  }

  return sendAPICall(postConfig)
}

export async function editReport(reportId, newParameters = null, newName = null, newData = null,
                                 newIcon = null, newColor = null, isPublic = null, newOwner = null) {
  let data = {}

  if (newData) {
    data['data'] = newData
  }
  if (newParameters) {
    data['default_parameters'] = newParameters
  }
  if (newName) {
    data['name'] = newName
  }
  if (newIcon) {
    data['icon'] = newIcon
  }
  if (newColor) {
    data['color'] = newColor
  }
  if (isPublic !== null) {
    data['is_public'] = isPublic
  }

  if (newOwner !== null) {
    data['owner_id'] = newOwner
  }

  const postConfig = {
    method: 'post',
    url: `/stats/reports/${reportId}/edit`,
    data
  }

  return sendAPICall(postConfig)
}

export async function addReport(name, ownerId, icon) {
  let data = {
    name: name,
    owner_id: ownerId,
    icon: icon
  }

  const postConfig = {
    method: 'post',
    url: `/stats/reports/add`,
    data
  }

  return sendAPICall(postConfig)
}


export async function getStats(statistics, startDate, endDate, dategrouping, devices = null) {
  let data = {
    statistics,
    startDate,
    endDate,
    time_grouping: dategrouping,
    timezone: new Date().getTimezoneOffset()
  }
  if (devices) {
    data['related_ids'] = devices
  }
  const postConfig = {
    method: 'post',
    url: '/stats/statistics',
    data: data
  }

  return sendAPICall(postConfig)
}

export async function getStatsByParams(reportId, statistics, reportParams) {

  reportParams['report_id'] = reportId
  reportParams['statistics'] = statistics

  const postConfig = {
    method: 'post',
    url: '/stats/statistics',
    data: reportParams
  }

  return sendAPICall(postConfig)
}

export async function getTask(taskId) {
  const postConfig = {
    method: 'post',
    url: '/tasks/' + taskId
  }

  return sendAPICall(postConfig)
}


export async function adminGetTasks() {
  const postConfig = {
    method: 'post',
    url: '/admin/tasks',
    data: {}
  }

  return sendAPICall(postConfig)
}

export async function adminGetRQJobs() {
  const postConfig = {
    method: 'post',
    url: '/admin/tasks/rq_jobs',
    data: {}
  }

  return sendAPICall(postConfig)
}


export async function adminGetRQWorker() {
  const postConfig = {
    method: 'post',
    url: '/admin/tasks/rq_workers',
    data: {}
  }

  return sendAPICall(postConfig)
}


export async function adminGetStatTypes() {
  const postConfig = {
    method: 'post',
    url: '/admin/statistics/stat_types',
    data: {}
  }

  return sendAPICall(postConfig)
}

export async function adminGetStatCoverage(statistics, startDate, endDate, timezone) {
  const postConfig = {
    method: 'post',
    url: '/admin/statistics/coverage',
    data: {
      start_date: startDate,
      end_date: endDate,
      statistics: statistics,
      timezone: timezone
    }
  }

  return sendAPICall(postConfig)
}

export async function adminCreateStatsTask(statistics, startDate, endDate, timezone) {
  const postConfig = {
    method: 'post',
    url: '/admin/statistics/queue_stats_job',
    data: {
      start_date: startDate,
      end_date: endDate,
      statistics: statistics,
      timezone: timezone
    }
  }

  return sendAPICall(postConfig)
}

export async function adminDeleteStats(statistics, startDate, endDate, timezone, relatedType, relatedIds) {
  const postConfig = {
    method: 'post',
    url: '/admin/statistics/delete',
    data: {
      start_date: startDate,
      end_date: endDate,
      statistics: statistics,
      timezones: [timezone],
      related_type: relatedType,
      related_ids: relatedIds
    }
  }

  return sendAPICall(postConfig)
}

export async function adminRunStats(statistics, startDate, endDate, timezone, relatedType, relatedIds) {
  const postConfig = {
    method: 'post',
    url: '/admin/statistics/run',
    data: {
      start_date: startDate,
      end_date: endDate,
      statistics: statistics,
      timezone: timezone,
      related_type: relatedType,
      related_ids: relatedIds
    }
  }

  return sendAPICall(postConfig)
}

export async function getLookbook(deviceIMEI, startDate, endDate, validState, workUse, odometerCorrection) {
  const postConfig = {
    method: 'post',
    url: `/location/trips/${deviceIMEI}/logbook`,
    data: {
      start_date: startDate,
      end_date: endDate,
      valid_state: validState,
      work_use: workUse,
      final_odometer: odometerCorrection
    }
  }

  return sendAPICall(postConfig)
}

export async function adminGetTemplates() {
  const postConfig = {
    method: 'post',
    url: '/admin/templates/',
    data: {}
  }

  return sendAPICall(postConfig)
}

export async function getTemplates() {
  const postConfig = {
    method: 'post',
    url: '/forms/templates/',
    data: {}
  }

  return sendAPICall(postConfig)
}

export async function getTemplate(templateId, revisionNumber = null) {
  const postConfig = {
    method: 'post',
    url: `/forms/templates/${templateId}`,
    data: {
      revision_number: revisionNumber
    }
  }

  return sendAPICall(postConfig)
}

export async function createTemplate(templateData) {
  const postConfig = {
    method: 'post',
    url: `/forms/templates/create`,
    data: {...templateData}
  }

  return sendAPICall(postConfig)
}

export async function updateTemplate(templateData) {
  let templateId = templateData.template_id
  let data = {...templateData}
  delete data['template_id']
  delete data['revision_number']

  const postConfig = {
    method: 'post',
    url: `/forms/templates/${templateId}/update`,
    data: data
  }

  return sendAPICall(postConfig)
}

export async function deleteTemplate(templateId) {
  const postConfig = {
    method: 'post',
    url: `/forms/templates/${templateId}/delete`,
    data: {}
  }

  return sendAPICall(postConfig)
}

export async function getForms(limit = null, offset = null, filterState = null, startDate = null,
                               endDate = null, deviceIds = null, relatedTypes = null, templateId = null) {
  const postConfig = {
    method: 'post',
    url: '/forms/formdata/',
    data: {
      limit,
      offset,
      filter_state: filterState,
      start_date: startDate,
      end_date: endDate,
      device_ids: deviceIds,
      related_types: relatedTypes,
      template_id: templateId
    }
  }

  return sendAPICall(postConfig)
}

export async function getForm(formId) {
  const postConfig = {
    method: 'post',
    url: `/forms/formdata/${formId}`,
    data: {}
  }

  return sendAPICall(postConfig)
}

export async function updateForm(formId, formData = null, newStat = null) {
  let data = {}
  if (formData) {
    data['data'] = formData
  }
  if (newStat) {
    data['state'] = newStat
  }
  const postConfig = {
    method: 'post',
    url: `/forms/formdata/${formId}/update`,
    data
  }

  return sendAPICall(postConfig)
}

export async function deleteForm(formId) {
  const postConfig = {
    method: 'post',
    url: `/forms/formdata/${formId}/delete`,
    data: {}
  }

  return sendAPICall(postConfig)
}

export async function getAuditLog(relatedId, relatedType, limit = null, offset = null) {
  const postConfig = {
    method: 'post',
    url: `/logs/history/`,
    data: {
      related_id: relatedId,
      related_type: relatedType,
      limit,
      offset
    }
  }

  return sendAPICall(postConfig)
}


export async function createForm (templateId, state, relatedId, relatedType, formData) {
  console.log(relatedType)
  let data = {
    template_id: templateId,
    state: state,
    data: formData
  }
  if (relatedType) {
    data['related_type'] = relatedType
  }
  if (relatedId) {
    data['related_id'] = relatedId
  }
  const postConfig = {
    method: 'post',
    url: '/forms/formdata/create',
    data: data
  }

  return sendAPICall(postConfig)
}

export async function adminGenerateToken(userEmail) {
  const postConfig = {
    method: 'post',
    url: '/admin/gentoken',
    data: {
      email: userEmail
    }
  }

  return sendAPICall(postConfig)
}

export async function adminGetLinks(start, limit) {
  const postConfig = {
    method: 'post',
    url: `/admin/links/`,
    data: {
      start: start,
      limit: limit
    }
  }

  return sendAPICall(postConfig)
}

export async function adminGetLinkData(linkId) {
  const postConfig = {
    method: 'post',
    url: `/admin/links/${linkId}`
  }

  return sendAPICall(postConfig)
}

export async function adminCreateLinkData(linkData) {
  const postConfig = {
    method: 'post',
    url: `/admin/links/create`,
    data: linkData
  }

  return sendAPICall(postConfig)
}

export async function adminEditLinkData(linkId, linkData) {
  const postConfig = {
    method: 'post',
    url: `/admin/links/${linkId}/edit`,
    data: linkData
  }

  return sendAPICall(postConfig)
}

export async function adminDeleteLink(linkId) {
  const postConfig = {
    method: 'post',
    url: `/admin/links/${linkId}/delete`
  }

  return sendAPICall(postConfig)
}
