<template>
  <div>
    <div v-if="eventList" class="event-container">
      <EventAvatar v-for="(event, idx) of eventList" v-bind:key="idx"
                   :event="event"
                   :selected="selectedEvents.includes(event)"
                   @click="clickEvent(event, $event)"
                   :class="applyHighlightFunc(event)"
      ></EventAvatar>
    </div>
    <div class="empty-text" v-if="!eventList || eventList.length === 0">
      No Events
    </div>
  </div>
</template>

<script>

import EventAvatar from '@/components/events/EventAvatar.vue'

/****
 *
 *  Event List
 *
 *  Display a list of Events
 *
 */
export default {
  name: 'EventList',
  components: {EventAvatar},
  props: {
    page_size: Number,
    events: Array,
    select_multiple: Boolean, // Allow multiple trips to be selected
    select_on_load: Boolean,
    show_addresses: Boolean,
    highlight_func: Function
  },
  mounted() {
  },
  data: function () {
    return {
      pageSize: this.page_size ? this.page_size : 10,
      eventList: this.events ? this.events : null,
      selectedEvents: [],
    }
  },
  methods: {
    applyHighlightFunc(event) {
      if (this.highlight_func) {
        return this.highlight_func(event)
      } else {
        return ''
      }
    },
    selectAll() {
      this.selectedEvents = this.eventList
      this.emitSelection(this.selectedEvents)
    },
    selectNone() {
      this.selectedEvents = []
      this.emitSelection(this.selectedEvents)
    },
    selectByEventId(eventId) {
      console.log('Select Event By Id', eventId, this.eventList)
      this.selectedEvents = this.eventList.filter(t => t.id.toString() === eventId.toString())
      console.log('Selected Events: ', this.selectedEvents)
      this.emitSelection(this.selectedEvents)
    },
    clickEvent(event, e) {
      if (this.select_multiple) {
        if (this.selectedEvents.includes(event)) {
          this.selectedEvents = this.selectedEvents.filter(t => t !== event)
        } else {
          this.selectedEvents.push(event)
        }
        this.emitSelection(this.selectedEvents)
      } else {
        this.selectedEvents = [event]
        this.emitSelection(this.selectedEvents[0])
      }
    },
    emitSelection(selection) {
      this.$emit('input', selection)
      this.$emit('select', selection)
    },
  },
  watch: {
    'events' (newVal) {
      this.eventList = newVal
      if (this.select_on_load) {
        this.selectedEvents = newVal
        this.$emit('input', this.selectedEvents)
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/variables';
@import '@/animations';

.event-container {
  padding: 5px;
  align-items: stretch;
}

.empty-text {
  color: white;
  font-weight: 600;
}

.warning {
  box-shadow: 0 0 0 0.2rem rgba(255, 194, 0, 0.82);
}

.danger {
  box-shadow: 0 0 0 0.2rem rgb(255, 49, 49, 0.8)!important;
}

.danger:hover .danger:focus {
  box-shadow: 0 0 0 0.2rem rgb(255, 49, 49, 1)!important;
}
</style>
