<template>
  <div class="device-selector" v-if="this.deviceList">
    <b-dropdown v-if="dropdown_mode" split-variant="outline-success"
                :text="dropdownText" split variant="success"
    >
      <b-dropdown-form>
        <b-form-checkbox
          :checked="deviceSelectionIsAll"
          :indeterminate="deviceSelectIntermediate"
          aria-describedby="devices"
          aria-controls="devices"
          @change="toggleAllDeviceSelection"
        >
          All Devices
        </b-form-checkbox>
      </b-dropdown-form>

      <b-dropdown-form v-for="(item, idx) of deviceCheckboxGroupOptions" v-bind:key="'drop-form-' + idx">
        <b-form-checkbox
          :checked="selectedDevices.includes(item.value.toString())"
          aria-describedby="devices"
          aria-controls="devices"
          @change="changeDropSelect(item.value, $event)"
        >
          {{item.text}}
        </b-form-checkbox>
      </b-dropdown-form>
    </b-dropdown>

    <b-select class="text-input" @input="selectDevice" v-if="!dropdown_mode"
              :options="deviceSelectionModeOptions" v-model="currentDeviceSelectionMode"/>

    <div class="device-select-outer flex-column align-items-start" v-if="currentDeviceSelectionMode==='selection' && !dropdown_mode">
      <b-form-checkbox
        :checked="deviceSelectionIsAll"
        :indeterminate="deviceSelectIntermediate"
        aria-describedby="devices"
        aria-controls="devices"
        @change="toggleAllDeviceSelection"
      >
        All Devices
      </b-form-checkbox>
      <b-form-checkbox-group class="flex-column align-items-start"
        id="devices"
        v-model="selectedDevices"
        :options="deviceCheckboxGroupOptions"
        name="devices"
        aria-label="Select Devices"
        stacked
        @change="deviceCheckboxChange"
      ></b-form-checkbox-group>
    </div>
  </div>
</template>

<script>


import * as DataProvider from '@/components/helpers/DataProvider'

/****
 *
 *  Device Selector
 *
 *  Provides a configurable control to select devices for the user.
 *
 */
export default {
  name: 'DeviceSelector',
  components: { },
  props: {
    value: [Array, Number, String], // v-model binding
    editable_only: Boolean, // Only show editable devices
    selection_mode: String, // Single or multi
    return_prop: String, // which prop to return (e.g. device_imei, device_id)
    require_features: Array, // filter to devices with certain features (e.g. 'device_trips' for vehicles only)
    dropdown_mode: Boolean, // More compact mode for multiselect (only applicable for select_mode = multi mode)
    emit_on_load: Boolean, // Should we emit a value immediately upon load?
    force_array: Boolean, // Always return an array, even in single select mode.
    null_value_as_all: Boolean // Treat a null value as an 'all' selection
  },
  data: function () {
    return {
      deviceSelectMode: 'single',
      currentDeviceSelectionMode: null,
      selectedDevices: [],
      deviceList: null,
      returnPropKey: 'device_imei'
    }
  },
  async mounted() {
    if (this.selection_mode) {
      this.deviceSelectMode = this.selection_mode
    }
    if (this.return_prop) {
      this.returnPropKey = this.return_prop
    }
    await this.getDevices()

    this.updateValue(this.value)

    if (this.emit_on_load) {
      this.emitCurrentSelection()
    }
  },
  methods: {
    async getDevices() {
      let deviceCall = await DataProvider.getDeviceList(['device_name', 'features', this.returnPropKey])
      this.deviceList = deviceCall.data
    },
    toggleAllDeviceSelection (newState) {
      this.selectedDevices = newState ? this.getAllDeviceKeys() : []
      this.emitCurrentSelection()
    },
    toggleAllDeviceDefaultSelection (newState) {
      this.currentDeviceSelectionValue = newState ? this.getAllDeviceKeys() : []
    },
    deviceCheckboxChange (selectedDevices) {
      this.selectedDevices = selectedDevices
      this.emitCurrentSelection()
    },
    changeDropSelect(deviceKey, value) {
      this.currentDeviceSelectionMode = 'selection'
      if (value) {
        this.selectedDevices.push(deviceKey.toString())
      } else {
        this.selectedDevices = this.selectedDevices.filter(d => d !== deviceKey.toString())
      }
      this.emitCurrentSelection()
    },
    selectDevice(val) {
      this.emitCurrentSelection(val)
    },
    getAllDeviceKeys() {
      return Object.values(this.deviceList).map(d => d[this.returnPropKey].toString())
    },
    emitCurrentSelection () {
      let value
      if (this.deviceSelectMode === 'single' && this.force_array) {
        value = [this.currentDeviceSelectionMode]
      } else if (this.deviceSelectMode === 'single') {
        value = this.currentDeviceSelectionMode
      } else if (this.currentDeviceSelectionMode === 'all') {
        value = this.null_value_as_all ? null : this.getAllDeviceKeys()
      } else {
        value = this.selectedDevices
      }
      this.$emit('input', value)
    },
    updateValue(value) {
      // If we're in single mode, set the value to whatever was passed to our .value (v-model binding)
      if (value && this.deviceSelectMode === 'single') {
        if (Array.isArray(value)) {
          this.currentDeviceSelectionMode = value[0]
        } else {
          this.currentDeviceSelectionMode = value
        }
      } else if (this.deviceSelectMode === 'multi') {
        if (value === 'all' || (value === null && this.null_value_as_all)) {
          this.currentDeviceSelectionMode = 'all'
          this.selectedDevices = this.getAllDeviceKeys()
        } else {
          this.currentDeviceSelectionMode = 'selection'
          this.selectedDevices = value.map(x => x.toString())
        }
      }
    }
  },
  computed: {
    dropdownText () {
      if (this.selectedDevices.length === 0) {
        return '< Select Devices >'
      } else {
        return `Selected ${this.selectedDevices.length} Device(s)`
      }
    },
    deviceCheckboxGroupOptions () {
      if (this.deviceList) {
        return Object.keys(this.deviceList).map(deviceImei => { return {
          value: this.deviceList[deviceImei][this.returnPropKey],
          text: this.deviceList[deviceImei].device_name,
          disabled: false
        }
        })
      } else {
        return []
      }
    },
    deviceSelectionModeOptions () {
      if (this.deviceSelectMode === 'multi') {
        return [
          {
            value: null,
            text: '<Select Devices>'
          },
          {
            value: 'all',
            text: 'All Devices'
          },
          {
            value: 'selection',
            text: 'Specific Devices'
          }
        ]
      } else {
        let deviceOptions = [
          {
            value: null,
            text: '<Select a Device>'
          }
        ]
        Object.keys(this.deviceList).forEach(deviceIMEI => {
          deviceOptions.push({
            text: this.deviceList[deviceIMEI].device_name,
            value: this.deviceList[deviceIMEI][this.returnPropKey]
          })
        })
        return deviceOptions
      }
    },
    deviceSelectIntermediate () {
      // Determines if the 'All Devices' checkbox should be displayed as intermediate
      if (Array.isArray(this.selectedDevices)) {
        return this.selectedDevices.length > 0 &&
          this.selectedDevices.length < Object.keys(this.deviceList).length
      } else {
        return false
      }
    },
    deviceSelectionIsAll () {
      if (Array.isArray(this.selectedDevices)) {
        return this.selectedDevices.length === Object.keys(this.deviceList).length
      } else {
        return false
      }
    },
    deviceSelectionDefaultIsAll () {
      return this.selectedDevices.length === Object.keys(this.deviceList).length
    },
  },
  watch: {
    value (newVal) {
      this.updateValue(newVal)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/variables';
@import '@/animations';

.device-selector {
  position: relative;
  z-index: 1000;
}

.device-select-outer {
  overflow: auto;
  margin-top: 0.5em;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  border-style: solid;
  border-width: 0px 0px 1px;
  border-color: #000 #000 #ababab;
  border-radius: 5px;
  background-color: rgba(0,0,0, 0.25);
}

</style>
