import moment from 'moment'

export class DateTimeHelper {
  /**
   * Merge Date and Time string values into a single moment object (useful for Input control)
   * @param date
   * @param time
   * @returns {moment.Moment}
   */
  static mergeDateAndTime (date, time) {
    return moment(moment(date).format('DD-MM-YYYY') + ' ' + time)
  }

  /**
   * Turn a Datetime string or object into a UTC Datetime
   * @param datetime
   * @returns {moment.Moment}
   */
  static toUTC (datetime) {
    return moment(datetime).utc()
  }

  /***
   * Turn the datetime into a Unix Timestamp
   * @param datetime
   * @return {number}
   */
  static toTimestamp (datetime) {
    return moment(datetime).unix()
  }

  /**
   * Convert a Timestamp number to a formatted DateTime String
   * @param timestamp
   * @returns {string}
   */
  static timestampToLocalDateTime (timestamp) {
    return moment.unix(timestamp).local().format('DD-MM-YYYY HH:mm')
  }

  /**
   * Convert a Timestamp number to a formatted Date-only string
   * @param timestamp
   * @returns {string}
   */
  static timestampToLocalDate (timestamp) {
    if (!moment(timestamp).isValid()) {
      return ''
    } else {
      return moment.unix(timestamp).local().format('DD-MM-YYYY')
    }
  }

  /**
   * Check if a timestamp is 'today'
   * @param timestamp
   * @returns {boolean}
   */
  static isTimestampToday (timestamp) {
    if (!moment(timestamp).isValid()) {
      return false
    } else {
      return moment.unix(timestamp).local().date() === moment().local().date()
    }
  }

  /**
   * Convert a timestamp number to a formatted Time-only string
   * @param timestamp
   * @param showSeconds
   * @returns {string}
   */
  static timestampToLocalTime (timestamp, showSeconds= false) {
    let format = showSeconds ? 'HH:mm:ss' : 'HH:mm'
    return moment.unix(timestamp).local().format(format)
  }

  /**
   * Convert a timestamp number to a Date format that a HTML Input element can understand
   * @param timestamp
   * @returns {string}
   */
  static timestampToLocalHTMLDate (timestamp) {
    return moment.unix(timestamp).local().format('YYYY-MM-DD')
  }

  /**
   * Convert a Datetime or Moment object for a formatted Time-Only String
   * @param datetime
   * @returns {string}
   */
  static toTime (datetime) {
    return moment(datetime).format('HH:mm')
  }

  /**
   * Convert a Datetime or Moment object for a formatted Time-Only String
   * @param datetime
   * @returns {string}
   */
  static toLocalTime (datetime) {
    return moment(datetime).local().format('HH:mm')
  }


  /**
   * Convert a Datetime or Moment object to a Formatted Date-Only String
   * @param datetime
   * @returns {string}
   */
  static toDate (datetime) {
    return moment(datetime).format('DD-MM-YYYY')
  }

  /**
   * Convert a Datetime or Moment object to a Formatted Date-Only String
   * @param datetime
   * @returns {string}
   */
  static toLocalDate (datetime) {
    return moment(datetime).local().format('DD-MM-YYYY')
  }

  /**
   * Convert a Datetime or Moment object into a Formatted Date and Time String
   * @param datetime
   * @returns {string}
   */
  static toDateTime (datetime) {
    return moment(datetime).format('DD-MM-YYYY HH:mm')
  }

  /**
   * Convert a Datetime or Moment object into a Formatted Date and Time String
   * @param datetime
   * @returns {string}
   */
  static toLocalDateTime (datetime) {
    return moment(datetime).local().format('DD-MM-YYYY HH:mm')
  }

  /***
   * Convert a number of seconds into a time string (HH:mm:ss) string.
   * @param seconds
   * @param format
   * @return {string}
   */
  static secondsToTime (seconds, format= 'HH:mm:ss') {
    let duration = moment.duration(seconds, 'seconds')
    return moment.utc(duration.as('milliseconds')).format(format)
  }
}

