<template>
  <div class="register-user">
    <div class="" v-if="!loading">
      <b-form v-on:submit.prevent="registerUser()">
        <div class="col s4" :class="{ 'form-error': $v.fullname.$error }">
          <label for="full_name" class="green-label">Full Name</label>
          <input type="text" v-model="$v.fullname.$model" class="w-100"
                 maxlength="256" placeholder="Full Name" id="full_name">
          <div class="input-error" v-if="!$v.fullname.required">Field is Required</div>
          <div class="green-divider"></div>
        </div>
        <div class="col s4" :class="{ 'form-error': $v.email.$error }">
          <label for="email_address" class="green-label">Email Address</label>
          <input type="text" v-model="$v.email.$model" class="w-100"
                 maxlength="256" placeholder="Email Address" id="email_address">
          <div class="input-error" v-if="!$v.email.required">Field is Required</div>
          <div class="input-error" v-if="!$v.email.email">Must contain a valid email address</div>
          <div class="green-divider"></div>
        </div>

        <div class="col s4" :class="{ 'form-error': $v.mobile.$error }">
          <label for="mobile_number" class="green-label">Mobile Phone</label>
          <input type="text" v-model="$v.mobile.$model" class="w-100"
                 maxlength="256" placeholder="Mobile Number" id="mobile_number">
          <div class="input-error" v-if="!$v.mobile.required">Field is Required</div>
          <div class="input-error" v-if="!$v.mobile.minLength">Phone number must be at least 10 numbers.</div>
          <div class="green-divider"></div>
        </div>

        <div class="footer mt-2">
          <button type="submit" class="button w-100 mt-5" id="button-save">Register</button>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import * as DataProvider from '@/components/helpers/DataProvider'
import * as AlertHelper from '@/components/helpers/AlertHelper'
import { minLength, required, email } from 'vuelidate/lib/validators'
import * as ErrorHelper from '@/components/helpers/ErrorHelper'

export default {
  name: 'register-user',
  props: {
    imei: String,
    event: Object,
    editable: Boolean, // Flags if the DEVICE is editable
    modal: String
  },
  data: function () {
    return {
      loading: true,
      fullname: '',
      email: '',
      mobile: '',
      creationComplete: false
    }
  },
  validations: {
    formGroup: ['fullname', 'email', 'mobile'],
    fullname: {
      required
    },
    email: {
      required,
      email
    },
    mobile: {
      required,
      minLength: minLength(10)
    }
  },
  created () {
    this.loading = false
  },
  methods: {
    registerUser: async function () {
      this.$v.formGroup.$touch()
      if (this.$v.$anyError) {
        ErrorHelper.displayGeneralErrorToast('Some Fields Contain Invalid Data. Please fix them.', 'Invalid Fields')
        return
      }
      let result = await DataProvider.registerUser(this.fullname, this.email, this.mobile)

      if (result.success) {
        AlertHelper.successToast('Your registration request was successful!', 'Registration Complete')
        this.creationComplete = true
        this.$emit('registration_complete')
      } else {
        ErrorHelper.displayDataErrorToast(result)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../variables';

.register-user{
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.green-label {
  width: 100%;
}

.button {
  font-size: 20px;
}

</style>
