<template>
  <div class="logout">
    <div class="logout-container">
        <img class="logo" src="../assets/Protekt-logo-medium.png"/>

      <div class="d-flex justify-content-center mt-5">
        <h2>{{message}}</h2>
      </div>
    </div>
  </div>
</template>

<script>
import * as DataProvider from '../components/helpers/DataProvider'
import * as ErrorHelper from '../components/helpers/ErrorHelper'

export default {
  name: 'logout-view',
  data: function () {
    return {
      message: 'Loading...',
      timer: 0
    }
  },
  components: {
  },
  async created () {
    let response = await DataProvider.logOut()
    this.$auth.purgeCache()
    if (!response.success) {
      ErrorHelper.displayDataErrorToast(response)
      this.message = 'An Error Occurred Logging you out!'
    } else {
      this.message = 'You have been logged out. You will now be returned to the login page...'
    }
    this.timer = setTimeout(() => {
      this.$router.push({ path: '/login' })
    }, 2000)
  },
  beforeDestroy () {
    clearTimeout(this.timer)
  },
  methods: { }
}
</script>

<style scoped lang="scss">
  @import '@/variables';

  .logout {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: url('../assets/Satellite-View.png') black;
    background-size: cover;
    background-repeat: no-repeat;
    color: $text-color-invert;
  }

  .logout-container {
    max-width: 650px;
    padding: 15px;
    border-radius: 15px;
    background: $default-sidebar-background;
  }

  .logo {
    max-width: 100%;
  }

  .footer {
    //bottom: 2vw;
    //position: absolute;
    //left: 50%;
    //transform: translate(-50%, 0);
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
    margin-top: 5vh;
  }
</style>
