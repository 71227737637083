<template>
  <div id="missing">
    <div class="content">
      <div class="w-100 mt-5">
        <h1>Server Unreachable</h1>
        <img src="@/assets/Pro-tekt-logo.png" style="width: 80%">
        <p>We're having trouble connecting to the Protekt server.</p>
        <b-spinner></b-spinner>
      </div>
    </div>

  </div>
</template>

<script>
import * as DataProvider from '../components/helpers/DataProvider'

export default {
  name: 'main-view',
  components: {
  },
  data: function () {
    return {
      redirect: null,
      interval: null
    }
  },
  created () {
    if (this.$route.query.hasOwnProperty('redirect')) {
      this.redirect = this.$route.query.redirect
    }
    this.startTimer()
  },
  methods: {
    startTimer: function () {
      this.interval = setInterval(() => {
        this.checkConnection()
      }, 5000)
    },
    clearTimer: function () {
      clearInterval(this.interval)
    },
    checkConnection: async function () {
      let check = await DataProvider.heartbeat()
      if (check.success) {
        this.clearTimer()
        if (this.redirect) {
          await this.$router.replace(this.redirect)
        } else {
          await this.$router.replace('/')
        }
      }
    }
  },
  beforeDestroy () {
    if (this.interval) {
      this.clearTimer()
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/variables';

#missing {
  margin: 0;
  display: flex;
  flex-direction: row-reverse;
  width: 100vw;
  height: 100vh;
  font-family: 'Open Sans', sans-serif;

  background-color: $default-content-background;
}

.content {
  display: flex;
  flex-direction: row-reverse;
  flex: 2;
  text-align: center;
  color: $text-color-invert;
  background-color: $theme-color-background-2;
  overflow: hidden;
  width: 100%;
}
</style>
