<template>
  <div class="vehicle-dtc-control" v-if="device">
      <h1 class="yellow-label">Vehicle DTCs
        <i class="fa fa-exclamation-triangle dtc_codes" :id="'dtc-icon'+device.device_id"
        ></i>
      </h1>
      <div class="yellow-divider"></div>

      <div class="d-flex flex-column justify-content-between device-control-heading">
       <p>This Vehicle is reporting the following Diagnotic Trouble Codes:</p>
        <ul>
          <li v-for="(code, idx) of device.vehicle_dtcs.dtc_codes" v-bind:key="idx" class="field-container">
            {{code}}
          </li>
        </ul>
        <p>
          Timestamp: <span class="timestamp-text">{{formatDTCTimestamp(device.vehicle_dtcs.timestamp)}}</span>
        </p>
      </div>
    </div>
</template>

<script>

import moment from 'moment'
import * as DTCHelper from '@/components/helpers/DTCHelper'

export default {
  name: 'vehicle-dtc-control',
  components: { },
  props: {
    device: Object,
    device_imei: String,
    enableEdit: Boolean
  },
  data: function () {
    return {
      loading: true,
      deviceImei: '',
      geolimitControlEnabled: false,
      inputRadius: 0,
      geolimitData: {
        active: false,
        lat: null,
        lng: null,
        radius: null
      },
      currentPos: null,
      // Placeholder for device info
      deviceInternal: {
        geolimit_status: {}
      },
      dtcHelper: DTCHelper
    }
  },
  async mounted () {
    this.loading = false
  },
  methods: {
    formatDTCTimestamp: function (timestamp) {
      return moment.unix(timestamp).local().format('YYYY-MM-DD HH:mm')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '@/variables';

  .vehicle-dtc-control{
    margin-top: 0;
    margin-bottom: 1rem;
    text-align: left;
  }

  p {
    font-size: 0.75rem;
  }

  ul {
    font-size: 0.9rem;
    color: #a88601;
  }

  .timestamp-text {
    font-style: italic;
  }

  .yellow-label {
    margin-top: 2em;
    margin-bottom: 0px;
    padding-top: 5px;
    padding-right: 20px;
    padding-left: 20px;
    color: #ffce35;
    font-size: 15px;
    line-height: 17px;
    font-weight: bold;
    text-align: left;
  }

  // Horizontal Rule
  .yellow-divider {
    width: 99%;
    height: 2px;
    margin: 10px;
    padding-left: 20px;
    float: none;
    clear: both;
    background-color: #eee;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ffe76e), to(#917919));
    background-image: linear-gradient(180deg, #ffe76e, #917919);
    color: $text-color-default;
    text-align: center;
    position: relative;
    left: auto;
    top: auto;
    right: 0%;
    bottom: auto;
    overflow: hidden;
  }

</style>
