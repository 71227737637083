var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "device-event-control" },
    [
      _c(
        "div",
        { staticClass: "event-controls" },
        [
          _c("DateSelector", {
            staticClass: "w-100",
            attrs: {
              default_filter_mode: _vm.defaultDateMode,
              default_date: _vm.start_date
            },
            model: {
              value: _vm.selectedDateRange,
              callback: function($$v) {
                _vm.selectedDateRange = $$v
              },
              expression: "selectedDateRange"
            }
          }),
          _c(
            "div",
            { staticClass: "flex-column mt-1" },
            [
              _c("label", [_vm._v("Filter Type")]),
              _c("b-select", {
                staticClass: "w-100",
                attrs: { size: "sm", options: _vm.eventTypeOptions },
                on: { input: _vm.onFilterChange },
                model: {
                  value: _vm.eventTypeFilter,
                  callback: function($$v) {
                    _vm.eventTypeFilter = $$v
                  },
                  expression: "eventTypeFilter"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("loading-box", { attrs: { loading: _vm.loading } }),
      _c("div", { staticClass: "green-divider" }),
      _c(
        "div",
        { staticClass: "event-list-container" },
        [
          _c("EventList", {
            attrs: { events: _vm.eventList },
            on: { select: _vm.clickEvent }
          })
        ],
        1
      ),
      _c(
        "b-modal",
        {
          staticClass: "modal-content",
          attrs: {
            id: "view-event-modal",
            centered: "",
            size: "lg",
            "hide-footer": "",
            title: "View Event"
          }
        },
        [
          _c(
            "b-tabs",
            [
              _c(
                "b-tab",
                { attrs: { title: "Event" } },
                [
                  _c("device-edit-event", {
                    attrs: {
                      device_imei: _vm.device_imei,
                      editable: _vm.editable,
                      event: _vm.selectedEvent,
                      modal: "view-event-modal"
                    },
                    on: {
                      save: _vm.refresh,
                      select: function($event) {
                        return _vm.selectEvent($event)
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "b-tab",
                { attrs: { title: "AuditLog" } },
                [
                  _vm.selectedEvent
                    ? _c("AuditLog", {
                        attrs: {
                          related_type: "event",
                          related_id: _vm.selectedEvent.event_id
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }