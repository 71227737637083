<template>
  <div class="trip-tag" :class="{'tag-editable': editable, 'tag-removeable': editable && show_remove_icon }"
       :style = "'color: ' + color + 'DD; background: ' + color + '22; border-color: ' + color + '; outline-color: ' + color + ';'"
       @click="$emit('click', $event)"
  >
  <span class="tag-text">
    {{text}}
  </span>
  </div>
</template>
<script>

/*****
 *
 * Color Tag
 *
 *  A simple component to display coloured text tags. Currently used for Trip Tags.
 *
 */
export default {
  name: 'ColorTag',
  props: {
    text: String,
    color: String,
    editable: Boolean,
    show_remove_icon: Boolean
  }
}
</script>

<style scoped lang="scss">
@import '@/variables';

.trip-tag {
  position: relative;
  padding: 1px 3px;
  background: grey;
  border: 1px solid darkgray;
  margin: 1px 2px;
  border-radius: 8px;
  font-size: 12px;
  user-select: none;
  opacity: 0.9;
  outline-style: solid;
  outline-width: 0;
}

.tag-editable:hover {
  opacity: 1;
  outline-width: 1px;
}

.tag-removeable:hover:after {
  position: absolute;
  top: -4px;
  right: -3px;
  content: "X";
  background: white;
  color: black;
  font-weight: 800;
  font-size: 8px;
  border: 1px solid darkgray;
  border-radius: 15px;
  width: 14px;
  text-align: center;
}

</style>
