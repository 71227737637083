<template>
  <div class="edit-event">
    <div class="" v-if="!loading">
    </div>
      <div class="flex-row">
        <i :class="eventIcon" class="event-icon"></i>
        <div class="flex-column flex-grow-1">
          <label for="event_type" class="device-label">Event Type</label>
          <b-select v-if="enableEdit" type="text" v-model="eventInternal.event_type" class=""
                    :options="creatableEventTypes" @change="changeEventType($event)"
                    maxlength="256" placeholder="Select Event Type" id="event_type" :disabled="!enableEdit"></b-select>
          <!--        <div class="input-error" v-if="!$v.device.name.required">Field is Required</div>-->
          <div v-else>
            <h4>{{eventConfig.name}}</h4>
          </div>
        </div>
      </div>
    <!--        Datetime-->
    <div v-if="eventInternal.event_type !== null" class="event-prop w-100 z-1">
      <label class="device-label" >Event Start</label>
      <b-input-group v-if="enableEdit">
        <input  type="date" v-model="eventInternal.startDate" class="" size="sm"
                            maxlength="256" placeholder="Start Date" id="event_start_date" :disabled="!enableEdit">
        <input type="time" v-model="eventInternal.startTime" class="" size="sm"
                           maxlength="256" placeholder="Start Time" id="event_start_time" :disabled="!enableEdit"
        >
      </b-input-group>
      <div v-else>
        {{eventInternal.startDate}} - {{eventInternal.startTime}}
      </div>
    </div>
    <div  v-if="eventConfig !== null" class="event-props-container z-0">
      <div v-for="(eventProp, idx) of eventConfig.event_props" v-bind:key="idx" class="event-prop" :class="{ 'w-100': eventProp.fullWidth }">
        <label class="device-label" :for="'prop'+idx">{{eventProp.title}}</label>
        <!--        Text Area-->
        <textarea v-if="eventProp.control==='textarea'" type="textarea" v-model="eventData[eventProp.propName]" class="text-field w-input"
                  maxlength="256" :id="'prop'+idx" :placeholder="eventProp.placeholder" :disabled="!enableEdit" :rows="eventProp.rows"
                  :class="{ 'w-100': eventProp.fullWidth }"
        ></textarea>
        <!--        Select-->
        <b-select v-else-if="eventProp.control==='select'" type="text" v-model="eventData[eventProp.propName]" class="text-field w-input"
                  :options="eventProp.options"
                  maxlength="256" :placeholder="eventProp.placeholder" id="event_type" :disabled="!enableEdit" :class="{ 'w-100': eventProp.fullWidth }">
        </b-select>
        <!--        Checkbox-->
        <b-checkbox v-else-if="eventProp.control==='checkbox'" type="text" v-model="eventData[eventProp.propName]" class="text-field w-input z-2"
                  maxlength="256" :placeholder="eventProp.placeholder" id="event_type" :disabled="!enableEdit">{{eventProp.placeholder}}</b-checkbox>
        <!--       Datepicker -->
        <b-form-datepicker  v-else-if="eventProp.control==='datepicker'" type="text" v-model="eventData[eventProp.propName]"
                            class="text-input date-picker" size="sm"
                            maxlength="256" :placeholder="eventProp.placeholder" id="event_type"
                            :disabled="!enableEdit"></b-form-datepicker>
        <input v-else-if="eventProp.control==='number'" type="number" step="any" v-model="eventData[eventProp.propName]" class="text-field w-input"
               :id="'prop'+idx" maxlength="256" :placeholder="eventProp.placeholder" :disabled="!enableEdit" :class="{ 'w-100': eventProp.fullWidth }">
        <!-- Default: Text Field-->
        <input v-else type="text" v-model="eventData[eventProp.propName]" class="text-field w-input"
               :id="'prop'+idx" maxlength="256" :placeholder="eventProp.placeholder" :disabled="!enableEdit" :class="{ 'w-100': eventProp.fullWidth }">
      </div>
    </div>
    <div class="event-update-timestamp">
      {{updateText}}
    </div>
      <div class="w-100 flex-row justify-content-end mt-2">
        <button class="button" @click="saveChanges()" id="button-save" v-if="enableEdit">Save Changes</button>
        <button class="button" @click="clickShow()" id="button-show" v-if="hasLocation">Show Location</button>
        <button class="button" @click="clickLink()" id="button-show" v-if="hasLink">Show Related</button>
        <button class="button" @click="clickClose()" id="button-close">Close</button>
      </div>
  </div>
</template>

<script>
import * as DataProvider from '../helpers/DataProvider'
import * as AlertHelper from '../helpers/AlertHelper'
import * as ErrorHelper from '../helpers/ErrorHelper'
import { DateTimeHelper as dt } from '../helpers/DateTimeHelper'
import moment from 'moment'

export default {
  name: 'device-edit-event',
  props: {
    device_imei: String,
    event: Object,
    editable: Boolean, // Flags if the DEVICE is editable
    modal: String,
    event_type: Number
  },
  data: function () {
    return {
      showButtons: true,
      isNew: false,
      loading: true,
      enableEdit: true, // determines if the controls are disabled
      eventInternal: {
        event_id: null,
        event_type: null,
        event_subtype: 0,
        startTime: new Date(),
        startDate: new Date(),
        event_data: {}
      },
      eventData: {},
      eventConfig: {},
      creatableEventTypes: [],
      updateText: ''
    }
  },
  async created () {
    // this.creatableEventTypes = this.eventTypes.filter(x => x.creatable === true)
    this.creatableEventTypes = await this.$eventService.getCreateableEventTypeOpts()
    // Display an error if no IMEI is provided
    if (!this.device_imei) {
      ErrorHelper.displayGeneralErrorToast('No IMEI Was specified for this new Event.',
        'IMEI Not Provided.')
    }
    if (this.event) {
      await this.loadEvent(this.event)
    } else {
      this.isNew = true
      this.eventInternal = {
        startTime: moment().format('HH:mm'),
        startDate: moment().toISOString(),
        event_subtype: 0,
        event_data: {},
        event_type: this.event_type || null
      }
    }
    this.loading = false
  },
  methods: {
    loadEvent: async function (event) {
      this.eventInternal = { ...event }
      // Check for the 'isNew' prop on the event. This lets us pass a partial event as a template
      if (event.hasOwnProperty('isNew')) {
        this.isNew = event.isNew
      }
      await this.changeEventType(this.eventInternal.event_type)
      if (this.eventInternal.hasOwnProperty('event_data')) {
        this.eventData = this.eventInternal.event_data
      }
      if (this.eventInternal.source === 'system') {
        this.enableEdit = false
      } else {
        this.enableEdit = this.editable
      }
      this.eventInternal.startTime = dt.timestampToLocalTime(event.start_time)
      this.eventInternal.startDate = dt.timestampToLocalHTMLDate(event.start_time)
      if (event.event_data.hasOwnProperty('last_updated')) {
        let timestamp = dt.timestampToLocalDateTime(event.event_data.last_updated)
        this.updateText = `Last Updated: ${timestamp} by ${event.event_data.updated_by}`
      }
    },
    changeEventType: async function (val) {
      if (val) {
        this.eventConfig = await this.$eventService.getEventTypeConfig(val)
        this.eventData = {}
        this.eventConfig.event_props.forEach(x => { this.eventData[x.propName] = x.value })
      }
    },
    saveChanges: async function () {
      // Run through all of the dynamic properties and add them to event data
      let result
      let startDT = moment(this.eventInternal.startDate + ' ' + this.eventInternal.startTime).utc()
      let startTimestamp = startDT.unix()
      // TODO - Properly implement end timestamps
      let endTimestamp = startTimestamp
      if (this.isNew) {
        result = await DataProvider.addDeviceEvent(
          this.device_imei,
          startTimestamp,
          endTimestamp,
          this.eventInternal.event_type,
          this.eventInternal.event_subtype,
          this.eventData
        )
      } else {
        result = await DataProvider.editDeviceEvent(
          this.device_imei,
          this.eventInternal.event_id,
          startTimestamp,
          endTimestamp,
          this.eventInternal.event_type,
          this.eventInternal.event_subtype,
          this.eventData)
      }
      // this.eventTypes[this.eventTypeIndex].eventProps.forEach( x => dynamic_data[x.propName] = x.value)
      if (result.success) {
        AlertHelper.successToast('Your changes to this event have been saved.', 'Event Updated')
        this.$emit('save') // Emit a save event, since we've just saved
        this.clickClose()
      } else {
        this.$bvToast.toast('Uh Oh! Something went wrong saving changes to the server. Please try again later.',
          {
            title: 'Server Communication Error',
            variant: 'danger',
            toaster: 'b-toaster-top-center'
          })
      }
    },
    clickShow: function () {
      this.$emit('select', this.event.event_id)
    },
    clickClose: async function () {
      // Check if we've been passed a modal to close.
      if (this.modal) {
        this.$bvModal.hide(this.modal)
      } else {
        this.$router.go(-1)
      }
    },
    clickLink() {
      this.$router.push(this.eventInternal.link)
    }
  },
  computed: {
    eventIcon () {
      if (this.eventConfig) {
        return this.eventConfig.icon
      } else {
        return this.$config.icons.events.default
      }
    },
    hasLocation () {
      return 'location' in this.eventInternal.event_data || 'latitude' in this.eventInternal.event_data
    },
    hasLink () {
      return 'link' in this.eventInternal
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../variables';

.edit-event{
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.device-label{
  font-family: 'Open Sans', sans-serif;
  color: $theme-color-primary-3;
  align-self: flex-start;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
}
// NOTE: The date/time picker's parent div MUST be a higher z-order than the following divs so the pop-out works.
.z-0 {
  z-index: 0;
}

.z-1 {
  z-index: 1;
}

.z-2 {
  z-index: 2;
}

.device-code{
  color: $text-color-invert;
  margin-left: 5%;
}

.event-props-container {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}

.event-prop {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 5px;
  flex-grow: 1;
}

.event-update-timestamp {
  font-style: italic;
  font-size: 0.75rem;
}

.footer{
  position: relative;
  width: 100%;
  bottom: 2%;
  display: flex;
  justify-content: flex-end;
  margin: 0 1% 0 1%;
  z-index: 0;
}

.icon_select_color {
  border: solid $theme-color-primary-3;
}

.event-icon {
  font-size: 3em;
  padding: 20px;
  background: black;
  border-radius: 10px;
  margin-right: 10px;
}

</style>
