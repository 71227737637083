<template>
  <div class="livetrack-control">
    <loading-box v-bind:loading="loading"></loading-box>
    <label>Live Tracking</label>
    <div class="green-divider"></div>
    <div class="d-flex justify-content-between device-control-heading">

      <b-form-checkbox size="md" name="check-button" id="live-track-switch" switch
                       v-model="livetrackState" v-on:change="livetrackChange($event)"
                       :disabled="!enableEdit"
      >
        Enable Live Tracking
      </b-form-checkbox>
      <b-tooltip target="live-track-switch" noninteractive variant="success" placement="left"
                 :delay="{ show: $config.general.tooltipShowDelay, hide: $config.general.tooltipHideDelay }">
        Toggle Livetracking Mode
      </b-tooltip>
    </div>

    <!--    Livetrack Popup Div-->
    <div class="livetrack_window" v-if="livetrackState">
      <div class="row justify-content-center">
        <b-spinner variant="success" type="grow" label="Spinning"></b-spinner>
        <div class="livetrack_heading">Livetrack Mode Enabled</div>
        <b-spinner variant="success" type="grow" label="Spinning"></b-spinner>
      </div>
    </div>
  </div>
</template>

<script>

import * as DataProvider from '@/components/helpers/DataProvider'
import LoadingBox from '@/components/helpers/LoadingBox'
import * as ErrorHelper from '@/components/helpers/ErrorHelper'

export default {
  name: 'livetrack-control',
  components: { LoadingBox },
  props: {
    device: Object,
    device_imei: String,
    enableEdit: Boolean
  },
  data: function () {
    return {
      loading: true,
      deviceImei: '',
      livetrackState: false
    }
  },
  async mounted () {
    if (this.device) {
      this.deviceImei = this.device.device_imei
    } else if (this.device_imei) {
      this.deviceImei = this.device_imei
    }
    await this.getLivetrackState()
  },
  beforeDestroy () {
    if (this.livetrackState) {
      this.setLivetrackState(false)
    }
  },
  methods: {
    // Event Handler for Livetrack button being toggled.
    livetrackChange: async function (newState) {
      if (newState !== this.livetrackState) {
        await this.setLivetrackState(newState)
      }
    },
    // Set Livetrack state to the given value
    setLivetrackState: async function (state) {
      this.loading = true
      let response = await DataProvider.setDeviceLivetrack(this.deviceImei, state)
      if (!response.success) {
        ErrorHelper.displayDataErrorToast(response)
        this.livetrackState = !state // Reverse UI change if server won't accept change.
        return this.livetrackState
      }
      this.livetrackState = state
      this.$emit('change', state)
      this.loading = false
      return this.livetrackState
    },
    getLivetrackState: async function () {
      this.loading = true
      let response = await DataProvider.getDeviceLivetrack(this.deviceImei)
      if (!response.success) {
        ErrorHelper.displayDataErrorToast(response)
        return null
      }

      this.livetrackState = response.data.livetrack_status
      this.loading = false
      return response
    }
  },
  watch: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '@/variables';

  .livetrack-control{
    margin-top: 0;
    margin-bottom: 1rem;
    text-align: left;
  }

  .livetrack_window {
    display: block;
    -ms-overflow-style: none;
    background-color: #2c3237;
    width: 30em;
    height: auto;
    z-index: 1;
    position: fixed;
    top: 5%;
    left: calc(50vw - 155px);
    transform: translate(-50%, 10px);
    overflow-y: hidden;
    overflow-x: hidden;
    border-radius: 15px;
    border: 4px solid $theme-color-primary-3;
  }

  .livetrack_heading {
    color: $text-color-invert;
    font-weight: 900;
    font-size: 1.5em;
  }
</style>
