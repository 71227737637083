<template>
  <div class="register">
    <div class="register-outer">
      <div>
        <img class="logo" src="../assets/Protekt-logo-medium.png"/>
        <h2 class="register-title">Registration</h2>
      </div>

      <div class="register-container justify-content-center" v-if="!registered">
        <register-user class="login-item" @registration_complete="registrationComplete()"></register-user>
      </div>
      <div class="register-container justify-content-center" v-if="registered">
        <b-alert variant="success" show class="login-item"> Your account has been created successfully.
          Please check your email and follow the activation link
          to start using Pro-Tekt GPS Tracking services.
          click <router-link to="login">HERE</router-link> to go to the login page.
        </b-alert>
      </div>
    </div>
    <div class="footer">
      <router-link to="terms">Terms and Conditions</router-link>
    </div>
  </div>
</template>

<script>
import RegisterUser from '@/components/auth/RegisterUser'

export default {
  name: 'register-view',
  components: {
    RegisterUser
  },
  data: function () {
    return {
      returnUrl: null,
      registered: false
    }
  },
  created () {
  },
  methods: {
    registrationComplete: function () {
      this.registered = true
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@/variables';

  .register {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: url('../assets/Satellite-View.png') black;
    background-size: cover;
    background-repeat: no-repeat;
    color: $text-color-invert;
    overflow: auto;
  }

  .register-outer {
    max-width: 650px;
    padding: 15px;
    border-radius: 15px;
    background: $default-sidebar-background;
  }

  .logo {
    max-width: 530px;
    max-height: 40vh;
  }

  .register-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  .login-item {
    width: 100%;
    max-width: 35rem;
  }

  .footer {
    position: absolute;
    left: 50%;
    bottom: 20px;
    transform: translate(-50%, 0);
    margin-top: 5vh;
  }

  .register-title {
    color: $theme-color-primary-3;
  }

  @media screen and (max-height: 945px) {
    .footer {
      position: relative;
      width: 100%;
      left: 50%;
      bottom: 20px;
      transform: translate(-50%, 0);
      margin-top: 5vh;
    }
  }
</style>
