var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "trips-control" },
    [
      !_vm.showPopout
        ? _c("loading-box", { attrs: { loading: _vm.loading } })
        : _vm._e(),
      _c("label", [_vm._v("Trips")]),
      _c("div", { staticClass: "green-divider" }),
      _c(
        "button",
        {
          staticClass: "button w-100",
          attrs: { id: "current-location" },
          on: {
            click: function($event) {
              return _vm.clickShowCurrent()
            }
          }
        },
        [_vm._v("Go to Current Location")]
      ),
      _c(
        "button",
        {
          staticClass: "button w-100",
          attrs: { id: "route -history" },
          on: {
            click: function($event) {
              return _vm.clickShowTripHistory()
            }
          }
        },
        [_vm._v("Trip History")]
      ),
      this.deviceImei
        ? _c(
            "PopoutMenu",
            {
              attrs: { title: "Trip History" },
              model: {
                value: _vm.showPopout,
                callback: function($$v) {
                  _vm.showPopout = $$v
                },
                expression: "showPopout"
              }
            },
            [
              _c("loading-box", { attrs: { loading: _vm.loading } }),
              _c("label", [_vm._v("Date Selection")]),
              _c("DateSelector", {
                staticClass: "px-2 w-100",
                attrs: {
                  default_filter_mode: _vm.defaultDateMode,
                  default_date: _vm.start_date
                },
                model: {
                  value: _vm.selectedDateRange,
                  callback: function($$v) {
                    _vm.selectedDateRange = $$v
                  },
                  expression: "selectedDateRange"
                }
              }),
              _c("div", { staticClass: "green-divider" }),
              _c("label", [_vm._v("Route Highlighting")]),
              _c(
                "div",
                { staticClass: "px-2 flex-column" },
                [
                  _c("b-form-radio-group", {
                    attrs: {
                      id: "btn-radios-1",
                      options: _vm.routeLineModeOptions,
                      "aria-describedby": "Route Line Mode",
                      name: "radios-btn-default",
                      buttons: "",
                      "button-variant": "success"
                    },
                    on: { change: _vm.changeRouteLineMode },
                    model: {
                      value: _vm.routeLineMode,
                      callback: function($$v) {
                        _vm.routeLineMode = $$v
                      },
                      expression: "routeLineMode"
                    }
                  }),
                  _vm.routeLineMode === "idle"
                    ? _c("div", { staticClass: "w-100" }, [
                        _c("label", [
                          _vm._v("Idle Threshold: "),
                          _c("span", { staticClass: "text-white" }, [
                            _vm._v(_vm._s(_vm.idleThresholdText) + "s")
                          ])
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.idleThreshold,
                              expression: "idleThreshold"
                            }
                          ],
                          staticClass: "idle-range-input",
                          attrs: {
                            type: "range",
                            min: _vm.minIdleTime,
                            max: _vm.maxIdleTime
                          },
                          domProps: { value: _vm.idleThreshold },
                          on: {
                            change: _vm.changeRouteLineMode,
                            __r: function($event) {
                              _vm.idleThreshold = $event.target.value
                            }
                          }
                        })
                      ])
                    : _vm._e()
                ],
                1
              ),
              _c("div", { staticClass: "green-divider" }),
              _c("div", { staticClass: "flex-row slim-controls" }, [
                _c("button", { on: { click: _vm.selectAll } }, [_vm._v("All")]),
                _c("button", { on: { click: _vm.selectNone } }, [
                  _vm._v("Clear")
                ]),
                _c("label", [_vm._v("Auto Select")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selectOnLoad,
                      expression: "selectOnLoad"
                    }
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.selectOnLoad)
                      ? _vm._i(_vm.selectOnLoad, null) > -1
                      : _vm.selectOnLoad
                  },
                  on: {
                    change: [
                      function($event) {
                        var $$a = _vm.selectOnLoad,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.selectOnLoad = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.selectOnLoad = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.selectOnLoad = $$c
                        }
                      },
                      _vm.changeSelectOnLoad
                    ]
                  }
                })
              ]),
              _c(
                "div",
                {
                  ref: "tripsListContainer",
                  staticClass: "trips-list-container"
                },
                [
                  _c("ScrollTrigger", {
                    attrs: { threshold: _vm.loadThreshold },
                    on: { scroll_threshold: _vm.onScrollTrips }
                  }),
                  _c("TripList", {
                    ref: "tripsListComponent",
                    attrs: {
                      trips: _vm.trips,
                      show_addresses: _vm.showAddresses,
                      show_tags: "",
                      select_multiple: "",
                      select_on_load: _vm.selectOnLoad
                    },
                    on: { input: _vm.onTripSelect }
                  })
                ],
                1
              ),
              _vm.maxPage > 1
                ? _c(
                    "div",
                    {
                      staticClass:
                        "flex-row slim-controls justify-content-between"
                    },
                    [
                      _c("span", { staticClass: "trip-counter-text" }, [
                        _vm._v(
                          "Loaded Trips: " +
                            _vm._s(_vm.trips.length) +
                            "/" +
                            _vm._s(_vm.maxTrips)
                        )
                      ])
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass:
            "map-button flex-column refresh-timer clickable animate-in fade-in delay-05",
          on: { click: _vm.clickToggleTimer }
        },
        [
          _c("div", { staticClass: "position-relative" }, [
            _c("div", {
              staticClass: "progress-circle",
              class: { inactive: !_vm.updateTimerOn },
              style: _vm.refreshProgressVar
            })
          ]),
          _c("div", { staticClass: "refresh-timer-text" }, [
            _c("i", { staticClass: "flex-row fa fa-refresh mr-1" }),
            _vm._v(" " + _vm._s(_vm.updateTimerSeconds) + " ")
          ])
        ]
      ),
      _vm.selectedTrips.length > 0
        ? _c(
            "div",
            {
              staticClass:
                "map-button flex-row route-legend animate-in fade-in delay-05"
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "flex-column justify-content-between legend-text-container"
                },
                _vm._l(_vm.legendLabels, function(label, idx) {
                  return _c("div", { key: idx, staticClass: "mr-1" }, [
                    _vm._v(" " + _vm._s(label) + " ")
                  ])
                }),
                0
              ),
              _c("div", {
                staticClass: "legend-gradient",
                style: _vm.legendVars
              })
            ]
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "map-button direction-icon-button",
          class: { disabled: !_vm.useDirectionIcons },
          attrs: { id: "toggle-direction-control" },
          on: { click: _vm.toggleDirectionIcons }
        },
        [
          _c("i", {
            directives: [{ name: "b-tooltip", rawName: "v-b-tooltip" }],
            staticClass: "fa fa-location-arrow",
            attrs: { title: "Toggle Direction Icons" }
          })
        ]
      ),
      _c(
        "div",
        { staticClass: "selected-trip-summary-bar flex-row" },
        [
          _c(
            "PopoverElement",
            {
              attrs: { direction: "top", value: _vm.selectedTrips.length > 0 }
            },
            [
              _c(
                "div",
                { staticClass: "selected-trip-summary-inner flex-column" },
                [
                  _c("h5", { staticClass: "text-center" }, [
                    _vm._v("Selected Trips Statistics")
                  ]),
                  _c("div", { staticClass: "green-divider" }),
                  _vm.selectedTrips.length
                    ? _c("div", { staticClass: "flex-row" }, [
                        _c("div", { staticClass: "flex-row" }, [
                          _c("label", [_vm._v("# Trips: ")]),
                          _vm._v(
                            " " + _vm._s(_vm.routeSummary.totalTrips) + " "
                          )
                        ]),
                        _c("div", { staticClass: "flex-row" }, [
                          _c("label", [_vm._v("Distance: ")]),
                          _vm._v(" " + _vm._s(_vm.routeSummary.distance) + " ")
                        ]),
                        _c("div", { staticClass: "flex-row" }, [
                          _c("label", [_vm._v("Duration: ")]),
                          _vm._v(" " + _vm._s(_vm.routeSummary.duration) + " ")
                        ]),
                        _c("div", { staticClass: "flex-row" }, [
                          _c("label", [_vm._v("Fuel: ")]),
                          _vm._v(" " + _vm._s(_vm.routeSummary.fuelUsed) + " ")
                        ]),
                        _c("div", { staticClass: "flex-row" }, [
                          _c("label", [_vm._v("Avg Speed: ")]),
                          _vm._v(
                            " " +
                              _vm._s(_vm.routeSummary.averageSpeed) +
                              " Kph "
                          )
                        ]),
                        _c("div", { staticClass: "flex-row" }, [
                          _c("label", [_vm._v("# Events: ")]),
                          _vm._v(
                            " " + _vm._s(_vm.routeSummary.totalEvents) + " "
                          )
                        ])
                      ])
                    : _vm._e()
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "selected-trip-summary-close clickable",
                  on: { click: _vm.selectNone }
                },
                [_c("i", { staticClass: "fa fa-times" })]
              )
            ]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "selected-trip-edit-bar flex-row" },
        [
          _c(
            "PopoverElement",
            {
              staticClass: "selected-trip-edit-inner flex-row",
              attrs: {
                direction: "bottom",
                value: _vm.selectedTrips.length > 0
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "device-icon flex-column justify-content-center"
                },
                [_c("img", { attrs: { src: _vm.deviceIcon } })]
              ),
              _c(
                "div",
                { staticClass: "flex-column ml-2 align-items-stretch" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "vertical-tab flex-grow-1",
                      class: { active: _vm.popoutControlMode === "play" },
                      on: {
                        click: function($event) {
                          _vm.popoutControlMode = "play"
                        }
                      }
                    },
                    [_c("i", { class: _vm.$config.icons.playback.play })]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "vertical-tab flex-grow-1",
                      class: { active: _vm.popoutControlMode === "edit" },
                      on: {
                        click: function($event) {
                          _vm.popoutControlMode = "edit"
                        }
                      }
                    },
                    [_c("i", { class: _vm.$config.icons.general.edit })]
                  )
                ]
              ),
              _vm.popoutControlMode === "play"
                ? _c(
                    "div",
                    {
                      staticClass:
                        "flex-row flex-grow-1 flex-nowrap w-100 vertical-tab-body"
                    },
                    [
                      _c("TripsPlaybackControl", {
                        attrs: {
                          device: _vm.device,
                          routeData: _vm.routeData,
                          routeMode: _vm.routeLineMode
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.popoutControlMode === "edit" && _vm.selectedTrips.length === 1
                ? _c(
                    "div",
                    {
                      staticClass:
                        "flex-row align-items-stretch flex-grow-1 flex-nowrap w-100 vertical-tab-body"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex-column justify-content-between trip-notes flex-grow-0 p-1"
                        },
                        [
                          _c("label", { staticClass: "label-underline" }, [
                            _vm._v("Work Use")
                          ]),
                          _c("b-checkbox", {
                            staticClass: "trip-purpose-checkbox",
                            attrs: { size: "lg", switch: "" },
                            on: { change: _vm.changeTripPurpose },
                            model: {
                              value: _vm.selectedTrips[0].work_use,
                              callback: function($$v) {
                                _vm.$set(_vm.selectedTrips[0], "work_use", $$v)
                              },
                              expression: "selectedTrips[0].work_use"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex-column justify-content-between trip-notes flex-grow-1 p-1"
                        },
                        [
                          _c("label", { staticClass: "label-underline" }, [
                            _vm._v("Trip Notes")
                          ]),
                          _c(
                            "b-input-group",
                            { staticClass: "flex-row w-100" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.tempNotes,
                                    expression: "tempNotes"
                                  }
                                ],
                                staticClass: "flex-grow-1",
                                attrs: {
                                  placeholder: "Enter Trip Notes",
                                  type: "text"
                                },
                                domProps: { value: _vm.tempNotes },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.tempNotes = $event.target.value
                                  }
                                }
                              }),
                              _vm.tripNotesDirty
                                ? _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "edit-notes-button",
                                          attrs: { variant: "success" },
                                          on: { click: _vm.saveTrip }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-check"
                                          })
                                        ]
                                      ),
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "edit-notes-button",
                                          attrs: { variant: "danger" },
                                          on: { click: _vm.clearTempNotes }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-times"
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.isFormsUser
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "flex-column justify-content-between trip-forms flex-grow-1 p-1 align-content-xl-stretch"
                            },
                            [
                              _c(
                                "label",
                                { staticClass: "text-nowrap label-underline" },
                                [_vm._v("Attached Forms")]
                              ),
                              _c("FormListInline", {
                                staticClass: "flex-grow-1",
                                attrs: { editable: _vm.enableEdit },
                                on: { change: _vm.onFormChange },
                                model: {
                                  value: _vm.selectedTrips[0],
                                  callback: function($$v) {
                                    _vm.$set(_vm.selectedTrips, 0, $$v)
                                  },
                                  expression: "selectedTrips[0]"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex-column justify-content-between trips-tags flex-grow-1 p-1"
                        },
                        [
                          _c("label", { staticClass: "label-underline" }, [
                            _vm._v("Trip Tags")
                          ]),
                          _c(
                            "b-input-group",
                            { staticClass: "flex-row w-100" },
                            [
                              _c("TripTags", {
                                attrs: { editable: _vm.enableEdit },
                                model: {
                                  value: _vm.tempTags,
                                  callback: function($$v) {
                                    _vm.tempTags = $$v
                                  },
                                  expression: "tempTags"
                                }
                              }),
                              _vm.tripTagsDirty
                                ? _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "edit-notes-button",
                                          attrs: { variant: "success" },
                                          on: { click: _vm.saveTrip }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-check"
                                          })
                                        ]
                                      ),
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "edit-notes-button",
                                          attrs: { variant: "danger" },
                                          on: { click: _vm.clearTempTags }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-times"
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e(),
              _vm.popoutControlMode === "edit" && _vm.selectedTrips.length > 1
                ? _c(
                    "div",
                    {
                      staticClass:
                        "selected-trip-edit-inner flex-column vertical-tab-body align-content-xl-stretch flex-grow-1"
                    },
                    [
                      _c("h6", { staticClass: "text-center mr-3" }, [
                        _vm._v("Select a single Trip to Edit")
                      ])
                    ]
                  )
                : _vm._e()
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }