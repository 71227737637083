import { render, staticRenderFns } from "./NotificationAgent.vue?vue&type=template&id=e9dc1060&"
import script from "./NotificationAgent.vue?vue&type=script&lang=js&"
export * from "./NotificationAgent.vue?vue&type=script&lang=js&"
import style0 from "./NotificationAgent.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/pro-tekt/protekt_webapp/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e9dc1060')) {
      api.createRecord('e9dc1060', component.options)
    } else {
      api.reload('e9dc1060', component.options)
    }
    module.hot.accept("./NotificationAgent.vue?vue&type=template&id=e9dc1060&", function () {
      api.rerender('e9dc1060', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/helpers/NotificationAgent.vue"
export default component.exports