<template>
  <div class="workflow-progress-widget">
    <div class="progress-bar">
      <div class="progress-color"
          :style="`width: calc(${barWidth}% - 25px)`">
      </div>
    </div>
    <div class="progress-bar-triangle" :style="`left: calc(${arrowPosition}% - 25px)`"></div>
    <div class="step-overlay">
      <div class="step-outer"
           :class="{'active': idx === progressVal, 'complete': idx < progressVal || (idx === progressVal && idx === barMax - 1)}"
          v-for="(step, idx) of stepItems" v-bind:key="idx">{{step}}
        <div class="complete-icon">
          <i class="fa fa-check"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

/****
 *
 *  Workflow Progress Widget
 *
 * A simple GUI element to show a list of steps and what the current progress is.
 * Uses CSS transition animations.
 */
export default {
  name: 'WorkflowProgressWidget',
  props: {
    steps: [Array, Object],
    progress: Number
  },
  data: function () {
    return {
      progressStored: 0
    }
  },
  mounted() {
    // This ensures that we have enough of a gap to show the CSS animations when the progress loads.
    setTimeout(() => { this.progressStored = this.progress }, 250)
  },
  methods: {
  },
  computed: {
    stepItems() {
      if (Array.isArray(this.steps)) {
        return this.steps
      } else {
        return Object.values(this.steps)
      }
    },
    progressVal() {
      return parseInt(this.progressStored)
    },
    barMax () {
      return this.stepItems.length
    },
    barWidth () {
      let val = this.progressVal
      // This looks a bit odd, but we want the arrow to be pointed to the step that we're currently up to (as opposed to
      // being displayed past the current step), so we're showing it at -1 index...
      // ... unless it's the final step, which is usually 'complete', then jump to 100%.
      if (val === this.barMax - 1) {
        return 100
      } else if (val > 0) {
        return (val / this.barMax) * 100
      } else {
        return 10
      }
    },
    arrowPosition () {
      let val = parseFloat(this.progressVal)
      if (val === this.barMax - 1) {
        return 100
      } else if (val > 0) {
        return Math.min((val / this.barMax) * 100, 100)
      } else {
        return 10
      }
    }
  },
  watch: {
    progress(newVal) {
      this.progressStored = newVal
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/variables';
@import '@/animations';

.workflow-progress-widget {
  display: flex;
  position: relative;
  flex-flow: column nowrap;
  justify-content: stretch;
  width: 100%;
  min-height: 2em;
  padding: 10px 0;
}

.progress-bar {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: #212121;
  height: 1em;
}

.progress-color {
  background: linear-gradient(
          20deg,
          hsl(118deg 100% 14%) 0%,
          hsl(117deg 100% 19%) 34%,
          hsl(116deg 100% 24%) 57%,
          hsl(118deg 98% 31%) 71%,
          hsl(119deg 97% 37%) 80%,
          hsl(119deg 62% 59%) 100%
  );
  height: 1em;
  transition: width 1.0s ease-in-out;
}

.progress-bar-triangle {
  position: absolute;
  background: transparent;
  border-top: 23px solid transparent;
  border-left: 23px solid hsl(119deg 62% 59%);
  border-bottom: 23px solid transparent;
  top: calc(50% - 23px);
  border-radius: 5px;
  transition: left 1.0s ease-in-out;
}

.step-overlay {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 100%;
}

.step-outer {
  position: relative;
  color: #a4a4a4;
  font-weight: 600;
  background: #0f6918;
  padding: 6px;
  border-radius: 25px;
  outline: 2px solid #047226;
  border: 2px solid #047226;
}

.complete {
  color: #d3d1d1;
  background: #169624;
  .complete-icon {
    opacity: 100;
  }
}

.complete-icon{
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -60%);
  color: white;
  font-size: 2em;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.active {
  color: white;
  background: #169624;
  border: 2px solid #00e64d;
}

</style>
