<template>
  <div id="missing">
    <div class="content">
      <div class="w-100 mt-5">
        <h1>Uh Oh... We couldn't find the page you're looking for.</h1>
        <img src="@/assets/Pro-tekt-logo.png" style="width: 100%">
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'main-view',
  components: {
  },
  created () {
  }
}
</script>

<style scoped lang="scss">
@import '@/variables';

  #missing {
    margin: 0;
    display: flex;
    flex-direction: row-reverse;
    width: 100vw;
    height: 100vh;
    font-family: 'Open Sans', sans-serif;

    background-color: $default-content-background;
  }

  .content {
    display: flex;
    flex-direction: row-reverse;
    flex: 2;
    text-align: center;
    color: $text-color-invert;
    background-color: $theme-color-background-2;
    overflow: hidden;
    width: 100%;
  }
</style>
