<template>
  <div class="device-integrations-view">
    <div class="flex-row">
      <div class="hint-box">
        <h5>Integration Configuration</h5>
        <p>
          This screen allows you to configure integrations with other external systems, including Fuel Card Providers.
          This screen specifically lists the integrations for this device.
          If a system is not listed here, please contact support.
        </p>
      </div>
    </div>
    <div class="flex-row">
      <div v-if="integrations && integrations.length > 0">
        <div v-for="integration in integrations" v-bind:key="integration.integration_id">
          <div class="device-label">{{ integration_types[integration.integration_type].title }}</div>
          <div class="flex-row">
            <div v-for="(data_type, key) in integration_types[integration.integration_type].required_fields" v-bind:key="key">
              <div class="device-label">{{ key }}</div>
              <b-input v-model="integration['configuration'][key]" type="password"></b-input>
            </div>
          </div>
          <div class="flex-row">
            <button class="button" @click="saveIntegration(integration)" id="button-edit">Save</button>
            <button class="button" @click="deleteIntegration(integration)" id="button-delete">Delete</button>
          </div>
          <hr>

        </div>
      </div>
      <div v-else>
        <p>
          There are no integrations configured for this device.
        </p>
      </div>
    </div>

    <div class="flex-row">
      <h3>Add Integration</h3>
      <b-form-group>
        <b-select v-model="newIntegrationType" :options="type_options" class="text-input"></b-select>
        <b-button :disabled="!newIntegrationType" @click="addIntegration()" variant="primary">Add Integration</b-button>
      </b-form-group>
    </div>
    <div class="flex-row">
      <div class="col-12 footer mt-2">
        <button class="button" @click="clickClose()" id="button-close">Close</button>
      </div>
    </div>
  </div>
</template>

<script>
import * as DataProvider from '../helpers/DataProvider'
import {required, numeric, minValue} from 'vuelidate/lib/validators'
import * as ErrorHelper from '../helpers/ErrorHelper'
import * as AlertHelper from '../helpers/AlertHelper'
import moment from 'moment-timezone'

export default {
  name: 'device-integrations-config',
  components: {},
  props: {
    isModal: Boolean,
    modal: String,
    device: Object,
    editable: Boolean
  },
  computed: {
    timezones: function () {
      // return ['Australia', 'New Zealand']
      return moment.tz.names()
    },
    usersTimeZone: function () {
      return moment.tz.guess()
    },
    type_options: function () {
      if (!this.integration_types) {
        return []
      }
      let options = []
      // Loop over the keys in this.integration_types
      for (let key in this.integration_types) {
        let integrationType = this.integration_types[key]
        options.push({
          value: key,
          text: `${integrationType.title} - ${integrationType.description}`
        })
      }
      console.log(options)
      return options
    }
  },
  data: function () {
    return {
      loading: true,
      integrations: [],
      integration_types: ['dummy'],

      /* new Integration info */
      newIntegrationType: null,
    }
  },
  async created() {
    await this.refreshIntegrations()
    await this.refreshIntegrationTypes()
    this.loading = false
  },
  methods: {
    deleteIntegration: async function (integration) {
      let res = await DataProvider.deleteIntegration(integration.id)
      if (res) {
        // AlertHelper.alertSuccess('Integration Deleted', 'The integration has been deleted successfully.')
        await this.refreshIntegrations()
      } else {
        // AlertHelper.alertError('Error Deleting Integration', 'There was an error deleting the integration.')
      }
    },
    refreshIntegrations: async function () {
      let results = await DataProvider.getDeviceIntegrations(this.device.device_imei)
      console.log('Integrations:')
      console.log(results)
      if (results) {
        this.integrations = results.data.integrations
      }
    },
    refreshIntegrationTypes: async function () {
      let results = await DataProvider.getIntegrationTypes()
      if (results) {
        this.integration_types = results.data.integration_types
        console.log('Integration Types:')
        console.log(this.integration_types)
      } else {
        console.log('No Integration Types')
      }
    },
    clickClose: async function () {
      if (this.$v.$anyDirty) {
        let res = await this.$bvModal.msgBoxConfirm('You have unsaved changes, are you sure you want to close this window?', {
          title: 'Confirm Discard Changes',
          okVariant: 'warning',
          centered: true
        })
        if (!res) {
          return
        }
      }
      // Check if we've been passed a modal to close.
      if (this.modal) {
        this.$bvModal.hide(this.modal)
      } else {
        this.$router.go(-1)
      }
    },
    saveIntegration: async function (integration) {
      let res = await DataProvider.addIntegration(integration)
      if (res) {
        // AlertHelper.alertSuccess('Integration Updated', 'The integration has been updated successfully.')
        await this.refreshIntegrations()
      } else {
        // AlertHelper.alertError('Error Updating Integration', 'There was an error updating the integration.')
      }
    },
    addIntegration: async function () {
      let integrationType = this.integration_types[this.newIntegrationType]
      if (!integrationType) {
        console.log('No integration type selected')
        return
      }
      // Copy the config from the relevant type
      let defaultConfiguration = {...this.integration_types[this.newIntegrationType]['required_fields']}
      let integration = {
        'integration_type': this.newIntegrationType,
        'related_type': 'device',
        'related_id': this.device.device_id,
        'configuration': defaultConfiguration
      }
      let res = await DataProvider.addIntegration(integration)
      if (res) {
        // AlertHelper.alertSuccess('Integration Added', 'The integration has been added successfully.')
        await this.refreshIntegrations()
      } else {
        // AlertHelper.alertError('Error Adding Integration', 'There was an error adding the integration.')
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../variables';

.device-label {
  font-family: 'Open Sans', sans-serif;
  color: $theme-color-primary-3;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
}

.bootstrap-timepicker-widget.dropdown-menu {
  z-index: 1050 !important;
}

.footer {
  display: flex;
  flex-direction: row-reverse;
}

.footer button {
  display: inline-block;
}
</style>
