<template>
  <div id="main">
    <NavBar :is="navComp"></NavBar>
    <div class="content nav-content">
      <router-view/>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

import NavBar from '@/components/NavBar'

export default {
  name: 'main-view',
  components: {
    NavBar
  },
  data () {
    return {
      navComp: NavBar
    }
  },
  mounted () {
  }
}
</script>

<style scoped lang="scss">
@import '@/variables';

.nav-content {
  margin-right: 60px;
}

</style>
