// Manage Event and Notification Configuration and data

import * as DataProvider from '@/components/helpers/DataProvider'
import ConfigService from '@/components/helpers/ConfigHelper'

export default class EventService {
  static sortedEvents = {}

  // Load default config from file when the service is started
  constructor () {
    // this._setEventConfigCache(nc.event_types)
    // this._setNotificationConfigCache(nc.notification_types)
    // this.refreshConfigData()
  }

  // Get Event Config
  async getEventConfig () {
    let config = await ConfigService.getConfig('events')
    return config.event_types
  }

  // Get Event Config
  async getNotificationConfig () {
    let config = await ConfigService.getConfig('events')
    return config.notification_types
  }

  /*
  Sort the raw event data into a convenient hashtable of data
   */
  _sortEvents (eventData) {
    let data = {}
    // Loop through each category
    for (let catIdx in eventData) {
      for (let eventType in eventData[catIdx].children) {
        data[eventType] = eventData[catIdx].children[eventType]
      }
    }
    return data
  }

  /*
    Return an array of available notification types, compatible with a standard select box
   */
  async getNotificationTypeOpts () {
    let nTypes = await this.getNotificationConfig()
    let data = []
    for (let opt in nTypes) {
      data.push({
        text: nTypes[opt],
        value: opt
      })
    }
    return data
  }

  /*
    Return an array of available Event types, compatible with a standard select box
   */
  async getEventTypeOpts () {
    let eTypes = await this.getEventConfig()
    let data = []
    // Go through all event categories and create an object compatible with select/combo boxes.
    for (let catId in eTypes) {
      let opts = [{
        value: eTypes[catId].id,
        text: eTypes[catId].generic_name
      }]
      // Add any children for this category into an array that will be attached to the category heading
      for (let optId in eTypes[catId].children) {
        // Only include Events which are subscribeable
        if (eTypes[catId].children[optId].allow_subscribe) {
          opts.push({
            value: eTypes[catId].children[optId].event_type,
            text: eTypes[catId].children[optId].name
          })
        }
      }
      // Add the category heading to the array
      data.push({
        text: eTypes[catId].category_name,
        description: eTypes[catId].description,
        value: catId,
        options: opts
      })
    }
    return data
  }

  /*
    Return an array of available Event types, which users are allowed to create, compatible with a standard select box
   */
  async getCreateableEventTypeOpts () {
    let eTypes = await this.getEventConfig()
    let data = []
    // Go through all event categories and create an object compatible with select/combo boxes.
    for (let catId in eTypes) {
      let opts = []
      // Add any children for this category into an array that will be attached to the category heading
      for (let optId in eTypes[catId].children) {
        // Only include Events which are subscribeable
        if (eTypes[catId].children[optId].allow_create) {
          opts.push({
            value: eTypes[catId].children[optId].event_type,
            text: eTypes[catId].children[optId].name
          })
        }
      }
      // Add the category heading to the array
      if (opts.length > 0) {
        data.push({
          text: eTypes[catId].category_name,
          description: eTypes[catId].description,
          value: catId,
          options: opts
        })
      }
    }

    return data
  }

  /*
    Return the Event Config for a specific event type
   */
  async getEventTypeConfig (eventType) {
    let config = await this.getEventConfig()
    let sortedEvents = this._sortEvents(config)

    if (eventType in sortedEvents) {
      let x = sortedEvents[eventType]
      return x
    } else {
      return null
    }
  }

  /**
   * Returns object data and config using cached config and event data from the server.
   * @param eventId (int)
   * @returns {Promise<Object>} Event Data Object
   */
  async getEventById (eventId) {
    let resp = await DataProvider.getEvent(eventId)

    if (resp.success) {
      let config = await this.getEventTypeConfig(resp.data.event_type)
      resp.data.event_name = config.name
      if (config.hasOwnProperty('event_link_options')) {
        resp.data.event_link_options = config.event_link_options
      }
      return resp.data
    } else {
      return null
    }
  }
}
