var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading
    ? _c("div", { staticClass: "loading-parent" }, [
        _c("div", { staticClass: "background" }),
        _c(
          "div",
          { staticClass: "spinner-container" },
          [
            _c("div", { staticClass: "loading-text", style: _vm.textStyle }, [
              _vm._v(_vm._s(this.displayMessage))
            ]),
            _c("b-spinner", {
              staticClass: "spinner",
              staticStyle: { width: "3rem", height: "3rem" }
            })
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }