var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login" },
    [
      _c("div", { staticClass: "login-outer" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "login-container justify-content-center" },
          [
            _c("login-box", { staticClass: "login-item" }),
            _c(
              "div",
              {
                staticClass: "fake-link",
                on: {
                  click: function($event) {
                    return _vm.$bvModal.show("forgotten-password-modal")
                  }
                }
              },
              [_vm._v("Forgotten Password?")]
            ),
            _c(
              "div",
              { staticClass: "fake-link" },
              [
                _c("router-link", { attrs: { to: "register" } }, [
                  _vm._v("Need to Create an Account?")
                ])
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _c("router-link", { attrs: { to: "terms" } }, [
            _vm._v("Terms and Conditions")
          ])
        ],
        1
      ),
      _c(
        "b-modal",
        {
          staticClass: "modal-content",
          attrs: {
            id: "forgotten-password-modal",
            centered: "",
            size: "lg",
            "hide-footer": "",
            title: "Forgotten Password"
          }
        },
        [
          _c("forgotten-password-box", {
            attrs: { modal: "forgotten-password-modal" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        staticClass: "logo",
        attrs: { src: require("../assets/Protekt-logo-medium.png") }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }