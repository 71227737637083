// Helper to hold common functions for Diagnotic Trouble Codes from OBD enabled devices.

import moment from 'moment'

/***
 * Check device data and return if we should consider the device to have DTCs that need to be displayed.
 * @param device
 * @returns {*|boolean}
 */
export function hasDTCErrors (device) {
  return (device.hasOwnProperty('vehicle_dtcs') && device.vehicle_dtcs !== null &&
    device.vehicle_dtcs.dtc_flag === 0 && device.vehicle_dtcs.dtc_codes.length > 0)
}

/***
 * Return a list of DTC codes affecting the device if any are available
 * @param device
 * @returns {*}
 */
export function getDTCList (device) {
  if (device.hasOwnProperty('vehicle_dtcs') && device.vehicle_dtcs.dtc_flag === 0 && device.vehicle_dtcs.dtc_codes) {
    return device.vehicle_dtcs.dtc_codes
  }
}

/***
 * Format DTC Timestamp to a be human readable
 * @param device
 * @returns {string}
 */
export function formatDTCTimestamp (device) {
  return moment.unix(device.vehicle_dtcs.timestamp).local().format('YYYY-MM-DD HH:mm')
}

// TODO - Add a function to retrieve a brief description of each DTC.
