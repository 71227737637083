var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c("div", { staticClass: "generic-status-widget" }, [
        _c("div", [
          _c(
            "label",
            {
              staticClass: "green-label feature-label",
              attrs: { for: "feature_control_" + _vm.feature_code }
            },
            [_vm._v(_vm._s(_vm.config.title))]
          )
        ]),
        _c(
          "div",
          {
            staticClass: "feature_control_form",
            attrs: { id: "form_" + _vm.feature_code }
          },
          [
            _vm.displayValue === null
              ? _c("input", {
                  staticClass: "w-100",
                  attrs: { disabled: "", value: "No Data" }
                })
              : _vm._e(),
            ["text", "textarea", "number"].includes(_vm.config.control) &&
            this.displayValue !== null
              ? _c("b-input-group", [
                  _vm.config.control === "checkbox"
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.displayValue,
                            expression: "displayValue"
                          },
                          { name: "b-tooltip", rawName: "v-b-tooltip" }
                        ],
                        staticClass: "text-field w-100 w-input input",
                        attrs: {
                          maxlength: "256",
                          placeholder: _vm.config.placeholder,
                          id: "feature_control_" + _vm.feature_code,
                          disabled: "",
                          title: _vm.description,
                          type: "checkbox"
                        },
                        domProps: {
                          checked: Array.isArray(_vm.displayValue)
                            ? _vm._i(_vm.displayValue, null) > -1
                            : _vm.displayValue
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.displayValue,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.displayValue = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.displayValue = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.displayValue = $$c
                            }
                          }
                        }
                      })
                    : _vm.config.control === "radio"
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.displayValue,
                            expression: "displayValue"
                          },
                          { name: "b-tooltip", rawName: "v-b-tooltip" }
                        ],
                        staticClass: "text-field w-100 w-input input",
                        attrs: {
                          maxlength: "256",
                          placeholder: _vm.config.placeholder,
                          id: "feature_control_" + _vm.feature_code,
                          disabled: "",
                          title: _vm.description,
                          type: "radio"
                        },
                        domProps: { checked: _vm._q(_vm.displayValue, null) },
                        on: {
                          change: function($event) {
                            _vm.displayValue = null
                          }
                        }
                      })
                    : _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.displayValue,
                            expression: "displayValue"
                          },
                          { name: "b-tooltip", rawName: "v-b-tooltip" }
                        ],
                        staticClass: "text-field w-100 w-input input",
                        attrs: {
                          maxlength: "256",
                          placeholder: _vm.config.placeholder,
                          id: "feature_control_" + _vm.feature_code,
                          disabled: "",
                          title: _vm.description,
                          type: _vm.config.control
                        },
                        domProps: { value: _vm.displayValue },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.displayValue = $event.target.value
                          }
                        }
                      })
                ])
              : _vm._e(),
            ["range"].includes(_vm.config.control) && this.displayValue !== null
              ? _c(
                  "b-input-group",
                  [
                    _c("b-progress-bar", {
                      staticClass: "feature-bar",
                      style: "background: " + _vm.barColor + ";",
                      attrs: {
                        value: _vm.displayValue,
                        max: _vm.config.range_max,
                        "show-value": ""
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c("b-input-group-append", [
              _vm.description
                ? _c("i", {
                    directives: [{ name: "b-tooltip", rawName: "v-b-tooltip" }],
                    staticClass: "control-indicator",
                    class: _vm.$config.icons.general.info,
                    attrs: { title: _vm.description }
                  })
                : _vm._e()
            ])
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }