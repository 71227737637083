var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "PopoverElement",
    {
      staticClass: "popover-menu animate-in-out",
      attrs: { value: _vm.isVisible, direction: _vm.direction }
    },
    [
      _c("div", { staticClass: "popover-menu-header" }, [
        _c("h4", { staticClass: "mb-0" }, [_vm._v(_vm._s(_vm.title))]),
        _c("div", {
          staticClass: "header-control clickable fa fa-arrow-right",
          on: { click: _vm.hide }
        })
      ]),
      _c("div", { staticClass: "popover-menu-content" }, [_vm._t("default")], 2)
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }