var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "device-advanced-settings flex-row" },
    [
      _c("loading-box", { attrs: { loading: _vm.loading } }),
      _c("div", { staticClass: "flex-column" }, [
        _c("div", { staticClass: "flex-column w-100 align-items-between" }, [
          _c("h4", [_vm._v("Trip Computation")]),
          _c(
            "div",
            {
              staticClass:
                "flex-row align-items-start justify-content-between w-100"
            },
            [
              _c(
                "div",
                { staticClass: "flex-column" },
                [
                  _c("label", [_vm._v("Trip Computation Mode")]),
                  _c("b-select", {
                    attrs: {
                      options: _vm.tripCompModes,
                      disabled: !_vm.editable
                    },
                    model: {
                      value: _vm.tripMode,
                      callback: function($$v) {
                        _vm.tripMode = $$v
                      },
                      expression: "tripMode"
                    }
                  }),
                  _vm.tripMode === 2
                    ? _c("div", { staticClass: "flex-column" }, [
                        _c("label", [
                          _vm._v("Trip Parked Threshold (seconds)")
                        ]),
                        _c("div", { staticClass: "flex-row" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.tripMoveTimeLimit,
                                expression: "tripMoveTimeLimit"
                              }
                            ],
                            attrs: { type: "number", min: "30" },
                            domProps: { value: _vm.tripMoveTimeLimit },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.tripMoveTimeLimit = $event.target.value
                              }
                            }
                          }),
                          _c("i", {
                            directives: [
                              { name: "b-tooltip", rawName: "v-b-tooltip" }
                            ],
                            staticClass: "icon-button fa",
                            class: _vm.$config.icons.general.info,
                            attrs: {
                              title:
                                "How long in seconds, is a device stationary before it is considered 'parked'?"
                            }
                          })
                        ])
                      ])
                    : _vm._e()
                ],
                1
              ),
              _vm._m(0)
            ]
          )
        ])
      ]),
      _c("div", { staticClass: "footer" }, [
        _vm.modal
          ? _c(
              "button",
              {
                on: {
                  click: function($event) {
                    return _vm.$bvModal.hide(_vm.modal)
                  }
                }
              },
              [_vm._v("Close")]
            )
          : _vm._e(),
        _vm.isDirty
          ? _c("button", { on: { click: _vm.saveSettings } }, [
              _vm._v("Save Changes")
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hint-box" }, [
      _c("h5", [_vm._v("Trip Computation Modes")]),
      _c("p", [
        _vm._v(
          " Trip computation determines how we break up vehicle locations into trips. Most devices work best when using the standard 'Ignition Based' mode, which determines when a trip starts and finishes based on whether the engine is running or not. "
        )
      ]),
      _c("p", [
        _vm._v(" We "),
        _c("span", { staticClass: "font-weight-bold" }, [_vm._v("strongly")]),
        _vm._v(
          " recommend sticking with the standard, Ignition based trip mode as it generally produces the best trips under the wides set of circumstances. "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }