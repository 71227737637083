var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "missing" } }, [
    _c("div", { staticClass: "content" }, [
      _c(
        "div",
        { staticClass: "w-100 mt-5" },
        [
          _c("h1", [_vm._v("Server Unreachable")]),
          _c("img", {
            staticStyle: { width: "80%" },
            attrs: { src: require("@/assets/Pro-tekt-logo.png") }
          }),
          _c("p", [
            _vm._v("We're having trouble connecting to the Protekt server.")
          ]),
          _c("b-spinner")
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }