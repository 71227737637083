var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "device-integrations-view" }, [
    _vm._m(0),
    _c("div", { staticClass: "flex-row" }, [
      _vm.integrations && _vm.integrations.length > 0
        ? _c(
            "div",
            _vm._l(_vm.integrations, function(integration) {
              return _c("div", { key: integration.integration_id }, [
                _c("div", { staticClass: "device-label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.integration_types[integration.integration_type].title
                    )
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "flex-row" },
                  _vm._l(
                    _vm.integration_types[integration.integration_type]
                      .required_fields,
                    function(data_type, key) {
                      return _c(
                        "div",
                        { key: key },
                        [
                          _c("div", { staticClass: "device-label" }, [
                            _vm._v(_vm._s(key))
                          ]),
                          _c("b-input", {
                            attrs: { type: "password" },
                            model: {
                              value: integration["configuration"][key],
                              callback: function($$v) {
                                _vm.$set(integration["configuration"], key, $$v)
                              },
                              expression: "integration['configuration'][key]"
                            }
                          })
                        ],
                        1
                      )
                    }
                  ),
                  0
                ),
                _c("div", { staticClass: "flex-row" }, [
                  _c(
                    "button",
                    {
                      staticClass: "button",
                      attrs: { id: "button-edit" },
                      on: {
                        click: function($event) {
                          return _vm.saveIntegration(integration)
                        }
                      }
                    },
                    [_vm._v("Save")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "button",
                      attrs: { id: "button-delete" },
                      on: {
                        click: function($event) {
                          return _vm.deleteIntegration(integration)
                        }
                      }
                    },
                    [_vm._v("Delete")]
                  )
                ]),
                _c("hr")
              ])
            }),
            0
          )
        : _c("div", [
            _c("p", [
              _vm._v(" There are no integrations configured for this device. ")
            ])
          ])
    ]),
    _c(
      "div",
      { staticClass: "flex-row" },
      [
        _c("h3", [_vm._v("Add Integration")]),
        _c(
          "b-form-group",
          [
            _c("b-select", {
              staticClass: "text-input",
              attrs: { options: _vm.type_options },
              model: {
                value: _vm.newIntegrationType,
                callback: function($$v) {
                  _vm.newIntegrationType = $$v
                },
                expression: "newIntegrationType"
              }
            }),
            _c(
              "b-button",
              {
                attrs: {
                  disabled: !_vm.newIntegrationType,
                  variant: "primary"
                },
                on: {
                  click: function($event) {
                    return _vm.addIntegration()
                  }
                }
              },
              [_vm._v("Add Integration")]
            )
          ],
          1
        )
      ],
      1
    ),
    _c("div", { staticClass: "flex-row" }, [
      _c("div", { staticClass: "col-12 footer mt-2" }, [
        _c(
          "button",
          {
            staticClass: "button",
            attrs: { id: "button-close" },
            on: {
              click: function($event) {
                return _vm.clickClose()
              }
            }
          },
          [_vm._v("Close")]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-row" }, [
      _c("div", { staticClass: "hint-box" }, [
        _c("h5", [_vm._v("Integration Configuration")]),
        _c("p", [
          _vm._v(
            " This screen allows you to configure integrations with other external systems, including Fuel Card Providers. This screen specifically lists the integrations for this device. If a system is not listed here, please contact support. "
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }