<template>
  <div class="generic-status-widget" v-if="!loading">
    <div>
      <label :for="'feature_control_' + feature_code" class="green-label feature-label">{{config.title}}</label>
    </div>

    <div class="feature_control_form" :id="'form_' + feature_code">
      <input class="w-100" disabled value="No Data" v-if="displayValue === null">
        <b-input-group v-if="['text', 'textarea', 'number'].includes(config.control) && this.displayValue !== null">
          <input
                        :type="config.control" v-model="displayValue" class="text-field w-100 w-input input"
                        maxlength="256" :placeholder="config.placeholder" :id="'feature_control_' + feature_code"
                        disabled v-b-tooltip :title="description"
          />
        </b-input-group>
        <b-input-group v-if="['range'].includes(config.control) && this.displayValue !== null">
          <b-progress-bar class="feature-bar" :style="'background: '+barColor+';'"
            :value="displayValue" :max="config.range_max" show-value></b-progress-bar>
        </b-input-group>
        <b-input-group-append>
          <i v-if="description"
             class="control-indicator" :class="$config.icons.general.info"
             v-b-tooltip :title="description"></i>
        </b-input-group-append>
    </div>
  </div>
</template>

<script>
import * as DataProvider from '@/components/helpers/DataProvider'
import * as ErrorHelper from '@/components/helpers/ErrorHelper'
import moment from 'moment'

export default {
  name: 'generic-status-widget',
  props: {
    device: Object,
    feature_value: Object,
    editable: Boolean,
    feature_code: String
  },
  data: function () {
    return {
      showButtons: true,
      loading: true,
      displayDevice: null,
      value: {},
      config: {},
      // Text string representing control timestamp (if there is one)
      offValue: 0,
      secondaryPropName: 'threshold'
    }
  },
  async mounted () {
    this.config = await this.$deviceService.getFeatureConfig(this.feature_code)
    // Check if we've been provided with the control data as a prop
    if (this.feature_value) {
      this.value = this.feature_value
      // Check if the data prop is available on the device data we have
    } else if (this.config.prop_name in this.device) {
      this.value = this.device[this.config.prop_name]
    } else {
      // If all else fails, request the feature state from the server
      this.value = await this.getFeatureValue(this.config.prop_name)
    }
    this.loading = false
  },
  methods: {
    /***
     * Fetch the control value from the server. Ignores the data type and just returns whatever the API provides.
     */
    getFeatureValue: async function (propName) {
      let resp = await DataProvider.getDeviceProperty(this.device.device_imei, propName)
      if (resp.success) {
        // Check to see if the server returned the prop wee asked for. If not return null (better than an empty object)
        if (resp.data.hasOwnProperty(propName)) {
          return resp.data[propName]
        } else {
          return null
        }
      } else {
        ErrorHelper.displayGeneralErrorToast(resp)
      }
    },
    /***
     * Return a formatted Timestamp String or an empty string
     * @param timestamp Unix Timestamp
     * @returns {string}
     */
    formatTimestamp: function (timestamp) {
      if (timestamp) {
        return moment.unix(timestamp).local().format('YYYY/MM/DD HH:mm')
      } else {
        return ''
      }
    }
  },
  computed: {
    description () {
      let timestamp = ''
      if (this.value && this.value.hasOwnProperty('timestamp')) {
        timestamp = this.formatTimestamp(this.value.timestamp)
      }
      if (this.config.description) {
        return this.config.description + ' ' + timestamp
      }
      return timestamp
    },
    displayValue () {
      if (this.value && this.value.hasOwnProperty('value')) {
        return this.value.value
      } else if (this.value !== null) {
        return this.value
      } else if (this.config.hasOwnProperty('default_value')) {
        return this.config.default_value
      } else {
        return null
      }
    },
    barColor () {
      if (this.config.control === 'range' && this.config.hasOwnProperty('color_range')) {
        let colorConfig = Object.keys(this.config.color_range).map(x => parseInt(x))
        colorConfig.sort((a, b) => b - a)
        let cIdx = colorConfig.reduce((lastValue, colorRangeId) => {
          if (this.value <= colorRangeId && colorRangeId < lastValue) {
            return colorRangeId
          } else {
            return lastValue
          }
        }, 100)
        return this.config.color_range[+cIdx]
      } else {
        return '#38D429FF'
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '@/variables';

  .generic-feature-control{
    font-family: 'Open Sans', sans-serif;
    color: $theme-color-primary-3;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .feature_control_form {
    display: flex;
    flex-direction: row;
    flex-grow: 2;
    align-items: center;
    background: $theme-color-background-5;
    border-radius: 5px;
    padding: 5px;
    width: 100%;
  }

  .control-indicator {
    font-size: 24px;
    align-self: center;
    margin-left: 1em;
  }

  .feature-label {
    margin: 5px 5px 0 5px;
  }

  input {
    margin-bottom: 0
  }

  .feature-bar {
    padding: 0.375rem 0.75rem;
    margin: 1px;
    color: black;
    border-radius: 4px;
  }

</style>
