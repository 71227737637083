<template>
  <div class="device-alarms">
    <div class="alarms-container" v-if="!loading">
        <GenericFeatureControl v-for="(feature, idx) of deviceFeatures" v-bind:key="idx" class=""
                               :device="device" :feature_code="feature" :editable="editable"
                               v-on:change="unsavedChanges = true" :ref="feature" v-on:save="onSave"
        ></GenericFeatureControl>
    </div>
    <div class="spacing"></div>
      <div class="footer mt-2">
        <button class="button" @click="clickClose()" id="button-close">Close</button>
        <button class="button" @click="clickSave()" id="button-save" v-if="editable">Save Changes</button>
      </div>

  </div>
</template>

<script>

import GenericFeatureControl from '@/components/device/device_controls/GenericFeatureControl'
import * as DataProvider from '@/components/helpers/DataProvider'
import * as AlertHelper from '@/components/helpers/AlertHelper'
import * as ErrorHelper from '@/components/helpers/ErrorHelper'

export default {
  name: 'device-alarms',
  components: { GenericFeatureControl },
  props: {
    isModal: Boolean,
    modal: String,
    device: Object,
    editable: Boolean
  },
  data: function () {
    return {
      showButtons: true,
      loading: true,
      deviceFeatures: [],
      displayDevice: null,
      unsavedChanges: false
    }
  },
  async created () {
    // Hide Controls so the parent modal can display them
    if (this.isModal) {
      this.showButtons = false
    }
    this.deviceFeatures = await this.$deviceService.getDeviceFeatures(this.device.device_type)
    let featureConfig = await this.$deviceService.getFeatureConfigs(this.deviceFeatures)
    // Filter the features down to the ones that are of the 'alarm' type. The v-for in the template will then
    // render only the ones remaining in the 'deviceFeatures' list.
    this.deviceFeatures = this.filterFeaturesByType(featureConfig, 'alarm').sort()
    this.loading = false
  },
  methods: {
    filterFeaturesByType: function (featureList, featureType) {
      let result = []
      for (let feature in featureList) {
        if (featureList[feature].feature_type === featureType && featureList[feature].published) {
          result.push(feature)
        }
      }
      return result
    },
    clickClose: async function () {
      if (this.unsavedChanges) {
        let res = await this.$bvModal.msgBoxConfirm('You have unsaved changes, are you sure you want to close this window?', {
          title: 'Confirm Discard Changes',
          okVariant: 'warning',
          centered: true
        })
        if (!res) {
          return
        }
      }
      this.$bvModal.hide(this.modal)
    },
    /***
      Looks for dirty child components and saves them in one single transaction.
     This is ugly, but should be more efficient than updating each individually
     ***/
    clickSave: async function () {
      let childData = []
      let whitelist = []
      let propRefs = {}
      for (let feature of this.deviceFeatures) {
        // Note: There's a 'feature' in Vue that refs created within a v-for are part of an array...
        let component = this.$refs[feature][0]
        if (component.dirty) {
          childData.push(component.getSaveData())
          whitelist.push(component.config.prop_name)
          propRefs[component.config.prop_name] = component
        }
      }
      if (childData.length > 0) {
        let data = Object.assign({}, ...childData)
        data['whitelist'] = whitelist
        let respData = await this.updateDeviceProperties(data)
        // Update the values of the components that were returned to us.
        for (let prop in respData) {
          propRefs[prop].updateFeatureValue(respData[prop])
        }
        this.unsavedChanges = false
      }
    },
    updateDeviceProperties: async function (data) {
      let resp = await DataProvider.setDeviceProperty(this.device.device_imei, data)
      if (resp.success) {
        AlertHelper.successToast('Device Parameters Updated.', 'Success')
        this.$emit('save')
        return resp.data
      } else {
        ErrorHelper.displayDataErrorToast(resp)
        throw Error('Error saving Alarm changes.')
      }
    },
    /***
     * Save Event - Called when a child component saves a change successfully.
     */
    onSave: function () {
      this.$emit('save')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '../../variables';

  .alarms-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .footer{
    //width: 100%;
    //bottom: 2%;
    display: flex;
    justify-content: flex-end;
  }

  .icon_select_color {
    border: solid $theme-color-primary-3;
  }

</style>
