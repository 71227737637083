var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.device && _vm.deviceFeatures
    ? _c(
        "div",
        {
          staticClass: "device-container-outer",
          class: {
            selected: _vm.selected === true
          },
          on: { click: _vm.clickDevice }
        },
        [
          _c(
            "div",
            {
              staticClass: "device-container-inner",
              class: {
                "invalid-subscription":
                  _vm.showIndicators && !_vm.device.has_subscription,
                clickable: _vm.enable_link || !_vm.disabled,
                selected: _vm.selected === true,
                "device-avatar-hover": !_vm.disabled,
                disabled: _vm.disabled
              }
            },
            [
              _c("div", { staticClass: "device-image" }, [
                _c("img", {
                  attrs: { src: _vm.iconImage, alt: _vm.device.icon }
                }),
                _vm.showIndicators &&
                _vm.deviceFeatures.includes("device_trips")
                  ? _c(
                      "span",
                      {
                        directives: [
                          { name: "b-tooltip", rawName: "v-b-tooltip" }
                        ],
                        staticClass: "error-badge badge rounded-pill badge-acc",
                        class: _vm.accOnBadgeVariant,
                        attrs: { title: _vm.accTooltip }
                      },
                      [_c("i", { staticClass: "fa fa-power-off" })]
                    )
                  : _vm._e(),
                _vm.showIndicators &&
                _vm.deviceFeatures.includes("device_battery_level") &&
                _vm.batteryIcon
                  ? _c(
                      "span",
                      {
                        directives: [
                          { name: "b-tooltip", rawName: "v-b-tooltip" }
                        ],
                        staticClass: "error-badge badge rounded-pill badge-acc",
                        class: _vm.batteryBadgeVariant,
                        attrs: { title: _vm.device.device_battery_level + "%" }
                      },
                      [
                        _c("i", {
                          staticClass: "battery-icon fa",
                          class: _vm.batteryIcon
                        })
                      ]
                    )
                  : _vm._e()
              ]),
              _c("div", { staticClass: "device-inner" }, [
                _c("div", { staticClass: "device-name" }, [
                  _vm._v(" " + _vm._s(_vm.device.device_name) + " ")
                ]),
                _c("div", { staticClass: "detail-text" }, [
                  _c("div", [_vm._v(_vm._s(_vm.device.device_code))])
                ])
              ])
            ]
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }