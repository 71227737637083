// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/Satellite-View.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "/*\n  SCSS Variables Files\n\n  This file should be imported to all components that need to access the SCSS Variables for themeing\n  It *SHOULD NOT* contain any CSS Rules. Only Variables.\n  Global Styles should be added to the custom.scss file, which is imported globally and applied to all components\n  automatically.\n */\n.register[data-v-63ae9146] {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") black;\n  background-size: cover;\n  background-repeat: no-repeat;\n  color: #e5e5e5;\n  overflow: auto;\n}\n.register-outer[data-v-63ae9146] {\n  max-width: 650px;\n  padding: 15px;\n  border-radius: 15px;\n  background: #2a2a2a;\n}\n.logo[data-v-63ae9146] {\n  max-width: 530px;\n  max-height: 40vh;\n}\n.register-container[data-v-63ae9146] {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  align-items: center;\n}\n.login-item[data-v-63ae9146] {\n  width: 100%;\n  max-width: 35rem;\n}\n.footer[data-v-63ae9146] {\n  position: absolute;\n  left: 50%;\n  bottom: 20px;\n  transform: translate(-50%, 0);\n  margin-top: 5vh;\n}\n.register-title[data-v-63ae9146] {\n  color: #38d429;\n}\n@media screen and (max-height: 945px) {\n.footer[data-v-63ae9146] {\n    position: relative;\n    width: 100%;\n    left: 50%;\n    bottom: 20px;\n    transform: translate(-50%, 0);\n    margin-top: 5vh;\n}\n}", ""]);
// Exports
module.exports = exports;
