var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "flex-column trip-avatar",
      class: {
        selected: _vm.selected === true,
        hover: !_vm.disabled,
        disabled: _vm.disabled
      },
      on: { click: _vm.clickTrip }
    },
    [
      _c("div", { staticClass: "flex-row flex-grow-1 align-items-stretch" }, [
        _vm.location
          ? _c("i", {
              staticClass: "trip-icon trip-icon-border",
              class: _vm.$config.icons.general.locations
            })
          : _c("img", { staticClass: "trip-icon", attrs: { src: _vm.icon } }),
        _c(
          "div",
          { staticClass: "flex-column align-items-sm-center flex-grow-1" },
          [
            !_vm.location
              ? _c("div", { staticClass: "flex-row trip-avatar-main" }, [
                  _c("div", [_vm._v(_vm._s(_vm.stateText))]),
                  _c("div", [
                    _vm._v(
                      _vm._s(_vm.dt.timestampToLocalTime(_vm.trip.start_time))
                    )
                  ]),
                  _vm._m(0),
                  _c("div", [
                    _vm._v(
                      _vm._s(_vm.dt.timestampToLocalTime(_vm.trip.end_time))
                    )
                  ])
                ])
              : _c("div", { staticClass: "flex-row trip-avatar-main" }, [
                  _c("div", [_vm._v("Location")]),
                  _c("div", [_vm._v("@")]),
                  _c("div", [
                    _vm._v(
                      _vm._s(_vm.dt.timestampToLocalTime(_vm.trip.start_time))
                    )
                  ])
                ]),
            _vm.location
              ? _c("div", { staticClass: "trip-avatar-date" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.dt.timestampToLocalDateTime(_vm.trip.start_time)
                      ) +
                      " "
                  )
                ])
              : _c("div", { staticClass: "trip-avatar-date" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.dt.timestampToLocalDateTime(_vm.trip.start_time)
                      ) +
                      " to " +
                      _vm._s(
                        _vm.dt.timestampToLocalDateTime(_vm.trip.end_time)
                      ) +
                      " "
                  )
                ])
          ]
        ),
        _vm.indicatorIcons.length
          ? _c(
              "div",
              { staticClass: "trip-indicators ml-1" },
              _vm._l(_vm.indicatorIcons, function(indicator, idx) {
                return _c("i", {
                  directives: [{ name: "b-tooltip", rawName: "v-b-tooltip" }],
                  key: idx,
                  staticClass: "indicator-icon",
                  class: indicator.icon,
                  attrs: { title: indicator.text }
                })
              }),
              0
            )
          : _vm._e()
      ]),
      _vm.show_address && _vm.hasAddressData
        ? _c(
            "div",
            {
              staticClass: "flex-column trips-addresses align-items-md-stretch"
            },
            [
              _c(
                "div",
                { staticClass: "flex-row justify-content-between" },
                [
                  _c("div", { staticClass: "trips-addresses-label" }, [
                    _vm._v("From:")
                  ]),
                  _c("b-tooltip", {
                    attrs: {
                      target: "trip-" + _vm.trip.id + "-startAddress",
                      noninteractive: "",
                      title: _vm.startAddressFull
                    }
                  }),
                  _c(
                    "div",
                    {
                      directives: [
                        { name: "b-tooltip", rawName: "v-b-tooltip" }
                      ],
                      attrs: { id: "trip-" + _vm.trip.id + "-startAddress" }
                    },
                    [_vm._v(_vm._s(_vm.startAddressTruncated))]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "flex-row justify-content-between" },
                [
                  _c("div", { staticClass: "trips-addresses-label" }, [
                    _vm._v("To:")
                  ]),
                  _c("b-tooltip", {
                    attrs: {
                      target: "trip-" + _vm.trip.id + "-endAddress",
                      noninteractive: "",
                      title: _vm.startAddressFull
                    }
                  }),
                  _c(
                    "div",
                    { attrs: { id: "trip-" + _vm.trip.id + "-endAddress" } },
                    [_vm._v(" " + _vm._s(_vm.endAddressTruncated) + " ")]
                  )
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _vm.show_tags && _vm.hasTags
        ? _c(
            "div",
            { staticClass: "trip-section" },
            [_c("TripTags", { attrs: { value: _vm.trip.tags } })],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("i", { staticClass: "fa fa-arrow-right" })])
  }
]
render._withStripped = true

export { render, staticRenderFns }