var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "trip-tag",
      class: {
        "tag-editable": _vm.editable,
        "tag-removeable": _vm.editable && _vm.show_remove_icon
      },
      style:
        "color: " +
        _vm.color +
        "DD; background: " +
        _vm.color +
        "22; border-color: " +
        _vm.color +
        "; outline-color: " +
        _vm.color +
        ";",
      on: {
        click: function($event) {
          return _vm.$emit("click", $event)
        }
      }
    },
    [
      _c("span", { staticClass: "tag-text" }, [
        _vm._v(" " + _vm._s(_vm.text) + " ")
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }