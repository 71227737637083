<template>
  <div class="flex-row trip-tags">
<!--    Control to Add new Tags-->
    <b-dropdown no-caret toggle-class="tag-dropdown" dropup v-if="editable" @show="showAddMenu">
      <template #button-content>+</template>
      <b-dropdown-form class="slim-controls flex-column" @submit.prevent="addCustomTag">
        <label>Add Tags</label>
        <div class="flex-row flex-nowrap mb-2">
            <input placeholder="Enter a new tag.." v-model="customTagText" required type="text" @input="customInput"
                   minlength="1" maxlength="16" ref="customTagInput">
          <button>+</button>
        </div>

        <div class="flex-row">
          <ColorTag v-for="(tag, idx) of appendableTags" v-bind:key="'add-tag-' + idx" :color="tag.color" :text="tag.text" :editable="editable"
                    @click="clickAddTag(tag)"
          >
          <span class="tag-text">
            {{tag.text}}
          </span>
          </ColorTag>
        </div>
      </b-dropdown-form>
    </b-dropdown>
    <ColorTag class="trip-tag" v-for="(tag, idx) of tags" v-bind:key="'tag-' + idx" :color="tag.color" :text="tag.text" :editable="editable" show_remove_icon
         @click="clickRemoveTag(tag)"
    >
      <span class="tag-text">
        {{tag.text}}
      </span>

    </ColorTag>
  </div>
</template>
<script>

/****
 *
 *  TripTags
 *
 *   Used to display and edit the tags attached to a trip. Can be used with a v-model directive.
 *
 *
 */

import ColorHelper from '@/components/helpers/ColorHelper'
import ColorTag from '@/components/trips/ColorTag.vue'

export default {
  name: 'TripTags',
  components: {ColorTag},
  props: {
    editable: Boolean,
    value: Object
  },
  data () {
    return {
      tagColors: {},
      tags: [],
      customTagText: ''
    }
  },
  async mounted () {
    await this.loadColors()
    if (this.value) {
      await this.parseTags()
    }
  },
  methods: {
    async loadColors() {
      let savedColors = await this.$auth.getUserSetting('tag_colors')
      if (typeof savedColors !== 'object' || savedColors === null) {
        console.warn('Discarding Saved colors as they are of an invalid format.')
        this.tagColors = {}
      } else {
        this.tagColors = savedColors
      }
    },
    async parseTags () {
      let colorsUpdate = false
      this.tags = []
      for (let tag in this.value) {
        // Check if we have an existing color assigned to this tag
        let color
        if (Object.hasOwn(this.tagColors, tag)) {
          color = this.tagColors[tag]
        } else { // We don't so assign one.
          color = ColorHelper.getPastelColor(Object.keys(this.tagColors).length)
          this.tagColors[tag] = color
          colorsUpdate = true
        }
        this.tags.push({
          text: tag,
          color: color
        })
        if (colorsUpdate) {
          await this.saveColors()
        }
      }
    },
    async saveColors() {
      await this.$auth.setUserSetting('tag_colors', this.tagColors)
    },
    showAddMenu() {
      this.loadColors()
    },
    clickAddTag (tagName) {
      this.tags.push(tagName)
      this.emitTagChange()
    },
    clickRemoveTag (tag) {
      this.tags = this.tags.filter(t => t !== tag)
      this.emitTagChange()
    },
    customInput () {
      if (this.usedTags.includes(this.formatTagText(this.customTagText))) {
        this.$refs.customTagInput.setCustomValidity('Tag Name Already in Use.')
      } else {
        this.$refs.customTagInput.setCustomValidity('')
      }
    },
    addCustomTag() {
      let text = this.formatTagText(this.customTagText)
      if (!this.usedTags.includes(text)) {
        let color = ColorHelper.getPastelColor(Object.keys(this.tagColors).length)
        this.tags.push({
          text: text,
          color: color
        })
        this.tagColors[text] = color
        this.customTagText = ''
      } else {
        this.$refs.customTagInput.setCustomValidity('Tag Name Already in Use.')
      }
      this.emitTagChange()
      this.$auth.setUserSetting('tag_colors', this.tagColors)
    },
    formatTagText (text) {
      return text.toLowerCase()
    },
    emitTagChange() {
      this.$auth.setUserSetting('tag_colors', this.tagColors)
      this.$emit('input', this.tagArrayToObject(this.usedTags))
    },
    tagArrayToObject (tagArray) {
      let tagObj = {}
      tagArray.forEach(t => tagObj[t] = true)
      return tagObj
    }
  },
  computed: {
    usedTags () {
      return this.tags.map(t => t.text)
    },
    appendableTags () {
      if (this.tagColors) {
        let unusedTags = Object.keys(this.tagColors).filter(t => !this.usedTags.includes(t))
        return unusedTags.map((tagName) => { return { text: tagName, color: this.tagColors[tagName] } })
      } else {
        return []
      }
    }
  },
  watch: {
    value (v) {
      this.parseTags()
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/variables';
.trip-tags {
}
</style>
