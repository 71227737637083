<template>
  <div class="notification-list">
    <div class="flex-row justify-content-end">
      <button v-b-tooltip.hover.left title="Refresh List" @click="refreshNotifications">Refresh</button>
    </div>
    <div v-if="noData" class="no-data-message">Nothing to show right now.</div>
    <div class="notification-table">
      <div class="filter-bar" v-if="!hideFilter">
        <div class="slim-controls">
          <button @click="showAdvancedFilters = !showAdvancedFilters">Adv Filters</button>
          <div v-show="showAdvancedFilters" class="">
            <search-filters
              :filters="filters"
              @change="changeFilter"
              @press-enter="applyFilter"
              ref="searchFilters"
            ></search-filters>
            <div class="flex-row slim-controls">
              <button
                @click="resetFilters"
              >Clear</button>
              <button class="success"
                v-if="filterMethod === 'remote'"
                @click="applyFilter"
                >Apply</button
              >
            </div>

          </div>
        </div>
      </div>
      <b-table
        striped
        hover
        :items="tableDataSource"
        :fields="notificationFields"
        select-mode="single"
        selected-variant="success"
        :current-page="currentPage"
        :per-page="perPage"
        td-class="admin-device-table-data"
        th-class="admin-device-table-header"
        ref="notificationTable"
        :sort-desc="true"
        sort-by="id"
      >
        <!--Read/Unread Header Element-->
        <template v-slot:head(selected)="row">
        </template>
        <template v-slot:cell(selected)="row">
          <b-badge pill v-if="!row.item.read" variant="warning">New</b-badge>
        </template>
        <!--Link Header Element-->
        <template v-slot:head(action)="row">
        </template>
        <template v-slot:cell(action)="row">
          <i v-if="row.item.link"
             v-b-tooltip.hover title="View Event"
             @click="clickLink(row.item)"
            class="fa fa-external-link row-icon-button"></i>
        </template>
        <template v-slot:cell(alert_type)="row">
          <b-badge class="type" pill variant="info" v-b-tooltip.hover :title="alertTypeTooltips[row.item.alert_type]"
          >{{row.item.alert_type}}</b-badge>
        </template>
      </b-table>
      </div>
      <div class="page-bar">
        <b-form-select
          v-model="perPage"
          id="perPageSelect"
          size="sm"
          :options="pageOptions"
          class="page-select"
        >
        </b-form-select>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="page-buttons"
        ></b-pagination>
      </div>
  </div>
</template>

<script>
import moment from 'moment'
import * as DataProvider from '../helpers/DataProvider'
import * as ErrorHelper from '@/components/helpers/ErrorHelper'
import SearchFilters from '@/components/shared/SearchFilters'

export default {
  name: 'notification-list',
  components: {
    SearchFilters
  },
  props: {
    data: Array,
    userEmail: String
  },
  data: function () {
    return {
      // Table Filtering
      filterMethod: 'remote', // Filter Locally or remotely at the server-side
      filter: '',
      showAdvancedFilters: false,
      hideFilter: false,
      notifications: [],
      dayMatrix: {},
      days: [],
      noData: false,
      loading: true,
      tableDataSource: null,
      notificationFields: [
        {
          key: 'selected',
          label: '-'
        },
        // { key: 'id',
        //   label: 'ID#',
        //   sortable: true
        // },
        { key: 'datetime',
          label: 'Timestamp',
          sortable: true,
          formatter: (value) => {
            return moment.utc(value).local().format('YYYY-MM-DD HH:mm')
          }
        },
        { key: 'message',
          label: 'Message'
        },
        { key: 'alert_type',
          label: 'Method',
          sortable: true
        },
        {
          key: 'action',
          label: '-'
        }
      ],
      // Table Pagination Options
      currentPage: 1,
      totalRows: 0,
      perPage: 25,
      pageOptions: [
        { value: 25, text: '25' },
        { value: 50, text: '50' },
        { value: 100, text: '100' }
      ],
      filters: [
        {
          label: 'Filter By Type',
          type: 'select',
          placeholder: 'Alert Type...',
          fieldName: 'alert_type',
          options: [
            { value: '', text: 'Any' },
            { value: 'push', text: 'Push' },
            { value: 'web', text: 'Web' },
            { value: 'email', text: 'Email' }
          ]
        },
        {
          label: 'Filter By Unread',
          type: 'boolean',
          placeholder: 'Alert Type...',
          fieldName: 'unread_only'
        },
        {
          label: 'Filter After Date',
          type: 'date',
          placeholder: 'After Date',
          fieldName: 'start_date'
        },
        {
          label: 'Filter Before Date',
          type: 'date',
          placeholder: 'Before Date',
          fieldName: 'end_date'
        }
      ],
      currentFilter: [],
      alertTypeTooltips: {
        'push': 'Push Notification to iOS or Android Device.',
        'web': 'Web Notifications appear only on the fleet screen.',
        'email': 'Email Notification message sent.'
      }
    }
  },
  async mounted () {
    await this.refreshNotifications()
  },
  methods: {
    async refreshNotifications () {
      if (this.userEmail) {
        this.tableDataSource = this.adminGetNotifications
      } else {
        this.tableDataSource = this.getNotifications
        setTimeout(() => {
          this.markAllRead()
        }, 2000)
      }
      this.$refs.notificationTable.refresh()
    },
    // async getNotificationCount () {
    //   let filters = {}
    //   if (this.showAdvancedFilters) {
    //     filters = this.currentFilter
    //   }
    //   let resp = await DataProvider.getNotificationCount(filters)
    //   if (!resp.success) {
    //     ErrorHelper.displayDataErrorToast(resp)
    //   } else {
    //     this.totalRows = resp.data.notification_count
    //   }
    // },
    // async adminGetNotificationCount () {
    //   let resp = await DataProvider.adminGetNotificationCount(this.userEmail, false)
    //   if (!resp.success) {
    //     ErrorHelper.displayDataErrorToast(resp)
    //   } else {
    //     this.totalRows = resp.data.notification_count
    //   }
    // },
    async getNotifications (ctx) {
      let filters = {}
      if (this.showAdvancedFilters) {
        filters = this.currentFilter
      }

      let resp = await DataProvider.getNotificationsPage(ctx.perPage, (ctx.currentPage - 1) * ctx.perPage, ctx.sortBy, ctx.sortDesc, filters)
      if (!resp.success) {
        ErrorHelper.displayDataErrorToast(resp)
      } else {
        if (resp.data.hasOwnProperty('total')) {
          this.totalRows = resp.data.total
        }
        return resp.data.alerts
      }
    },
    async adminGetNotifications (ctx) {
      let filters = {}
      if (this.showAdvancedFilters) {
        filters = this.currentFilter
      }
      let resp = await DataProvider.adminGetNotificationsPage(this.userEmail, ctx.perPage, (ctx.currentPage - 1) * ctx.perPage, ctx.sortBy, ctx.sortDesc, filters)
      if (!resp.success) {
        ErrorHelper.displayDataErrorToast(resp)
      } else {
        this.totalRows = resp.data.total
        return resp.data.alerts
      }
    },
    applyFilter: function () {
      this.refreshNotifications()
    },
    changeFilter: function (filters) {
      console.log('Set Filters: ', filters)
      this.currentFilter = filters
    },
    resetFilters: function () {
      this.$refs.searchFilters.reset()
    },
    // Event Handler for links being clicked
    // Some extra logic here to make notification links work when we are already on the screen they are for.
    async clickLink (notification) {
      if (notification.link) {
        let query = {}
        if (notification.hasOwnProperty('event_id')) {
          query.event_id = notification.event_id
        }
        try {
          // Ignore Duplicate navigation errors
          await this.$router.replace({ path: notification.link, query: query })
          // NOTE: If we ever use the notification list somewhere else we'll need to pass this as a prop
          this.$bvModal.hide('modal-notifications')
        } catch (e) {
          if (e.name !== 'NavigationDuplicated') {
            console.log(e)
          }
        }
      }
    },
    async markAllRead () {
      let resp = await DataProvider.markAllNotificationsRead()

      if (!resp.success) {
        this.$bvToast.toast(resp.error.action,
          {
            title: resp.error.message,
            variant: 'danger',
            toaster: 'b-toaster-top-center'
          })
      }
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '../../variables';

  .notification-list {
    padding: 0.5rem;
    color: $text-color-invert;
  }

  .notification-table {
    background: $theme-color-background-1;
    max-height: 70vh;
    overflow-y: auto;
    padding: 10px;
  }

  .notification-line {
    display: flex;
    flex-direction: row;
    width: 100%;
    border-top: 1px solid rgba(121, 121, 121, 0.62);
  }

  .no-data-message {
    color: $theme-color-primary-3;
    font-style: italic;
  }

  .notification-timestamp {
    font-style: italic;
    margin-right: 1.2rem;
  }

  .clear-icon {
    color: red;
    opacity: 0.7;
    margin-left: 1rem;
  }

  .clear-icon:hover {
    opacity: 1;
  }

  .page-bar {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 95%;
  }

  .page-select {
    width: 10%;
    min-width: 50px;
  }

  .page-buttons {
    width: 25%;
    min-width: 150px;
  }

  .type:first-letter {
    text-transform:capitalize;
  }

</style>
