var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-edit" },
    [
      _c("LoadingBox", { attrs: { loading: _vm.loading } }),
      _c("div", { staticClass: "form-create-inner animate-in slide-left" }, [
        _c(
          "div",
          {
            staticClass: "flex-row justify-content-between align-items-stretch"
          },
          [
            !_vm.isNew
              ? _c(
                  "div",
                  { staticClass: "flex-column mr-3" },
                  [
                    _c("label", [_vm._v("User")]),
                    _vm.user
                      ? _c("UserAvatar", { attrs: { user: _vm.user } })
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e(),
            !_vm.isNew
              ? _c(
                  "div",
                  { staticClass: "flex-column mr-3" },
                  _vm._l(_vm.relatedTypeControls, function(option, idx) {
                    return _c(
                      "div",
                      { key: idx },
                      [
                        _c("label", [_vm._v(_vm._s(option.showText))]),
                        option.value === "device" && _vm.device
                          ? _c("DeviceAvatar", {
                              attrs: { device: _vm.device }
                            })
                          : option.value === "device"
                          ? _c("div", [_vm._v("Device Unavailable")])
                          : _vm._e(),
                        option.value === "trip" && _vm.tripObject
                          ? _c("TripAvatar", {
                              attrs: {
                                trip: _vm.tripObject,
                                show_tags: "",
                                show_address: ""
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm.isNew
              ? _c(
                  "div",
                  { staticClass: "flex-column flex-grow-1" },
                  [
                    _c("label", [_vm._v("Template")]),
                    _c("b-select", {
                      attrs: {
                        disabled: _vm.disabled,
                        options: _vm.templateOptions
                      },
                      on: { change: _vm.changeTemplate },
                      model: {
                        value: _vm.templateId,
                        callback: function($$v) {
                          _vm.templateId = $$v
                        },
                        expression: "templateId"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.isNew
              ? _c(
                  "div",
                  { staticClass: "flex-column flex-grow-1 mr-3" },
                  _vm._l(_vm.relatedTypeControls, function(option, idx) {
                    return _c(
                      "div",
                      { key: idx },
                      [
                        _c("label", [_vm._v(_vm._s(option.pickText))]),
                        option.value === "device"
                          ? _c("DeviceSelector", {
                              attrs: { return_prop: "device_id" },
                              model: {
                                value: _vm.deviceId,
                                callback: function($$v) {
                                  _vm.deviceId = $$v
                                },
                                expression: "deviceId"
                              }
                            })
                          : _vm._e(),
                        option.value === "trip" && _vm.tripObject
                          ? _c("TripAvatar", {
                              attrs: { trip: _vm.tripObject }
                            })
                          : option.value === "trip" && _vm.tripObject === null
                          ? _c("div", [_vm._v("Trip Not Found")])
                          : _vm._e()
                      ],
                      1
                    )
                  }),
                  0
                )
              : _vm._e(),
            !_vm.isNew && this.template
              ? _c(
                  "div",
                  { staticClass: "flex-column flex-grow-1" },
                  [
                    _c("label", [_vm._v("Template")]),
                    _c("FormTemplateAvatar", {
                      attrs: { template: _vm.template }
                    })
                  ],
                  1
                )
              : _vm._e()
          ]
        ),
        _c("div", { staticClass: "green-divider" }),
        !_vm.isNew
          ? _c(
              "div",
              [
                _c("label", {}, [_vm._v("Workflow Progress")]),
                _c("WorkflowProgressWidget", {
                  staticClass: "mt-3",
                  attrs: {
                    steps: _vm.formSteps,
                    progress: _vm.workflowProgress
                  }
                }),
                _c("div", { staticClass: "green-divider" })
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "template-container" },
          [
            _vm.template
              ? _c("FormTemplate", {
                  ref: "jsonForm",
                  staticClass: "form-template",
                  attrs: {
                    template: _vm.template,
                    form_data: _vm.formData,
                    disabled: !_vm.editMode
                  },
                  on: { submit: _vm.saveForm, change: _vm.formDataChange }
                })
              : _vm._e()
          ],
          1
        )
      ]),
      _c("div", { staticClass: "flex-row justify-content-end" }, [
        !_vm.isNew
          ? _c(
              "button",
              { staticClass: "danger", on: { click: _vm.deleteForm } },
              [_vm._v("Delete")]
            )
          : _vm._e(),
        !_vm.isNew && !_vm.editMode
          ? _c(
              "button",
              {
                staticClass: "warning",
                on: {
                  click: function($event) {
                    _vm.editMode = true
                  }
                }
              },
              [_vm._v("Edit")]
            )
          : _vm._e(),
        _vm.isReviewRequired
          ? _c(
              "button",
              { staticClass: "success", on: { click: _vm.markComplete } },
              [_vm._v("Mark Complete")]
            )
          : _vm._e(),
        _vm.isDirty && !_vm.isNew
          ? _c(
              "button",
              { staticClass: "warning", on: { click: _vm.resetForm } },
              [_vm._v("Reset")]
            )
          : _vm._e(),
        _vm.isDirty && !_vm.isNew
          ? _c(
              "button",
              {
                attrs: { disabled: _vm.disableSave },
                on: { click: _vm.saveForm }
              },
              [_vm._v("Save")]
            )
          : _vm._e(),
        _vm.isNew
          ? _c(
              "button",
              {
                attrs: { disabled: _vm.disableCreate },
                on: { click: _vm.createForm }
              },
              [_vm._v("Create Form")]
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }