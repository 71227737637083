<template>
  <div class="device-view-exclusions">
    <div class="flex-row">
      <div class="hint-box">
        <h5>View Exclusions</h5>
        <p>
          A View Exclusion is a period of time where those with read-only access cannot see the given device.
          Those with write level or above permissions can see the device at all times. This can be useful if you want
          to share the location of a vehicle during business hours but keep after hours trips private.
        </p>
      </div>
    </div>
    <b-table
      v-if="!loading"
      striped
      hover
      :items="exclusions"
      :fields="exclusionFields"
      td-class="admin-device-table-data"
      th-class="admin-device-table-header"
      ref="exclusionTable"
    >
      <template v-slot:cell(id)="row">
        <i
          class="row-icon-button-danger row-action ml-2"
          :class="$config.icons.general.remove"
          @click="exclusionDelete(row.item.id)"
          v-b-tooltip.hover
          title="Delete Exclusion"
        ></i>
      </template>
    </b-table>

    <div class="row">
      <div class="col-4">
        <label for="start-select" class="device-label">Start:
          <i class="row-icon-button" :class="$config.icons.general.info"
             v-b-tooltip.hover title="When does the exclusion start?"
          ></i>
        </label>
        <b-timepicker v-model="addStartTime" class="text-input" close-button-variant="danger">
        </b-timepicker>
        <b-datepicker v-model="addStartDate" class="text-input" close-button-variant="danger">
        </b-datepicker>
      </div>
      <div class="col-4" style="position: relative">
        <label for="end-select" class="device-label">End:
          <i class="row-icon-button" :class="$config.icons.general.info"
             v-b-tooltip.hover title="When does the exclusion finish?"
          ></i>
        </label>
        <b-timepicker v-model="addEndTime" class="text-input" close-button-variant="danger">
        </b-timepicker>
        <b-datepicker v-model="addEndDate" class="text-input" close-button-variant="danger">
        </b-datepicker>
      </div>
      <div class="col-4">
        <button class="button" @click="setStartNow()" id="button-one-day">Start Now</button>
        <button class="button" @click="setEndAfterOneDay()" id="button-one-day">End after one Day</button>
        <button class="button" @click="setEndToEndOfDay()" id="button-one-day">End of Day</button>
        <button class="button" @click="saveChanges()" id="button-add" v-if="editable">Save</button>
      </div>
    </div>
    <div class="row">
      <div class="col-12 footer mt-2">
        <button class="button" @click="clickClose()" id="button-close">Close</button>
      </div>
    </div>
<!--    <div class="row">-->
<!--      <div class="col-12">-->
<!--        <p>-->
<!--          A View Exclusion is a period of time where those with read-only access cannot see the given device.-->
<!--          Those with write level or above permissions can see the device at all times.-->
<!--        </p>-->
<!--      </div>-->
<!--    </div>-->

  </div>
</template>

<script>
import * as DataProvider from '../helpers/DataProvider'
import {required, numeric, minValue} from 'vuelidate/lib/validators'
import * as ErrorHelper from '../helpers/ErrorHelper'
import * as AlertHelper from '../helpers/AlertHelper'
import moment from 'moment-timezone'

export default {
  name: 'device-view-exclusions',
  components: {},
  props: {
    isModal: Boolean,
    modal: String,
    device: Object,
    editable: Boolean
  },
  computed: {
    timezones: function () {
      // return ['Australia', 'New Zealand']
      return moment.tz.names()
    },
    usersTimeZone: function () {
      return moment.tz.guess()
    }
  },
  data: function () {
    return {
      loading: true,
      exclusions: [],
      exclusionFields: [
        {
          key: 'start_time',
          label: 'Start',
          formatter: this.formatDate
        },
        {
          key: 'end_time',
          label: 'End',
          formatter: this.formatDate
        },
        {
          key: 'id',
          label: 'Remove'
        }
      ],

      // Adding a new entry
      addStartTime: null,
      addStartDate: null,
      addEndTime: null,
      addEndDate: null
    }
  },
  validations: {
    addStartDate: {
      required
    },
    addStartTime: {
      required
    },
    addEndDate: {
      required
    },
    addEndTime: {
      required
    }
  },
  async created() {
    this.refreshExclusions()
    this.loading = false
  },
  methods: {
    formatDate: function (date) {
      return moment(date).format('dddd Do MM YYYY, h:mm a')
    },
    setStartNow: async function () {
      let now = moment()
      this.addStartTime = now.format('HH:mm')
      this.addStartDate = now.format('YYYY-MM-DD')
    },

    setEndAfterOneDay: async function () {
      if (!(this.addStartTime && this.addStartDate)) {
        return
      }

      let startDateTime = moment(`${this.addStartDate}T${this.addStartTime}`)
      let newEndDateTime = startDateTime.add(1, 'day')
      this.addEndTime = newEndDateTime.format('HH:mm')
      this.addEndDate = newEndDateTime.format('YYYY-MM-DD')
    },
    setEndToEndOfDay: async function () {
      if (!(this.addStartTime && this.addStartDate)) {
        return
      }
      let startDateTime = moment(`${this.addStartDate}T${this.addStartTime}`)
      let newEndDateTime = startDateTime.endOf('day')
      this.addEndTime = newEndDateTime.format('HH:mm')
      this.addEndDate = newEndDateTime.format('YYYY-MM-DD')
    },
    refreshExclusions: async function () {
      let results = await DataProvider.getDeviceExclusions(this.device.device_imei)
      console.log('Exclusions:')
      console.log(results)
      if (results) {
        this.exclusions = results.data.exclusions
      }
    },
    exclusionDelete: async function (id) {
      let res = await this.$bvModal.msgBoxConfirm(
        `Are you sure you want to delete this exclusion?`,
        {
          title: 'Confirm Delete',
          okVariant: 'danger',
          centered: true
        }
      )
      if (res) {
        let response = await DataProvider.deleteExclusion(id)
        if (response.success) {
          AlertHelper.successToast('The Exclusion was successfully deleted.', 'Delete Successful')
          await this.refreshExclusions()
        } else {
          ErrorHelper.displayDataErrorToast(response)
        }
      }
    },
    saveChanges: async function () {
      if (this.$v.$anyError) {
        ErrorHelper.displayGeneralErrorToast('Some Fields Contain Invalid Data. Please fix them.', 'Invalid Fields')
        return
      }
      let exclusion = {
        'start': `${this.addStartDate}T${this.addStartTime}`,
        'end': `${this.addEndDate}T${this.addEndTime}`
      }

      // Update Selected icon
      try {
        let res = await DataProvider.addDeviceExclusion(
          this.device.device_imei, exclusion
        )

        if (res.success) {
          this.$v.$reset()
          this.$emit('save') // Emit a save event, since we've just saved
          AlertHelper.successToast('View Exclusion Settings Update!', 'Settings Updated')
          this.clickClose()
          this.refreshExclusions()
        } else {
          this.$bvToast.toast('Uh Oh! Something went wrong saving changes to the server. Please try again later.',
            {
              title: 'Server Communication Error',
              variant: 'danger',
              toaster: 'b-toaster-top-center'
            })
        }
      } catch (err) {
        console.error(err)
        this.$bvToast.toast('An Error Occurred Getting a Device List from the Server.',
          {
            title: 'Server Communication Error',
            variant: 'danger',
            toaster: 'b-toaster-top-center'
          })
      }
    },
    clickClose: async function () {
      if (this.$v.$anyDirty) {
        let res = await this.$bvModal.msgBoxConfirm('You have unsaved changes, are you sure you want to close this window?', {
          title: 'Confirm Discard Changes',
          okVariant: 'warning',
          centered: true
        })
        if (!res) {
          return
        }
      }
      // Check if we've been passed a modal to close.
      if (this.modal) {
        this.$bvModal.hide(this.modal)
      } else {
        this.$router.go(-1)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../variables';

.device-label {
  font-family: 'Open Sans', sans-serif;
  color: $theme-color-primary-3;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
}

.bootstrap-timepicker-widget.dropdown-menu {
  z-index: 1050 !important;
}

.footer {
  display: flex;
  flex-direction: row-reverse;
}

.footer button {
  display: inline-block;
}
</style>
