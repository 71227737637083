var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return this.deviceList
    ? _c(
        "div",
        { staticClass: "device-selector" },
        [
          _vm.dropdown_mode
            ? _c(
                "b-dropdown",
                {
                  attrs: {
                    "split-variant": "outline-success",
                    text: _vm.dropdownText,
                    split: "",
                    variant: "success"
                  }
                },
                [
                  _c(
                    "b-dropdown-form",
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: {
                            checked: _vm.deviceSelectionIsAll,
                            indeterminate: _vm.deviceSelectIntermediate,
                            "aria-describedby": "devices",
                            "aria-controls": "devices"
                          },
                          on: { change: _vm.toggleAllDeviceSelection }
                        },
                        [_vm._v(" All Devices ")]
                      )
                    ],
                    1
                  ),
                  _vm._l(_vm.deviceCheckboxGroupOptions, function(item, idx) {
                    return _c(
                      "b-dropdown-form",
                      { key: "drop-form-" + idx },
                      [
                        _c(
                          "b-form-checkbox",
                          {
                            attrs: {
                              checked: _vm.selectedDevices.includes(
                                item.value.toString()
                              ),
                              "aria-describedby": "devices",
                              "aria-controls": "devices"
                            },
                            on: {
                              change: function($event) {
                                return _vm.changeDropSelect(item.value, $event)
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(item.text) + " ")]
                        )
                      ],
                      1
                    )
                  })
                ],
                2
              )
            : _vm._e(),
          !_vm.dropdown_mode
            ? _c("b-select", {
                staticClass: "text-input",
                attrs: { options: _vm.deviceSelectionModeOptions },
                on: { input: _vm.selectDevice },
                model: {
                  value: _vm.currentDeviceSelectionMode,
                  callback: function($$v) {
                    _vm.currentDeviceSelectionMode = $$v
                  },
                  expression: "currentDeviceSelectionMode"
                }
              })
            : _vm._e(),
          _vm.currentDeviceSelectionMode === "selection" && !_vm.dropdown_mode
            ? _c(
                "div",
                {
                  staticClass:
                    "device-select-outer flex-column align-items-start"
                },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: {
                        checked: _vm.deviceSelectionIsAll,
                        indeterminate: _vm.deviceSelectIntermediate,
                        "aria-describedby": "devices",
                        "aria-controls": "devices"
                      },
                      on: { change: _vm.toggleAllDeviceSelection }
                    },
                    [_vm._v(" All Devices ")]
                  ),
                  _c("b-form-checkbox-group", {
                    staticClass: "flex-column align-items-start",
                    attrs: {
                      id: "devices",
                      options: _vm.deviceCheckboxGroupOptions,
                      name: "devices",
                      "aria-label": "Select Devices",
                      stacked: ""
                    },
                    on: { change: _vm.deviceCheckboxChange },
                    model: {
                      value: _vm.selectedDevices,
                      callback: function($$v) {
                        _vm.selectedDevices = $$v
                      },
                      expression: "selectedDevices"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }