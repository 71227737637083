<template>
  <div class="device-status">
    <div class="widget-container" v-if="!loading">
        <GenericStatusWidget v-for="(feature, idx) of status_widgets" v-bind:key="idx" class="col-6"
                               :device="device" :feature_code="feature" :editable="editable"
                               v-on:change="unsavedChanges = true" :ref="feature"
        ></GenericStatusWidget>
    </div>
    <div class="spacing"></div>
      <div class="footer mt-2">
        <button class="button" @click="clickClose" id="button-close">Close</button>
      </div>

  </div>
</template>

<script>

import GenericStatusWidget from '@/components/device/device_status_widgets/GenericStatusWidget'

export default {
  name: 'device-status',
  components: { GenericStatusWidget },
  props: {
    isModal: Boolean,
    modal: String,
    device: Object,
    editable: Boolean
  },
  data: function () {
    return {
      showButtons: true,
      loading: true,
      deviceFeatures: [],
      featureConfig: [],
      displayDevice: null,
      unsavedChanges: false
    }
  },
  async created () {
    // Hide Controls so the parent modal can display them
    if (this.isModal) {
      this.showButtons = false
    }
    this.deviceFeatures = await this.$deviceService.getDeviceFeatures(this.device.device_type)
    this.featureConfig = await this.$deviceService.getFeatureConfigs(this.deviceFeatures)
    this.loading = false
  },
  methods: {
    clickClose () {
      this.$bvModal.hide(this.modal)
    }
  },
  computed: {
    status_widgets () {
      console.log(this.deviceFeatures)
      console.log(this.featureConfig)
      let missingConfig = this.deviceFeatures.filter(featureCode => !Object.hasOwn(this.featureConfig, featureCode))
      if (missingConfig.length) {
        console.error('ERROR: Missing Feature configuration for feature codes: ', missingConfig)
      }
      return this.deviceFeatures
        .filter(x => this.featureConfig[x].feature_type === 'state' && this.featureConfig[x].control && this.featureConfig[x].published)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '../../variables';

  .device-label{
    font-family: 'Open Sans', sans-serif;
    color: $theme-color-primary-3;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
  }

  .device-code{
    color: $text-color-invert;
    margin-left: 5%;
  }

  .widget-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .footer{
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .icon_select_color {
    border: solid $theme-color-primary-3;
  }

</style>
