import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/Main'
import Login from '../views/Login'
import Logout from '../views/Logout'
import ForgottenPassword from '../views/ForgottenPassword'
import Missing from '../views/Missing'
import * as config from '../config'
import AuthService from '@/components/helpers/AuthService'
import ServerDown from '@/views/ServerDown'
import Register from '@/views/Register'


Vue.use(VueRouter)
const Auth = new AuthService()

const routes = [
  {
    path: '/',
    component: Main,
    meta: {
      // requiredRoles: config.roles.fleetscreen
    },
    children: [
      {
        path: '',
        name: 'fleet',
        component: () => import('../views/Fleet.vue'),
        meta: {
          requiredRoles: config.roles.fleetscreen
        }
      },
      {
        path: 'device/:id',
        name: 'device',
        component: () => import('../views/Device.vue')
      },
      {
        path: 'fleet-manager',
        name: 'fleet-manager',
        meta: {
          requiredRoles: config.roles.fleetscreen
        },
        component: () => import('../views/FleetManager.vue')
      },
      {
        path: 'warboard',
        meta: {
          requiredRoles: config.roles.fleetscreen
        },
        component: () => import('../views/Warboard.vue')
      },
      {
        path: 'trips',
        name: 'trips',
        meta: {
          requiredRoles: config.roles.fleetscreen
        },
        component: () => import('../views/Trips.vue')
      },
      {
        path: 'forms',
        name: 'forms',
        meta: {
          requiredRoles: config.roles.fleetscreen
        },
        component: () => import('../views/Forms.vue')
      },
      {
        path: 'dashboard',
        name: 'dashboard',
        meta: {
          requiredRoles: config.roles.fleetscreen
        },
        component: () => import('../views/DashboardView.vue')
      },
      {
        path: 'stats',
        name: 'stats',
        meta: {
          requiredRoles: config.roles.fleetscreen
        },
        component: () => import('../views/Stats.vue')
      },
      {
        path: 'reports',
        name: 'reports',
        meta: {
          requiredRoles: config.roles.fleetscreen
        },
        component: () => import('../views/Reports.vue')
      },
      {
        path: 'reports/:id',
        name: 'reports_view',
        meta: {
          requiredRoles: config.roles.fleetscreen
        },
        component: () => import('../views/ReportView.vue')
      },
      {
        path: 'reports/:id/edit',
        name: 'reports_edit',
        meta: {
          requiredRoles: config.roles.admin
        },
        component: () => import('../views/ReportEdit.vue')
      },
      {
        path: 'preferences',
        component: () => import('../views/Preferences.vue'),
        meta: {
          subscriptionExempt: true,
          requiresAuth: true
        }
      },
      {
        path: 'subscribe',
        component: () => import('../views/SetupSubscription.vue'),
        meta: {
          subscriptionExempt: true,
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: '/admin',
    component: Main,
    meta: {
      requiredRoles: config.roles.admin
    },
    children: [
      {
        path: '',
        component: () => import('../views/Admin.vue')
      },
      {
        path: 'users',
        component: () => import('../views/admin/AdminUsers.vue')
      },
      {
        path: 'devices',
        component: () => import('../views/admin/AdminDevices.vue')
      },
      {
        path: 'archived-devices',
        component: () => import('../views/admin/AdminArchivedDevices.vue')
      },
      {
        path: 'devices/unassigned',
        component: () => import('../views/admin/AdminUnassignedDevices.vue')
      },
      {
        path: 'devices/assign',
        component: () => import('../views/admin/AdminAssignDevices.vue')
      },
      {
        path: 'devices/bulkadd',
        component: () => import('../views/admin/AdminBulkAddDevice.vue')
      },
      {
        path: 'devices/unhealthy',
        component: () => import('../views/admin/AdminUnhealthyDevices.vue')
      },
      {
        path: 'devices/commands',
        component: () => import('../views/admin/AdminBulkCommandDevices.vue')
      },
      {
        path: 'users/bulkadd',
        component: () => import('../views/admin/AdminBulkAddUser.vue')
      },
      {
        path: 'users/billing',
        component: () => import('../views/admin/AdminUsersBilling.vue')
      },
      {
        path: 'user/:user_id',
        component: () => import('../views/admin/AdminUserEdit.vue')
      },
      {
        path: 'queue-health',
        component: () => import('../views/admin/AdminQueueHealth.vue')
      },
      {
        path: 'perf-stats',
        component: () => import('../views/admin/AdminPerformanceStats.vue')
      },
      {
        path: 'products',
        component: () => import('../views/admin/AdminProducts.vue')
      },
      {
        path: 'shop-products',
        component: () => import('../views/admin/AdminShopProducts.vue')
      },
      {
        path: 'shop-product/:shop_product_id',
        component: () => import('../views/admin/AdminShopProductEdit.vue')
      },
      {
        path: 'shop-orders',
        component: () => import('../views/admin/AdminShopOrders.vue')
      },
      {
        path: 'shop-order/:order_id',
        component: () => import('../views/admin/AdminShopOrderEdit.vue')
      },
      {
        path: 'system-controls',
        component: () => import('../views/admin/AdminSystemCtrl.vue')
      },
      {
        path: 'sim-cards',
        component: () => import('../views/admin/AdminSIMCards.vue')
      },
      {
        path: 'tasks',
        component: () => import('../views/admin/AdminTasks.vue')
      },
      {
        path: 'rq-status',
        component: () => import('../views/admin/AdminRQStatus.vue')
      },
      {
        path: 'stats-coverage',
        component: () => import('../views/admin/AdminStatsCoverage.vue')
      },
      {
        path: 'templates',
        component: () => import('../views/admin/AdminTemplates.vue')
      },
      {
        path: 'templates/:template_id',
        component: () => import('../views/admin/AdminTemplateEdit.vue')
      },
      {
        path: 'forms',
        component: () => import('../views/admin/AdminFormsData.vue')
      },
      {
        path: 'links',
        component: () => import('../views/admin/AdminLinks.vue')
      },
      {
        path: 'links/:link_id',
        component: () => import('../views/admin/AdminLinkDataEdit.vue')
      }
    ]
  },
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout
  },
  {
    path: '/forgotten_password',
    name: 'forgotten_password',
    component: ForgottenPassword
  },
  {
    path: '/activate_account',
    name: 'activate_account',
    component: ForgottenPassword
  },
  {
    path: '/connectivity',
    name: 'connectivity',
    component: ServerDown
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import('../views/Terms'),
    meta: {
      subscriptionExempt: true
    }
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('../views/Privacy'),
    meta: {
      subscriptionExempt: true
    }
  },
  {
    path: '/test',
    component: Main,
    meta: {
      requiredRoles: config.roles.admin
    },
    children: [
      {
        path: '',
        component: () => import('../views/test/TestIndex.vue')
      },
      {
        path: 'forms',
        component: () => import('../views/test/TestForms.vue')
      },
      {
        path: 'modals',
        component: () => import('../views/test/TestModals.vue')
      }
    ]
  },
  {
    path: '*',
    name: 'missing',
    component: Missing
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(globalGuard)

function buildURL (basePath, queryParams) {
  let url = new URL(basePath, `${window.location.protocol}${window.location.host}`)
  Object.entries(queryParams).forEach(([k, v]) => {
    url.searchParams.set(k, v)
  })
  return url.pathname + url.search
}

// Function that is executed before each navigation event. Determines if a user can do to a page.
async function globalGuard (to, from, next) {
  // Allow admins to go wherever they want, but log a console message, so they know
  if (await Auth.roleIn(config.roles.admin)) {
    console.log('Router: Admin Override. Allowing Route.')
    return next()
  }
  if (to.matched.some(record => record.meta.requiredRoles || record.meta.requiresAuth)) {
    // If the user isn't logged in they have no roles!
    let query = {}
    if (!Auth.getToken()) {
      query = { }
      if ('token' in to.query) {
        query['token'] = to.query['token']
        // Scrub token from URL if the user is already logged in
        delete to.query['token']
      }
      query['returnUrl'] = buildURL(to.path, to.query)
      console.log({ query })
      console.log('token' in query)

      if ('token' in query) {
        console.log('Attempting a login by URL token...')
        try {
          await Vue.prototype.$auth.setToken(query.token)
          await Vue.prototype.$auth.queryUserId()
          return next()
        } catch (e) {
          console.error(e) // Will fall through to redirecting to login
        }
      }
      return next({ path: '/login', query: query })
    } else if (!await Auth.meetsSubscriptionRequirement() && !to.matched.some(record => record.meta.subscriptionExempt)) {
      return next({ path: '/subscribe?reason=invalid' })
    }
  }
  for (let route of to.matched) {
    if (route.meta.requiredRoles) {
      if (!await Auth.roleIn(route.meta.requiredRoles)) {
        console.log('Required roles not found, redirecting user to Preferences page...', route.path)
        return next({ path: '/preferences', query: { returnUrl: to.fullPath } })
      }
    }
  }
  next()
}

export default router
