<template>
  <div class="form-avatar-container" v-if="template" :class="{'clickable': !disabled, 'icon': icon}">
    <div class="form-image">
      <i class="template-icon fa" :class="cformIconClass" :style="'color: ' + cformIconColor + ';'"></i>
    </div>
    <div class="detail-outer">

        {{cName}}

      <div class="detail-inner" v-show="!hide_description && !icon">
        <div>{{cDesc}}</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'FormTemplateAvatar',
  props: {
    template: Object,
    hide_description: Boolean,
    disabled: Boolean,
    icon: Boolean
  },
  data () {
    return {

    }
  },
  async mounted () {

  },
  methods: {

  },
  computed: {
    cName () {
      return this.template.name || 'Prestart 1234'
    },
    cDesc () {
      return this.template.description || ''
    },
    cformIconClass () {
      return this.template.icon || 'fa-folder-o'
    },
    cformIconColor () {
      return this.template.color || '#FFFFFF'
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/variables';

.form-avatar-container {
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  padding: 5px 10px;
  align-items: center;
  justify-content: space-between;
  background: $theme-color-background-4;

}

.form-avatar-container.icon {
  flex-direction: column;

  .form-image{
    .template-icon {
      font-size: 1em;
    }
  }
  .detail-outer {
    font-size: 1em;
  }
}

.device-image img {
  width: 100%;
}

.detail-outer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-weight: 600;
  color: $theme-color-primary-3;
  font-size: 24px;
}

.detail-inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  font-size: 0.6em;
  color: white;
  font-weight: 500;
}

.template-icon {
  font-size: 2em;
  padding: 5px;
  border-radius: 5px;
  width: 1.5em;
}

.clickable:active, .clickable:focus {
  outline: 2px solid #9aea92;
  border-color: #9aea92;
  background-color: rgba(150,150,150,0.25);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out, outline-color 0.15s ease-in-out;
}


/* Very Narrow Screens */
@media screen and (max-width: 300px) {
  .device-container {
    padding: 5px 5px;
  }

  .status-icons {
    display: none;
  }
}
</style>
