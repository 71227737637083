var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "trip-playback-control flex-row flex-grow-1 flex-nowrap w-100"
    },
    [
      _c(
        "div",
        { staticClass: "flex-column flex-grow-1 align-items-stretch" },
        [
          _c(
            "div",
            {
              staticClass:
                "flex-row flex-grow-1 time-control-container position-relative"
            },
            [
              _c(
                "div",
                {
                  ref: "timelinePopover",
                  staticClass: "time-popover flex-column",
                  class: { active: _vm.showTimePopover },
                  style: "transform: translateX(" + _vm.timePopOffset + "px);"
                },
                [
                  _vm.warningIcons
                    ? _c(
                        "div",
                        _vm._l(_vm.warningIcons, function(warningIcon, idx) {
                          return _c("span", { key: "w-icon-" + idx }, [
                            _c("i", { class: warningIcon.classes }),
                            _vm._v(" " + _vm._s(warningIcon.title) + " ")
                          ])
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm._v(" " + _vm._s(_vm.currentTime) + " ")
                ]
              ),
              _c(
                "div",
                {
                  ref: "timelineContainer",
                  staticClass: "w-100 time-control-parent"
                },
                [
                  _c(
                    "div",
                    { staticClass: "timeline-bg" },
                    _vm._l(_vm.timelineBackground, function(segment, idx) {
                      return _c("div", {
                        directives: [
                          { name: "b-tooltip", rawName: "v-b-tooltip" }
                        ],
                        key: "tlbg-" + idx,
                        staticClass: "timeline-bg-segment",
                        class: "timeline-bg-" + segment.color,
                        style: "width: " + segment.width + "px;",
                        attrs: { title: "BACKGROUND SEGMENT!" }
                      })
                    }),
                    0
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.timelineIdx,
                        expression: "timelineIdx"
                      }
                    ],
                    staticClass: "w-100 time-control",
                    attrs: {
                      disabled: !_vm.hasRouteData,
                      min: "0",
                      max: _vm.timelineMax,
                      type: "range",
                      list: "steplist"
                    },
                    domProps: { value: _vm.timelineIdx },
                    on: {
                      input: _vm.onTimelineChange,
                      __r: function($event) {
                        _vm.timelineIdx = $event.target.value
                      }
                    }
                  }),
                  _c(
                    "datalist",
                    { attrs: { id: "steplist" } },
                    _vm._l(_vm.timelineDataset, function(timelineOption, idx) {
                      return _c(
                        "option",
                        {
                          key: idx,
                          attrs: {
                            label: timelineOption.label,
                            hidden: timelineOption.hidden
                          }
                        },
                        [_vm._v(" " + _vm._s(timelineOption.value) + " ")]
                      )
                    }),
                    0
                  )
                ]
              )
            ]
          ),
          _c(
            "div",
            { staticClass: "flex-row flex-grow-1 justify-content-between" },
            [
              _c("i", {
                staticClass: "icon-button",
                class: _vm.$config.icons.playback.rewind,
                on: {
                  click: function($event) {
                    return _vm.setTimelineIdx(0)
                  }
                }
              }),
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.playbackTimer,
                    expression: "!playbackTimer"
                  }
                ],
                staticClass: "icon-button",
                class: _vm.$config.icons.playback.play,
                on: { click: _vm.startPlayback }
              }),
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.playbackTimer,
                    expression: "playbackTimer"
                  }
                ],
                staticClass: "icon-button",
                class: _vm.$config.icons.playback.pause,
                on: { click: _vm.pausePlayback }
              }),
              _c("i", {
                staticClass: "icon-button",
                class: _vm.$config.icons.playback.fastForward,
                on: {
                  click: function($event) {
                    return _vm.setTimelineIdx(_vm.routeData.length - 1)
                  }
                }
              }),
              _c("div", { staticClass: "flex-row" }, [
                _c("label", [_vm._v("Speed")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.speedValue,
                      expression: "speedValue"
                    }
                  ],
                  staticClass: "p-0",
                  attrs: { type: "range", min: "0", max: "90" },
                  domProps: { value: _vm.speedValue },
                  on: {
                    __r: function($event) {
                      _vm.speedValue = $event.target.value
                    }
                  }
                })
              ]),
              _c(
                "div",
                { staticClass: "flex-row" },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: { name: "check-button", switch: "" },
                      model: {
                        value: _vm.followMarker,
                        callback: function($$v) {
                          _vm.followMarker = $$v
                        },
                        expression: "followMarker"
                      }
                    },
                    [_vm._v("Follow ")]
                  )
                ],
                1
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }