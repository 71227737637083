/*
    Loading Spinner
    Displays a spinner and translucent overlay on its parent div while the *loading*
    property is *true*.
*/
<template>
  <div v-if="loading" class="loading-parent">
    <div class="background"></div>
    <div class="spinner-container">
      <div class="loading-text" :style="textStyle">{{this.displayMessage}}</div>
      <b-spinner class="spinner" style="width: 3rem; height: 3rem;" ></b-spinner>
    </div>
  </div>

</template>

<script>

export default {
  name: 'loading-box',
  props: {
    loading: Boolean,
    message: String,
    fontsize: String
  },
  data: function () {
    return {
      showSpinner: true,
      textStyle: {
        fontSize: '3em'
      }
    }
  },
  mounted () {
    if (this.fontsize) {
      this.textStyle.fontSize = this.fontsize
    }
  },
  computed: {
    displayMessage: function () {
      if (this.message) {
        return this.message
      } else {
        return 'Loading...'
      }
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '../../variables';

  .loading-parent {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }

  .spinner-container {
    z-index: 99;
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    color: $theme-color-primary-2;
    transform: translate(-50%, -50%);
  }

  .loading-text {
    //margin-top: 30%;
    font-weight: 600;
    //font-size: 3em;
    color: $text-color-invert;
    -webkit-text-stroke-color: #343434;
    -webkit-text-stroke-width: 1px;
  }

  .background {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(2px);
    background: rgba(0, 0, 0, 0.25);
    z-index: 10;
  }

  .spinner {
    margin: 0 auto;
  }

</style>
