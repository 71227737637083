/* Protek Webapp Configuration file */

export const auth = {
  // How long to trust roles data before refreshing
  userDataCacheSeconds: process.env.VUE_APP_AUTH_USER_CACHE_SECONDS,
  activeSubscriptionStates: ['active', 'trialing', 'new'],
  actionRequiredSubscriptionStates: ['past_due', 'unpaid', 'incomplete'],
  canceledSubscriptionStates: ['canceled', 'incomplete_expired', 'ended'],
  // Below are states which are technically inactive, but can be remedied (mostly by payment).
  recoverableSubscriptionStates: ['active', 'trialing', 'past_due', 'overdue', 'incomplete', 'new']
}

export const geolimit = {
  precision: 100,
  max: 999900,
  min: 100
}

export const queueHealth = {
  warningThreshold: 200,
  dangerThreshold: 500,
  updateInterval: 5000
}

export const general = {
  // Update Timers are measured in 1000ms ticks.
  deviceUpdateDelay: 30,
  livetrackUpdateDelay: 5,
  // Tooltip show/hide delays
  tooltipShowDelay: 500,
  tooltipHideDelay: 200,
  notificationDataCacheTime: 5, // Seconds to keep the notification Data cached (NOTE: SECONDS)
  notificationCheckInternal: process.env.VUE_APP_NOTIFICATION_INTERVAL, // How frequently we check the server for new notifications (NOTE: milliseconds)
  notificationMaxAlertDisplay: 3, // Maximum number of alerts before switching to a count
  defaultZoomLevel: 18, // Default Zoom level google maps will use.
  minErrorAlertTime: 10000 // Min delay between displaying error toast
}

export const stats = {
  reportStartDate: '2019/1/1', // First date to show for report date pickers
  reportMonthsCap: 32 // Hard Cap on the number of reporting months to show.
}

// Icons for Admin System
export const icons = {
  general: {
    back: 'fa fa-arrow-left',
    add: 'fa fa-plus-circle',
    remove: 'fa fa-minus-circle',
    edit: 'fa fa-pencil-square-o',
    assign: 'fa fa-arrow-circle-o-down',
    unassign: 'fa fa-arrow-circle-o-up',
    alarm: 'fa fa-bullhorn',
    refresh: 'fa fa-refresh',
    info: 'fa fa-info-circle',
    sendNotification: 'fa fa-paper-plane',
    products: 'fa fa-shopping-basket',
    export: 'fa fa-file-text-o',
    success: 'fa fa-check-circle-o',
    failure: 'fa fa-times-circle-o',
    unknown: 'fa fa-circle-o',
    trips: 'fa fa-car',
    alert: 'fa fa-exclamation-triangle',
    file: 'fa fa-file-o',
    locations: 'fa fa-crosshairs',
    paste: 'fa fa-clipboard',
    copy: 'fa fa-files-o',
    clearClipboard: 'fa fa-times',
    dataTable: 'fa fa-table',
    print: 'fa fa-print',
    repeat: 'fa fa-repeat'
  },
  nav: {
    fleet: 'fa fa-map-marker',
    fleetManager: 'fa fa-wrench',
    stats: 'fa fa-bar-chart',
    reports: 'fa fa-line-chart',
    notifications: 'fa fa-commenting',
    preferences: 'fa fa-user',
    admin: 'fa fa-cogs',
    signout: 'fa fa-sign-out',
    warboard: 'fa fa-television',
    trips: 'fa fa-road',
    forms: 'fa fa-file-text-o',
    dashboard: 'fa fa-pie-chart'
  },
  user: {
    base: 'fa fa-user',
    add: 'fa fa-user-plus',
    remove: '',
    bulkAdd: 'fa fa-upload'
  },
  device: {
    base: 'fa fa-car', // The icon to use as the base for composite icons (e.g. Add Device)
    assign: 'fa fa-arrow-circle-o-down',
    bulkAdd: 'fa fa-upload',
    unhealthy: 'fa fa-medkit',
    commands: 'fa fa-keyboard-o',
    sim: 'fa fa-phone-square',
    archived: 'fa fa-book'
  },
  messaging: {
    to_device: 'fa fa-arrow-right to_device_indicator',
    from_device: 'fa fa-arrow-left from_device_indicator',
    data: 'fa fa-wifi',
    sms: 'fa fa-envelope'
  },
  states: {
    success: 'fa fa-check-circle success',
    unknown: 'fa fa-question-circle',
    failed: 'fa fa-times-circle',
    partial: 'fa fa-check-circle-o',
    none: 'fa fa-circle-o'
  },
  twisties: {
    collapsed: 'fa fa-caret-left',
    collapsed_before: 'fa fa-caret-right',
    expanded: 'fa fa-caret-down'
  },
  shopify: {
    shopProducts: 'fa fa-shopping-bag',
    shopOrders: 'fa fa-shopping-cart'
  },
  admin: {
    queue_health: 'fa fa-microchip',
    performance: 'fa fa-bar-chart',
    tasks: 'fa fa-tasks',
    rq_jobs: 'fa fa-caret-square-o-right',
    templates: 'fa fa-file-text-o',
    forms: 'fa fa-files-o',
    links: 'fa fa-link'
  },
  events: {
    'default': 'fa fa-exclamation-triangle'
  },
  feature_controls: {
    confirmed: 'fa fa-check-circle success',
    sent: 'fa fa-question-circle',
    failed: 'fa fa-times-circle'
  },
  stats: {
    runReport: 'fa fa-play',
    runReportOptions: 'fa fa-step-forward',
    exportCSVFile: 'fa fa-file-excel-o',
    copy: 'fa fa-files-o',
    config: 'fa fa-cogs',
    coverage: 'fa fa-calendar',
    next: 'fa fa-forward',
    previous: 'fa fa-backward'
  },
  warboard: {
    config: 'fa fa-cogs'
  },
  form_states: {
    new: 'fa fa-check-circle-o',
    unknown: 'fa fa-check-circle-o',
    review_required: 'fa fa-question-circle',
    complete: 'fa fa-check-circle'
  },
  playback: {
    play: 'fa fa-play',
    pause: 'fa fa-pause',
    rewind: 'fa fa-step-backward',
    fastForward: 'fa fa-step-forward',
    next: 'fa fa-step-forward',
    previous: 'fa fa-backward'
  }
}

export const configHelper = {
  refreshTimes: {
    devices: process.env.VUE_APP_CONFIG_REFRESH_DEVICES,
    events: process.env.VUE_APP_CONFIG_REFRESH_EVENTS,
    tlvCodes: process.env.VUE_APP_CONFIG_REFRESH_TLV
  }
}

export const trips = {
  // Boolean tags that are available for trips
  tags: [
    {
      propName: 'is_offroad',
      text: 'Trip was Off-Road?',
      title: 'Offroad Tag'
    }
  ]
}

// Which roles can see which features. Must be consistent with REST API settings.
export const roles = {
  admin: ['admin'],
  user: ['admin', 'user', 'fleet_user'],
  fleetscreen: ['admin', 'fleet_user'],
  power_user: ['admin', 'power_user'],
  forms: ['admin', 'forms']
}

export const stripeKey = 'pk_live_51Gx3syLOjleZM3QByRD5U7hQI0479P9918VyCXWgHakdeIGOxAmPVuMuboYRl6Z2dNJLgIXlYqqBM3X3ddhCvGED00gQMzhsJj'
