var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "register-user" }, [
    !_vm.loading
      ? _c(
          "div",
          {},
          [
            _c(
              "b-form",
              {
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.registerUser()
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "col s4",
                    class: { "form-error": _vm.$v.fullname.$error }
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "green-label",
                        attrs: { for: "full_name" }
                      },
                      [_vm._v("Full Name")]
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.$v.fullname.$model,
                          expression: "$v.fullname.$model"
                        }
                      ],
                      staticClass: "w-100",
                      attrs: {
                        type: "text",
                        maxlength: "256",
                        placeholder: "Full Name",
                        id: "full_name"
                      },
                      domProps: { value: _vm.$v.fullname.$model },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.$v.fullname,
                            "$model",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    !_vm.$v.fullname.required
                      ? _c("div", { staticClass: "input-error" }, [
                          _vm._v("Field is Required")
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "green-divider" })
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "col s4",
                    class: { "form-error": _vm.$v.email.$error }
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "green-label",
                        attrs: { for: "email_address" }
                      },
                      [_vm._v("Email Address")]
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.$v.email.$model,
                          expression: "$v.email.$model"
                        }
                      ],
                      staticClass: "w-100",
                      attrs: {
                        type: "text",
                        maxlength: "256",
                        placeholder: "Email Address",
                        id: "email_address"
                      },
                      domProps: { value: _vm.$v.email.$model },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.$v.email, "$model", $event.target.value)
                        }
                      }
                    }),
                    !_vm.$v.email.required
                      ? _c("div", { staticClass: "input-error" }, [
                          _vm._v("Field is Required")
                        ])
                      : _vm._e(),
                    !_vm.$v.email.email
                      ? _c("div", { staticClass: "input-error" }, [
                          _vm._v("Must contain a valid email address")
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "green-divider" })
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "col s4",
                    class: { "form-error": _vm.$v.mobile.$error }
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "green-label",
                        attrs: { for: "mobile_number" }
                      },
                      [_vm._v("Mobile Phone")]
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.$v.mobile.$model,
                          expression: "$v.mobile.$model"
                        }
                      ],
                      staticClass: "w-100",
                      attrs: {
                        type: "text",
                        maxlength: "256",
                        placeholder: "Mobile Number",
                        id: "mobile_number"
                      },
                      domProps: { value: _vm.$v.mobile.$model },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.$v.mobile, "$model", $event.target.value)
                        }
                      }
                    }),
                    !_vm.$v.mobile.required
                      ? _c("div", { staticClass: "input-error" }, [
                          _vm._v("Field is Required")
                        ])
                      : _vm._e(),
                    !_vm.$v.mobile.minLength
                      ? _c("div", { staticClass: "input-error" }, [
                          _vm._v("Phone number must be at least 10 numbers.")
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "green-divider" })
                  ]
                ),
                _c("div", { staticClass: "footer mt-2" }, [
                  _c(
                    "button",
                    {
                      staticClass: "button w-100 mt-5",
                      attrs: { type: "submit", id: "button-save" }
                    },
                    [_vm._v("Register")]
                  )
                ])
              ]
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }