<template>
  <div class="control">
    <label :class="labelClass" @click="handleChange(control.path, !control.data)">{{control.label}}</label>
    <div class="wrapper">
      <select v-if="controlMode === 'select'"
              :id="control.id + '-select'"
              class="form-select form-control flex-grow-1"
              :value="control.data"
              :disabled="!control.enabled"
              v-model="control.data"
              @change="handleChange(control.path, control.data)"
              @focus="isFocused = true"
              @blur="isFocused = false"

      >
        <option key="empty" :value="undefined" label="Select an Option" disabled/>
        <option
            v-for="optionElement in controlOptions"
            :key="optionElement.value"
            :value="optionElement.value"
            :label="optionElement.label"
        ></option>
      </select>
    </div>
    <div v-if="controlMode === 'radio'" class="wrapper-radio">
      <div class="form-check mb-1" v-for="optionElement in controlOptions"
           :key="optionElement.value">
        <input @change="handleChange(control.path, optionElement.value)" :disabled="!control.enabled"
            class="form-check-input mt-0" type="radio" :value="optionElement.value"
            :checked="control.data === optionElement.value">
        <label :class="btnClass" class="form-check-label ml-2" for="exampleRadios1">
          {{optionElement.label}}
        </label>
      </div>
    </div>

    <div v-if="controlMode === 'button-group'" class="wrapper-radio">
      <b-form-radio-group buttons class="form-check mb-1" v-model="control.data"
                          >
        <b-form-radio v-for="optionElement in controlOptions" :key="optionElement.value" :class="btnClass"
                      @change="handleChange(control.path, optionElement.value)"
                      :disabled="!control.enabled" :value="optionElement.value"
        >
          {{optionElement.label}}
        </b-form-radio>
      </b-form-radio-group>
    </div>
    <div class="description">
      {{ errors ? errors : showDescription ? description : null }}
    </div>
  </div>

</template>

<script>

import {
  rendererProps,
  useJsonFormsControl,
} from '@jsonforms/vue2'
import {isDescriptionHidden, isEnumControl, rankWith} from '@jsonforms/core'

const renderer = {
  name: 'EnumControlRendererNew',
  components: {},
  props: {
    ...rendererProps(),
    styles: {},
    errors: {},
    description: {}
  },
  setup(props) {
    return useJsonFormsControl(props)
  },
  mounted () {
  },
  methods: {
  },
  computed: {
    labelClass() {
      if (Object.hasOwn(this.uischema, 'options')) {
        if (Object.hasOwn(this.uischema.options, 'value_variants') &&
          Object.keys(this.uischema.options.value_variants).includes(this.control.data)) {
          return this.uischema.options.value_variants[this.control.data]
        } else {
          return 'success'
        }
      } else {
        return 'success'
      }
    },
    btnClass() {
      if (this.labelClass !== '') {
        return 'button-' + this.labelClass
      } else {
        return ''
      }
    },
    controlMode () {
      if (Object.hasOwn(this.uischema, 'options')) {
        return this.uischema.options.format || 'select'
      } else {
        return 'select'
      }
    },
    controlOptions () {
      // TODO - Make this pretty
      return this.control.schema.enum.map((value) => {
        return {
          value: value,
          label: value
        }
      })
    },
    showDescription() {
      return !isDescriptionHidden(
        this.visible,
        this.description,
        this.isFocused,
        !!this.appliedOptions?.showUnfocusedDescription
      )
    },
  }
}
export default renderer

export const enumRendererEntry = {
  renderer: renderer,
  tester: rankWith(3, isEnumControl),
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '@/variables';
.form-check {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.form-check-input {
  width: 1.6em;
  height: 1.6em;
}

.form-check label {
  //font-size: 1.6em;
}

.wrapper-radio {
  flex-direction: column;
  align-items: flex-start;
}

.btn-primary {
  background-color: grey;
  border-color: white;
}

.warning {
  color: #e5bf00;
}

.danger {
  color: #ff4848;
}

.button-danger.active {
  background-color: #ff4848!important;
  color: #5d0202!important;
  border-color: #bb0101!important;
}

.button-warning.active {
  background-color: #e5bf00!important;
  color: #483101!important;
  border-color: #bdbd04;
}

.button-success.active{
  background-color: $theme-color-primary-3!important;
  color: #074200!important;
  border-color: #01bb0a !important;
}

.button-primary {
  color: black;
  text-decoration: underline;
  background-color: $theme-color-primary-4;
  border-color: $theme-color-primary-3;
  box-shadow: none;
}

</style>
