var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "forgotten-password" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "login-container justify-content-center mt-5" },
      [
        _c("forgotten-password-box", {
          staticClass: "login-item",
          attrs: { code: _vm.resetCode, email: _vm.email }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "footer" },
      [
        _c("router-link", { attrs: { to: "terms" } }, [
          _vm._v("Terms and Conditions")
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex justify-content-center" }, [
      _c("img", {
        staticClass: "logo",
        attrs: { src: require("../assets/Pro-tekt-logo.png") }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }