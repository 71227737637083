<template>
<div class="disconnect-popup "  :class="{'animate-in slide-left': show, 'animate-out-fade': !show}">
  <div class="disconnect-inner flex-row">
    <i class="fa fa-plug animate-pulse mr-2"></i>
    Server Connectivity Problem
  </div>
</div>

</template>
<script>

export default {
  name: 'DisconnectPopup',
  props: {
    show: Boolean
  },
  created() {
    console.log('I LIVE!')
  },
  mounted() {
    console.log('CREATE DISCONNECT MESSAGE!!!')
    console.log(this.show)
  },
  data: function () {
    return {
      things: 'stuff'
    }
  },
  watch: {
    show(newVal) {
      console.log('show!', this.newVal)
    }
  }
}

</script>
<style scoped lang="scss">
@import '@/variables';
@import '@/animations';

.disconnect-popup {
  position: fixed;
  font-size: 18px;
  font-weight: 600;
  z-index: 1000;
  top: 5px;
  right: 0;
  padding: 5px;
  border-radius: 5px 0 0 0 ;
  background: $theme-color-background-4;
  box-shadow: -5px 5px 5px black;
}

.disconnect-inner {
  border-radius: 10px;
  padding: 5px;
  border: 1px solid $theme-color-primary-3;
  i {
    font-size: 32px;
  }
}
</style>
