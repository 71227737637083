<template>
  <div class="trip-playback-control flex-row flex-grow-1 flex-nowrap w-100">
    <div class="flex-column flex-grow-1 align-items-stretch">
      <!--            Timeline -->
      <div class="flex-row flex-grow-1 time-control-container position-relative">
        <div class="time-popover flex-column" ref="timelinePopover" :class="{'active': showTimePopover}"
             :style="`transform: translateX(${timePopOffset}px);`"
        >
          <div v-if="warningIcons">
            <span v-for="(warningIcon, idx) of warningIcons" v-bind:key="'w-icon-' + idx"
            >
              <i :class="warningIcon.classes"></i>
              {{ warningIcon.title }}
            </span>

          </div>
          {{ currentTime }}
        </div>
        <div class="w-100 time-control-parent" ref="timelineContainer">
          <div class="timeline-bg">
            <div v-for="(segment, idx) of timelineBackground" v-bind:key="'tlbg-' + idx"
                 :class="'timeline-bg-' + segment.color"
                 :style="`width: ${segment.width}px;`"
                 class="timeline-bg-segment"
                 v-b-tooltip title="BACKGROUND SEGMENT!"
            >
            </div>
          </div>

          <input class="w-100 time-control"
                 v-model="timelineIdx"
                 @input="onTimelineChange"
                 :disabled="!hasRouteData"
                 min="0" :max="timelineMax"
                 type="range" list="steplist">
          <datalist id="steplist">
            <option v-for="(timelineOption, idx) of timelineDataset" v-bind:key="idx"
                    :label="timelineOption.label" :hidden="timelineOption.hidden">
              {{ timelineOption.value }}
            </option>
          </datalist>
        </div>

      </div>
      <!--            Control Buttons -->
      <div class="flex-row flex-grow-1 justify-content-between">
        <!--        <i class="icon-button" :class="$config.icons.playback.previous"></i>-->
        <i class="icon-button" :class="$config.icons.playback.rewind" @click="setTimelineIdx(0)"></i>
        <i class="icon-button" :class="$config.icons.playback.play"
           v-show="!playbackTimer"
           @click="startPlayback"></i>
        <i class="icon-button" :class="$config.icons.playback.pause"
           v-show="playbackTimer"
           @click="pausePlayback"></i>
        <i class="icon-button" :class="$config.icons.playback.fastForward"
           @click="setTimelineIdx(routeData.length - 1)"></i>
        <!--        <i class="icon-button" :class="$config.icons.playback.next"></i>-->
        <div class="flex-row">
          <label>Speed</label>
          <input class="p-0" type="range" min="0" max="90" v-model="speedValue">
        </div>
        <div class="flex-row">
          <!--          <b-form-checkbox name="check-button" switch-->
          <!--                           v-model="actualTime">Real Time-->
          <!--          </b-form-checkbox>-->
          <b-form-checkbox name="check-button" switch
                           v-model="followMarker">Follow
          </b-form-checkbox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import * as DataProvider from '@/components/helpers/DataProvider'
import LoadingBox from '@/components/helpers/LoadingBox'
import * as ErrorHelper from '@/components/helpers/ErrorHelper'
import moment from 'moment'
import PopoutMenu from '@/components/shared/PopoutMenu.vue'
import {MetricFormatter as mf} from '@/components/helpers/MetricFormatter'
import {DateTimeHelper as dt} from '@/components/helpers/DateTimeHelper'
import TripList from '@/components/trips/TripList.vue'
import ScrollTrigger from '@/components/shared/ScrollTrigger.vue'
import PopoverElement from '@/components/shared/PopoverElement.vue'
import DateSelector from '@/components/shared/DateSelector.vue'
import TripTags from '@/components/trips/TripTags.vue'
import FormListInline from '@/components/forms/FromListInline.vue'
import * as AlertHelper from '@/components/helpers/AlertHelper'
import * as config from '@/config'
import * as IconHelper from '@/components/helpers/IconHelper'

export default {
  name: 'trips-playback-control',
  components: {},
  props: {
    device: Object,
    routeData: Array,
    routeMode: String
  },
  data: function () {
    return {
      speedValue: 50,
      minSpeed: 0,
      maxSpeed: 90,
      speedMultiplier: 10, // control value * speedMultipluer ms
      useDirectionIcons: true,
      timelineIdx: 0,
      playbackTimer: null,
      actualTime: false,
      followMarker: false,
      marker: null,
      animTimer: null,
      mapMoving: false,
      listeners: null,
      showTimePopover: false,
      hideTimePopTimer: null,
      timelineBgWidth: null,
      timePopOffset: 0,
      mapDragging: false,
      stopDragCallback: null
    }
  },
  async mounted() {
    this.listeners = [
      this.$maps.addListener('dragstart', this.mapChangeStart),
      this.$maps.addListener('zoom_changed', this.mapChangeStart),
      this.$maps.addListener('idle', this.mapChangeEnd),
    ]
    document.addEventListener('keypress', this.onKeyPress)
    // React to changes in the background size
    this._bgReizeObserver = new ResizeObserver((entries) => {
      console.log('Start Resize Listener!')
      console.log(this.timelineBackground)
      this.timelineBgWidth = this.$refs.timelineContainer.clientWidth
    })
    this._bgReizeObserver.observe(this.$refs.timelineContainer)
    // react to changes in the time popup width
    this._timePopReizeObserver = new ResizeObserver((entries) => {
      console.log('Start Resize Listener!')
      this.timePopOffset = this.getTimelinePopOffset()
    })
    this._timePopReizeObserver.observe(this.$refs.timelinePopover)
  },
  beforeDestroy() {
    document.removeEventListener('keypress', this.onKeyPress)
    this.listeners.forEach(l => this.$maps.removeListener(l))
    // TODO - Remove the icons lol
    this.removeMarker()
    this._bgReizeObserver.disconnect()
    this._timePopReizeObserver.disconnect()
  },
  methods: {
    mapChangeStart() {
      this.disableAnimations()
      this.mapDragging = true
      if (this.playbackTimer) {
        clearTimeout(this.playbackTimer)
        this.stopDragCallback = () => {
          setTimeout(() => {
            this.startPlayback()
          }, this.playbackTick)
        }
      }
    },
    mapChangeEnd() {
      this.disableAnimations()
      this.mapDragging = false
      if (this.stopDragCallback) {
        this.stopDragCallback()
        this.stopDragCallback = null
      }
    },
    onKeyPress(e) {
      // keypresses without a target come in with a source as document.body, i.e presses not inside an input box.
      if (e.code === 'Space' && (e.target === document.body || this.$el.contains(e.target)) && this.routeData.length > 0) {
        if (this.playbackTimer) {
          this.pausePlayback()
        } else {
          this.startPlayback()
        }
      }
    },
    disableAnimations() {
      if (this.marker) {
        let parent = this.marker.content.parentElement.parentElement
        parent.style.transition = ''
      }
    },
    onTimelineChange(newVal) {
      this.showTimePopover = true
      console.log('New Value: ', newVal.target.value)
      if (this.hasRouteData) {
        let pos = this.routeData[newVal.target.value]
        console.log('Position', pos)
        this.drawLocation(pos)
      }
      if (this.hideTimePopTimer) {
        clearTimeout(this.hideTimePopTimer)
      }
      this.timePopOffset = this.getTimelinePopOffset()
      this.hideTimePopTimer = setTimeout(() => {
        this.showTimePopover = false
      }, 1000)
    },
    drawLocation: function (pos, lastPos = null) {
      if (!this.marker) {
        // Create a New Map Marker
        let markerData = {
          id: 'playback-position',
          title: this.device.name,
          position: {lat: pos.latitude, lng: pos.longitude},
          zIndex: 9999999
        }
        // console.log('Current Position: ', pos)
        if (this.useDirectionIcons && pos.data.direction !== null) {
          markerData.direction_arrow = pos.data.acc_on ? 'arrowCircleOn' : 'arrowCircle'
          markerData.direction_arrow_colour = pos.data.acc_on ? 'rgb(5,192,5)' : 'rgba(255,255,255, 1)'
          markerData.position.direction = pos.data.direction
        } else {
          markerData.icon = this.device.icon
        }
        this.marker = this.$maps.drawMarker(markerData)
      } else {
        // Update the existing marker
        // we're doing this at a pretty low level so we can get animations and a few clever tricks.
        let rotation = pos.data.direction / 10
        this.marker.position = {lat: pos.latitude, lng: pos.longitude}
        // HACK - Rather than animating this by force, we're getting the element
        let parent = this.marker.content.parentElement.parentElement
        if (this.followMarker) {
          parent.style.transition = 'none'
        } else {
          parent.style.transition = `transform ${this.playbackTick / 1000}s`
        }
        // Update the rotation and color of the icon based on the last position
        let img = this.marker.content.getElementsByTagName('img')[0]
        img.style.transform = `rotate(${rotation}deg)`
        img.src = IconHelper.directionIcons[pos.data.acc_on ? 'arrowCircleOn' : 'arrowCircle']
      }
      this.addMarkerIcons(this.marker.content, pos)
      if (this.followMarker) {
        this.$maps.setCenter(pos.latitude, pos.longitude, true)
      }
      // console.log(this.playbackTick)
      setTimeout(() => this.disableAnimations(), this.playbackTick)
    },
    removeMarker() {
      if (this.marker) {
        this.marker.map = null
        this.marker = null
      }
    },
    setTimelineIdx(newIdx) {
      let lastPos = this.routeData[this.timelineIdx]
      this.timelineIdx = newIdx
      let pos = this.routeData[this.timelineIdx]
      this.drawLocation(pos, lastPos)
    },
    startPlayback() {
      this.$maps.updateRouteonPan = false
      this.showTimePopover = true
      this.tickPlayback()
    },
    pausePlayback() {
      this.$maps.updateRouteonPan = true
      clearTimeout(this.playbackTimer)
      this.showTimePopover = false
      this.playbackTimer = null
    },
    tickPlayback() {
      // The HTML component returns a string... :/
      let idx = parseInt(this.timelineIdx)
      if (this.timelineIdx < this.timelineMax) {
        let lastPos = this.routeData[idx]
        idx += 1
        let pos = this.routeData[idx]
        this.timelineIdx = idx
        this.drawLocation(pos, lastPos)
        this.timePopOffset = this.getTimelinePopOffset()
        this.playbackTimer = setTimeout(() => {
          this.tickPlayback()
        }, this.playbackTick)
      }
    },
    getTimelinePopOffset() {
      if (this.$refs.timelineContainer) {
        let parentWidth = this.$refs.timelineContainer.clientWidth - 15
        return parentWidth * (this.timelineIdx / (this.routeData.length - 1)) - this.$refs.timelinePopover.clientWidth * 0.5
      } else {
        return 0
      }
    },
    getPointColor(point) {
      // Time and Speed modes, use this
      console.log(this.routeMode)
      if (this.routeMode === 'time' || this.routeMode === 'speed') {
        if (this.device.overspeed_limit && point.data.speed > this.device.overspeed_limit) {
          return 'red'
        } else {
          return point.data.acc_on ? 'green' : 'yellow'
        }
      } else {
        // idle mode uses this
        if (point.idleTime > 0) {
          return 'orange'
        } else {
          return point.data.acc_on ? 'green' : 'yellow'
        }
      }
    },
    addMarkerIcons(contentParent, point) {
      let iconContainer = document.getElementById('playbackIconContainer')
      if (iconContainer === null) {
        iconContainer = document.createElement('div')
        iconContainer.classList.add('marker-icon-container')
        iconContainer.id = 'playbackIconContainer'
        contentParent.appendChild(iconContainer)
      }
      // if (this.device.overspeed_limit && point.data.speed > this.device.overspeed_limit) {
      //   icons.push('<i class="warning fa fa-exclamation-triangle"></i>')
      // }
      // console.log('WarningIcons: ', this.warningIcons)
      // console.log('pbt: ', this.playbackTick)
      iconContainer.innerHTML = this.warningIcons.map(wIcon => `<i class="${wIcon.classes}"></i>`)
    }
  },
  computed: {
    hasRouteData() {
      return this.routeData && this.routeData.length
    },
    timelineMax() {
      if (this.routeData) {
        return this.routeData.length - 1
      } else {
        return 0
      }
    },
    timelineDataset() {
      console.log('RouteData', this.routeData)
      if (this.hasRouteData) {
        let samplePoints = [0, 25, 50, 75, 100]
        let options = samplePoints.map((point) => {
          let pIdx = Math.round((this.routeData.length - 1) * (point / 100))
          return {
            label: dt.timestampToLocalTime(this.routeData[pIdx].timestamp),
            value: pIdx
          }
        })
        console.log('Options: ', options)
        return options
      } else {
        return []
      }
    },
    /**
     * Generate an Array of background segments (width and color) to populate the back of the timeline
     * Currently just shows Ignition status
     * @return {[{color: (string), width: number}]|*[]}
     */
    timelineBackground() {
      if (this.timelineBgWidth && this.hasRouteData) {
        let parentWidth = this.timelineBgWidth - 15
        let timelineSegments = [{positions: 0, width: 0, color: this.getPointColor(this.routeData[0])}]
        let lastSegment = timelineSegments[0]
        this.routeData.forEach((point) => {
          let pointColor = this.getPointColor(point)
          if (lastSegment.color === pointColor) {
            lastSegment.positions += 1
          } else {
            lastSegment = {positions: 1, color: pointColor}
            timelineSegments.push(lastSegment)
          }
          lastSegment.width = parentWidth * (lastSegment.positions / this.routeData.length)
        })

        console.log('Timeline Segments: ', timelineSegments)
        return timelineSegments
      } else {
        return []
      }
    },
    currentTime() {
      if (this.hasRouteData) {
        return dt.timestampToLocalTime(this.routeData[this.timelineIdx].timestamp, true)
      } else {
        return '00:00:00'
      }
    },
    playbackTick() {
      return (100 - this.speedValue) * this.speedMultiplier
    },
    warningIcons() {
      if (this.routeData && this.routeData.length) {
        let currentPos = this.routeData[this.timelineIdx]
        let icons = []
        if (this.device.overspeed_limit && currentPos.data.speed > this.device.overspeed_limit) {
          icons.push({
            title: `Overspeed ${currentPos.data.speed}km/h`,
            classes: 'warning fa fa-exclamation-triangle'
          })
        }
        return icons
      } else {
        return []
      }
    }
  },
  watch: {
    device_imei: async function (newVal) {
      if (!this.deviceImei) {
        this.deviceImei = newVal
        if (this.autoload_route && this.deviceImei) {
          await this.getCurrentLocation()
          // await this.loadTodaysRoute()
        }
      }
    },
    device: async function (newVal) {
      if (!this.device) {
        this.device = newVal
        this.deviceImei = this.device.device_imei
        if (this.autoload_route && this.deviceImei) {
          await this.getCurrentLocation()
          // await this.loadTodaysRoute()
        }
      }
    },
    routeData() {
      this.timelineIdx = 0
      this.removeMarker()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '@/variables';
@import '@/animations';

.trips-control {
  margin-top: 0;
  margin-bottom: 1rem;
  text-align: left;
}

.device-icon {
  background: $theme-color-background-4;
  border: 1px solid grey;
  border-radius: 10px;
}

.vertical-tab {
  display: flex;
  text-align: center;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  color: #6c757d;
  text-decoration: none;
  padding: 5px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  background: black;
  background: linear-gradient(-90deg, #2a2a2a 0%, #2a2a2a 60%, #707070 100%);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.vertical-tab.active {
  color: #38d429 !important;
  border-color: #38d429 transparent #38d429 #38d429 !important;
}

.vertical-tab-body {
  border: 1px solid #38d429;
  border-radius: 0 10px 0 0;
}

.time-control-container {
  background: $theme-color-background-4;
  border-bottom: 1px solid grey;
}

.time-control-parent {
  input[type=range] {
    //-webkit-appearance: none;
    border: none;
    background: transparent;
  }

  input[type=range]:focus {
    outline: none;
    background: transparent;
  }

  input[type=range]::-webkit-slider-runnable-track {
    background: transparent;
    //width: 100%;
    //height: 5px;
    //cursor: pointer;
    //animate: 0.2s;
    //box-shadow: 0px 0px 0px #000000;
    //background: #2497E3;
    //border-radius: 1px;
    //border: 0px solid #000000;
  }

  input[type=range]::-webkit-slider-thumb {
    //background: transparent;
    //box-shadow: 0px 0px 0px #000000;
    //border: 1px solid black;
    //height: 22px;
    //width: 22px;
    //border-radius: 25px;
    //background: $theme-color-primary-2;;
    //cursor: pointer;
    //-webkit-appearance: none;
    ////margin-top: -7px;
    //margin-left: -5px;
  }

  input[type=range]:focus::-webkit-slider-runnable-track {
    //background: #2497E3;
  }

  input[type=range]::-moz-range-track {
    //width: 100%;
    //height: 5px;
    //cursor: pointer;
    //animate: 0.2s;
    //box-shadow: 0px 0px 0px #000000;
    background: transparent;
    //border-radius: 1px;
    //border: 0px solid #000000;
  }

  //input[type=range]::-moz-range-thumb {
  //  box-shadow: 0px 0px 0px #000000;
  //  border: 1px solid #2497E3;
  //  height: 18px;
  //  width: 18px;
  //  border-radius: 25px;
  //  background: #A1D0FF;
  //  cursor: pointer;
  //}
}

.timeline-bg {
  position: absolute;
  display: flex;
  flex-direction: row;
  z-index: 1;
  height: 15px;
  width: 100%;
  top: 0;
  margin-left: 7px;
  margin-right: 7px;
}

.timeline-bg-segment {
}

.time-control {
  padding: 0;
  background: transparent;
  position: relative;
  z-index: 2;
}

.time-control:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

//input[type='range']::-webkit-slider-thumb.time-control {
//  width: 10px;
//  -webkit-appearance: none;
//  height: 10px;
//  cursor: ew-resize;
//  background: #434343;
//  box-shadow: -80px 0 0 80px #43e5f7;
//}

datalist {
  color: white;
  user-select: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
  font-weight: 100;
  padding: 0;
  font-size: 0.8em;
  margin-top: -5px;
}

option {
  padding: 0
}

option:hover {
  background-color: transparent;
}

.time-popover {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  bottom: 75px;
  left: 5px;
  font-size: 0.9em;
  padding: 5px;
  //height: 50px;
  align-items: center;
  // width: 75px;
  background: $theme-color-background-3;
  border-radius: 10px;
  transition: opacity 1s ease-in-out;
}

.time-popover.active {
  opacity: 1;
  transition: opacity 0s ease-in-out;
}

.time-popover:before {
  pointer-events: none;
  content: "";
  position: absolute;
  top: 100%;
  transform: translateX(50%);
  right: 50%;
  width: 0;
  border-top: 20px solid $theme-color-background-3;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}

.timeline-bg-green {
  background: #0c700c;
}

.timeline-bg-yellow {
  background: #a68e2c;
}

.timeline-bg-red {
  background: #a62c2c;
}

.timeline-bg-orange {
  background: #ff6f1c;
}

</style>
