var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex-row trip-tags" },
    [
      _vm.editable
        ? _c(
            "b-dropdown",
            {
              attrs: {
                "no-caret": "",
                "toggle-class": "tag-dropdown",
                dropup: ""
              },
              on: { show: _vm.showAddMenu },
              scopedSlots: _vm._u(
                [
                  {
                    key: "button-content",
                    fn: function() {
                      return [_vm._v("+")]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                4177344164
              )
            },
            [
              _c(
                "b-dropdown-form",
                {
                  staticClass: "slim-controls flex-column",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.addCustomTag.apply(null, arguments)
                    }
                  }
                },
                [
                  _c("label", [_vm._v("Add Tags")]),
                  _c("div", { staticClass: "flex-row flex-nowrap mb-2" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.customTagText,
                          expression: "customTagText"
                        }
                      ],
                      ref: "customTagInput",
                      attrs: {
                        placeholder: "Enter a new tag..",
                        required: "",
                        type: "text",
                        minlength: "1",
                        maxlength: "16"
                      },
                      domProps: { value: _vm.customTagText },
                      on: {
                        input: [
                          function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.customTagText = $event.target.value
                          },
                          _vm.customInput
                        ]
                      }
                    }),
                    _c("button", [_vm._v("+")])
                  ]),
                  _c(
                    "div",
                    { staticClass: "flex-row" },
                    _vm._l(_vm.appendableTags, function(tag, idx) {
                      return _c(
                        "ColorTag",
                        {
                          key: "add-tag-" + idx,
                          attrs: {
                            color: tag.color,
                            text: tag.text,
                            editable: _vm.editable
                          },
                          on: {
                            click: function($event) {
                              return _vm.clickAddTag(tag)
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "tag-text" }, [
                            _vm._v(" " + _vm._s(tag.text) + " ")
                          ])
                        ]
                      )
                    }),
                    1
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._l(_vm.tags, function(tag, idx) {
        return _c(
          "ColorTag",
          {
            key: "tag-" + idx,
            staticClass: "trip-tag",
            attrs: {
              color: tag.color,
              text: tag.text,
              editable: _vm.editable,
              show_remove_icon: ""
            },
            on: {
              click: function($event) {
                return _vm.clickRemoveTag(tag)
              }
            }
          },
          [
            _c("span", { staticClass: "tag-text" }, [
              _vm._v(" " + _vm._s(tag.text) + " ")
            ])
          ]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }