var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.template
    ? _c(
        "div",
        { staticClass: "form-container" },
        [
          _vm.customAjv
            ? _c("json-forms", {
                ref: "jsonForm",
                attrs: {
                  schema: _vm.schema,
                  uischema: _vm.uiSchema,
                  data: _vm.data,
                  renderers: _vm.renderers,
                  ajv: _vm.customAjv,
                  readonly: _vm.isReadOnly
                },
                on: { change: _vm.onChange }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }