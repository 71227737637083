<template>
  <div class="geolimit-control">
    <loading-box v-bind:loading="loading"></loading-box>
    <label>Geolimit</label>
      <div class="green-divider"></div>

      <div class="">

        <b-form-checkbox size="md" name="check-button" switch id="geo-limit-switch"
                         v-model="geolimitData.active"
                         v-on:change="clickGeolimitActive"
                         v-bind:disabled="!geolimitControlEnabled">
          Enable Geolimit
        </b-form-checkbox>
        <b-tooltip target="geo-limit-switch" noninteractive variant="success" placement="left"
                   :delay="{ show: $config.general.tooltipShowDelay, hide: $config.general.tooltipHideDelay }">
          Toggle Geolimit Alarm
        </b-tooltip>
      </div>
    <label>Geolimit Radius</label>
      <input type="number" class="w-100 mt-2" v-model="inputRadius" id="geo-limit-input"
                    v-bind:min="$config.geolimit.min"
                    v-bind:max="$config.geolimit.max"
                    v-bind:step="$config.geolimit.precision"
                    :disabled="!geolimitControlEnabled">

      <b-tooltip target="geo-limit-input" noninteractive variant="success" placement="left"
                 :delay="{ show: $config.general.tooltipShowDelay, hide: $config.general.tooltipHideDelay }">
        Geolimit Radius in Meters
      </b-tooltip>

      <button class="w-100" id="set-geolimit-radius"
                @click="clickSetGeolimitRadius"
                :disabled="!geolimitControlEnabled">Set with Radius</button>
      <button class="w-100" id="set-geolimit-current"
                @click="clickSetGeolimitLocation"
                :disabled="!geolimitControlEnabled">Set to Current</button>
      <button class="w-100" id="goto-geolimit"
                @click="clickViewGeolimit"
                :disabled="!geolimitControlEnabled">Go to Geolimit</button>
    </div>
</template>

<script>

import * as DataProvider from '@/components/helpers/DataProvider'
import LoadingBox from '@/components/helpers/LoadingBox'
import * as ErrorHelper from '@/components/helpers/ErrorHelper'

export default {
  name: 'geolimit-control',
  components: { LoadingBox },
  props: {
    device: Object,
    device_imei: String,
    enableEdit: Boolean
  },
  data: function () {
    return {
      loading: true,
      deviceImei: '',
      geolimitControlEnabled: false,
      inputRadius: 0,
      geolimitData: {
        active: false,
        lat: null,
        lng: null,
        radius: null
      },
      currentPos: null,
      // Placeholder for device info
      deviceInternal: {
        geolimit_status: {}
      }
    }
  },
  async mounted () {
    this.geolimitControlEnabled = this.enableEdit

    if (this.device && this.enableEdit) {
      this.deviceImei = this.device.device_imei
      this.updateGeolimitData(await this.getGeolimitState(), false)
    } else if (this.device_imei) {
      this.deviceImei = this.device_imei
    }
    this.loading = false
  },
  async beforeDestroy () {
    this.$maps.clearGeolimit()
  },
  methods: {
    // Load Geolimit Data from Server
    getGeolimitState: async function () {
      // Load Device Geolimit
      this.loading = true
      let geoDataQuery = await DataProvider.getDeviceGeolimit(this.deviceImei)
      console.log('Geolimit: ', geoDataQuery)

      if (!geoDataQuery.success) {
        console.log('Failed to get existing Geolimit Data')
        ErrorHelper.displayDataErrorToast(geoDataQuery)
        this.geolimitControlEnabled = false
        this.geolimitData = null
        this.loading = false
      } else {
        this.loading = false
        return {
          active: geoDataQuery.data.geolimit_settings.is_active,
          lat: geoDataQuery.data.geolimit_settings.latitude,
          lng: geoDataQuery.data.geolimit_settings.longitude,
          radius: geoDataQuery.data.geolimit_settings.radius
        }
      }
    },
    // Load Current Location from Server
    getCurrentLocation: async function () {
      this.loading = true
      let result
      let locationData = await DataProvider.getDeviceLocation(this.deviceImei)
      if (!locationData.success) {
        ErrorHelper.displayDataErrorToast(locationData)
        result = { lat: 0, lng: 0 }
      } else {
        result = { lat: locationData.data.latitude, lng: locationData.data.longitude }
      }
      this.loading = false
      return result
    },
    updateGeolimitData: function (geolimitData, save = true) {
      if (this.geolimitData && !this.compareSettings(this.geolimitData, geolimitData)) {
        if (save) {
          this.saveGeolimitData(geolimitData)
        }
        this.inputRadius = geolimitData.radius
        this.geolimitData = geolimitData
        if (this.geolimitData.lat && this.geolimitData.lng) {
          this.updateMapDisplay(this.geolimitData)
        }
      }
    },
    saveGeolimitData: async function (newGeolimit) {
      let response = await DataProvider.setDeviceGeolimit(
        this.deviceImei, newGeolimit.lat, newGeolimit.lng, newGeolimit.radius, newGeolimit.active)
      if (response.success) {
        this.$bvToast.toast('Geolimit Settings have been updated!',
          {
            title: 'Changes Saved',
            variant: 'success',
            toaster: 'b-toaster-top-center'
          })
      } else {
        ErrorHelper.displayDataErrorToast(response)
      }
    },
    // Refresh/Load the current location and update the map.
    clickGeolimitActive: async function (event) {
      // Geolimit can be null if it has never been set before. Check and use current location if missing.
      if (!this.geolimitData.lat || !this.geolimitData) {
        let currentPos = await this.getCurrentLocation()
        this.currentPos = currentPos
        this.geolimitData.lat = currentPos.lat
        this.geolimitData.lng = currentPos.lng
      }
      if (!this.geolimitData.radius) {
        this.geolimitData.radius = 200
      }
      this.updateGeolimitData({
        active: event,
        lat: this.geolimitData.lat,
        lng: this.geolimitData.lng,
        radius: this.geolimitData.radius
      })
    },
    updateMapDisplay: function (newGeolimitData) {
      this.$maps.setGeolimit(newGeolimitData, (newData) => { this.updateGeolimitData(newData, true) })
    },
    clickSetGeolimitRadius: function () {
      if (isNaN(this.inputRadius)) {
        ErrorHelper.displayGeneralWarningToast('Radius Value must be a number', 'Invalid Number')
        this.inputRadius = this.geolimitData.radius
        return
      }
      let newLimit = this.nearestMultiple(parseInt(this.inputRadius), this.$config.geolimit.precision)
      this.inputRadius = Math.min(Math.max(newLimit, this.$config.geolimit.min), this.$config.geolimit.max)

      // Note: We're deliberately creating a new object here to ensure change detection is triggered.
      this.updateGeolimitData({
        active: this.geolimitData.active,
        lat: this.geolimitData.lat,
        lng: this.geolimitData.lng,
        radius: this.inputRadius
      }, true)
    },
    clickSetGeolimitLocation: async function () {
      let currentPos = await this.getCurrentLocation()
      this.currentPos = currentPos

      if (!this.geolimitData.radius) {
        this.geolimitData.radius = 200
      }
      this.updateGeolimitData({
        active: this.geolimitData.active,
        lat: currentPos.lat,
        lng: currentPos.lng,
        radius: this.geolimitData.radius
      })
    },
    clickViewGeolimit: function () {
      if (this.geolimitData.lat && this.geolimitData.lng) {
        this.$maps.setZoomToGeolimit()
      } else {
        ErrorHelper.displayGeneralWarningToast('No Geolimit has been set, so we can\'t move the view to it!',
          'No Geolimit set.')
      }
    },
    nearestMultiple (number, multiple) {
      return Math.round(number / multiple) * multiple
    },
    compareSettings: function (newData, oldData) {
      return (
        newData.lat === oldData.lat &&
        newData.lng === oldData.lng &&
        newData.active === oldData.active &&
        newData.radius === oldData.radius
      )
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '@/variables';

  .geolimit-control{
    margin-top: 0;
    margin-bottom: 1rem;
    text-align: left;
  }


</style>
