<template>
  <div>
    <div v-if="trips" class="trip-container">
      <TripAvatar v-for="(trip, idx) of tripList" v-bind:key="idx"
                  :trip="trip"
                  :location="locations"
                  :selected="selectedTrips.includes(trip)"
                  @click="clickTrip(trip, $event)"
                  :show_address="show_addresses"
                  :show_tags="show_tags"
      ></TripAvatar>
    </div>
    <div class="empty-text p-2" v-if="!tripList || tripList.length === 0">
      No Trips
    </div>
  </div>
</template>

<script>

import TripAvatar from '@/components/trips/TripAvatar.vue'

/****
 *
 *  Trip List
 *
 *  Display a list of Trips
 *
 */
export default {
  name: 'TripList',
  components: {TripAvatar},
  props: {
    page_size: Number,
    trips: Array,
    select_multiple: Boolean, // Allow multiple trips to be selected
    select_on_load: Boolean,
    show_addresses: Boolean,
    locations: Boolean, // Show as Locations rather than trips (no duration)
    show_tags: Boolean
  },
  data: function () {
    return {
      pageSize: this.page_size ? this.page_size : 10,
      tripList: this.trips ? this.trips : null,
      selectedTrips: []
    }
  },
  methods: {
    selectAll() {
      this.selectedTrips = this.tripList
      this.$emit('input', this.selectedTrips)
    },
    selectNone() {
      this.selectedTrips = []
      this.$emit('input', this.selectedTrips)
    },
    selectByTripId(tripId) {
      console.log('Select Trip By Id', tripId, this.tripList)
      this.selectedTrips = this.tripList.filter(t => t.id.toString() === tripId.toString())
      console.log('Selected Trips: ', this.selectedTrips)
      this.$emit('input', this.selectedTrips)
    },
    clickTrip(trip, e) {
      if (this.select_multiple) {
        if (e.shiftKey && !this.selectedTrips.includes(trip)) {
          this.selectedTrips.push(trip)
        } else if (e.shiftKey && this.selectedTrips.includes(trip)) {
          this.selectedTrips = this.selectedTrips.filter(t => t !== trip)
        } else {
          this.selectedTrips = [trip]
        }
        // if (this.selectedTrips.includes(trip)) {
        //   this.selectedTrips = this.selectedTrips.filter(t => t !== trip)
        // } else {
        //   this.selectedTrips.push(trip)
        // }
        this.$emit('input', this.selectedTrips)
      } else {
        this.selectedTrips = [trip]
        this.$emit('input', this.selectedTrips[0])
      }
    }
  },
  watch: {
    trips (newVal) {
      this.tripList = newVal
      if (this.select_on_load) {
        this.selectedTrips = newVal
        this.$emit('input', this.selectedTrips)
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/variables';
@import '@/animations';

.trip-container {
  padding: 5px;
}

.empty-text {
  color: white;
  font-weight: 600;
  text-align: center;
}

</style>
