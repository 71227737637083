import * as DataProvider from '@/components/helpers/DataProvider'
import moment from 'moment'
import * as config from '../../config'

/***
 * Config Helper
 * Downloads and caches configuration data for use by other helpers and components.
 */

const STORAGE_KEY_TEMPLATE_PREFIX = 'protekt-config-'
const STORAGE_KEY_TIMESTAMP_PREFIX = 'protekt-config-timestamp-'

// Config Addresses. These are the suffixes for the config endpoints in the API
const configAddresses = {
  devices: 'devices',
  events: 'events',
  tlvCodes: 'admin/tlv_codes',
  concoxCodes: 'admin/concox_commands',
  dashboard: 'dashboard'
}

export default class ConfigService {
  static refreshing = {}
  static refreshCallbacks = {}
  static configs = {}

  // Load default config from file when the service is started
  constructor () {
    // this.refreshConfigData()
  }

  static purgeCache (configName) {
    localStorage.removeItem(STORAGE_KEY_TEMPLATE_PREFIX + configName)
    localStorage.removeItem(STORAGE_KEY_TIMESTAMP_PREFIX + configName)
  }

  /***
   * Retrieve Configuration data corresponding to the given name. Automatically caches config data for the time
   * given in the app config.
   * @param configName
   * @returns {Promise<{error: boolean}|any>}
   */
  static async getConfig (configName) {
    if (!(configName in configAddresses)) {
      throw new Error('Unknown Config Name')
    }
    if (ConfigService.isRefreshTime(configName) || !JSON.parse(localStorage.getItem(STORAGE_KEY_TEMPLATE_PREFIX + configName))) {
      await ConfigService.refreshConfigData(configName)
    }
    try {
      return JSON.parse(localStorage.getItem(STORAGE_KEY_TEMPLATE_PREFIX + configName))
    } catch (e) {
      console.log(`Config Helper - WARNING: Failed to parse ${configName} from cache`)
      return { error: true }
    }
  }

  static async _setConfigCache (configName, newData) {
    if (newData === null) {
      newData = {}
    }
    localStorage.setItem(STORAGE_KEY_TEMPLATE_PREFIX + configName, JSON.stringify(newData))
  }

  /***
   * Check if the stored config has expired. TTL is set in the app config (config.js)
   * @param configName
   * @returns {boolean}
   */
  static isRefreshTime (configName) {
    let lastUpdate = localStorage.getItem(STORAGE_KEY_TIMESTAMP_PREFIX + configName)
    let updateThreshold = moment().subtract(config.configHelper.refreshTimes[configName], 'seconds')
    return (!lastUpdate || !moment(lastUpdate).isSameOrAfter(updateThreshold))
  }

  /***
   * Download new config data. This method should handle being called multiple times, by multiple components while the
   * query is being made to the server and queueing the queries. i.e. can be called multiple times 'simultaneously' and
   * will not duplicate it's server query, but will still provide data back to all the requesters via promises.
   * @param configName
   * @returns {Promise<unknown>}
   */
  static async refreshConfigData (configName) {
    if (!ConfigService.refreshCallbacks[configName]) {
      ConfigService.refreshCallbacks[configName] = []
    }

    if (ConfigService.refreshing[configName]) {
      return new Promise(resolve => {
        ConfigService.refreshCallbacks[configName].push(resolve)
      })
    } else {
      ConfigService.refreshing[configName] = true
      let resp = await DataProvider.getConfig(configAddresses[configName])
      if (resp.success) {
        localStorage.setItem(STORAGE_KEY_TIMESTAMP_PREFIX + configName, moment().toISOString())
        await ConfigService._setConfigCache(configName, resp.data)
        // We're keeping a copy in the service
        ConfigService.configs[configName] = resp.data
      } else {
        console.log(`Failed to get ${configName} Config from server. Keeping Stale Data`)
      }
      ConfigService.refreshing[configName] = false
      ConfigService.refreshCallbacks[configName].forEach((resolve) => {
        resolve()
      })
      ConfigService.refreshCallbacks[configName] = []
    }
  }
}
