<template>
  <div class="device-event-control">
    <div class="event-controls">
      <DateSelector class="w-100" v-model="selectedDateRange" :default_filter_mode="defaultDateMode"
                    :default_date="start_date"></DateSelector>
      <div class="flex-column mt-1">
        <label>Filter Type</label>
        <b-select class="w-100" size="sm" :options="eventTypeOptions" v-model="eventTypeFilter" @input="onFilterChange"></b-select>
      </div>
    </div>
    <loading-box v-bind:loading="loading"></loading-box>
    <div class="green-divider"></div>
    <div class="event-list-container">
      <EventList :events="eventList" @select="clickEvent"></EventList>
    </div>
    <!--    Add Event Modal Template-->
    <b-modal id="view-event-modal" centered class="modal-content" size="lg" hide-footer title="View Event">
      <b-tabs>
        <b-tab title="Event">
          <device-edit-event :device_imei="device_imei" :editable="editable" :event="selectedEvent" modal="view-event-modal"
                             @save="refresh" @select="selectEvent($event)">
          </device-edit-event>
        </b-tab>
        <b-tab title="AuditLog">
          <AuditLog v-if="selectedEvent" related_type="event" :related_id="selectedEvent.event_id"></AuditLog>
        </b-tab>
      </b-tabs>

    </b-modal>
  </div>
</template>

<script>
import LoadingBox from '@/components/helpers/LoadingBox'
import * as DataProvider from '../helpers/DataProvider'
import * as ErrorHelper from '../helpers/ErrorHelper'
import { DateTimeHelper as dt } from '@/components/helpers/DateTimeHelper'
import DeviceEditEvent from '@/components/device/DeviceEditEvent'
import moment from 'moment'
import EventList from '@/components/events/EventList.vue'
import AuditLog from '@/components/shared/AuditLogList.vue'
import DateSelector from '@/components/shared/DateSelector.vue'

export default {
  name: 'device-event-list',
  props: {
    device_imei: String,
    events: Array,
    editable: Boolean,
    hideFilters: Boolean,
    start_date: [String, Object],
    end_date: [String, Object],
  },
  components: { DateSelector, AuditLog, EventList, DeviceEditEvent, LoadingBox },
  data: function () {
    return {
      loading: true,
      tripClusterIconOptions: {
        iconType: 'font',
        icon: 'fa fa-cog',
        labelSuffix: ' events'
      },
      defaultDateMode: 'today',
      eventList: [],
      eventConfig: [],
      eventTypes: [],
      eventTypeFilter: null,
      selectedDateRange: null,
      selectedEvent: null,
    }
  },
  async mounted () {
    await this.$maps.setClusterRendererOptions(this.tripClusterIconOptions)
    this.eventConfig = await this.$eventService.getEventTypeOpts()
    if (this.events) {
      await this.loadEventData(this.events)
    } else {
      // // Default to loading today's events
      let start = moment(moment(new Date()).format('YYYY-MM-DD') + ' 00:00').utc()
      let end = moment(moment(new Date()).format('YYYY-MM-DD') + ' 23:59').utc()
      await this.getEvents(start, end)
    }
    this.loading = false
  },
  methods: {
    getEvents: async function (start, end, eventType = null) {
      this.loading = true
      let response = await DataProvider.getDeviceEvents(this.device_imei, start, end, eventType)
      // NOTE: Important to use a local variable for our calculations, to avoid Vue reactivity! (performance)
      if (response.success) {
        // await this.loadEventData(response.data.events)
        console.log('Downloaded Events: ', response.data.events)
        this.eventList = response.data.events
      } else {
        ErrorHelper.displayDataErrorToast(response)
      }
      this.loading = false
    },
    loadEventData: async function (eventData) {
      let eventList = []
      for (let event of eventData) {
        eventList.push(
          await this.formatEvent(event)
        )
      }
      this.eventList = eventList
    },
    // Format dates, event names, etc
    formatEvent: async function (event) {
      // Format Dates
      event.startTimeHuman = dt.timestampToLocalDateTime(event.start_time)
      if (event.hasOwnProperty('end_time')) {
        event.endTimeHuman = dt.timestampToLocalDateTime(event.end_time)
      }
      // Looks up Event Types
      let config = await this.$eventService.getEventTypeConfig(event.event_type)
      if (config) {
        // If the Event Type is in the dump from the website...
        event.title = config.name
        if ('icon' in config) {
          // Check if we have a special icon for this event type.
          event.icon = config.icon
        } else {
          event.icon = this.$config.icons.events['default']
        }
      } else {
        event.title = `Event #${event.event_type}-${event.event_subtype}`
        event.icon = this.$config.icons.events['default']
      }
      return event
    },
    clickEvent: function (event) {
      this.selectedEvent = event
      if (event.event_data.hasOwnProperty('location')) {
        this.$emit('select', event.event_id)
      } else {
        this.$bvModal.show('view-event-modal')
      }
    },
    // applyFilter: async function (filter) {
    //   let start = moment(moment(new Date()).format('YYYY-MM-DD') + ' 00:00')
    //   let end = moment(moment(new Date()).format('YYYY-MM-DD') + ' 23:59')
    //   // TODO - Consider writing this in a less ugly way
    //   // Filter for Yesterday
    //   if (filter === 'Yesterday') {
    //     start = moment(moment(new Date()).subtract(1, 'day').format('YYYY-MM-DD') + ' 00:00')
    //     end = moment(moment(new Date()).subtract(1, 'day').format('YYYY-MM-DD') + ' 23:59')
    //   } else if (filter === 'This Week') {
    //     start = moment(moment(new Date()).subtract(7, 'day').format('YYYY-MM-DD') + ' 00:00')
    //     end = moment(moment(new Date()).format('YYYY-MM-DD') + ' 23:59')
    //   } else if (filter === 'Custom' && this.customStart && this.customEnd) {
    //     start = moment(moment(this.customStart).format('YYYY-MM-DD') + ' 00:00')
    //     end = moment(moment(this.customEnd).format('YYYY-MM-DD') + ' 23:59')
    //   }
    //   this.loadEvents(start.utc(), end.utc())
    // },
    refresh: function () {
      this.getEvents(this.selectedDateRange.start, this.selectedDateRange.end)
    },
    selectEvent: function (eventId) {
      this.$emit('select', eventId)
    },
    onFilterChange (newFilter) {
      console.log('Filter: ', newFilter)
      this.getEvents(this.selectedDateRange.start, this.selectedDateRange.end, newFilter)
    }
  },
  computed: {
    eventTypeOptions () {
      let opts = [{ value: null, text: 'Any' }]
      if (this.eventConfig) {
        opts = opts.concat(this.eventConfig)
      }
      return opts
    }
  },
  watch: {
    events: function (newEvents) {
      this.loadEventData(newEvents)
    },
    selectedDateRange (newDates) {
      this.getEvents(newDates.start, newDates.end)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../variables';

.device-event-control {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.event-controls{
  padding: 1px 10px;
  flex-grow: 0;
}

.event-list-container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  //padding: 5px;
  max-height: 100%;
  z-index: 0;
  flex-grow: 1;
}

.custom-date-picker {
  font-size: 0.9rem;
}

.text-input {
  z-index: 0;
}

.z-0 {
  z-index: 0;
}

.z-1 {
  z-index: 1;
}

.event-heading {
  flex-grow: 2;
}

.event-title {
  font-size: 1rem;
}

.event-timestamp {
  font-size: 0.7rem;
  font-style: italic;
}

</style>
