<template>
  <div class="date-selector">
    <form :class="{'grow-horizontal' : horizontal, 'grow-vertical': !horizontal}" class="date-range-form"
         v-on:submit.prevent="emitDates" ref="dateForm">
      <div v-if="select_picker">
        <b-form-select
          id="select-month"
          aria-placeholder="Select Dates"
          v-model="dateMode"
          :options="dateSelectOptions"
          @input="changeFilter"
        ></b-form-select>
      </div>
      <div v-else class="w-100">
        <div class="form-check">
          <input class="form-check-input" type="radio" :name="`filterToday_${_uid}`" :id="`filterToday_${_uid}`" :checked="dateMode==='today'"
                 @change="changeFilter('today' )">
          <label class="form-check-label" :for="`filterToday_${_uid}`">
            Today
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" :name="`filterYesterday_${_uid}`" :id="`filterYesterday_${_uid}`" :checked="dateMode==='yesterday'"
                 @change="changeFilter('yesterday' )">
          <label class="form-check-label" :for="`filterYesterday_${_uid}`">
            Yesterday
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" :name="`filterCustom_${_uid}`" :id="`filterCustom_${_uid}`" :checked="dateMode==='custom'"
                 @change="changeFilter('custom' )">
          <label class="form-check-label" :for="`filterCustom_${_uid}`">
            Specific Date
          </label>
        </div>
      </div>
      <div v-show="dateMode==='custom'" class="custom-filter-container" :class="{'grow-horizontal' : horizontal, 'grow-vertical': !horizontal}">
        <input class="" type="date" id="customStart" v-model="customDate" aria-invalid="true" required
               placeholder="Start Date" @input="selectCustomDate" ref="customDate">
      </div>
    </form>

  </div>
</template>
<script>

import moment from 'moment'

export default {
  name: 'DateSelector',
  props: {
    default_filter_mode: String,
    default_date: [String, Object],
    allow_any: Boolean,
    return_local: Boolean,
    horizontal: Boolean, // Selector grows Horizontally
    select_picker: Boolean, // Use a select box instead of Radio Buttons
    value: Object
  },
  emits: [
    'input',
    'cancel'
  ],
  data () {
    return {
      dateMode: 'today',
      customDate: null,
      include_null_filter: false, // 'Any' Option returns a null value, which is ignored by Python
      dateFunctionsEmitOnSelect: {
        any: true,
        today: true,
        yesterday: true,
        custom: false
      },
      dateFunctions: {
        any: () => { return { start: null, end: null } },
        today: this.getToday,
        yesterday: this.getYesterday,
        custom: this.getCustom
      }
    }
  },
  async mounted () {
    // Use the custom default filter mode if it's been provided
    if (this.default_filter_mode) {
      this.dateMode = this.default_filter_mode.toLowerCase()
    }

    if (this.default_date) {
      this.selectedDate = this.default_date
    }

    // Handle 'value' being set. This is mostly for v-model integration.
    if (this.value) {
      this.syncFromValue(this.value)
    } else {
      if (this.dateMode === 'today' || this.dateMode === 'yesterday') {
        this.emitDatesNextTick()
      } else if (this.default_start_date && this.default_end_date && this.dateMode === 'custom') {
        this.emitDatesNextTick()
      } else if (this.default_month && this.dateMode === 'month') {
        this.selectedMonth = this.default_month
        this.emitDatesNextTick()
      } else if (this.default_calendar_year && this.dateMode === 'calendar-year') {
        this.selectedCalYear = this.default_calendar_year
        this.emitDatesNextTick()
      } else if (this.default_financial_year && this.dateMode === 'financial-year') {
        this.selectedFiYear = this.default_financial_year
        this.emitDatesNextTick()
      }
    }
  },
  methods: {
    /***
     * This allows a parent to pass a v-model value to the component, which the component can then use.
     * @param newVal
     */
    syncFromValue(newVal) {
      if (newVal && Object.hasOwn(newVal, 'dateMode')) {
        this.dateMode = newVal.dateMode
      }
      if (newVal && Object.hasOwn(newVal, 'start')) {
        this.customStart = moment(newVal.start).format('YYYY-MM-DD')
        // Single date mode uses the start date value
        this.customDate = moment(newVal.start).format('YYYY-MM-DD')
      }
      if (newVal && Object.hasOwn(newVal, 'end')) {
        this.customEnd = moment(newVal.end).format('YYYY-MM-DD')
      }
    },
    /***
     * Emit Date Values on the next Tick
     */
    emitDatesNextTick() {
      this.$nextTick(() => {
        this.emitDates()
      })
    },
    /***
     * Implements the standard HTML 'SetCustomValidity' function for the datepicker.
     * The message is set on the visible UI element that is currently incomplete.
     * @param validityMessage
     */
    setCustomValidity(validityMessage) {
      if (this.dateMode === 'custom') {
        if (!this.customStart) {
          this.$refs.customDate.setCustomValidity(validityMessage)
        } else {
          this.$refs.customDate.setCustomValidity('')
        }
        this.$refs.dateForm.reportValidity()
      }
    },
    /***
     * Handle the event when the datepicker mode is changed. Emits dates immediately  if the datemode does not require
     * further selections to be made. (e.g. Today)
     * @param dateFuncKey
     */
    changeFilter (dateFuncKey) {
      this.dateMode = dateFuncKey
      this.$emit('date_mode_change', dateFuncKey)
      if (this.dateFunctionsEmitOnSelect[dateFuncKey]) {
        this.emitDates()
      }
    },
    /***
     * Generate a date range for 'today'
     * @return {{start: moment.Moment, end: moment.Moment}}
     */
    getToday() {
      return {
        start: moment().startOf('day'),
        end: moment().endOf('day')
      }
    },
    /***
     * Generate a date range for 'Yesterday'
     * @return {{start: moment.Moment, end: moment.Moment}}
     */
    getYesterday() {
      return {
        start: moment().subtract(1, 'day').startOf('day'),
        end: moment().subtract(1, 'day').endOf('day')
      }
    },
    /***
     * Generate a date range based on the custom start and end dates.
     * @return {{start: moment.Moment, end: moment.Moment}}
     */
    getCustom () {
      return {
        start: moment(this.customDate).startOf('day'),
        end: moment(this.customDate).endOf('day')
      }
    },
    /***
     * Called when the component is ready to send it's input event
     */
    emitDates() {
      let dates = this.dateFunctions[this.dateMode]()
      console.log(this.dateMode)
      if (this.return_local && this.dateMode !== 'any') {
        dates.start = moment(dates.start).local().format('YYYY-MM-DD HH:mm:ss')
        dates.end = moment(dates.end).local().format('YYYY-MM-DD HH:mm:ss')
      } else if (this.dateMode !== 'any') {
        dates.start = moment.utc(dates.start).toISOString()
        dates.end = moment.utc(dates.end).toISOString()
      }
      dates.mode = this.dateMode
      console.log('emit Dates', dates)
      this.$emit('input', dates)
    },
    /***
     * Converts a Date to a string that the HTML Date picker can understand
     * @param date
     */
    dateToString(date) {
      return moment(date).format('YYYY-MM-DD')
    },
    /***
     * Turn a key into a human readable name
     * @param key
     */
    formatName (key) {
      key = key.charAt(0).toUpperCase() + key.slice(1, key.length)
      return key.replace('-', ' ')
    },
    /***
     * Parent Event handler for selecting a custom date.
     */
    selectCustomDate() {
      console.log('select custom')
      if (this.customDate) {
        console.log('X', this.customDate)
        this.emitDates()
      }
    }
  },
  computed: {
    /***
     * Generates a list of Available Date Modes
     * @return {{text: *, value: *}[]}
     */
    dateSelectOptions () {
      return Object.keys(this.dateFunctions).map((dk) => { return { text: this.formatName(dk), value: dk } })
    },
    /***
     * Generates a list of available Month-Year pairs for use when selecting a month.
     * @return {{disabled: boolean, text: string, value: null}[]}
     */
    monthOptions () {
      let m = [...Array(12).keys()].map(i => moment.months(i))
      let y = Array.from({length: moment().year() - this.startYear + 1}, (_, i) => i + this.startYear)
      let months = [].concat(...y.map(year => m.map((month, mn) => {
        return {
          text: `${year}-${month}`,
          value: { month: mn, year }
        }
      }))).reverse()
      return [{
        value: null,
        text: 'Select Month',
        disabled: true
      }].concat(months.filter(o => o.value.year !== moment().year() || o.value.month <= moment().month()))
    },
    calYearOptions () {
      let years = Array.from({length: moment().year() - this.startYear + 1}, (_, i) => i + this.startYear).reverse()
      let yearOptions = years.map((y) => { return { text: `${y} - Jan to Dec`, value: y } })
      return [{
        value: null,
        text: 'Select Calendar Year',
        disabled: true
      }].concat(yearOptions)
    },
    fiYearOptions () {
      let years = Array.from({length: moment().year() - this.startYear + 1}, (_, i) => i + this.startYear).reverse()
      let yearOptions = years.map((y) => { return { text: `FY${y} - Jul ${y - 1} to Jun ${y}`, value: y } })
      return [{
        value: null,
        text: 'Select Financial Year',
        disabled: true
      }].concat(yearOptions)
    }
  },
  watch: {
    value (newVal) {
      this.syncFromValue(newVal)
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../variables';

.date-range-selector {
  display: flex;
  align-content: flex-start;
  color: $text-color-invert;
}

.grow-vertical {
  flex-flow: column wrap!important;
  flex-grow: 1;
}

.grow-horizontal {
  flex-flow: row wrap!important;
}

.date-range-form {
  display: flex;
  align-content: flex-start;
}

.grow-horizontal > div:not(:first-child) {
  margin-left: 2em;
}

.form-check-input {
  height: 1em;
  width: 1em;
  margin-top: 0.1rem;
  margin-left: -0.25rem;
}

.form-check-label {
  text-align: start;
  margin-left: 1.2em;
}

.custom-filter-container {
  display: flex;
  //align-items: center;
}

.green-label {
  margin-top: 0;
}

.text-input {
  color-scheme: dark;
  border-radius: 5px;
  background-color: rgba(0,0,0, 0.25);
}

.custom-select {
  padding-right: 2em;
}

.form-control:focus {
  color: inherit;
  background-color: rgba(0,0,0, 0.25);
}

</style>
