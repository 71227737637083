var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "device-view-exclusions" },
    [
      _vm._m(0),
      !_vm.loading
        ? _c("b-table", {
            ref: "exclusionTable",
            attrs: {
              striped: "",
              hover: "",
              items: _vm.exclusions,
              fields: _vm.exclusionFields,
              "td-class": "admin-device-table-data",
              "th-class": "admin-device-table-header"
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "cell(id)",
                  fn: function(row) {
                    return [
                      _c("i", {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true }
                          }
                        ],
                        staticClass: "row-icon-button-danger row-action ml-2",
                        class: _vm.$config.icons.general.remove,
                        attrs: { title: "Delete Exclusion" },
                        on: {
                          click: function($event) {
                            return _vm.exclusionDelete(row.item.id)
                          }
                        }
                      })
                    ]
                  }
                }
              ],
              null,
              false,
              4087182065
            )
          })
        : _vm._e(),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-4" },
          [
            _c(
              "label",
              { staticClass: "device-label", attrs: { for: "start-select" } },
              [
                _vm._v("Start: "),
                _c("i", {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      modifiers: { hover: true }
                    }
                  ],
                  staticClass: "row-icon-button",
                  class: _vm.$config.icons.general.info,
                  attrs: { title: "When does the exclusion start?" }
                })
              ]
            ),
            _c("b-timepicker", {
              staticClass: "text-input",
              attrs: { "close-button-variant": "danger" },
              model: {
                value: _vm.addStartTime,
                callback: function($$v) {
                  _vm.addStartTime = $$v
                },
                expression: "addStartTime"
              }
            }),
            _c("b-datepicker", {
              staticClass: "text-input",
              attrs: { "close-button-variant": "danger" },
              model: {
                value: _vm.addStartDate,
                callback: function($$v) {
                  _vm.addStartDate = $$v
                },
                expression: "addStartDate"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-4", staticStyle: { position: "relative" } },
          [
            _c(
              "label",
              { staticClass: "device-label", attrs: { for: "end-select" } },
              [
                _vm._v("End: "),
                _c("i", {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      modifiers: { hover: true }
                    }
                  ],
                  staticClass: "row-icon-button",
                  class: _vm.$config.icons.general.info,
                  attrs: { title: "When does the exclusion finish?" }
                })
              ]
            ),
            _c("b-timepicker", {
              staticClass: "text-input",
              attrs: { "close-button-variant": "danger" },
              model: {
                value: _vm.addEndTime,
                callback: function($$v) {
                  _vm.addEndTime = $$v
                },
                expression: "addEndTime"
              }
            }),
            _c("b-datepicker", {
              staticClass: "text-input",
              attrs: { "close-button-variant": "danger" },
              model: {
                value: _vm.addEndDate,
                callback: function($$v) {
                  _vm.addEndDate = $$v
                },
                expression: "addEndDate"
              }
            })
          ],
          1
        ),
        _c("div", { staticClass: "col-4" }, [
          _c(
            "button",
            {
              staticClass: "button",
              attrs: { id: "button-one-day" },
              on: {
                click: function($event) {
                  return _vm.setStartNow()
                }
              }
            },
            [_vm._v("Start Now")]
          ),
          _c(
            "button",
            {
              staticClass: "button",
              attrs: { id: "button-one-day" },
              on: {
                click: function($event) {
                  return _vm.setEndAfterOneDay()
                }
              }
            },
            [_vm._v("End after one Day")]
          ),
          _c(
            "button",
            {
              staticClass: "button",
              attrs: { id: "button-one-day" },
              on: {
                click: function($event) {
                  return _vm.setEndToEndOfDay()
                }
              }
            },
            [_vm._v("End of Day")]
          ),
          _vm.editable
            ? _c(
                "button",
                {
                  staticClass: "button",
                  attrs: { id: "button-add" },
                  on: {
                    click: function($event) {
                      return _vm.saveChanges()
                    }
                  }
                },
                [_vm._v("Save")]
              )
            : _vm._e()
        ])
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12 footer mt-2" }, [
          _c(
            "button",
            {
              staticClass: "button",
              attrs: { id: "button-close" },
              on: {
                click: function($event) {
                  return _vm.clickClose()
                }
              }
            },
            [_vm._v("Close")]
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-row" }, [
      _c("div", { staticClass: "hint-box" }, [
        _c("h5", [_vm._v("View Exclusions")]),
        _c("p", [
          _vm._v(
            " A View Exclusion is a period of time where those with read-only access cannot see the given device. Those with write level or above permissions can see the device at all times. This can be useful if you want to share the location of a vehicle during business hours but keep after hours trips private. "
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }