<template>
  <div></div>
</template>

<script>
import * as DataProvider from '../helpers/DataProvider'
import moment from 'moment'

export default {
  name: 'notification-agent',
  created: async function () {
    await this.refreshAlerts()
    this.interval = setInterval(() => this.refreshAlerts(), this.$config.general.notificationCheckInternal)
  },
  beforeDestroy () {
    if (this.interval) {
      clearInterval(this.interval)
    }
  },
  data: function () {
    return {
      alertIdList: [], // Alert Ids we've already handled
      totalUnread: 0,
      interval: null
    }
  },
  methods: {
    refreshAlerts: async function () {
      // It's possible for the agent to still be running after we're logged out. So handle that.
      if (this.$route.name === 'login' || this.$route.name === 'logout') {
        clearInterval(this.interval)
        console.log('Login/Logout Page found. Notification Agent Stopping.')
      }

      // General Notification Alerts
      let alertData = await this.$notificationService.getNotifications()
      let newTotalUnread = await this.$notificationService.getUnreadCount()
      let newAlerts = alertData.filter(x => !this.alertIdList.includes(x.id))
      // Bvtoast keeps a queue of alerts to display when the window has focus and we don't have a way to view the queue
      // so only 'display' toast when the document has focus.
      if (document.hasFocus()) {
        if ((newTotalUnread - this.totalUnread) > this.$config.general.notificationMaxAlertDisplay) {
          this.showUnreadCountToast(newTotalUnread)
        } else if ((newTotalUnread - this.totalUnread) > 0) {
          let alertQueue = newAlerts.reverse()
          for (let i = 0; i < (newTotalUnread - this.totalUnread); i++) {
            this.showCustomToast(alertQueue[i])
          }
        }
        // Long Message Popups (Device Sharing, etc)
        let settings = await this.$auth.getUserSettings()
        if (settings.hasOwnProperty('message_popups')) {
          // console.log('Message Popups: ', settings.message_popups)
          while (settings.message_popups.length > 0) {
            let popup = settings.message_popups.pop()
            let options = {
              title: popup.title,
              size: 'md',
              centered: true,
              okVariant: 'success'
            }
            // Process Custom Dialog Options
            if (popup.hasOwnProperty('options')) {
              for (let prop in popup.options) {
                options[prop] = popup.options[prop]
              }
            }
            await this.$auth.setUserSettings({
              message_popups: settings.message_popups
            })
            let resp = await this.$bvModal.msgBoxOk(popup.body, options)
          }
        }
      }

      this.alertIdList = this.alertIdList.concat(newAlerts.map(x => x.id))
      this.totalUnread = newTotalUnread
      this.$emit('update', this.totalUnread)
    },
    showCustomToast: function (alert) {
      let friendlyTimestamp = moment(alert.datetime).fromNow()
      // Create the title
      const vNodesTitle = this.$createElement(
        'div',
        { class: ['d-flex', 'flex-grow-1', 'align-items-baseline', 'mr-2'], on: { click: () => { this.clickAlert(alert) } } },
        [
          this.$createElement('strong', { class: 'mr-2' }, 'Protekt Alert'),
          this.$createElement('small', { class: 'ml-auto text-italics' }, friendlyTimestamp)
        ]
      )
      const vNodeBody = this.$createElement(
        'div',
        { class: ['clickable'],
          on: { click: () => {
            this.clickAlert(alert)
          } } },
        `${alert.message}`
      )
      // Pass the VNodes as an array for message and title
      let opts = {
        title: [vNodesTitle],
        solid: true,
        toastClass: 'custom-toast',
        toaster: 'b-toaster-top-left',
        autoHideDelay: 5000
      }
      // Note: we've gone full custom here, for the click handler so we can mark notifications read if they are clicked
      this.$bvToast.toast(vNodeBody, opts)
    },
    showUnreadCountToast: function (count) {
      let friendlyTimestamp = moment(alert.datetime).fromNow()
      // Create the title
      const vNodesTitle = this.$createElement(
        'div',
        { class: ['d-flex', 'flex-grow-1', 'align-items-baseline', 'mr-2'], on: { click: () => { this.$bvModal.show('modal-notifications') } } },
        [
          this.$createElement('strong', { class: 'mr-2' }, 'Protekt Alert'),
          this.$createElement('small', { class: 'ml-auto text-italics' }, friendlyTimestamp)
        ]
      )
      const vNodeBody = this.$createElement(
        'div',
        { class: ['clickable'],
          on: { click: () => {
            this.$bvModal.show('modal-notifications')
          } } },
        `You have ${count} unread notifications.`
      )
      // Pass the VNodes as an array for message and title
      let opts = {
        title: [vNodesTitle],
        solid: true,
        toastClass: 'custom-toast',
        toaster: 'b-toaster-top-left',
        autoHideDelay: 5000
      }
      this.$bvToast.toast(vNodeBody, opts)
    },
    clickAlert: function (notification) {
      this.markRead(notification.id)
      if (notification.link) {
        this.$router.push(notification.link)
      }
    },
    markRead: function (notificationId) {
      let resp = DataProvider.markNotificationRead(notificationId)
    }
  }
}
</script>

<style lang="scss">
  @import '@/variables';

  .custom-toast {
    background: $theme-color-background-1;
    color: black;
  }

  .custom-toast header {
    background: $theme-color-background-4;
    color: $text-color-invert;
  }

  .custom-toast .toast-body {
    background: $theme-color-background-1;
    color: $theme-color-primary-3;
  }

</style>
