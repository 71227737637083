<template>
  <div class="device-view-schedule">
    <div class="flex-row" v-if="!loading">
      <div class="col-8">
        <b-check v-model="view_schedule.enabled" class="device-label"
                 button-variant="success">Enable View Schedule?
        </b-check>
      </div>
    </div>
    <div class="green-divider"></div>
    <div class="flex-row flex-nowrap">
      <div class="col-4" v-if="view_schedule.enabled">
        <label for="timezone-select" class="device-label">Timezone:
          <i class="row-icon-button" :class="$config.icons.general.info"
             v-b-tooltip.hover title="Which timezone is the schedule applicable for?"
          ></i>
        </label>
        <b-form-select v-model="view_schedule.effective_time_zone" :options="timezones"></b-form-select>
        <button class="button" @click="setTimezoneToCurrent()" id="button-close">Current</button>
      </div>
      <div class="hint-box">
        <h5>View Schedule</h5>
        <p>
          A View Schedule denotes which trips a vehicle takes can be seen by those with read-only access.
          Only trips that fall entirely within the View Schedule can be seen by those people.
          If someone has a Device Share with write access or higher, they can see all trips.
          If the View Schedule is not enabled, then someone with read-only access can view all trips.
        </p>
      </div>
    </div>
    <div class="row" v-if="!loading && view_schedule.enabled">
      <div class="col-12">
        <table>
          <tr>
            <th>Day</th>
            <th>Start</th>
            <th>End</th>
          </tr>
          <tr v-for="day in daysOfWeek" :key="day">
            <th>{{ day }}</th>
            <td style="z-index: 2">

              <b-input-group class="mb-2">
                <b-form-input v-model="view_schedule[day].start_time">Text</b-form-input>
                <b-input-group-append style="margin-top: -2px;">
                  <b-form-timepicker v-model="view_schedule[day].start_time"
                                     dropup button-only
                                     locale="en"></b-form-timepicker>
                </b-input-group-append>
              </b-input-group>
            </td>
            <td style="z-index: 1">
              <b-input-group class="mb-2">
                <b-form-input v-model="view_schedule[day].end_time">Text</b-form-input>
                <b-input-group-append style="margin-top: -2px;">
                  <b-form-timepicker class="text-field w-input" v-model="view_schedule[day].end_time"
                                     dropup button-only
                                     locale="en"></b-form-timepicker>
                </b-input-group-append>
              </b-input-group>
            </td>
          </tr>
        </table>
        <div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 footer mt-2">
        <button class="button" @click="saveChanges()" id="button-save" v-if="editable">Save Changes</button>
        <button class="button" @click="clickClose()" id="button-close">Close</button>
        <button class="button" @click="setDefaultViewSchedule()" id="button-default" v-if="view_schedule.enabled">Default</button>
      </div>
    </div>

  </div>
</template>

<script>
import * as DataProvider from '../helpers/DataProvider'
import {required, numeric, minValue} from 'vuelidate/lib/validators'
import * as ErrorHelper from '../helpers/ErrorHelper'
import * as AlertHelper from '../helpers/AlertHelper'
import moment from 'moment-timezone'

export default {
  name: 'device-view-schedule',
  components: {},
  props: {
    isModal: Boolean,
    modal: String,
    device: Object,
    editable: Boolean
  },
  computed: {
    view() {
      return view
    },
    timezones: function () {
      // return ['Australia', 'New Zealand']
      return moment.tz.names()
    },
    usersTimeZone: function () {
      return moment.tz.guess()
    }
  },
  data: function () {
    return {
      loading: true,
      view_schedule: null,
      default_view_schedule: {
        'enabled': false,
        'Monday': {},
        'Tuesday': {},
        'Wednesday': {},
        'Thursday': {},
        'Friday': {},
        'Saturday': {},
        'Sunday': {},
        'effective_time_zone': null
      },
      daysOfWeek: [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday'
      ]
    }
  },
  validations: {
    service_settings: {
      service_schedule_mode: {},
      service_frequency: {
        numeric,
        minValue: minValue(0)
      }
    },
    hours_tracked: {
      numeric,
      required
    },
    odometer_reading: {
      numeric,
      required
    }
  },
  async created() {
    if (this.device.hasOwnProperty('view_schedule')) {
      this.view_schedule = this.device.view_schedule
    }
    if (this.view_schedule === null) {
      this.view_schedule = this.default_view_schedule
    }
    if (this.view_schedule.effective_time_zone === null) {
      this.setTimezoneToCurrent()
    }
    this.loading = false
  },
  methods: {
    setDefaultViewSchedule: function () {
      for (let day of this.daysOfWeek) {

        if (day === 'Sunday') {
          this.view_schedule[day]['start_time'] = null
          this.view_schedule[day]['end_time'] = null
        } else {
          this.view_schedule[day]['start_time'] = '6:00'
          this.view_schedule[day]['end_time'] = '17:00'
        }

      }
      this.$set(this.view_schedule, 'enabled', true)
    },
    setTimezoneToCurrent: function () {
      console.log('Setting time zone to current')
      this.view_schedule.effective_time_zone = this.usersTimeZone
    },
    saveChanges: async function () {
      if (this.$v.$anyError) {
        ErrorHelper.displayGeneralErrorToast('Some Fields Contain Invalid Data. Please fix them.', 'Invalid Fields')
        return
      }
      // If the user didn't select a timezone, select their current one
      if (!this.view_schedule.effective_time_zone === null) {
        this.setTimezoneToCurrent()
      }
      // Update Selected icon
      try {
        let res = await DataProvider.updateDeviceViewSchedule(this.device.device_imei,
          this.view_schedule
        )

        if (res.success) {
          this.$v.$reset()
          this.$emit('save') // Emit a save event, since we've just saved
          AlertHelper.successToast('View Schedule Settings Update!', 'Settings Updated')
          this.clickClose()
        } else {
          this.$bvToast.toast('Uh Oh! Something went wrong saving changes to the server. Please try again later.',
            {
              title: 'Server Communication Error',
              variant: 'danger',
              toaster: 'b-toaster-top-center'
            })
        }
      } catch (err) {
        console.error(err)
        this.$bvToast.toast('An Error Occurred Getting a Device List from the Server.',
          {
            title: 'Server Communication Error',
            variant: 'danger',
            toaster: 'b-toaster-top-center'
          })
      }
    },
    clickClose: async function () {
      if (this.$v.$anyDirty) {
        let res = await this.$bvModal.msgBoxConfirm('You have unsaved changes, are you sure you want to close this window?', {
          title: 'Confirm Discard Changes',
          okVariant: 'warning',
          centered: true
        })
        if (!res) {
          return
        }
      }
      // Check if we've been passed a modal to close.
      if (this.modal) {
        this.$bvModal.hide(this.modal)
      } else {
        this.$router.go(-1)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../variables';

.device-label {
  font-family: 'Open Sans', sans-serif;
  color: $theme-color-primary-3;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
}

.bootstrap-timepicker-widget.dropdown-menu {
  z-index: 1050 !important;
}

.footer {
  display: flex;
  flex-direction: row-reverse;
}
</style>
