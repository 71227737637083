var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return this.event && this.config
    ? _c(
        "div",
        {
          staticClass: "event-avatar",
          class: { clickable: _vm.enable_link, selected: _vm.selected === true }
        },
        [
          _c("i", { staticClass: "event-icon", class: _vm.eventIcon }),
          _c(
            "div",
            { staticClass: "flex-grow-1", on: { click: _vm.onClick } },
            [
              _c("div", { staticClass: "event-heading" }, [
                _vm._v(_vm._s(_vm.eventTitle))
              ]),
              _c("div", { staticClass: "event-timestamp" }, [
                _vm._v(_vm._s(_vm.eventTimestamp))
              ])
            ]
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }