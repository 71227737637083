var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.template
    ? _c(
        "div",
        {
          staticClass: "form-avatar-container",
          class: { clickable: !_vm.disabled, icon: _vm.icon }
        },
        [
          _c("div", { staticClass: "form-image" }, [
            _c("i", {
              staticClass: "template-icon fa",
              class: _vm.cformIconClass,
              style: "color: " + _vm.cformIconColor + ";"
            })
          ]),
          _c("div", { staticClass: "detail-outer" }, [
            _vm._v(" " + _vm._s(_vm.cName) + " "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.hide_description && !_vm.icon,
                    expression: "!hide_description && !icon"
                  }
                ],
                staticClass: "detail-inner"
              },
              [_c("div", [_vm._v(_vm._s(_vm.cDesc))])]
            )
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }