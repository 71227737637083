var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "missing" } }, [
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "w-100 mt-5" }, [
          _c("h1", [
            _vm._v("Uh Oh... We couldn't find the page you're looking for.")
          ]),
          _c("img", {
            staticStyle: { width: "100%" },
            attrs: { src: require("@/assets/Pro-tekt-logo.png") }
          })
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }