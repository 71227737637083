<template>
  <div class="form-avatar-container" v-if="form"
       :class="{
              'clickable': !disabled,
              'disabled': disabled,
              'hover': !disabled,
              'selected': selected,
              'small': size === 'small',
              'outline': variant === 'outline'
              }"
       :style="`border-color: ${cAccentColor}; outline-color: ${cAccentColor}`"
       @click="emitClick"
  >
    <i class="template-icon fa" :class="cformIconClass" :style="'color: ' + cformIconColor + ';'"></i>

    <div class="detail-outer">
      <div class="form-avatar-heading flex-row">
        <div :style="`color: ${cAccentColor}`"> {{cName}} </div>
      </div>

      <div class="detail-inner">
        <i class="status-icon fa" :class="cStatusIcon" v-b-tooltip :title="cStatusText"></i> {{cCreated}}
      </div>
    </div>
  </div>
</template>

<script>

import {DateTimeHelper as dt} from '@/components/helpers/DateTimeHelper'

export default {
  name: 'FormDataAvatar',
  props: {
    form: Object,
    disabled: Boolean,
    selected: Boolean,
    size: String,
    variant: String
  },
  data () {
    return {
      statusIcons: {
        new: 'fa-circle-o',
        review_required: 'fa-question-circle-o',
        complete: 'fa-check-circle-o'
      },
      statusText: {
        new: 'New',
        review_required: 'Review Required',
        complete: 'Complete'
      }
    }
  },
  async mounted () {

  },
  methods: {
    emitClick($event) {
      this.$emit('click', $event)
    }
  },
  computed: {
    cName () {
      return this.form.template.name || 'Form Name'
    },
    cformIconClass () {
      return this.form.template.icon || 'fa-folder-o'
    },
    cformIconColor () {
      return this.form.template.color || '#FFFFFF'
    },
    cAccentColor () {
      if (this.variant === 'outline') {
        return this.form.template.color || '#FFFFFF'
      } else {
        return null
      }
    },
    cStatusText () {
      if (Object.hasOwn(this.statusText, this.form.state)) {
        return this.statusText[this.form.state]
      } else {
        return this.form.state
      }
    },
    cCreated () {
      return dt.toLocalDateTime(this.form.created) || ''
    },
    cStatusIcon () {
      console.log(this.form.status)
      if (Object.hasOwn(this.statusIcons, this.form.state)) {
        return this.statusIcons[this.form.state]
      } else {
        return 'fa fa-circle-o'
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/variables';

.form-avatar-container {
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  align-items: center;
  justify-content: space-between;
  background: $theme-color-background-4;
  user-select: none;
  padding: 2px 5px;
}

.form-avatar-container.selected{
  box-shadow: 0 0 0 0.2rem rgba(45, 231, 28, 0.75);
  background-color: rgba(50, 50, 50,0.25);
}

.form-avatar-container.hover:hover {
  background-color: rgba(0,0,0,0.15);
  outline-style: solid;
  outline-width: 3px;
  outline-color: rgba(45, 231, 28, 0.5);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  .form-avatar-heading {
    color: white;
    transition: color 0.15s ease-in-out;
  }
  .detail-inner {
    color: white;
    transition: color 0.15s ease-in-out;
  }
  .status-icon {
    color: white;
    transition: color 0.15s ease-in-out;
  }
}

.form-avatar-container.small{
  padding: 3px;
  font-size: 0.75em;
  .form-avatar-heading {

  }
  .template-icon {
    width: auto;
    //padding: 0;
    font-size: 1.5em;
  }
  .detail-inner {
    display: none;
  }
  .status-icon {
    display: none;
  }
}

.form-avatar-container.outline{
  background: transparent;
  border: 1px solid green;
  border-radius: 8px;
  outline-width: 1px;
  filter: brightness(80%);
  transition: filter 0.15s ease-in-out;
  .form-avatar-heading {

  }
  .template-icon {

  }
  .detail-inner {

  }
  .status-icon {

  }
}

.form-avatar-container.outline.hover:hover {
  filter: brightness(120%);
  transition: filter 0.15s ease-in-out;
}

.form-avatar-container.disabled {

  background-color: rgba(0,0,0,0.3);
  .form-avatar-heading {
    color: $theme-color-primary-4;
  }
  .detail-inner {
    color: grey;
  }
  .template-icon {
    filter: brightness(50%);
  }
  .status-icon {
    filter: brightness(50%);
  }
}

h4 {
  font-weight: 600;
  color: $theme-color-primary-3;
}

.form-avatar-heading {
  font-weight: 600;
  color: $theme-color-primary-3;
  align-content: center;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
}

.detail-outer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 1;
  margin-left: 0.5em;
}

.detail-inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 0.9em;
  color: #afafaf;
  font-style: italic;
}

.template-icon {
  font-size: 2.5em;
  width: 1.5em;
}

.clickable:active, .clickable:focus {
  outline: 2px solid #9aea92;
  border-color: #9aea92;
  background-color: rgba(150,150,150,0.25);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out, outline-color 0.15s ease-in-out;
}

.status-icon {
  margin-right: 0.25em;
  color: #cecece;
}

/* Very Narrow Screens */
@media screen and (max-width: 300px) {
  .device-container {
    padding: 5px 5px;
  }

  .status-icon {
    display: none;
  }
}
</style>
