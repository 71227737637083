var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.device
    ? _c("div", { staticClass: "vehicle-dtc-control" }, [
        _c("h1", { staticClass: "yellow-label" }, [
          _vm._v("Vehicle DTCs "),
          _c("i", {
            staticClass: "fa fa-exclamation-triangle dtc_codes",
            attrs: { id: "dtc-icon" + _vm.device.device_id }
          })
        ]),
        _c("div", { staticClass: "yellow-divider" }),
        _c(
          "div",
          {
            staticClass:
              "d-flex flex-column justify-content-between device-control-heading"
          },
          [
            _c("p", [
              _vm._v(
                "This Vehicle is reporting the following Diagnotic Trouble Codes:"
              )
            ]),
            _c(
              "ul",
              _vm._l(_vm.device.vehicle_dtcs.dtc_codes, function(code, idx) {
                return _c("li", { key: idx, staticClass: "field-container" }, [
                  _vm._v(" " + _vm._s(code) + " ")
                ])
              }),
              0
            ),
            _c("p", [
              _vm._v(" Timestamp: "),
              _c("span", { staticClass: "timestamp-text" }, [
                _vm._v(
                  _vm._s(
                    _vm.formatDTCTimestamp(_vm.device.vehicle_dtcs.timestamp)
                  )
                )
              ])
            ])
          ]
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }