var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "workflow-progress-widget" }, [
    _c("div", { staticClass: "progress-bar" }, [
      _c("div", {
        staticClass: "progress-color",
        style: "width: calc(" + _vm.barWidth + "% - 25px)"
      })
    ]),
    _c("div", {
      staticClass: "progress-bar-triangle",
      style: "left: calc(" + _vm.arrowPosition + "% - 25px)"
    }),
    _c(
      "div",
      { staticClass: "step-overlay" },
      _vm._l(_vm.stepItems, function(step, idx) {
        return _c(
          "div",
          {
            key: idx,
            staticClass: "step-outer",
            class: {
              active: idx === _vm.progressVal,
              complete:
                idx < _vm.progressVal ||
                (idx === _vm.progressVal && idx === _vm.barMax - 1)
            }
          },
          [_vm._v(_vm._s(step) + " "), _vm._m(0, true)]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "complete-icon" }, [
      _c("i", { staticClass: "fa fa-check" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }