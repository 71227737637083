import * as DataProvider from '@/components/helpers/DataProvider'
import moment from 'moment'
import * as config from '../../config'

/***
 * Notification Service
 * Handles downloading and caching notifications (replaces the 'NotificationAgent' component)
 */

const NOTIFICATION_CACHE_KEY = 'protekt-notifications-cache'
const NOTIFICATION_COUNT_CACHE_KEY = 'protekt-notification-unread-count-cache'
const NOTIFICATION_CACHE_TIMESTAMP_KEY = 'protekt-notifications-cache-timestamp'

export default class NotificationService {
  static refreshing = false
  static refreshCallbacks = []
  static notification_cache = []
  static notification_unread_count = null

  // Load default config from file when the service is started
  constructor () {
    this.refreshNotificationData()
    localStorage.removeItem(NOTIFICATION_CACHE_KEY)
  }

  static purgeCache () {
    localStorage.removeItem(NOTIFICATION_CACHE_KEY)
    localStorage.removeItem(NOTIFICATION_COUNT_CACHE_KEY)
    localStorage.removeItem(NOTIFICATION_CACHE_TIMESTAMP_KEY)
  }

  /***
   * Check if the stored config has expired. TTL is set in the app config (config.js)
   * @param configName
   * @returns {boolean}
   */
  static isRefreshTime (configName) {
    let lastUpdate = localStorage.getItem(NOTIFICATION_CACHE_TIMESTAMP_KEY)
    let updateThreshold = moment().subtract(config.general.notificationDataCacheTime, 'seconds')
    return (!lastUpdate || !moment(lastUpdate).isSameOrAfter(updateThreshold))
  }

  /***
   * Get a list of the currently cached notification Ids (mostly to tell the server not to resend them)
   */
  static getCachedIds () {
    if (!NotificationService.notification_cache) {
      NotificationService.notification_cache = []
    }
    return NotificationService.notification_cache.map(x => x.id)
  }

  // update Notification Data Cache
  static async _setNotificationCache (newData) {
    if (newData === null) {
      newData = {}
    }
    localStorage.setItem(NOTIFICATION_CACHE_KEY, JSON.stringify(newData))
  }

  // update Notification Data Cache
  static async _setNotificationCountCache (newData) {
    if (newData === null) {
      newData = 0
    }
    localStorage.setItem(NOTIFICATION_COUNT_CACHE_KEY, newData)
  }

  // Download new user data, if there's already a request for new data pending, return a promise that will be
  // resolved when the data has been loaded.
  static async refreshNotificationData () {
    if (NotificationService.refreshing) {
      return new Promise(resolve => {
        NotificationService.refreshCallbacks.push(resolve)
      })
    } else {
      NotificationService.refreshing = true
      // console.log('Refreshing Notification Data....')
      let cachedIds = NotificationService.getCachedIds()
      let resp = await DataProvider.getNotifications(cachedIds, { unread_only: true })
      if (resp.success) {
        localStorage.setItem(NOTIFICATION_CACHE_TIMESTAMP_KEY, moment().toISOString())

        if (NotificationService.notification_cache) {
          NotificationService.notification_cache = NotificationService.notification_cache.concat(resp.data.alerts)
        } else {
          NotificationService.notification_cache = resp.data.alerts
        }
        await this._setNotificationCache(NotificationService.notification_cache)
        NotificationService.notification_unread_count = resp.data.total
        await this._setNotificationCountCache(NotificationService.notification_unread_count)
      } else {
        console.log('Failed to get Notification Data from server. Keeping Stale Data')
      }
      if (NotificationService.notification_cache.length > 50) {
        console.log('Trimming Notification Cache...')
        NotificationService.notification_cache = NotificationService.notification_cache.slice(-49)
      }
      NotificationService.refreshing = false
      NotificationService.refreshCallbacks.forEach((resolve) => {
        resolve()
      })
      NotificationService.refreshCallbacks = []
    }
  }

  static async getNotifications () {
    if (NotificationService.isRefreshTime()) {
      await this.refreshNotificationData()
    }
    try {
      return NotificationService.notification_cache
    } catch (e) {
      console.log('Notification Service - WARNING: Failed to parse Notification Data from cache')
      return { error: true }
    }
  }

  static async getUnreadCount () {
    if (NotificationService.isRefreshTime() || !localStorage.getItem(NOTIFICATION_COUNT_CACHE_KEY) === null) {
      await this.refreshNotificationData()
    }
    try {
      NotificationService.notification_unread_count = localStorage.getItem(NOTIFICATION_COUNT_CACHE_KEY)
      return NotificationService.notification_unread_count
    } catch (e) {
      console.log('Notification Service - WARNING: Failed to parse Notification Data from cache')
      return { error: true }
    }
  }
}
