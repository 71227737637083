var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "locations-control" },
    [
      _c("loading-box", { attrs: { loading: _vm.loading } }),
      _c("label", [_vm._v("Locations")]),
      _c("div", { staticClass: "green-divider" }),
      _c(
        "button",
        {
          staticClass: "button w-100",
          attrs: { id: "current-location" },
          on: {
            click: function($event) {
              return _vm.clickShowCurrent()
            }
          }
        },
        [_vm._v("Go to Current Location")]
      ),
      _c(
        "button",
        {
          staticClass: "button w-100",
          attrs: { id: "route -history" },
          on: {
            click: function($event) {
              return _vm.clickShowLocationHistory()
            }
          }
        },
        [_vm._v("Locations History")]
      ),
      _c(
        "PopoutMenu",
        {
          attrs: { title: "Location History" },
          model: {
            value: _vm.showPopout,
            callback: function($$v) {
              _vm.showPopout = $$v
            },
            expression: "showPopout"
          }
        },
        [
          _c(
            "div",
            { staticClass: "flex-column overflow-hidden" },
            [
              _c("DateSelector", {
                staticClass: "w-100",
                attrs: {
                  default_filter_mode: _vm.defaultDateMode,
                  default_date: _vm.start_date
                },
                model: {
                  value: _vm.selectedDateRange,
                  callback: function($$v) {
                    _vm.selectedDateRange = $$v
                  },
                  expression: "selectedDateRange"
                }
              }),
              _c("div", { staticClass: "green-divider" }),
              _c("div", { staticClass: "flex-row slim-controls" }, [
                _c("button", { on: { click: _vm.selectAll } }, [_vm._v("All")]),
                _c("button", { on: { click: _vm.selectNone } }, [
                  _vm._v("Clear")
                ]),
                _c("label", [_vm._v("Auto Select")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selectOnLoad,
                      expression: "selectOnLoad"
                    }
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.selectOnLoad)
                      ? _vm._i(_vm.selectOnLoad, null) > -1
                      : _vm.selectOnLoad
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.selectOnLoad,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.selectOnLoad = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.selectOnLoad = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.selectOnLoad = $$c
                      }
                    }
                  }
                })
              ]),
              _c(
                "div",
                {
                  ref: "tripsListContainer",
                  staticClass: "trips-list-container"
                },
                [
                  _c("TripList", {
                    ref: "tripsListComponent",
                    attrs: {
                      trips: _vm.trips,
                      locations: "",
                      select_multiple: "",
                      select_on_load: _vm.selectOnLoad
                    },
                    on: { input: _vm.onTripSelect }
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }