<template>
<div v-if="this.user" class="user-avatar" @click="onClick"
     :class="{'selected': selected === true, 'user-avatar-hover': !disabled, 'user-avatar-disabled': disabled}">
  <i :class="userIcon" class="user-icon"></i>
  <div class="flex-column flex-wrap flex-grow-1 align-items-center">
    <div class="user-heading">{{username}}</div>
    <div class="detail-text">{{email}}</div>
  </div>

</div>
</template>

<script>
import { DateTimeHelper as dt } from '@/components/helpers/DateTimeHelper'

export default {
  name: 'user-avatar',
  props: {
    user: Object,
    selected: Boolean, // prop to enable highlighting
    disabled: Boolean, // Turns off the hover effect
  },
  data: function () {
    return {
      config: null
    }
  },
  async mounted() {

  },
  methods: {
    onClick(e) {
      this.$emit('click', e)
    }
  },
  computed: {
    userIcon () {
      return 'fa fa-user'
    },
    username () {
      if (this.user && this.user.name && this.user.name !== '') {
        return this.user.name
      } else {
        return 'User #' + this.user.user_id
      }
    },
    email () {
      return this.user.email || ''
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
@import '@/variables';
.user-avatar {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  background: $theme-color-background-4;
  color: $theme-color-primary-3;
  border-radius: 5px;
  padding: 5px 5px;
  margin: 2px 0px;
  z-index: 0;
  user-select: none;
}

.user-icon {
  color: $text-color-invert;
  font-size: 2.1em;
  line-height: inherit;
  width: 1.2em;
  overflow: hidden;
}

.user-heading {
  flex-grow: 2;
  font-size: 1.3em
}

.detail-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  font-size: 0.7em;
  color: $theme-color-primary-3;
  font-style: italic;
}
.user-avatar-hover:hover {
  color: white;
  background-color: rgba(0,0,0,0.15);
  box-shadow: 0 0 0 0.2rem rgba(56, 212, 41, 0.4);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.selected {
  background-color: rgba(50, 50, 50,0.25);
  box-shadow: 0 0 0 0.2rem rgba(45, 231, 28, 0.75);
}

.user-avatar-disabled {
  color: $theme-color-primary-4;
  background-color: rgba(0,0,0,0.3);
  .user-icon {
    color: grey;
  }
}

</style>
