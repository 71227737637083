var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "device-service-schedule" },
    [
      _c("LoadingBox", { attrs: { loading: _vm.loading } }),
      _c("div", { staticClass: "flex-row flex-nowrap align-items-stretch" }, [
        _c("div", { staticClass: "event-list" }, [
          _c("label", { attrs: { for: "device-event-list" } }, [
            _vm._v(" Service History (24 Months) ")
          ]),
          _c("div", { staticClass: "green-divider" }),
          _c(
            "div",
            { staticClass: "flex-column flex-grow-1 align-items-center" },
            [
              _c("EventList", {
                staticClass: "flex-grow-1 event-list-inner",
                attrs: {
                  events: _vm.sortedEvents,
                  highlight_func: _vm.getEventHighlightClass
                },
                on: { select: _vm.selectEvent }
              }),
              this.events.length < 1
                ? _c("div", {}, [
                    _vm._v(
                      " Enter your last service to enable estimate for future services. "
                    )
                  ])
                : _vm._e(),
              _c("div", { staticClass: "event-list-control slim-controls" }, [
                _c(
                  "button",
                  {
                    staticClass: "button",
                    attrs: { id: "button-add-service" },
                    on: {
                      click: function($event) {
                        return _vm.addEvent()
                      }
                    }
                  },
                  [_vm._v("Add Service")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "button",
                    attrs: { id: "button-add-service" },
                    on: {
                      click: function($event) {
                        return _vm.refreshEventList()
                      }
                    }
                  },
                  [
                    _vm._v("Refresh "),
                    _c("i", {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true }
                        }
                      ],
                      class: _vm.$config.icons.general.refresh,
                      attrs: { title: "Refresh List" }
                    })
                  ]
                )
              ])
            ],
            1
          )
        ]),
        _c(
          "div",
          { staticClass: "flex-column flex-grow-1 align-items-stretch" },
          [
            _c(
              "div",
              {
                staticClass: "flex-row flex-nowrap justify-content-stretch p-2"
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "flex-column flex-grow-1 align-items-stretch"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "flex-grow-1" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "device-label",
                            attrs: { for: "service_schedule_type" }
                          },
                          [
                            _vm._v(" Servicing Schedule "),
                            _c("i", {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover",
                                  modifiers: { hover: true }
                                }
                              ],
                              staticClass: "row-icon-button",
                              class: _vm.$config.icons.general.info,
                              attrs: {
                                title:
                                  "Determines how servicing is tracked for the vehicle."
                              }
                            })
                          ]
                        ),
                        _c("b-select", {
                          staticClass: "w-100",
                          attrs: {
                            id: "service_schedule_mode",
                            disabled: !_vm.editable,
                            options: _vm.service_schedule_options
                          },
                          on: { change: _vm.refreshServiceEstimate },
                          model: {
                            value:
                              _vm.$v.service_settings.service_schedule_mode
                                .$model,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.$v.service_settings.service_schedule_mode,
                                "$model",
                                $$v
                              )
                            },
                            expression:
                              "$v.service_settings.service_schedule_mode.$model"
                          }
                        })
                      ],
                      1
                    ),
                    _vm.service_settings.service_schedule_mode
                      ? _c("div", { staticClass: "flex-grow-1" }, [
                          _c(
                            "div",
                            {
                              staticClass: "w-100",
                              class: {
                                "form-error":
                                  _vm.$v.service_settings.service_frequency
                                    .$error
                              }
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "device-label",
                                  attrs: { for: "servicing_frequency" }
                                },
                                [
                                  _vm._v(
                                    "Service Interval " +
                                      _vm._s(
                                        _vm.service_mode_metrics[
                                          _vm.service_settings
                                            .service_schedule_mode
                                        ]
                                      ) +
                                      " "
                                  ),
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip.hover",
                                        modifiers: { hover: true }
                                      }
                                    ],
                                    staticClass: "row-icon-button",
                                    class: _vm.$config.icons.general.info,
                                    attrs: {
                                      title:
                                        "How long/far between each service?"
                                    }
                                  })
                                ]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.$v.service_settings.service_frequency
                                        .$model,
                                    expression:
                                      "$v.service_settings.service_frequency.$model"
                                  }
                                ],
                                staticClass: "w-100",
                                attrs: {
                                  type: "text",
                                  maxlength: "256",
                                  placeholder: "000",
                                  id: "service_frequency",
                                  disabled:
                                    !_vm.editable ||
                                    !_vm.service_settings.service_schedule_mode
                                },
                                domProps: {
                                  value:
                                    _vm.$v.service_settings.service_frequency
                                      .$model
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.$v.service_settings.service_frequency,
                                      "$model",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              !_vm.$v.service_settings.service_frequency
                                .minValue ||
                              !_vm.$v.service_settings.service_frequency.numeric
                                ? _c("div", { staticClass: "input-error" }, [
                                    _vm._v(
                                      " Service Interval Must be a positive number!"
                                    )
                                  ])
                                : _vm._e()
                            ]
                          )
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "green-divider" })
                  ]
                ),
                _vm._m(0)
              ]
            ),
            _vm.service_settings.service_schedule_mode === "distance"
              ? _c("div", { staticClass: "flex-row p-2" }, [
                  _c(
                    "div",
                    {
                      staticClass: "ml-3",
                      class: { "form-error": _vm.$v.odometer_reading.$error }
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "device-label",
                          attrs: { for: "service_odometer_reading" }
                        },
                        [_vm._v("Current Odometer")]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.$v.odometer_reading.$model,
                            expression: "$v.odometer_reading.$model"
                          }
                        ],
                        staticClass: "text-field w-input",
                        attrs: {
                          type: "text",
                          maxlength: "256",
                          placeholder: "000",
                          id: "service_odometer_reading",
                          disabled: !_vm.editable
                        },
                        domProps: { value: _vm.$v.odometer_reading.$model },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.$v.odometer_reading,
                              "$model",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      !_vm.$v.odometer_reading.minValue ||
                      !_vm.$v.odometer_reading.minValue
                        ? _c("div", { staticClass: "input-error" }, [
                            _vm._v(" Must be a positive number!")
                          ])
                        : _vm._e()
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "ml-3",
                      class: {
                        "form-error":
                          !_vm.loading &&
                          _vm.last_service_metric > _vm.odometer_reading
                      }
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "device-label",
                          attrs: { for: "avg_daily_kms" }
                        },
                        [
                          _vm._v("KMs at Last Service Event "),
                          _c("i", {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover",
                                modifiers: { hover: true }
                              }
                            ],
                            staticClass: "row-icon-button",
                            class: _vm.$config.icons.general.info,
                            attrs: {
                              title:
                                "Change the Odometer reading for your last service event on the left to change this value."
                            }
                          })
                        ]
                      ),
                      _c("div", { staticClass: "flex-row position-relative" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.last_service_metric,
                              expression: "last_service_metric"
                            }
                          ],
                          staticClass: "text-field w-100",
                          attrs: {
                            type: "text",
                            maxlength: "256",
                            placeholder: "000",
                            id: "avg_daily_kms",
                            disabled: ""
                          },
                          domProps: { value: _vm.last_service_metric },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.last_service_metric = $event.target.value
                            }
                          }
                        })
                      ]),
                      !_vm.loading &&
                      _vm.last_service_metric > _vm.odometer_reading
                        ? _c("div", { staticClass: "input-error" }, [
                            _vm._v(
                              " Hours Tracked at last Service Event should be LOWER than the current reading! "
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "ml-3",
                      class: {
                        "form-error": !_vm.loading && _vm.since_last_service < 0
                      }
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "device-label",
                          attrs: { for: "avg_daily_kms" }
                        },
                        [_vm._v("KMs Since Last Service")]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.since_last_service,
                            expression: "since_last_service"
                          }
                        ],
                        staticClass: "text-field w-100",
                        attrs: {
                          type: "text",
                          maxlength: "256",
                          placeholder: "-",
                          id: "avg_daily_kms",
                          disabled: ""
                        },
                        domProps: { value: _vm.since_last_service },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.since_last_service = $event.target.value
                          }
                        }
                      }),
                      !_vm.loading && _vm.since_last_service < 0
                        ? _c("div", { staticClass: "input-error" }, [
                            _vm._v(
                              " Negative distance since last service means either the current Odometer or the value for the last service is incorrect! "
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _c("div", { staticClass: "ml-3" }, [
                    _c(
                      "label",
                      {
                        staticClass: "device-label",
                        attrs: { for: "avg_daily_kms" }
                      },
                      [_vm._v("Avg Daily KMs")]
                    ),
                    _c("div", { staticClass: "flex-row position-relative" }, [
                      _c("input", {
                        staticClass: "text-field w-input",
                        class: {
                          warning: !_vm.loading && _vm.service_metric_mean === 0
                        },
                        attrs: {
                          type: "text",
                          maxlength: "256",
                          placeholder: "-",
                          id: "avg_daily_kms",
                          disabled: ""
                        },
                        domProps: { value: _vm.serviceMetricMeanText }
                      }),
                      !_vm.loading && _vm.service_metric_mean === 0
                        ? _c("i", {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover",
                                modifiers: { hover: true }
                              }
                            ],
                            staticClass:
                              "row-icon-button input-icon fa fa-exclamation-triangle warning-icon",
                            attrs: { title: "Not enough activity to estimate." }
                          })
                        : _vm._e()
                    ])
                  ]),
                  _c("div", { staticClass: "ml-3" }, [
                    _c(
                      "label",
                      {
                        staticClass: "device-label",
                        attrs: { for: "km_to_next" }
                      },
                      [_vm._v("KMs to next Service")]
                    ),
                    _c("input", {
                      staticClass: "text-field w-input",
                      class: {
                        warning: !_vm.loading && _vm.unitsToNextService <= 0
                      },
                      attrs: {
                        type: "text",
                        maxlength: "256",
                        placeholder: "-",
                        id: "km_to_next",
                        disabled: ""
                      },
                      domProps: { value: _vm.unitsToNextService }
                    })
                  ]),
                  _c("div", { staticClass: "ml-3" }, [
                    _c(
                      "label",
                      {
                        staticClass: "device-label",
                        attrs: { for: "est_next_service" }
                      },
                      [
                        _vm._v("Est Next Service "),
                        _c("i", {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true }
                            }
                          ],
                          staticClass: "row-icon-button",
                          class: _vm.$config.icons.general.info,
                          attrs: { title: _vm.next_service_est_tooltip }
                        })
                      ]
                    ),
                    _c("div", { staticClass: "position-relative flex-row" }, [
                      _c("input", {
                        staticClass: "text-field w-100",
                        class: {
                          warning: !_vm.loading && _vm.unitsToNextService <= 0
                        },
                        attrs: {
                          type: "text",
                          maxlength: "256",
                          placeholder: "Next Service",
                          id: "est_next_service",
                          disabled: ""
                        },
                        domProps: { value: _vm.nextServiceText }
                      }),
                      !_vm.loading && _vm.unitsToNextService <= 0
                        ? _c("i", {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover",
                                modifiers: { hover: true }
                              }
                            ],
                            staticClass:
                              "row-icon-button input-icon fa fa-exclamation-triangle warning-icon",
                            attrs: { title: "Overdue for next service!" }
                          })
                        : _vm._e(),
                      !_vm.loading && _vm.next_service_est === null
                        ? _c("i", {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover",
                                modifiers: { hover: true }
                              }
                            ],
                            staticClass:
                              "row-icon-button input-icon fa fa-exclamation-triangle warning-icon",
                            attrs: { title: "Unable to estimate next service." }
                          })
                        : _vm._e()
                    ])
                  ])
                ])
              : _vm._e(),
            _vm.service_settings.service_schedule_mode === "hours"
              ? _c("div", { staticClass: "flex-row p-2" }, [
                  _c(
                    "div",
                    {
                      staticClass: "ml-3",
                      class: { "form-error": _vm.$v.hours_tracked.$error }
                    },
                    [
                      _c(
                        "form",
                        {
                          class: { "form-error": _vm.$v.hours_tracked.$error },
                          attrs: {
                            id: "service_hours_tracked_form",
                            name: "hours-form",
                            "data-name": "Email Form"
                          }
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "device-label",
                              attrs: { for: "service_hours_tracked_form" }
                            },
                            [_vm._v("Current Hours Tracked")]
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.$v.hours_tracked.$model,
                                expression: "$v.hours_tracked.$model"
                              }
                            ],
                            staticClass: "text-field w-input",
                            attrs: {
                              type: "text",
                              maxlength: "256",
                              placeholder: "-",
                              id: "hours_tracked",
                              disabled: !_vm.editable
                            },
                            domProps: { value: _vm.$v.hours_tracked.$model },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.$v.hours_tracked,
                                  "$model",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          !_vm.$v.hours_tracked.minValue
                            ? _c("div", { staticClass: "input-error" }, [
                                _vm._v("Must be 0 or greater ")
                              ])
                            : _vm._e()
                        ]
                      )
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "ml-3",
                      class: {
                        "form-error":
                          !_vm.loading &&
                          _vm.last_service_metric > _vm.hours_tracked
                      }
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "device-label",
                          attrs: { for: "hour_at_service" }
                        },
                        [
                          _vm._v("Hours at Last Service Event "),
                          _c("i", {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover",
                                modifiers: { hover: true }
                              }
                            ],
                            staticClass: "row-icon-button",
                            class: _vm.$config.icons.general.info,
                            attrs: {
                              title:
                                "Change the hours in your last service event on hte left to change this value."
                            }
                          })
                        ]
                      ),
                      _c("div", { staticClass: "flex-row position-relative" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.last_service_metric,
                              expression: "last_service_metric"
                            }
                          ],
                          staticClass: "text-field w-100",
                          attrs: {
                            type: "text",
                            maxlength: "256",
                            placeholder: "-",
                            id: "hour_at_service",
                            disabled: ""
                          },
                          domProps: { value: _vm.last_service_metric },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.last_service_metric = $event.target.value
                            }
                          }
                        }),
                        _c("i", {
                          staticClass:
                            "row-icon-button input-icon fa fa-external-link",
                          on: { click: _vm.openLastService }
                        })
                      ]),
                      !_vm.loading &&
                      _vm.last_service_metric > _vm.hours_tracked
                        ? _c("div", { staticClass: "input-error" }, [
                            _vm._v(
                              " Hours Tracked at last Service Event should be LOWER than the current reading! "
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "ml-3",
                      class: {
                        "form-error": !_vm.loading && _vm.since_last_service < 0
                      }
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "device-label",
                          attrs: { for: "hours_since_service" }
                        },
                        [_vm._v("Hours Since Last Service")]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.since_last_service,
                            expression: "since_last_service"
                          }
                        ],
                        staticClass: "text-field w-100",
                        attrs: {
                          type: "text",
                          maxlength: "256",
                          placeholder: "-",
                          id: "hours_since_service",
                          disabled: ""
                        },
                        domProps: { value: _vm.since_last_service },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.since_last_service = $event.target.value
                          }
                        }
                      }),
                      !_vm.loading && _vm.since_last_service < 0
                        ? _c("div", { staticClass: "input-error" }, [
                            _vm._v(
                              " Negative hours since last service means either the current Hours Tracked or the value for the last service is incorrect! "
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _c("div", { staticClass: "ml-3" }, [
                    _c(
                      "label",
                      {
                        staticClass: "device-label",
                        attrs: { for: "avg_daily_hours" }
                      },
                      [_vm._v("Avg Daily Hours")]
                    ),
                    _c("div", { staticClass: "flex-row position-relative" }, [
                      _c("input", {
                        staticClass: "text-field w-input",
                        class: {
                          warning: !_vm.loading && _vm.service_metric_mean === 0
                        },
                        attrs: {
                          type: "text",
                          maxlength: "256",
                          placeholder: "-",
                          id: "avg_daily_hours",
                          disabled: ""
                        },
                        domProps: { value: _vm.serviceMetricMeanText }
                      }),
                      !_vm.loading && _vm.service_metric_mean === 0
                        ? _c("i", {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover",
                                modifiers: { hover: true }
                              }
                            ],
                            staticClass:
                              "row-icon-button input-icon fa fa-exclamation-triangle warning-icon",
                            attrs: { title: "Not enough activity to estimate." }
                          })
                        : _vm._e()
                    ])
                  ]),
                  _c("div", { staticClass: "ml-3" }, [
                    _c(
                      "label",
                      {
                        staticClass: "device-label",
                        attrs: { for: "hrs_to_next" }
                      },
                      [_vm._v("Hours to next Service")]
                    ),
                    _c("input", {
                      staticClass: "text-field w-input",
                      class: {
                        warning: !_vm.loading && _vm.unitsToNextService <= 0
                      },
                      attrs: {
                        type: "text",
                        maxlength: "256",
                        placeholder: "-",
                        id: "hrs_to_next",
                        disabled: ""
                      },
                      domProps: { value: _vm.unitsToNextService }
                    })
                  ]),
                  _c("div", { staticClass: "ml-3" }, [
                    _c(
                      "label",
                      {
                        staticClass: "device-label",
                        attrs: { for: "est_next_service" }
                      },
                      [
                        _vm._v("Est Next Service "),
                        _c("i", {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true }
                            }
                          ],
                          staticClass: "row-icon-button",
                          class: _vm.$config.icons.general.info,
                          attrs: { title: _vm.next_service_est_tooltip }
                        })
                      ]
                    ),
                    _c("div", { staticClass: "flex-row position-relative" }, [
                      _c("input", {
                        staticClass: "text-field w-input",
                        class: {
                          warning: !_vm.loading && _vm.unitsToNextService <= 0
                        },
                        attrs: {
                          type: "text",
                          maxlength: "256",
                          placeholder: "Next Service",
                          id: "est_next_service",
                          disabled: ""
                        },
                        domProps: { value: _vm.nextServiceText }
                      }),
                      !_vm.loading && _vm.unitsToNextService <= 0
                        ? _c("i", {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover",
                                modifiers: { hover: true }
                              }
                            ],
                            staticClass:
                              "row-icon-button input-icon fa fa-exclamation-triangle warning-icon",
                            attrs: { title: "Overdue for next service!" }
                          })
                        : _vm._e(),
                      !_vm.loading && _vm.next_service_est === null
                        ? _c("i", {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover",
                                modifiers: { hover: true }
                              }
                            ],
                            staticClass:
                              "row-icon-button input-icon fa fa-exclamation-triangle warning-icon",
                            attrs: { title: "Unable to estimate next service." }
                          })
                        : _vm._e()
                    ])
                  ])
                ])
              : _vm._e(),
            _c("div", { staticClass: "flex-row justify-content-end mt-2" }, [
              _c(
                "button",
                {
                  staticClass: "button",
                  attrs: { id: "button-close" },
                  on: {
                    click: function($event) {
                      return _vm.clickClose()
                    }
                  }
                },
                [_vm._v("Close")]
              ),
              _vm.editable
                ? _c(
                    "button",
                    {
                      staticClass: "button",
                      attrs: { id: "button-save" },
                      on: {
                        click: function($event) {
                          return _vm.saveChanges()
                        }
                      }
                    },
                    [_vm._v("Save Changes")]
                  )
                : _vm._e()
            ])
          ]
        )
      ]),
      _c(
        "b-modal",
        {
          staticClass: "modal-content",
          attrs: {
            id: "add-service-event-modal",
            centered: "",
            size: "lg",
            "hide-footer": "",
            title: "Add/Edit Service Event"
          }
        },
        [
          _c("device-edit-event", {
            attrs: {
              device_imei: _vm.device.device_imei,
              editable: _vm.editable,
              event: _vm.service_event,
              modal: "add-service-event-modal"
            },
            on: { save: _vm.refreshEventList }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hint-box ml-3" }, [
      _c("h5", [_vm._v("Service Scheduling")]),
      _vm._v(
        " Service Scheduling is used to estimate when a vehicle will next require servicing and to generate events/notifications when a service is overdue. Schedules are repeating and can be set in either "
      ),
      _c("strong", [_vm._v("Distance (KM)")]),
      _vm._v(" or "),
      _c("strong", [_vm._v("Runtime (Hours)")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }