<template>
  <div class="login-box">
      <div class="login-box">
        <b-alert variant="danger" :show="isInvalidLogin">
          Login Credentials Incorrect
        </b-alert>
        <b-form v-on:submit.prevent="clickLogin">
          <div class=" col s4">
            <label for="email">Email or Mobile Number</label>
            <input type="text" v-model="email" class="w-100" maxlength="128" autocomplete="username"
                   autofocus
                   placeholder="Email Address or Mobile Number" id="email" @input="setValidity(true)" ref="email">
            <div class="green-divider"></div>
          </div>
          <div class=" col s4">
            <label for="password">Password</label>
            <input type="password" v-model="password" class="w-100" maxlength="128" placeholder="Password"
                   id="password" autocomplete="current-password" ref="password" @input="setValidity(true)">
            <div class="green-divider"></div>
          </div>
          <button type="submit" class="button w-100 mt-5">Login</button>
        </b-form>
      </div>
  </div>

</template>

<script>

import * as DataProvider from '../helpers/DataProvider'
import * as ErrorHelper from '../helpers/ErrorHelper'

export default {
  name: 'login-box',
  data: function () {
    return {
      email: '',
      password: '',
      returnUrl: null,
      token: null,
      isInvalidLogin: false
    }
  },
  async mounted () {
    if (this.$route.query.hasOwnProperty('returnUrl')) {
      this.returnUrl = this.$route.query.returnUrl
    }
    if (this.$route.query.hasOwnProperty('token')) {
      this.token = this.$route.query.token
    }

    if (this.token) {
      let tokenSuccess = await this.setToken(this.token)
      if (tokenSuccess) {
        await this.defaultRouterPush()
      } else {
        ErrorHelper.displayGeneralErrorToast('The token provided is invalid. Please log in manually.',
          'Invalid Token')
      }
    }
  },
  methods: {
    setValidity (value) {
      this.isInvalidLogin = !value
      if (value) {
        this.$refs.email.setCustomValidity('')
        this.$refs.password.setCustomValidity('')
      } else {
        this.$refs.email.setCustomValidity('Invalid Credentials')
        this.$refs.password.setCustomValidity('Invalid Credentials')
      }
    },
    clickLogin: async function (e) {
      try {
        let res = await DataProvider.sendLogin(this.email, this.password)
        if (!res.success) {
          this.setValidity(false)
        } else {
          await this.defaultRouterPush()
        }
      } catch (e) {
        ErrorHelper.displayGeneralErrorToast('There was a problem logging you in! Please try again later.',
          'Something Went Wrong')
      }
      e.preventDefault()
    },
    async defaultRouterPush () {
      // Check if a redirect has been specified.
      console.log('RETURN URL: ', this.returnUrl)
      if (this.returnUrl) {
        this.$router.push({ path: this.returnUrl })
      } else {
        // TODO - Make this nicer. Maybe a config option?
        if (await this.$auth.hasRole('fleet_user')) {
          this.$router.push({ path: '/' })
        } else {
          this.$router.push({ path: '/preferences' })
        }
      }
    },
    async setToken (token) {
      try {
        await this.$auth.setToken(this.token)
        await this.$auth.queryUserId()
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '../../variables';

  .green-label {
    width: 100%;
  }

  .button {
    font-size: 20px;
  }

  input:invalid {
    outline: 1px solid red;
  }
</style>
