<template>
  <div class="">
  </div>
</template>

<script>

/****
 *
 *  Scroll Trigger
 *
 *  Monitors the Parent component and detects when scrolling reaches a certain threshold
 *  Useful for things like infinite scrolling setups
 *
 */
export default {
  name: 'ScrollTrigger',
  props: {
    threshold: Number
  },
  data: function () {
    return {
      parentElement: null
    }
  },
  mounted() {
    this.parentElement = this.$el.parentNode
    this.$el.parentNode.addEventListener('scroll', () => this.onScroll(), {passive: true})
  },
  methods: {
    onScroll () {
      let overflow = this.parentElement.scrollHeight - this.parentElement.clientHeight
      if (overflow) {
        let distanceToBottom = overflow - this.parentElement.scrollTop
        if (distanceToBottom <= this.threshold) {
          this.$emit('scroll_threshold')
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/variables';
@import '@/animations';

.hidden {
  display: none!important;
}

.visible {
  display: flex!important;
}

.popover-menu {
  position: fixed;
  left: auto;
  z-index: 2;
  top: 0;
  right: 60px;
  bottom: 0;
  // Min width is just based on the standard side menu width.
  min-width: 250px;
  height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  background-color: #2c3237;
  box-shadow: 1px 1px 20px -1px rgba(0, 0, 0, 0.51);
  overflow: hidden;
}

.popover-menu-header {
  position: relative;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  background-image: $theme-header-gradient;
}

.popover-menu-header h4 {
  color: white;
}

.popover-menu-content {
  display: flex;
  flex-flow: column nowrap;
  padding: 1px;
  overflow: hidden;
}

</style>
