<template>
  <div class="animate-in-out" :class="transitionClasses" :style="calcStyle">
    <slot></slot>
  </div>
</template>

<script>

/****
 *
 *  Popover Element
 *
 *  Animated Element designed to pop out from a side of the view.
 *  Unlike the Popout menu, this Component has minimal styling
 *
 */
export default {
  name: 'PopoverElement',
  props: {
    value: Boolean,
    direction: String,
    hidePosition: String
  },
  data: function () {
    return {
      isVisible: this.value,
      directionType: 'left',
      transitionClassIn: {
        left: 'slide-in-left',
        top: 'slide-in-top',
        bottom: 'slide-in-bottom'
      },
      transitionClassOut: {
        left: 'slide-out-left',
        top: 'slide-out-top',
        bottom: 'slide-out-bottom'
      }
    }
  },
  mounted() {
    if (this.direction) {
      if (Object.hasOwn(this.transitionClassIn, this.direction)) {
        this.directionType = this.direction
      } else {
        throw new Error('Unknown Direction Type: ' + this.direction)
      }
    }
  },
  methods: {
  },
  computed: {
    transitionClasses () {
      if (this.isVisible) {
        return this.transitionClassIn[this.directionType]
      } else {
        return this.transitionClassOut[this.directionType]
      }
    },
    calcStyle () {
      return !this.value && this.hidePosition ? `transform: translateX(${this.hidePosition})` : ''
    }
  },
  watch: {
    value (newVal) {
      this.isVisible = newVal
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/variables';
@import '@/animations';


</style>
