var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "register" }, [
    _c("div", { staticClass: "register-outer" }, [
      _vm._m(0),
      !_vm.registered
        ? _c(
            "div",
            { staticClass: "register-container justify-content-center" },
            [
              _c("register-user", {
                staticClass: "login-item",
                on: {
                  registration_complete: function($event) {
                    return _vm.registrationComplete()
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.registered
        ? _c(
            "div",
            { staticClass: "register-container justify-content-center" },
            [
              _c(
                "b-alert",
                {
                  staticClass: "login-item",
                  attrs: { variant: "success", show: "" }
                },
                [
                  _vm._v(
                    " Your account has been created successfully. Please check your email and follow the activation link to start using Pro-Tekt GPS Tracking services. click "
                  ),
                  _c("router-link", { attrs: { to: "login" } }, [
                    _vm._v("HERE")
                  ]),
                  _vm._v(" to go to the login page. ")
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ]),
    _c(
      "div",
      { staticClass: "footer" },
      [
        _c("router-link", { attrs: { to: "terms" } }, [
          _vm._v("Terms and Conditions")
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        staticClass: "logo",
        attrs: { src: require("../assets/Protekt-logo-medium.png") }
      }),
      _c("h2", { staticClass: "register-title" }, [_vm._v("Registration")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }