<template>
  <div class="generic-feature-control" v-if="!loading">
    <label :for="'feature_control_' + feature_code" class="green-label feature-label">{{config.title}}</label>

    <div class="feature_control_form" :id="'form_' + feature_code" :class="{ 'form-error': $v.value.$error }">
        <!--   Enable/Disable Checkbox for configurable features     -->
        <b-form-checkbox v-if="['text', 'textarea', 'number', 'range'].includes(config.control)"
                         size="lg" name="check-button" switch :id="'feature_control_' + feature_code + '_enable'"
                         v-model="enableFeature" v-on:change="onToggleEnable($event)"
                         :disabled="!editable || disableControl" v-b-tooltip title="Toggle Alarm"
        ></b-form-checkbox>

        <!-- Checkbox Control for boolean features-->
          <b-form-checkbox v-if="config.control === 'checkbox'"
              size="lg" name="check-button" switch :id="'feature_control_' + feature_code"
                           v-model="value" v-on:change="onValueChange($event)"
                           :disabled="!editable || disableControl" v-b-tooltip :title="description"
          ></b-form-checkbox>

        <!-- Input Control Types-->
        <b-input-group v-if="['text', 'textarea', 'number'].includes(config.control)"
                       :prepend="fieldPrepend" :append="fieldAppend">
          <b-form-input
                        :type="config.control" v-model="$v.value.$model" class="text-field w-100 w-input input"
                        maxlength="256" :placeholder="config.placeholder" :id="'feature_control_' + feature_code"
                        :disabled="!editable || disableControl || !enableFeature" v-b-tooltip :title="description"
                        v-on:change="onUnsavedSave"
          />
        </b-input-group>
        <b-input-group v-if="['range'].includes(config.control)" :prepend="fieldPrepend" :append="fieldAppend">
          <b-form-input v-if="['range'].includes(config.control)"
                        :type="config.control" v-model="$v.value.$model" class="text-field"
                        maxlength="256" :placeholder="config.placeholder" :id="'feature_control_' + feature_code"
                        :disabled="!editable || disableControl || !enableFeature" v-b-tooltip
                        :title="'Sensitivity '+ value + '%'"
                        v-on:change="onUnsavedSave"
          />
        </b-input-group>
<!--        <b-input-group-append v-if="this.config.hasOwnProperty('unit')">{{config.unit}}</b-input-group-append>-->
        <b-input-group-append>
          <i v-if="featureState === true"
             class="control-indicator" :class="$config.icons.feature_controls.confirmed"
             v-b-tooltip :title="'Confirmed '+timestampText"></i>
          <i v-if="featureState === false"
             class="control-indicator pending" :class="$config.icons.feature_controls.sent"
             v-b-tooltip :title="'Pending Device '+timestampText"></i>
          <i v-if="featureState === null"
             class="control-indicator failed" :class="$config.icons.feature_controls.failed"
             v-b-tooltip :title="'No Data '+timestampText"></i>
        </b-input-group-append>
        <div class="input-error" v-if="$v.value.hasOwnProperty('minValue') && !$v.value.minValue">
          Must be {{$v.value.$params.minValue.min}} or greater </div>
        <div class="input-error" v-if="$v.value.hasOwnProperty('maxValue') && !$v.value.maxValue">
          Must be {{$v.value.$params.maxValue.max}} or less </div>
        <div class="input-error" v-if="$v.value.hasOwnProperty('decimal') && !$v.value.decimal">
          Must be a decimal value. </div>
        <div class="input-error" v-if="$v.value.hasOwnProperty('integer') && !$v.value.integer">
          Must be a whole number value. </div>
    </div>
  </div>
</template>

<script>
import * as DataProvider from '@/components/helpers/DataProvider'
import { minLength, decimal, integer, minValue, maxLength, maxValue } from 'vuelidate/lib/validators'
import * as ErrorHelper from '@/components/helpers/ErrorHelper'
import * as AlertHelper from '@/components/helpers/AlertHelper'
import moment from 'moment'

export default {
  name: 'generic-feature-control',
  props: {
    device: Object,
    feature_value: Object,
    editable: Boolean,
    feature_code: String
  },
  data: function () {
    return {
      showButtons: true,
      loading: true,
      deviceFeatures: [],
      displayDevice: null,
      fieldPrepend: '',
      fieldAppend: '',
      description: '',
      value: '',
      config: this.$deviceService.getFeatureControlConfigSync(this.feature_code),
      validation_lookup: {
        'minLength': minLength,
        'maxLength': maxLength,
        'minValue': minValue,
        'maxValue': maxValue,
        'integer': integer,
        'decimal': decimal
      },
      disableControl: false, // 'editable' set to false in feature template, forces control to be disabled.
      // pending, confirmed, null
      featureState: null,
      // Text string representing control timestamp (if there is one)
      timestampText: '',
      dirty: false,
      enableFeature: false,
      offValue: 0,
      secondaryPropName: 'threshold'
    }
  },
  validations () {
    // We're doing this now, because apparently Vuelidate doesn't support adding validators later...
    if (this.config.validations) {
      let validations = {}
      for (let validatorName in this.config.validations) {
        validations[validatorName] = this.validation_lookup[validatorName](this.config.validations[validatorName])
      }
      return {
        value: validations }
    } else {
      return {
        value: {}
      }
    }
  },
  async mounted () {
    // Check if we've been provided with the control data as a prop
    if (this.feature_value) {
      this.updateFeatureValue(this.feature_value)
      // Check if the data prop is available on the device data we have
    } else if (this.config.prop_name in this.device) {
      this.updateFeatureValue(this.device[this.config.prop_name])
    } else {
      // If all else fails, request the feature state from the server
      this.updateFeatureValue(await this.getFeatureValue(this.config.prop_name))
    }
    // Optionally the feature config can disable a control
    if (this.config.editable === false) {
      this.disableControl = true
    }
    if (this.config.hasOwnProperty('description')) {
      this.description = this.config.description
    } else if (this.config.hasOwnProperty('placeholder')) {
      this.description = this.config.placeholder
    }
    // Legacy controls can set an 'value', since they don't have multiple properties
    if (this.config.hasOwnProperty('off_value')) {
      this.offValue = this.config.off_value
    }

    if (this.config.hasOwnProperty('unit')) {
      this.fieldAppend = this.config.unit
    }
    // Append the min/max values to range control fields
    if (this.config.control === 'range') {
      this.fieldAppend = this.config.validations.maxValue.toString()
      this.fieldPrepend = this.config.validations.minValue.toString()
    }
    this.loading = false
  },
  methods: {
    /***
     * Update/Change the value associated with the feature (i.e. Turn it off, turn it up, turn it down, etc)
     * Handles changing the value and translating between simple values and registered device commands.
     * @param featureValue Either a basic data type of an object like { value: any, timestamp: int, from_device: bool }
     */
    updateFeatureValue: function (featureValue) {
      // If the value is a registered control
      if (featureValue === null || featureValue === undefined) {
        // Use default if one is available
        if (this.config.hasOwnProperty('default_value')) {
          this.value = this.config.default_value
        } else {
          this.value = ''
        }
      } else if (featureValue.hasOwnProperty('value')) {
        if (featureValue.value === null) {
          this.value = null
        } else {
          // Use the configured tuning parameter name if there is one. Otherwise assume it's "threshold"
          if (this.config.hasOwnProperty('tuning_prop_name')) {
            this.secondaryPropName = this.config.tuning_prop_name
          }
          this.value = featureValue.value[this.secondaryPropName]
          if (featureValue.value.hasOwnProperty('enabled')) {
            this.enableFeature = featureValue.value.enabled
          }
        }
        this.timestampText = this.formatTimestamp(featureValue.timestamp)
        // Set the state indicator of the control
        // Note: a 'null' value, implies the control has never been set for the device.
        this.featureState = featureValue.from_device
      } else {
        // Looks like a simple value. We don't have visibility of change success so, just mark it as green.
        this.value = featureValue
        this.featureState = true
        this.enableFeature = !(featureValue === this.offValue)
      }
      this.dirty = false
    },
    /***
     * Fetch the control value from the server. Ignores the data type and just returns whatever the API provides.
     */
    getFeatureValue: async function (propName) {
      let resp = await DataProvider.getDeviceProperty(this.device.device_imei, propName)
      if (resp.success) {
        // Check to see if the server returned the prop wee asked for. If not return null (better than an empty object)
        if (resp.data.hasOwnProperty(propName)) {
          return resp.data[propName]
        } else {
          return null
        }
      } else {
        ErrorHelper.displayGeneralErrorToast(resp)
      }
    },
    /***
     * Enable/Disable a feature with threshold/sensitivity control
     *
     */
    onToggleEnable: function (newVal, $event) {
      this.dirty = true

      if (newVal && this.config.hasOwnProperty('default_value')) {
        this.value = this.config.default_value
      } else if (!newVal && this.config.hasOwnProperty('off_value')) {
        this.value = this.config.off_value
      }
    },
    /**
     * Value Change Event handler. Called when a control input changes.
     */
    onValueChange: async function (newVal, $event) {
      let oldValue = this.value
      // Does the config tell us to ask for confirmation?
      if (this.config.hasOwnProperty('confirmation')) {
        let resp
        // If the config has a 'confirmation_state' prop, then we only ask for confirmation when changing to that
        // specific state (i.e. when newVal = confirmation_state)
        if (this.config.hasOwnProperty('confirmation_state') && newVal !== this.config.confirmation_state) {
          resp = true
        } else if (this.config.confirmation === true) {
          // If 'confirmation' is a bool, use a default message
          resp = await this.$bvModal.msgBoxConfirm('Are you sure?')
        } else if (this.config.confirmation) {
          // Otherwise display confirmation as the confirm message
          resp = await this.$bvModal.msgBoxConfirm(this.config.confirmation)
        }
        if (resp) {
          this.value = newVal
          this.saveFeatureValue()
        } else {
          this.value = oldValue
        }
      } else {
        this.value = newVal
        await this.saveFeatureValue()
      }
    },
    /***
     * Return the data object that would be used to save to the server. This can be called by parent objects to combine
     * multiple transactions into a single transaction.
     */
    getSaveData: function () {
      let data = {}
      let value = this.value
      if (this.config.hasOwnProperty('percentage_threshold') && this.config.percentage_threshold === true) {
        value = value + '%'
      }
      // Handle legacy controls (a.k.a single values)
      if (this.config.is_legacy) {
        data[this.config.prop_name] = value
      } else {
        data[this.config.prop_name] = {
          enabled: this.enableFeature
        }
        data[this.config.prop_name][this.secondaryPropName] = value
      }
      return data
    },
    /***
     * Send changed values to the server. It doesn't matter if it's a registered value or not, as the extra data for
     * the registered values is generated by the server (timestamp and bool)
     */
    saveFeatureValue: async function () {
      if (this.$v.$anyError) {
        ErrorHelper.displayGeneralErrorToast('Some Fields Contain Invalid Data. Please fix them.', 'Invalid Fields')
        return
      }

      let data = this.getSaveData()
      // Add our prop_name to the whitelist, so we're given back an updated value
      data['whitelist'] = [this.config.prop_name]
      let resp = await DataProvider.setDeviceProperty(this.device.device_imei, data)
      if (resp.success) {
        if (resp.data.hasOwnProperty(this.config.prop_name)) {
          this.updateFeatureValue(resp.data[this.config.prop_name])
          this.$emit('save')
        }
        AlertHelper.successToast('Device Parameter Updated.', 'Success')
        this.dirty = false
      } else {
        ErrorHelper.displayDataErrorToast(resp)
      }
    },
    /***
     * Event called if the control is of a type which does not immediately save changes (e.g. a Textbox)
     * this fires an event that the parent component picks up (since it has the 'save' button)
     */
    onUnsavedSave: function () {
      if (this.$v.$anyDirty) {
        this.dirty = true
        this.$emit('change')
      }
    },
    /***
     * Save unsaved changed. This function is also called by the parent component
     */
    saveChanges: async function () {
      if (this.dirty) {
        return this.saveFeatureValue()
      } else {
        return true
      }
    },
    /***
     * Return a formatted Timestamp String or an empty string
     * @param timestamp Unix Timestamp
     * @returns {string}
     */
    formatTimestamp: function (timestamp) {
      if (timestamp) {
        return moment.unix(timestamp).local().format('YYYY/MM/DD HH:mm')
      } else {
        return ''
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '@/variables';

  .generic-feature-control{
    font-family: 'Open Sans', sans-serif;
    color: $theme-color-primary-3;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: stretch;
    flex-grow: 1;
    min-width: 300px;
    background: $theme-color-background-1;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
  }

  .feature_control_form {
    display: flex;
    flex-direction: row;
    flex-grow: 2;
    align-items: center;
    width: 100%;
  }

  .control-indicator {
    font-size: 24px;
    align-self: center;
  }

  .feature-label {
    margin: 5px 5px 0 5px;
  }

  .failed {
    color: red;
  }

  .pending {
    color: yellow;
  }

</style>
