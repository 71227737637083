var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-box" }, [
    _c(
      "div",
      { staticClass: "login-box" },
      [
        _c(
          "b-alert",
          { attrs: { variant: "danger", show: _vm.isInvalidLogin } },
          [_vm._v(" Login Credentials Incorrect ")]
        ),
        _c(
          "b-form",
          {
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.clickLogin.apply(null, arguments)
              }
            }
          },
          [
            _c("div", { staticClass: "col s4" }, [
              _c("label", { attrs: { for: "email" } }, [
                _vm._v("Email or Mobile Number")
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.email,
                    expression: "email"
                  }
                ],
                ref: "email",
                staticClass: "w-100",
                attrs: {
                  type: "text",
                  maxlength: "128",
                  autocomplete: "username",
                  autofocus: "",
                  placeholder: "Email Address or Mobile Number",
                  id: "email"
                },
                domProps: { value: _vm.email },
                on: {
                  input: [
                    function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.email = $event.target.value
                    },
                    function($event) {
                      return _vm.setValidity(true)
                    }
                  ]
                }
              }),
              _c("div", { staticClass: "green-divider" })
            ]),
            _c("div", { staticClass: "col s4" }, [
              _c("label", { attrs: { for: "password" } }, [_vm._v("Password")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.password,
                    expression: "password"
                  }
                ],
                ref: "password",
                staticClass: "w-100",
                attrs: {
                  type: "password",
                  maxlength: "128",
                  placeholder: "Password",
                  id: "password",
                  autocomplete: "current-password"
                },
                domProps: { value: _vm.password },
                on: {
                  input: [
                    function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.password = $event.target.value
                    },
                    function($event) {
                      return _vm.setValidity(true)
                    }
                  ]
                }
              }),
              _c("div", { staticClass: "green-divider" })
            ]),
            _c(
              "button",
              { staticClass: "button w-100 mt-5", attrs: { type: "submit" } },
              [_vm._v("Login")]
            )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }