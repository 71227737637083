var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.trips
      ? _c(
          "div",
          { staticClass: "trip-container" },
          _vm._l(_vm.tripList, function(trip, idx) {
            return _c("TripAvatar", {
              key: idx,
              attrs: {
                trip: trip,
                location: _vm.locations,
                selected: _vm.selectedTrips.includes(trip),
                show_address: _vm.show_addresses,
                show_tags: _vm.show_tags
              },
              on: {
                click: function($event) {
                  return _vm.clickTrip(trip, $event)
                }
              }
            })
          }),
          1
        )
      : _vm._e(),
    !_vm.tripList || _vm.tripList.length === 0
      ? _c("div", { staticClass: "empty-text p-2" }, [_vm._v(" No Trips ")])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }