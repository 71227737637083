var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "notification-subscription-list" },
    [
      _c("loading-box", { attrs: { loading: _vm.loading } }),
      _c("b-table", {
        staticClass: "bv-table",
        attrs: {
          hover: "",
          items: _vm.notificationSubscriptions,
          fields: _vm.fields,
          "select-mode": "single",
          "selected-variant": "success",
          "th-class": "bv-table-header",
          responsive: "true"
        },
        scopedSlots: _vm._u([
          {
            key: "cell(event_type)",
            fn: function(row) {
              return [
                _c("b-select", {
                  attrs: {
                    disabled: row.item.disable_edit_event_type,
                    options: _vm.eventTypes,
                    placeholder: "Select Event Type"
                  },
                  on: {
                    change: function($event) {
                      return _vm.fieldChange(row.item, $event)
                    }
                  },
                  model: {
                    value: row.item.event_type,
                    callback: function($$v) {
                      _vm.$set(row.item, "event_type", $$v)
                    },
                    expression: "row.item.event_type"
                  }
                })
              ]
            }
          },
          {
            key: "cell(notification_type)",
            fn: function(row) {
              return [
                _c("b-form-select", {
                  attrs: {
                    disabled: row.item.disable_edit_notification_type,
                    options: _vm.notificationTypes,
                    "aria-placeholder": "Select Notification Type"
                  },
                  on: {
                    change: function($event) {
                      return _vm.fieldChange(row.item, $event)
                    }
                  },
                  model: {
                    value: row.item.notification_type,
                    callback: function($$v) {
                      _vm.$set(row.item, "notification_type", $$v)
                    },
                    expression: "row.item.notification_type"
                  }
                })
              ]
            }
          },
          {
            key: "cell(actions)",
            fn: function(row) {
              return [
                _c("div", { staticClass: "flex-row" }, [
                  row.item.id === null
                    ? _c(
                        "button",
                        {
                          on: {
                            click: function($event) {
                              return _vm.clickSave(row.item)
                            }
                          }
                        },
                        [_vm._v("Save")]
                      )
                    : _vm._e(),
                  !row.item.disable_delete
                    ? _c("i", {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true }
                          }
                        ],
                        staticClass: "icon-button danger",
                        class: _vm.$config.icons.general.remove,
                        attrs: { title: "Remove/Reset" },
                        on: {
                          click: function($event) {
                            return _vm.removeItem(row.item)
                          }
                        }
                      })
                    : _vm._e()
                ])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }