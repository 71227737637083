// Manage Device Configuration Data
// TODO - Add functions for loading device data and keeping a cache for data which doesn't change.

import DeviceGenericVehicle from '@/components/device/device_types/DeviceGenericVehicle'
import DeviceGenericObject from '@/components/device/device_types/DeviceGenericObject'
import ConfigService from '@/components/helpers/ConfigHelper'
import * as DTCHelper from '@/components/helpers/DTCHelper'
import moment from 'moment/moment'

const DEVICE_TYPE_COMPONENTS = {
  'generic_object_tracker': DeviceGenericObject,
  'device_benway_gv20': DeviceGenericVehicle,
  'generic_vehicle_tracker': DeviceGenericVehicle,
  'device_sinocastel_213lu': DeviceGenericVehicle,
  'device_kingwo_nt06e': DeviceGenericObject,
  'device_concox_vl502': DeviceGenericVehicle,
  'device_concox_vl512': DeviceGenericVehicle
}

export default class DeviceService {
  // Load default config from file when the service is started
  constructor () {

  }

  /***
   * Return an array of all known available device types.
   */
  getDeviceTypes () {
    // TODO - Make this part of the config downloaded from the server
    let types = []
    for (let deviceType in DEVICE_TYPE_COMPONENTS) {
      types.push(deviceType)
    }
    return types
  }

  /***
   * Return an array of feature codes that the provided device_type implements.
   * @param deviceType
   * @returns {Promise<void>}
   */
  async getDeviceFeatures (deviceType) {
    let configData = await ConfigService.getConfig('devices')
    if (configData.hasOwnProperty('device_type_features')) {
      if (deviceType in configData.device_type_features) {
        return configData.device_type_features[deviceType]
      } else {
        console.log('Unknown Device Type!')
        return null
      }
    } else {
      console.log('Incomplete Device Config Data!')
    }
  }

  /***
   * SYNCHRONOUSLY return if a devicetype has a given feature
   * @param deviceType
   * @param feature
   */
  hasFeatureSync (deviceType, feature) {
    let config
    if (ConfigService.configs['devices']) {
      config = ConfigService.configs['devices']
    } else if (localStorage.getItem('protekt-config-devices')) {
      config = JSON.parse(localStorage.getItem('protekt-config-devices'))
    } else {
      throw Error('Unable to load Device Config Synchronously!')
    }

    if ('device_type_features' in config && deviceType in config['device_type_features']) {
      return config['device_type_features'][deviceType].includes(feature)
    } else {
      throw Error(`Unknown Device Type: ${deviceType}`)
    }
  }

  /***
   * Return the component type to use for this device_type value.
   * @param deviceType
   */
  getDeviceTypeComponent (deviceType) {
    if (deviceType in DEVICE_TYPE_COMPONENTS) {
      return DEVICE_TYPE_COMPONENTS[deviceType]
    } else {
      throw new Error('Unknown Device Type')
    }
  }

  /***
   * SYNCHRONOUSLY Return the configuration data for the given feature type.
   * Note: this function will NOT fetch fresh data and will throw an error if no Device Config is available!
   * This function is needed to configure Vuelidate validations, which can't handle promises.
   * @param featureCode
   * @returns {{control: string, placeholder: string, validations: {minValue: number, maxValue: number}, title: string, value: string, propName: string}}
   */
  getFeatureControlConfigSync (featureCode) {
    // Note: This is needs to be accessible *synchronously* for GenericFeatureControl, since we're dynamically adding
    // Vuelidate validators.
    let config
    if (ConfigService.configs['devices']) {
      config = ConfigService.configs['devices']
    } else if (localStorage.getItem('protekt-config-devices')) {
      config = JSON.parse(localStorage.getItem('protekt-config-devices'))
    } else {
      throw Error('Unable to load Device Config Synchronously!')
    }

    if (config.hasOwnProperty('feature_config')) {
      if (featureCode in config.feature_config) {
        console.log('Found Config: ', config.feature_config[featureCode])
        return config.feature_config[featureCode]
      } else {
        throw new Error('Unknown Feature Code')
      }
    } else {
      console.log('Invalid Feature Config!')
    }
  }

  async getFeatureConfigs (featureCodes) {
    /***
     * Takes an Array of Feature codes and returns an object with the requested feature configs.
     * @param featureCodes An array of feature codes (string)
     */
    let result = {}
    let config = await ConfigService.getConfig('devices')
    featureCodes.forEach(code => {
      if (code in config.feature_config) {
        result[code] = config.feature_config[code]
      } else {
        console.error(`Unknown Feature Code "${code}"`)
      }
    })
    return result
  }

  async getFeatureConfig (featureCode) {
    /***
     * Get the featureConfig for a single Feature code.
     * @param featureCodes An array of feature codes (string)
     */
    let config = await ConfigService.getConfig('devices')
    if (featureCode in config.feature_config) {
      return config.feature_config[featureCode]
    } else {
      console.error(`Unknown Feature Code "${featureCode}"`)
    }
  }

  /***
   * Function to determine if the 'error' icon should be displayed
   * Triggers if there are DTC on the ECU or if the device hasn't reported for 48 hours.
   * @param device
   * @returns {*|boolean}
   */
  hasError (device) {
    // If there are DTCs or the device hasn't checked in for 48 hours +
    return DTCHelper.hasDTCErrors(device) ||
      ((device.location && moment().unix() - device.location.timestamp) > 4147200 )
  }

  /***
   * Get an array of error messages that relate to this object
   * @param device
   * @returns {*|boolean}
   */
  getErrorMessages (device) {
    let messages = []
    // If there are DTCs or the device hasn't checked in for 48 hours +

    if ((device.location && moment().unix() - device.location.timestamp) > 4147200 ) {
      // console.log(moment.unix(device.location.timestamp).format('YYYY-MM-DD HH:mm'))
      messages.push(`This device has not reported since ${moment.unix(device.location.timestamp).format('YYYY-MM-DD HH:mm')}`)
    }

    if (DTCHelper.hasDTCErrors(device)) {
      messages.push(`This device's ECU has recorded Diagnostic Trouble Codes (DTCs). Codes are: ${DTCHelper.getDTCList(device)}`)
    }

    return messages
  }
}
