<template>
  <div class="date-range-selector">
    <form :class="{'grow-horizontal' : horizontal, 'grow-vertical': !horizontal}" class="date-range-form"
         v-on:submit.prevent="emitDates" ref="dateForm">
      <div v-if="select_picker">
        <b-form-select
          id="select-month"
          aria-placeholder="Select Dates"
          v-model="dateMode"
          :options="dateSelectOptions"
          @input="changeFilter"
        ></b-form-select>
      </div>
      <div v-else class="w-100">
        <div class="form-check">
          <input class="form-check-input" type="radio" name="filterRadio" id="filterToday" :checked="dateMode==='today'"
                 @change="changeFilter('today' )">
          <label class="form-check-label" for="filterToday">
            Today
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="filterRadio" id="filterYesterday" :checked="dateMode==='yesterday'"
                 @change="changeFilter('yesterday' )">
          <label class="form-check-label" for="filterYesterday">
            Yesterday
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="filterRadio" id="filterYesterday" :checked="dateMode==='this-week'"
                 @change="changeFilter('this-week' )">
          <label class="form-check-label" for="filterYesterday">
            This Week
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="filterRadio" id="filterYesterday" :checked="dateMode==='month'"
                 @change="changeFilter('month' )">
          <label class="form-check-label" for="filterYesterday">
            Select Month
          </label>

        </div>
        <div class="form-check" v-show="!disable_years">
          <input class="form-check-input" type="radio" name="filterRadio" id="filterYesterday" :checked="dateMode==='calendar-year'"
                 @change="changeFilter('calendar-year' )">
          <label class="form-check-label" for="filterYesterday">
            Select Calendar Year
          </label>

        </div>
        <div class="form-check" v-show="!disable_years">
          <input class="form-check-input" type="radio" name="filterRadio" id="filterYesterday" :checked="dateMode==='financial-year'"
                 @change="changeFilter('financial-year' )">
          <label class="form-check-label" for="filterYesterday">
            Select Financial Year
          </label>

        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="filterRadio" id="filterCustom" :checked="dateMode==='custom'"
                 @change="changeFilter('custom' )">
          <label class="form-check-label" for="filterCustom">
            Custom
          </label>
        </div>

      </div>
<!--      Month-Year Picker -->
      <div v-show="dateMode==='month'" class="custom-filter-container" :class="{'grow-vertical': !horizontal}">
        <label v-show="!horizontal" class="green-label" for="select-month">
          Select Month
        </label>
        <b-form-select ref="monthSelect"
          id="select-month"
          class=""
          aria-placeholder="Select Month"
          v-model="selectedMonth"
          :options="monthOptions"
          @input="selectMonth"
        ></b-form-select>
      </div>
<!--      Calendar Year Picker -->
      <div v-show="dateMode==='calendar-year'" class="custom-filter-container" :class="{'grow-vertical': !horizontal}">
        <label v-show="!horizontal" class="green-label" for="select-calendar-year">
          Select Calendar Year
        </label>
        <b-form-select ref="calYearSelect"
                       id="select-calendar-year"
                       class=""
                       aria-placeholder="Select Calendar Year"
                       v-model="selectedCalYear"
                       :options="calYearOptions"
                       @input="selectCalYear"
        ></b-form-select>
      </div>
<!--      Financial Year Picker -->
      <div v-show="dateMode==='financial-year'" class="custom-filter-container" :class="{'grow-vertical': !horizontal}">
        <label v-show="!horizontal" class="green-label" for="select-calendar-year">
          Select Calendar Year
        </label>
        <b-form-select ref="fiYearSelect"
                       id="select-calendar-year"
                       class="text-input"
                       aria-placeholder="Select Financial Year"
                       v-model="selectedFiYear"
                       :options="fiYearOptions"
                       @input="selectFiYear"
        ></b-form-select>
      </div>
      <div v-show="dateMode==='custom'" class="custom-filter-container" :class="{'grow-horizontal' : horizontal, 'grow-vertical': !horizontal}">
        <label class="green-label" for="customStart">
          Start Date
        </label>
        <input class="" type="date" id="customStart" v-model="customStart" aria-invalid="true" required
               placeholder="Start Date" @blur="validateEnd" @input="selectCustomStart" :max="customEnd" ref="customStart">
        <label class="green-label" for="customEnd">
          End Date
        </label>
        <input class="" type="date" id="customEnd" v-model="customEnd" required @blur="validateEnd"
               placeholder="End Date" @input="selectCustomEnd" :min="customStart"
               ref="customEnd">
<!--        <button type="submit" class="button w-100" >Apply</button>-->
      </div>
    </form>

  </div>
</template>
<script>

import moment from 'moment'

export default {
  name: 'DateRangeSelector',
  props: {
    default_filter_mode: String,
    default_start_date: [String, Object],
    default_end_date: [String, Object],
    default_month: [String, Object],
    default_calendar_year: [String, Number],
    default_financial_year: [String, Number],
    allow_any: Boolean,
    return_local: Boolean,
    start_year: Number,
    horizontal: Boolean, // Selector grows Horizontally
    select_picker: Boolean, // Use a select box instead of Radio Buttons
    disable_years: Boolean, // Don't show year Options,
    value: Object
  },
  emits: [
    'input',
    'cancel',
    'select_month'
  ],
  data () {
    return {
      dateMode: 'today',
      startDate: null,
      endDate: null,
      showLogs: false,
      customStart: null,
      customEnd: null,
      selectedMonth: null,
      selectedCalYear: null,
      selectedFiYear: null,
      startYear: 2019,
      include_null_filter: false, // 'Any' Option returns a null value, which is ignored by Python
      dateFunctionsEmitOnSelect: {
        any: true,
        today: true,
        yesterday: true,
        'this-week': true,
        month: false,
        custom: false
      },
      dateFunctions: {
        any: () => { return { start: null, end: null } },
        today: this.getToday,
        yesterday: this.getYesterday,
        'this-week': this.getThisWeek,
        month: this.getMonth,
        'calendar-year': this.getCalYear,
        'financial-year': this.getFiYear,
        custom: this.getCustom
      }
    }
  },
  async mounted () {
    // Use the Custom start year if it's been set
    if (this.start_year) {
      this.startYear = this.start_year
    }
    // Use the custom default filter mode if it's been provided
    if (this.default_filter_mode) {
      this.dateMode = this.default_filter_mode.toLowerCase()
    }
    // Use custom start/end dates if they have been set.
    if (this.default_start_date) {
      this.customStart = this.default_start_date
    }
    if (this.default_end_date) {
      this.customEnd = this.default_end_date
    }

    // Handle 'value' being set. This is mostly for v-model integration.
    if (this.value) {
      this.syncFromValue(this.value)
    } else {
      if (this.dateMode === 'today' || this.dateMode === 'yesterday') {
        this.emitDatesNextTick()
      } else if (this.default_start_date && this.default_end_date && this.dateMode === 'custom') {
        this.emitDatesNextTick()
      } else if (this.default_month && this.dateMode === 'month') {
        this.selectedMonth = this.default_month
        this.emitDatesNextTick()
      } else if (this.default_calendar_year && this.dateMode === 'calendar-year') {
        this.selectedCalYear = this.default_calendar_year
        this.emitDatesNextTick()
      } else if (this.default_financial_year && this.dateMode === 'financial-year') {
        this.selectedFiYear = this.default_financial_year
        this.emitDatesNextTick()
      }
    }
  },
  methods: {
    /***
     * This allows a parent to pass a v-model value to the component, which the component can then use.
     * @param newVal
     */
    syncFromValue(newVal) {
      if (newVal && Object.hasOwn(newVal, 'dateMode')) {
        this.dateMode = newVal.dateMode
      }
      if (newVal && Object.hasOwn(newVal, 'start')) {
        this.customStart = moment(newVal.start).format('YYYY-MM-DD')
      }
      if (newVal && Object.hasOwn(newVal, 'end')) {
        this.customEnd = moment(newVal.end).format('YYYY-MM-DD')
      }
    },
    /***
     * Emit Date Values on the next Tick
     */
    emitDatesNextTick() {
      this.$nextTick(() => {
        this.emitDates()
      })
    },
    /***
     * Implements the standard HTML 'SetCustomValidity' function for the datepicker.
     * The message is set on the visible UI element that is currently incomplete.
     * @param validityMessage
     */
    setCustomValidity(validityMessage) {
      if (this.dateMode === 'month') {
        this.$refs.monthSelect.$el.setCustomValidity(validityMessage)
        this.$refs.monthSelect.$el.reportValidity()
      } else if (this.dateMode === 'custom') {
        if (!this.customStart) {
          this.$refs.customStart.setCustomValidity(validityMessage)
        } else {
          this.$refs.customStart.setCustomValidity('')
        }
        if (!this.customEnd) {
          this.$refs.customEnd.setCustomValidity(validityMessage)
        } else {
          this.$refs.customEnd.setCustomValidity('')
        }
        this.$refs.dateForm.reportValidity()
      } else if (this.dateMode === 'calendar-year') {
        this.$refs.calYearSelect.$el.setCustomValidity(validityMessage)
        this.$refs.calYearSelect.$el.reportValidity()
      } else if (this.dateMode === 'financial-year') {
        this.$refs.fiYearSelect.$el.setCustomValidity(validityMessage)
        this.$refs.fiYearSelect.$el.reportValidity()
      }
    },
    /***
     * Handle the event when the datepicker mode is changed. Emits dates immediately  if the datemode does not require
     * further selections to be made. (e.g. Today)
     * @param dateFuncKey
     */
    changeFilter (dateFuncKey) {
      this.dateMode = dateFuncKey
      this.$emit('date_mode_change', dateFuncKey)
      if (this.dateFunctionsEmitOnSelect[dateFuncKey]) {
        this.emitDates()
      }
    },
    /***
     * Generate a date range for 'today'
     * @return {{start: moment.Moment, end: moment.Moment}}
     */
    getToday() {
      return {
        start: moment().startOf('day'),
        end: moment().endOf('day')
      }
    },
    /***
     * Generate a date range for 'Yesterday'
     * @return {{start: moment.Moment, end: moment.Moment}}
     */
    getYesterday() {
      return {
        start: moment().subtract(1, 'day').startOf('day'),
        end: moment().subtract(1, 'day').endOf('day')
      }
    },
    /***
     * Generate a date range for 'This Week'
     * @return {{start: moment.Moment, end: moment.Moment}}
     */
    getThisWeek () {
      return {
        start: moment().startOf('week'),
        end: moment().endOf('week')
      }
    },
    /***
     * Generate a date range for the given month-year combo.
     * @return {{start: moment.Moment, end: moment.Moment}}
     */
    getMonth () {
      if (this.selectedMonth) {
        return {
          start: moment(new Date(this.selectedMonth.year, this.selectedMonth.month, 1)).startOf('month'),
          end: moment(new Date(this.selectedMonth.year, this.selectedMonth.month, 1)).endOf('month')
        }
      }
    },
    /***
     * Generate a date range for the selected Calendar year
     * @return {{start: moment.Moment, end: moment.Moment}}
     */
    getCalYear() {
      if (this.selectedCalYear) {
        return {
          start: moment(new Date(this.selectedCalYear, 1, 1)).startOf('month'),
          end: moment(new Date(this.selectedCalYear, 11, 1)).endOf('month')
        }
      }
    },
    getFiYear() {
      if (this.selectedFiYear) {
        return {
          start: moment(new Date(this.selectedFiYear - 1, 6, 1)).startOf('month'),
          end: moment(new Date(this.selectedFiYear, 7, 1)).endOf('month')
        }
      }
    },
    /***
     * Generate a date range based on the custom start and end dates.
     * @return {{start: moment.Moment, end: moment.Moment}}
     */
    getCustom () {
      return {
        start: moment(this.customStart).startOf('day'),
        end: moment(this.customEnd).endOf('day')
      }
    },
    /***
     * Handle selecting a month/year combo.
     */
    selectMonth() {
      this.setCustomValidity('')
      this.$emit('select_month', this.selectedMonth)
      this.emitDates()
    },
    /***
     * Handle selecting a month/year combo.
     */
    selectCalYear() {
      this.setCustomValidity('')
      this.$emit('select_calendar_year', this.selectedCalYear)
      this.emitDates()
    },
    /***
     * Handle selecting a month/year combo.
     */
    selectFiYear() {
      this.setCustomValidity('')
      this.$emit('select_financial_year', this.selectedFiYear)
      this.emitDates()
    },
    /***
     * Called when the componenet is ready to send it's input event
     */
    emitDates() {
      let dates = this.dateFunctions[this.dateMode]()
      if (this.return_local && this.dateMode !== 'any') {
        dates.start = moment(dates.start).local().format('YYYY-MM-DD HH:mm:ss')
        dates.end = moment(dates.end).local().format('YYYY-MM-DD HH:mm:ss')
      } else if (this.dateMode !== 'any') {
        dates.start = moment.utc(dates.start).toISOString()
        dates.end = moment.utc(dates.end).toISOString()
      }
      dates.mode = this.dateMode
      this.$emit('input', dates)
    },
    /***
     * Converts a Date to a string that the HTML Date picker can understand
     * @param date
     */
    dateToString(date) {
      return moment(date).format('YYYY-MM-DD')
    },
    /***
     * Validate custom start end dates to make sure the end is not before the start.
     * Note: The HTML Validation *should* take care of this.
     * @return {boolean}
     */
    validateEnd () {
      if (this.customEnd < this.customStart) {
        this.$refs.customEnd.setCustomValidity('End Date must be AFTER start date.')
        return false
      } else {
        // Yes... for whatever reason this is the way to clear the validator. I'm not angry JS, I'm just disappointed.
        this.$refs.customEnd.setCustomValidity('')
        return true
      }
    },
    /***
     * Turn a key into a human readable name
     * @param key
     */
    formatName (key) {
      key = key.charAt(0).toUpperCase() + key.slice(1, key.length)
      return key.replace('-', ' ')
    },
    /***
     * Event handler for selecting a custom Start Date
     */
    selectCustomStart() {
      this.$emit('select_custom_start', this.customStart)
      this.selectCustomDate()
    },
    /***
     * Event handler for selecting a custom End Date
     */
    selectCustomEnd() {
      this.$emit('select_custom_end', this.customEnd)
      this.selectCustomDate()
    },
    /***
     * Parent Event handler for selecting a custom date.
     */
    selectCustomDate() {
      if (this.customStart && this.customEnd) {
        if (this.validateEnd() && this.$refs.dateForm.checkValidity()) {
          this.emitDates()
        }
      }
    }
  },
  computed: {
    /***
     * Generates a list of Available Date Modes
     * @return {{text: *, value: *}[]}
     */
    dateSelectOptions () {
      return Object.keys(this.dateFunctions).map((dk) => { return { text: this.formatName(dk), value: dk } })
    },
    /***
     * Generates a list of available Month-Year pairs for use when selecting a month.
     * @return {{disabled: boolean, text: string, value: null}[]}
     */
    monthOptions () {
      let m = [...Array(12).keys()].map(i => moment.months(i))
      let y = Array.from({length: moment().year() - this.startYear + 1}, (_, i) => i + this.startYear)
      let months = [].concat(...y.map(year => m.map((month, mn) => {
        return {
          text: `${year}-${month}`,
          value: { month: mn, year }
        }
      }))).reverse()
      return [{
        value: null,
        text: 'Select Month',
        disabled: true
      }].concat(months.filter(o => o.value.year !== moment().year() || o.value.month <= moment().month()))
    },
    calYearOptions () {
      let years = Array.from({length: moment().year() - this.startYear + 1}, (_, i) => i + this.startYear).reverse()
      let yearOptions = years.map((y) => { return { text: `${y} - Jan to Dec`, value: y } })
      return [{
        value: null,
        text: 'Select Calendar Year',
        disabled: true
      }].concat(yearOptions)
    },
    fiYearOptions () {
      let years = Array.from({length: moment().year() - this.startYear + 1}, (_, i) => i + this.startYear).reverse()
      let yearOptions = years.map((y) => { return { text: `FY${y} - Jul ${y - 1} to Jun ${y}`, value: y } })
      return [{
        value: null,
        text: 'Select Financial Year',
        disabled: true
      }].concat(yearOptions)
    }
  },
  watch: {
    value (newVal) {
      this.syncFromValue(newVal)
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../variables';

.date-range-selector {
  display: flex;
  align-content: flex-start;
  color: $text-color-invert;
}

.grow-vertical {
  flex-flow: column wrap!important;
  flex-grow: 1;
}

.grow-horizontal {
  flex-flow: row wrap!important;
}

.date-range-form {
  display: flex;
  align-content: flex-start;
}

.grow-horizontal > div:not(:first-child) {
  margin-left: 2em;
}

.form-check-input {
  height: 1em;
  width: 1em;
  margin-top: 0.1rem;
  margin-left: -0.25rem;
}

.form-check-label {
  text-align: start;
  margin-left: 1.2em;
}

.custom-filter-container {
  display: flex;
  //align-items: center;
}

.green-label {
  margin-top: 0;
}

.text-input {
  color-scheme: dark;
  border-radius: 5px;
  background-color: rgba(0,0,0, 0.25);
}

.custom-select {
  padding-right: 2em;
}

.form-control:focus {
  color: inherit;
  background-color: rgba(0,0,0, 0.25);
}

</style>
