var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "logout" }, [
    _c("div", { staticClass: "logout-container" }, [
      _c("img", {
        staticClass: "logo",
        attrs: { src: require("../assets/Protekt-logo-medium.png") }
      }),
      _c("div", { staticClass: "d-flex justify-content-center mt-5" }, [
        _c("h2", [_vm._v(_vm._s(_vm.message))])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }