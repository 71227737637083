var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.control.visible
    ? _c(
        "div",
        { staticClass: "control", class: { "form-error": _vm.control.errors } },
        [
          _c(
            "label",
            {
              class: _vm.labelClass,
              on: {
                click: function($event) {
                  return _vm.handleChange(_vm.control.path, !_vm.control.data)
                }
              }
            },
            [_vm._v(_vm._s(_vm.labelText))]
          ),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.control.data,
                expression: "control.data"
              }
            ],
            attrs: {
              id: _vm.control.id + "-input",
              rows: _vm.computedRows,
              disabled: !_vm.control.enabled,
              placeholder: _vm.placeholderText,
              required: _vm.isRequired
            },
            domProps: { value: _vm.control.data },
            on: {
              change: function($event) {
                return _vm.handleChange(_vm.control.path, _vm.control.data)
              },
              focus: function($event) {
                _vm.isFocused = true
              },
              blur: function($event) {
                _vm.isFocused = false
              },
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.control, "data", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _vm.control.errors
            ? _c("div", { staticClass: "error" }, [
                _vm._v(" " + _vm._s(_vm.errorMessages) + " ")
              ])
            : _vm._e()
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }