var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.form
    ? _c(
        "div",
        {
          staticClass: "form-avatar-container",
          class: {
            clickable: !_vm.disabled,
            disabled: _vm.disabled,
            hover: !_vm.disabled,
            selected: _vm.selected,
            small: _vm.size === "small",
            outline: _vm.variant === "outline"
          },
          style:
            "border-color: " +
            _vm.cAccentColor +
            "; outline-color: " +
            _vm.cAccentColor,
          on: { click: _vm.emitClick }
        },
        [
          _c("i", {
            staticClass: "template-icon fa",
            class: _vm.cformIconClass,
            style: "color: " + _vm.cformIconColor + ";"
          }),
          _c("div", { staticClass: "detail-outer" }, [
            _c("div", { staticClass: "form-avatar-heading flex-row" }, [
              _c("div", { style: "color: " + _vm.cAccentColor }, [
                _vm._v(" " + _vm._s(_vm.cName) + " ")
              ])
            ]),
            _c("div", { staticClass: "detail-inner" }, [
              _c("i", {
                directives: [{ name: "b-tooltip", rawName: "v-b-tooltip" }],
                staticClass: "status-icon fa",
                class: _vm.cStatusIcon,
                attrs: { title: _vm.cStatusText }
              }),
              _vm._v(" " + _vm._s(_vm.cCreated) + " ")
            ])
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }