var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "device-details" },
    [
      _c("loading-box", { attrs: { loading: _vm.loading } }),
      _vm.deviceImei
        ? _c(
            "div",
            [
              _c("label", [_vm._v(_vm._s(_vm.deviceInternal.name))]),
              _c("div", { staticClass: "green-divider" }),
              _c("div", { staticClass: "device-blocks" }, [
                _c("div", { staticClass: "device-item" }, [
                  _c("p", { staticClass: "device-item-heading" }, [
                    _vm._v("Device Code: "),
                    _c("span", { staticClass: "device-item-text" }, [
                      _vm._v(_vm._s(_vm.deviceInternal.device_code))
                    ])
                  ])
                ]),
                _c("div", { staticClass: "device-item" }, [
                  _c("p", { staticClass: "device-item-heading" }, [
                    _vm._v("Plant Name: "),
                    _c("span", { staticClass: "device-item-text" }, [
                      _vm._v(_vm._s(_vm.deviceInternal.plant_num))
                    ])
                  ])
                ])
              ]),
              _vm.dtcHelper.hasDTCErrors(_vm.device)
                ? _c(
                    "div",
                    [
                      _c("vehicle-dtc-control", {
                        attrs: { device: _vm.deviceInternal }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c("div", [
                _c(
                  "button",
                  {
                    staticClass: "w-100",
                    attrs: { id: "events-log" },
                    on: {
                      click: function($event) {
                        return _vm.clickShowEvents()
                      }
                    }
                  },
                  [_vm._v("Events Log")]
                ),
                !_vm.enableEdit
                  ? _c(
                      "button",
                      {
                        staticClass: "w-100",
                        attrs: { id: "view-device" },
                        on: {
                          click: function($event) {
                            return _vm.clickUpdateDevice()
                          }
                        }
                      },
                      [_vm._v(" View Device Details")]
                    )
                  : _vm._e(),
                _vm.enableEdit
                  ? _c(
                      "button",
                      {
                        staticClass: "w-100",
                        attrs: { id: "update-device" },
                        on: {
                          click: function($event) {
                            return _vm.clickUpdateDevice()
                          }
                        }
                      },
                      [_vm._v(" Edit Device Details")]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "mt-2" },
                  [
                    _c("trips-control", {
                      ref: "trips_control",
                      attrs: {
                        device: _vm.deviceInternal,
                        "enable-edit": _vm.enableEdit,
                        autoload_route: _vm.autoLoadRoute,
                        event_id: _vm.selectedEventId,
                        start_date: _vm.startDate,
                        end_date: _vm.endDate,
                        trip_id: _vm.selectedTripId
                      },
                      on: {
                        "select-event": _vm.clickEventMarker,
                        change: _vm.tripsChange
                      }
                    })
                  ],
                  1
                )
              ]),
              _c(
                "div",
                { staticClass: "mt-2" },
                [
                  _c("livetrack-control", {
                    ref: "livetrack_control",
                    attrs: {
                      device_imei: _vm.deviceImei,
                      enableEdit: _vm.enableEdit
                    },
                    on: { change: _vm.livetrackChange }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mt-2" },
                [
                  _c("geolimit-control", {
                    attrs: {
                      device_imei: _vm.deviceImei,
                      enableEdit: _vm.enableEdit
                    }
                  })
                ],
                1
              ),
              _c(
                "PopoutMenu",
                {
                  attrs: { title: "Event History" },
                  model: {
                    value: _vm.showEvents,
                    callback: function($$v) {
                      _vm.showEvents = $$v
                    },
                    expression: "showEvents"
                  }
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "w-100",
                      attrs: { id: "add-event" },
                      on: {
                        click: function($event) {
                          return _vm.clickAddEvent()
                        }
                      }
                    },
                    [_vm._v("New Event")]
                  ),
                  _c("device-event-control", {
                    ref: "eventList",
                    attrs: {
                      device_imei: _vm.deviceImei,
                      editable: _vm.enableEdit,
                      start_date: _vm.startDate,
                      end_date: _vm.endDate
                    },
                    on: {
                      select: function($event) {
                        return _vm.selectEvent($event)
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.deviceInternal.has_subscription,
                      expression: "!deviceInternal.has_subscription"
                    }
                  ],
                  staticClass: "unpaid-popup"
                },
                [
                  _c(
                    "b-alert",
                    { attrs: { variant: "danger", show: "", dismissible: "" } },
                    [
                      _c(
                        "p",
                        [
                          _vm._v(
                            "This device currently has an expired subscription, which has temporarily paused tracking. To resume tracking, please update your subscription details using the "
                          ),
                          _c("strong", [_vm._v("Subscription")]),
                          _vm._v(" tab of the "),
                          _c("strong", [_vm._v("Preferences page")]),
                          _vm._v(" or by "),
                          _c("router-link", { attrs: { to: "/subscribe" } }, [
                            _vm._v("clicking here.")
                          ])
                        ],
                        1
                      ),
                      _vm._v(
                        " If you need any assistance, feel free to email our support team at support@protektgps.com or call 1300 95 25 30"
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "b-modal",
                {
                  staticClass: "modal-content",
                  attrs: {
                    id: "modal-1",
                    centered: "",
                    size: "lg",
                    "hide-footer": "",
                    title: "Edit Device"
                  }
                },
                [
                  _c(
                    "b-tabs",
                    [
                      _c(
                        "b-tab",
                        { attrs: { title: "Properties" } },
                        [
                          _c("edit-device", {
                            attrs: {
                              "is-modal": "",
                              modal: "modal-1",
                              device: _vm.deviceInternal,
                              editable: _vm.enableEdit
                            },
                            on: {
                              save: function($event) {
                                return _vm.onSave()
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-tab",
                        { attrs: { title: "Status" } },
                        [
                          _c("device-status", {
                            attrs: {
                              "is-modal": "",
                              modal: "modal-1",
                              device: _vm.deviceInternal,
                              editable: _vm.enableEdit
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-tab",
                        { attrs: { title: "Alarms" } },
                        [
                          _c("device-alarms", {
                            attrs: {
                              "is-modal": "",
                              modal: "modal-1",
                              device: _vm.deviceInternal,
                              editable: _vm.enableEdit
                            },
                            on: {
                              save: function($event) {
                                return _vm.onSave()
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-tab",
                        { attrs: { title: "Services" } },
                        [
                          _c("device-service-schedule", {
                            attrs: {
                              modal: "modal-1",
                              device: _vm.deviceInternal,
                              editable: _vm.enableEdit
                            },
                            on: {
                              save: function($event) {
                                return _vm.onSave()
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-tab",
                        { attrs: { title: "Notifications" } },
                        [
                          _c("edit-user-notifications", {
                            attrs: { device: _vm.deviceInternal }
                          })
                        ],
                        1
                      ),
                      _vm.enableEdit
                        ? _c(
                            "b-tab",
                            { attrs: { title: "Trip Purpose", lazy: "" } },
                            [
                              _c("DeviceTripPurposeSettings", {
                                attrs: {
                                  device: _vm.deviceInternal,
                                  "is-modal": "",
                                  modal: "modal-1",
                                  editable: _vm.enableEdit
                                },
                                on: { change: _vm.refreshDeviceData }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.enableEdit
                        ? _c(
                            "b-tab",
                            { attrs: { title: "Advanced", lazy: "" } },
                            [
                              _c("DeviceAdvancedSettings", {
                                attrs: {
                                  device: _vm.deviceInternal,
                                  "is-modal": "",
                                  modal: "modal-1",
                                  editable: _vm.enableEdit
                                },
                                on: { change: _vm.refreshDeviceData }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "b-tab",
                        { attrs: { title: "Auditlog" } },
                        [
                          _c("h2", [_vm._v("Audit Log")]),
                          _c("audit-log", {
                            attrs: {
                              related_type: "device",
                              related_id: _vm.deviceInternal.device_id
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-tab",
                        { attrs: { title: "Integrations" } },
                        [
                          _c("device-integrations-config", {
                            attrs: {
                              "is-modal": "",
                              modal: "modal-1",
                              device: _vm.deviceInternal,
                              editable: _vm.enableEdit
                            },
                            on: {
                              save: function($event) {
                                return _vm.onSave()
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-modal",
                {
                  staticClass: "modal-content",
                  attrs: {
                    id: "add-event-modal",
                    centered: "",
                    size: "lg",
                    "hide-footer": "",
                    title: "Edit Event"
                  }
                },
                [
                  _c("device-edit-event", {
                    attrs: {
                      device_imei: _vm.deviceImei,
                      editable: _vm.enableEdit,
                      modal: "add-event-modal",
                      event: _vm.selectedEventData
                    },
                    on: { save: _vm.refreshEvents }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }