import moment from 'moment'
import { DateTimeHelper as dt } from '@/components/helpers/DateTimeHelper'


export class MetricFormatter {
  static numberFormat (value, precision = 2) {
    if (isNaN(value)) {
      return value
    } else {
      if (value) {
        return parseFloat(value).toFixed(precision)
      } else {
        return 0
      }
    }
  }

  static integerFormat (value, defaultValue = 'None') {
    if (value === null) {
      return defaultValue
    } else {
      return `${value.toFixed(0)}`
    }
  }

  static boolFormat (value) {
    if (value) {
      return 'Yes'
    } else {
      return 'No'
    }
  }

  static fuelFormat (value) {
    if (value === 'None' || value === 0) {
      return '0.00'
    } else {
      return `${(value * 0.01).toFixed(2)}`
    }
  }

  static localDate (value) {
    if (value === null || value === 0) {
      return ''
    } else {
      return dt.toLocalDate(value)
    }
  }

  static localTime (value) {
    if (value === null || value === 0) {
      return ''
    } else {
      return dt.toLocalTime(value)
    }
  }

  static localDateTime (value) {
    if (value === null || value === 0) {
      return ''
    } else {
      return dt.toLocalDateTime(value)
    }
  }

  static secondsToTime (value) {
    if (value === null || value === 0) {
      return '00:00'
    } else {
      return dt.secondsToTime(value)
    }
  }

  static kmsFormat (value) {
    if (value === 'None' || value === 0) {
      return '0.00 Kms'
    } else {
      return `${(value).toFixed(2)} Kms`
    }
  }
}
