<template>
  <div class="edit-user-notifications" >
    <div>
      Please make sure you have read the
      <router-link
        :to="{ path: '/preferences', query: { tab: 'help' } }"
      >
        Help Section on Notification Rules</router-link
      >
      before you use this feature.
    </div>
    <LoadingBox :loading="loading"></LoadingBox>
    <div class="flex-row justify-content-end slim-controls">
      <button @click="resetRules" class="danger" v-if="!device">Reset All</button>
    </div>
    <div class="main-container">
      <div v-for="(category, idx) of categories" v-bind:key="idx" class="category-container">
        <div class="category-title-row">
          <div class="sub-item-name">
            <i class="icon-button" :class="$config.icons.twisties.collapsed_before"
               v-b-tooltip.hover title="Show Rules" v-if="!category.expanded" @click="category.expanded = true"></i>
            <i class="icon-button" :class="$config.icons.twisties.expanded" @click="category.expanded = false"
               v-b-tooltip.hover title="Hide Rules" v-if="category.expanded"></i>
            <strong @click="category.expanded = !category.expanded">{{category.name}}</strong> Rules
            <i class="icon-button" :class="$config.icons.general.info" v-b-tooltip.hover :title="category.help"></i>
          </div>
          <i class="icon-button" :class="$config.icons.general.add" v-b-tooltip.hover title="Add Rule" @click="addRule(category)"></i>
        </div>
        <div class="green-divider"></div>
        <div v-if="category.expanded" class="category-rules-container">
          <notification-subscription-list
            :data="category.children"
            @change="categoryChanged(category, $event)"
            :user="user">
          </notification-subscription-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as DataProvider from '../helpers/DataProvider'
import * as ErrorHelper from '../helpers/ErrorHelper'

import LoadingBox from '../helpers/LoadingBox'
import NotificationSubscriptionList from '@/components/notifications/NotificationSubscriptionList'

export default {
  name: 'edit-user-notifications',
  components: {
    LoadingBox,
    NotificationSubscriptionList
  },
  props: {
    modal: String,
    user: Object,
    device: Object
  },
  data: function () {
    return {
      loading: true,
      showButtons: true,
      defaultCategory: {
        name: 'Default',
        id: 'all',
        help: 'Rules created in the default category will apply to ALL vehicles, unless that vehicle has a more specific' +
          ' custom rule.',
        expanded: true,
        children: []
      },
      categories: [
        {
          name: 'All',
          id: 'all',
          children: []
        }
      ],
      notificationTypes: [
      ],
      defaultNotificationType: 'push',
      eventTypes: [],
      devices: [
        {
          device_imei: '',
          device_name: '',
          children: []
        }
      ],
      newTemplate: {
        id: null,
        device_imei: '',
        device_name: '',
        event_type: null,
        notification_type: null
      }
    }
  },
  async created () {
    if (this.device) {
      this.devices = {}
      this.devices[this.device.device_imei] = this.device
    } else {
      this.devices = await this.loadDevices()
    }
    let subdata
    let showDefaultCategory = true
    if (this.user) { // Admin Mode: Getting data for another user
      subdata = await this.adminLoadNotificationSubscriptions()
    } else if (this.device) { // Single-Device Mode: Getting data for a single device
      subdata = await this.loadSingleDeviceSubscriptions()
      showDefaultCategory = false
    } else { // Normal Mode: Preferences-type view, showing all devices for the current user
      subdata = await this.loadNotificationSubscriptions()
    }
    this.categories = this.loadCategories(this.devices, subdata, showDefaultCategory)
    this.loading = false
  },
  methods: {
    loadSingleDeviceSubscriptions: async function () {
      let result = await DataProvider.getNotificationSubscriptionsForDevice(this.device.device_imei)
      if (result.success) {
        return result.data
      } else {
        ErrorHelper.displayDataErrorToast(result)
      }
    },
    loadDevices: async function () {
      // TODO - Make add an option to pull only basic device data for functions like this
      let data = []
      if (this.user) {
        data = {}
        let deviceList = this.user.devices.concat(this.user.shared_devices)
        deviceList.forEach((x) => { data[x.device_imei] = x })
      } else {
        let resp = await DataProvider.getDeviceList()
        if (resp.success) {
          data = resp.data
        } else {
          ErrorHelper.displayDataErrorToast(resp)
        }
      }
      return data
    },
    loadNotificationSubscriptions: async function (data = null) {
      if (!data) {
        let response = await DataProvider.getNotificationSubscriptions()
        data = []
        // Give up if we failed to get data
        if (!response.success) {
          ErrorHelper.displayDataErrorToast(response)
          return
        }
        data = response.data
      }
      let defaultSub = data.find(sub => sub.event_type === 'all' && sub.device_imei === 'all')
      if (defaultSub) {
        defaultSub.disable_edit_event_type = true
        defaultSub.disable_delete = true
      }
      return data
    },
    adminLoadNotificationSubscriptions: async function (data = null) {
      if (!data) {
        let response = await DataProvider.adminGetNotificationSubscriptions(this.user.email)
        data = []
        // Give up if we failed to get data
        if (!response.success) {
          ErrorHelper.displayDataErrorToast(response)
          return
        }
        data = response.data
      }
      let defaultSub = data.find(sub => sub.event_type === 'all' && sub.device_imei === 'all')
      if (defaultSub) {
        defaultSub.disable_edit_event_type = true
        defaultSub.disable_delete = true
      }
      return data
    },
    // Organise NotificationSubscriptions by Device
    loadCategories: function (devices, subData, addDefaultCategory = true) {
      console.log('Displaying Rules: ', subData)
      let categories = []
      // Generate a list of all the user's devices
      if (addDefaultCategory) {
        categories = [
          this.defaultCategory
        ]
        categories[0].children = subData.filter(item => item.device_imei === 'all')
      }
      let deviceIds = Object.keys(devices)
      for (let deviceId of deviceIds) {
        categories.push({
          name: devices[deviceId].device_name,
          id: deviceId,
          expanded: false,
          help: `Rules added to this category will affect notifications for the ${devices[deviceId].device_name} vehicle
          only`,
          children: subData.filter(item => item.device_imei === deviceId)
        })
      }
      // If we didn't include a default category, expand the first category
      if (!addDefaultCategory) {
        categories[0].expanded = true
      }
      return categories
    },
    addRule: function (category) {
      category.expanded = true
      let newRule = { ...this.newTemplate }
      newRule.device_imei = category.id
      newRule.device_name = category.name
      category.children.push(newRule)
    },
    resetRules: async function () {
      let confirm
      if (this.user) {
        confirm = await this.$bvModal.msgBoxConfirm(`This will remove ALL custom notification rules for
        ${this.user.email}. Are you sure?`)
      } else {
        confirm = await this.$bvModal.msgBoxConfirm('This will remove ALL custom notification rules and' +
          ' return your notifications to defaults. Are you sure?')
      }

      if (confirm) {
        let res
        if (this.user) {
          res = await DataProvider.adminResetAllNotificationSubscriptions(this.user.email)
        } else {
          res = await DataProvider.resetAllNotificationSubscriptions()
        }
        console.log(res.data)
        if (res.success) {
          let subData = await this.loadNotificationSubscriptions(res.data)
          console.log(subData)
          this.categories = this.loadCategories(this.devices, subData)
        }
      }
    },
    // Event triggered when the NotificationSubscriptionList changes it's data somehow.
    categoryChanged: function (category, data) {
      // Update the category data we have stored with the changes from the NotificationSubscriptionList sub-component
      category.children = data
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '../../variables';

  .main-container{
    display: flex;
    flex-direction: column;
    flex-wrap:  nowrap;
    align-items: center;
    //background: $theme-color-background-4;
    width: 100%;
    min-height: 30vh;
    padding: 5px;
  }

  .sub-item-name {
    text-wrap: none;
    font-size: 1.5rem;
    margin-right: 2em;
  }

  .category-container {
    display: flex;
    flex-direction: column;
    flex-wrap:  nowrap;
    align-items: center;
    background: $theme-color-background-1;
    width: 100%;
    margin: 10px 5px;
    border-radius: 10px;
    box-shadow: 5px 5px 5px black;
  }

  .category-title-row {
    display: flex;
    flex-direction: row;
    flex-wrap:  nowrap;
    align-items: center;
    background: $theme-color-background-1;
    width: 100%;
    justify-content: space-between;
    border-radius: 10px;
  }

  .category-rules-container {
    display: flex;
    flex-direction: row;
    flex-wrap:  nowrap;
    align-items: center;
    background: $theme-color-background-2;
    width: 100%;
  }

  @media screen and (max-width: 600px) {
    .footer {
      justify-content: space-around;
      margin: 0;
    }
    .button {
      margin-top: 5%;
    }
  }

</style>
