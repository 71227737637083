<template>
  <div class="forgotten-password-box">
    <div v-if="mode==='sendLink'">
      <div class="property-item">
        <label for="user_email" class="device-label">Enter your Email Address or Mobile Number and we'll send you a code</label>
        <input type="text" v-model="identifier" class="w-100"
               maxlength="256" placeholder="Email or Mobile Number" id="user_email">
      </div>
      <div class="fake-link" @click="mode='enterCode'">I already have a reset or activation code</div>
      <b-button class="button" @click="clickReset()">Send</b-button>
    </div>

    <div v-if="mode==='enterCode'">
      <div class="property-item">
        <label for="reset-code" class="device-label">Enter the Reset Code from your email or SMS:</label>
        <input type="text" v-model="resetCode" class="w-100"
               maxlength="256" placeholder="Enter Reset Code from Email or SMS" id="reset-code">
      </div>
      <div class="property-item">
        <label for="password" class="device-label">Enter a New Password:</label>
        <input type="password" v-model="password" class="w-100"
               maxlength="256" placeholder="New Password" id="password">
      </div>
      <div class="property-item">
        <label for="confirm-password" class="device-label">Retype New Password:</label>
        <input type="password" v-model="passwordConfirm" class="w-100"
               maxlength="256" placeholder="Confirm Password" id="confirm-password">
      </div>
      <button class="" @click="clickChangePassword()">Change Password</button>
    </div>
  </div>
</template>

<script>
import * as DataProvider from '../helpers/DataProvider'
import * as ErrorHelper from '../helpers/ErrorHelper'
export default {
  name: 'forgotten-password-box',
  props: [ 'modal', 'code', 'email' ],
  data: function () {
    return {
      identifier: '',
      method: '',
      mode: 'sendLink',
      resetCode: '',
      password: '',
      passwordConfirm: ''
    }
  },
  mounted () {
    if (this.email) {
      this.identifier = this.email
    }
    if (this.code) {
      this.resetCode = this.code
    }
    if (this.code && this.email) {
      this.mode = 'enterCode'
    }
  },
  methods: {
    clickReset: async function () {
      let response
      if (this.identifier.includes('@')) {
        response = await DataProvider.forgottenPasswordEmail(this.identifier)
      } else {
        response = await DataProvider.forgottenPasswordMobile(this.identifier)
      }
      if (response.success) {
        this.mode = 'enterCode'
        this.$bvToast.toast('We\'ve sent you a reset code! Check your .', {
          title: 'Password Reset Code Sent',
          variant: 'success',
          toaster: 'b-toaster-top-center'
        })
      } else {
        ErrorHelper.displayDataErrorToast(response)
      }
    },
    clickChangePassword: async function () {
      if (this.resetCode === '') {
        ErrorHelper.displayGeneralErrorToast('You must enter a reset code to proceed.', 'Reset Code Empty')
        return
      }
      if (this.password === '') {
        ErrorHelper.displayGeneralErrorToast('You must enter a new password.', 'Password Blank')
        return
      }
      if (this.password !== this.passwordConfirm) {
        ErrorHelper.displayGeneralErrorToast('Your Password and Confirmed passwords do not match! Please enter them again.', 'Password Mismatch')
        return
      }
      let response = await DataProvider.forgottenPasswordChange(this.resetCode, this.password)
      if (response.success) {
        this.$bvToast.toast('Your password reset was successful! You are now being logged in.', {
          title: 'Password Change Successful',
          variant: 'success',
          toaster: 'b-toaster-top-center'
        })
        if (this.identifier !== '') {
          let loginResponse = await DataProvider.sendLogin(this.identifier, this.password)
          if (loginResponse.success) {
            this.$router.push('/')
          } else {
            ErrorHelper.displayDataErrorToast(loginResponse)
          }
        }
        // Check if we have a modal set. If we do, close it.
        if (this.modal) {
          this.$bvModal.hide(this.modal)
        }
      } else {
        ErrorHelper.displayDataErrorToast(response)
      }
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '../../variables';

  .forgotten-password-box {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

</style>
