<template>
  <div class="device-advanced-settings flex-row">
    <loading-box v-bind:loading="loading"></loading-box>
    <div class="flex-column">
<!--      Trip Computation Mode -->
      <div class="flex-column w-100 align-items-between">
        <h4>Trip Computation</h4>
        <div class="flex-row align-items-start justify-content-between w-100">
          <div class="flex-column">
            <label>Trip Computation Mode</label>
            <b-select :options="tripCompModes" v-model="tripMode" :disabled="!editable"></b-select>
            <div class="flex-column" v-if="tripMode===2">
              <label>Trip Parked Threshold (seconds)</label>
              <div class="flex-row">
                <input type="number" v-model="tripMoveTimeLimit" min="30">
                <i class="icon-button fa" :class="$config.icons.general.info"
                   v-b-tooltip title="How long in seconds, is a device stationary before it is considered 'parked'?"
                ></i>
              </div>
            </div>
          </div>
          <div class="hint-box">
            <h5>Trip Computation Modes</h5>
            <p>        Trip computation determines how we break up vehicle locations into trips. Most devices work best when using the
              standard 'Ignition Based' mode, which determines when a trip starts and finishes based on whether the engine is
              running or not.
            </p>
            <p>
              We <span class="font-weight-bold">strongly</span> recommend sticking with the standard, Ignition based trip
              mode as it generally produces the best trips under the wides set of circumstances.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <button v-if="modal" @click="$bvModal.hide(modal)">Close</button>
      <button @click="saveSettings" v-if="isDirty">Save Changes</button>
    </div>
  </div>
</template>

<script>

import * as DataProvider from '@/components/helpers/DataProvider'
import LoadingBox from '@/components/helpers/LoadingBox'
import * as ErrorHelper from '@/components/helpers/ErrorHelper'
import * as AlertHelper from '@/components/helpers/AlertHelper'

export default {
  name: 'DeviceAdvancedSettings',
  components: {
    LoadingBox
  },
  props: {
    device: Object,
    editable: Boolean,
    modal: String
  },
  data: function () {
    return {
      loading: false,
      tripCompModes: [
        {
          text: 'Ignition Based',
          value: 0
        },
        // Disabled - Picking this for anything other than a battery tracker might create an insane number of trips...
        // {
        //   text: 'Location Based',
        //   value: 0
        // },
        {
          text: 'Speed Based',
          value: 2
        }
      ],
      savedSettings: {
        tripMode: null,
        tripMoveTimeLimit: null
      },
      tripMode: 0,
      tripMoveTimeLimit: 180,
      enablePurposeTimes: false,
      currentTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    }
  },
  mounted () {
    this.loadSettings(this.device)
  },
  methods: {
    loadSettings(device) {
      if (Object.hasOwn(device.settings, 'trip_mode')) {
        this.tripMode = device.settings.trip_mode
      }
      if (Object.hasOwn(device.settings, 'trip_mode_movement_time_limit')) {
        this.tripMoveTimeLimit = device.settings.trip_mode_movement_time_limit
      }
      this.updateSavedSettings()
    },
    updateSavedSettings() {
      this.savedSettings.tripMode = this.tripMode
      this.savedSettings.tripMoveTimeLimit = this.tripMoveTimeLimit
    },
    async saveSettings() {
      let resp = await DataProvider.setDeviceProperty(this.device.device_imei, {
        settings: {
          trip_mode: this.tripMode,
          trip_mode_movement_time_limit: this.tripMoveTimeLimit
        }
      })
      if (resp.success) {
        this.loadSettings(resp.data)
        AlertHelper.successToast('Device Settings Updated!', 'Settings Saved')
        this.$emit('change')
      } else {
        ErrorHelper.displayDataErrorToast(resp)
      }
    }
  },
  computed: {
    isDirty() {
      return Object.keys(this.savedSettings).some(key => this.savedSettings[key] !== this[key])
    },
    timezoneOptions() {
      return Intl.supportedValuesOf('timeZone')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '../../variables';

  .hint-box {
    max-width: 30em;
    font-size: 0.9em;
  }

</style>
