/**
 * A customized popup on the map.

 based on the google example:
 https://developers.google.com/maps/documentation/javascript/examples/overlay-popup

 */

export class MapPopupOverlay extends google.maps.OverlayView {
  map
  popups

  constructor (map) {
    super()
    this.setMap(map)
    this.popups = []
  }

  createPopup (position, content, popupOptions = {}) {
    let popup = {
      position: position,
      content: content,
      containerDiv: document.createElement('div'),
      bubbleAnchor: document.createElement('div'),
      show: false
    }
    content.classList.add('popup-bubble')
    popup.bubbleAnchor.classList.add('popup-bubble-anchor')
    popup.bubbleAnchor.appendChild(content)

    popup.containerDiv.classList.add('popup-container')
    popup.containerDiv.appendChild(popup.bubbleAnchor)
    // Optionally stop clicks, etc., from bubbling up to the map.
    MapPopupOverlay.preventMapHitsAndGesturesFrom(popup.containerDiv)
    popup.closeOnBackgroundClick = popupOptions.closeOnBackgroundClick || true
    popup.clickHandler = (e) => { this.setVisibility(popup, false) }
    this.getPanes().floatPane.appendChild(popup.containerDiv)
    this.popups.push(popup)
    return popup
  }

  /** Called when the popup is added to the map. */
  // onAdd () {
  //   this.getPanes().floatPane.appendChild(this.containerDiv)
  // }

  setVisibility (popup, newState, redraw = true) {
    popup.show = newState
    if (popup.show) {
      popup.containerDiv.classList.add('active')
      if (popup.closeOnBackgroundClick) {
        document.addEventListener('click', popup.clickHandler)
      }
    } else {
      popup.containerDiv.classList.remove('active')
      if (popup.closeOnBackgroundClick) {
        document.removeEventListener('click', popup.clickHandler)
      }
    }
    if (redraw) {
      this.draw([popup])
    }
  }

  /** Called when the popup is removed from the map. */
  removePopup (popup) {
    if (popup.containerDiv.parentElement) {
      popup.containerDiv.parentElement.removeChild(this.containerDiv)
    }
    this.popups = this.popups.filter(p => p !== popup)
  }

  /** Called each frame when the popup needs to draw itself. */
  draw (popups = null) {
    if (popups === null) {
      popups = this.popups.filter(p => p.show === true)
    }
    popups.forEach(popup => {
      const divPosition = this.getProjection().fromLatLngToDivPixel(
        popup.position
      )
      if (Math.abs(divPosition.x) > 4000 || Math.abs(divPosition.y) > 4000) {
        this.setVisibility(popup, false, false)
      } else {
        popup.containerDiv.style.left = divPosition.x + 'px'
        popup.containerDiv.style.top = divPosition.y + 'px'
      }
    })
  }
}
