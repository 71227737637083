<template>
  <PopoverElement class="popover-menu animate-in-out" :value="isVisible" :direction="direction">
    <div class="popover-menu-header">
      <h4 class="mb-0">{{title}}</h4>
      <div @click="hide" class="header-control clickable fa fa-arrow-right"></div>
    </div>
    <div class="popover-menu-content">
      <slot></slot>
    </div>
  </PopoverElement>
</template>

<script>

import PopoverElement from '@/components/shared/PopoverElement.vue'

/****
 *
 *  Popout side menu
 *
 *  Parent components should be v-model to connect a visibility control variable, so the component can close itself.
 *
 */
export default {
  name: 'PopoutMenu',
  components: { PopoverElement },
  props: {
    title: String,
    value: Boolean,
    direction: String
  },
  data: function () {
    return {
      isVisible: this.value
    }
  },
  mounted() {
  },
  methods: {
    hide() {
      this.isVisible = false
      this.$emit('input', this.isVisible)
    }
  },
  computed: {
  },
  watch: {
    value (newVal) {
      this.isVisible = newVal
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/variables';
@import '@/animations';

.hidden {
  display: none!important;
}

.visible {
  display: flex!important;
}

.popover-menu {
  position: fixed;
  left: auto;
  z-index: 5;
  top: 0;
  right: 60px;
  bottom: 0;
  // Min width is just based on the standard side menu width.
  min-width: 250px;
  height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  background-color: #2c3237;
  box-shadow: 1px 1px 20px -1px rgba(0, 0, 0, 0.51);
  overflow: hidden;
}

.popover-menu-header {
  position: relative;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  background-image: $theme-header-gradient;
}

.header-control {
  font-size: 32px;
  color: $theme-color-primary-1;
}

.header-control:hover {
  color: $theme-color-primary-5;
  -webkit-text-stroke: 2px darkgreen;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, text-shadow 0.15s ease-in-out, -webkit-text-stroke 0.15s ease-in-out;
}

.popover-menu-header h4 {
  color: white;
}

.popover-menu-content {
  display: flex;
  flex-flow: column nowrap;
  padding: 1px;
  overflow: hidden;
}

</style>
