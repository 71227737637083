var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navigationbar" },
    [
      _c("notification-agent", { on: { update: _vm.updateUnreadBadge } }),
      _vm.isFleetUser
        ? _c(
            "router-link",
            {
              staticClass: "nav-item-container navbar-fleet",
              attrs: { to: "/" }
            },
            [
              _c("div", { staticClass: "nav-icon-outer" }, [
                _c("i", {
                  staticClass: "menu-item nav-icon",
                  class: _vm.$config.icons.nav.fleet
                })
              ]),
              _c("div", { staticClass: "nav-item-heading" }, [
                _vm._v(" Fleet Screen ")
              ])
            ]
          )
        : _vm._e(),
      _vm.isFormsUser
        ? _c(
            "router-link",
            {
              staticClass: "nav-item-container navbar-forms",
              attrs: { to: "/forms" }
            },
            [
              _c("div", { staticClass: "nav-icon-outer" }, [
                _c("i", {
                  staticClass: "menu-item nav-icon",
                  class: _vm.$config.icons.nav.forms
                })
              ]),
              _c("div", { staticClass: "nav-item-heading" }, [
                _vm._v(" Prestarts/Forms ")
              ])
            ]
          )
        : _vm._e(),
      _vm.isFleetUser
        ? _c(
            "div",
            {
              staticClass: "nav-item-container navbar-manage",
              on: {
                click: function($event) {
                  _vm.expandManagement = !_vm.expandManagement
                }
              }
            },
            [
              _c("div", { staticClass: "nav-icon-outer" }, [
                _c("i", {
                  staticClass: "menu-item nav-icon",
                  class: _vm.$config.icons.nav.fleetManager
                })
              ]),
              _c("div", { staticClass: "nav-item-heading" }, [
                _vm._v(" Manage Fleet "),
                !_vm.expandManagement
                  ? _c("i", {
                      staticClass: "expand_icon",
                      class: _vm.$config.icons.twisties.collapsed_before
                    })
                  : _c("i", {
                      staticClass: "expand_icon",
                      class: _vm.$config.icons.twisties.expanded
                    })
              ])
            ]
          )
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.expandManagement,
              expression: "expandManagement"
            }
          ],
          staticClass: "sub-menu-container"
        },
        [
          _vm.isFleetUser
            ? _c(
                "router-link",
                {
                  staticClass: "nav-item-container navbar-trips",
                  attrs: { to: "/trips" }
                },
                [
                  _c("div", { staticClass: "nav-icon-outer" }, [
                    _c("i", {
                      staticClass: "menu-item nav-icon",
                      class: _vm.$config.icons.nav.trips
                    })
                  ]),
                  _c("div", { staticClass: "nav-item-heading" }, [
                    _vm._v(" Vehicle Trips ")
                  ])
                ]
              )
            : _vm._e(),
          _vm.isFleetUser
            ? _c(
                "router-link",
                {
                  staticClass: "nav-item-container navbar-fm",
                  attrs: { to: "/fleet-manager" }
                },
                [
                  _c("div", { staticClass: "nav-icon-outer" }, [
                    _c("i", {
                      staticClass: "menu-item nav-icon",
                      class: _vm.$config.icons.nav.fleetManager
                    })
                  ]),
                  _c("div", { staticClass: "nav-item-heading" }, [
                    _vm._v(" Fleet Manager ")
                  ])
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "nav-item-container navbar-reports-menu",
          on: {
            click: function($event) {
              _vm.expandReports = !_vm.expandReports
            }
          }
        },
        [
          _c("div", { staticClass: "nav-icon-outer" }, [
            _c("i", {
              staticClass: "menu-item nav-icon",
              class: _vm.$config.icons.nav.reports
            })
          ]),
          _c("div", { staticClass: "nav-item-heading" }, [
            _vm._v(" Reports Menu "),
            !_vm.expandReports
              ? _c("i", {
                  staticClass: "expand_icon",
                  class: _vm.$config.icons.twisties.collapsed_before
                })
              : _c("i", {
                  staticClass: "expand_icon",
                  class: _vm.$config.icons.twisties.expanded
                })
          ])
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.expandReports,
              expression: "expandReports"
            }
          ],
          staticClass: "sub-menu-container"
        },
        [
          _vm.isFleetUser
            ? _c(
                "router-link",
                {
                  staticClass: "nav-item-container navbar-dashboard",
                  attrs: { to: "/dashboard" }
                },
                [
                  _c("div", { staticClass: "nav-icon-outer" }, [
                    _c("i", {
                      staticClass: "menu-item nav-icon",
                      class: _vm.$config.icons.nav.dashboard
                    })
                  ]),
                  _c("div", { staticClass: "nav-item-heading" }, [
                    _vm._v(" Dashboard ")
                  ])
                ]
              )
            : _vm._e(),
          _vm.isPowerUser
            ? _c(
                "router-link",
                {
                  staticClass: "nav-item-container navbar-reports",
                  attrs: { to: "/reports" }
                },
                [
                  _c("div", { staticClass: "nav-icon-outer" }, [
                    _c("i", {
                      staticClass: "menu-item nav-icon",
                      class: _vm.$config.icons.nav.reports
                    })
                  ]),
                  _c("div", { staticClass: "nav-item-heading" }, [
                    _vm._v(" Reports ")
                  ])
                ]
              )
            : _vm._e(),
          _vm.isFleetUser
            ? _c(
                "router-link",
                {
                  staticClass: "nav-item-container navbar-stats",
                  attrs: { to: "/stats" }
                },
                [
                  _c("div", { staticClass: "nav-icon-outer" }, [
                    _c("i", {
                      staticClass: "menu-item nav-icon",
                      class: _vm.$config.icons.nav.stats
                    })
                  ]),
                  _c("div", { staticClass: "nav-item-heading" }, [
                    _vm._v(" Legacy Stats ")
                  ])
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "router-link",
        {
          staticClass: "nav-item-container navbar-preferences",
          attrs: { to: "/preferences" }
        },
        [
          _c("div", { staticClass: "nav-icon-outer" }, [
            _c("i", {
              staticClass: "menu-item nav-icon",
              class: _vm.$config.icons.nav.preferences
            })
          ]),
          _c("div", { staticClass: "nav-item-heading" }, [
            _vm._v(" Preferences ")
          ])
        ]
      ),
      _vm.isFleetUser
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "b-modal",
                  rawName: "v-b-modal.modal-notifications",
                  modifiers: { "modal-notifications": true }
                }
              ],
              staticClass: "nav-item-container navbar-notifications clickable"
            },
            [
              _c("div", { staticClass: "nav-icon-outer" }, [
                _c(
                  "i",
                  {
                    staticClass: "menu-item nav-icon",
                    class: _vm.$config.icons.nav.notifications
                  },
                  [
                    _c(
                      "b-badge",
                      { staticClass: "unread-badge", attrs: { pill: "" } },
                      [
                        _vm._v(
                          _vm._s(_vm._f("limitUnreadBadge")(_vm.unreadCount)) +
                            " "
                        )
                      ]
                    )
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "nav-item-heading" }, [
                _vm._v(" Notifications ")
              ])
            ]
          )
        : _vm._e(),
      _vm.isAdmin
        ? _c(
            "router-link",
            {
              staticClass: "nav-item-container navbar-admin",
              attrs: { to: "/admin" }
            },
            [
              _c("div", { staticClass: "nav-icon-outer" }, [
                _c("i", {
                  staticClass: "menu-item nav-icon",
                  class: _vm.$config.icons.nav.admin
                })
              ]),
              _c("div", { staticClass: "nav-item-heading" }, [
                _vm._v(" Admin ")
              ])
            ]
          )
        : _vm._e(),
      _vm.isPowerUser
        ? _c(
            "router-link",
            {
              staticClass: "nav-item-container navbar-warboard",
              attrs: { to: "/warboard" }
            },
            [
              _c("div", { staticClass: "nav-icon-outer" }, [
                _c("i", {
                  staticClass: "menu-item nav-icon",
                  class: _vm.$config.icons.nav.warboard
                })
              ]),
              _c("div", { staticClass: "nav-item-heading" }, [
                _vm._v(" Warboard ")
              ])
            ]
          )
        : _vm._e(),
      _c(
        "router-link",
        {
          staticClass: "nav-item-container navbar-logout",
          attrs: { to: "/logout" }
        },
        [
          _c("div", { staticClass: "nav-icon-outer" }, [
            _c("i", {
              staticClass: "menu-item nav-icon",
              class: _vm.$config.icons.nav.signout
            })
          ]),
          _c("div", { staticClass: "nav-item-heading" }, [_vm._v(" Sign-Out ")])
        ]
      ),
      _c(
        "b-modal",
        {
          staticClass: "modal-content",
          attrs: {
            id: "modal-notifications",
            centered: "",
            size: "lg",
            "hide-footer": "",
            title: "Notifications"
          }
        },
        [_c("notification-list")],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }