var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "device-alarms" }, [
    !_vm.loading
      ? _c(
          "div",
          { staticClass: "alarms-container" },
          _vm._l(_vm.deviceFeatures, function(feature, idx) {
            return _c("GenericFeatureControl", {
              key: idx,
              ref: feature,
              refInFor: true,
              attrs: {
                device: _vm.device,
                feature_code: feature,
                editable: _vm.editable
              },
              on: {
                change: function($event) {
                  _vm.unsavedChanges = true
                },
                save: _vm.onSave
              }
            })
          }),
          1
        )
      : _vm._e(),
    _c("div", { staticClass: "spacing" }),
    _c("div", { staticClass: "footer mt-2" }, [
      _c(
        "button",
        {
          staticClass: "button",
          attrs: { id: "button-close" },
          on: {
            click: function($event) {
              return _vm.clickClose()
            }
          }
        },
        [_vm._v("Close")]
      ),
      _vm.editable
        ? _c(
            "button",
            {
              staticClass: "button",
              attrs: { id: "button-save" },
              on: {
                click: function($event) {
                  return _vm.clickSave()
                }
              }
            },
            [_vm._v("Save Changes")]
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }