var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "livetrack-control" },
    [
      _c("loading-box", { attrs: { loading: _vm.loading } }),
      _c("label", [_vm._v("Live Tracking")]),
      _c("div", { staticClass: "green-divider" }),
      _c(
        "div",
        {
          staticClass: "d-flex justify-content-between device-control-heading"
        },
        [
          _c(
            "b-form-checkbox",
            {
              attrs: {
                size: "md",
                name: "check-button",
                id: "live-track-switch",
                switch: "",
                disabled: !_vm.enableEdit
              },
              on: {
                change: function($event) {
                  return _vm.livetrackChange($event)
                }
              },
              model: {
                value: _vm.livetrackState,
                callback: function($$v) {
                  _vm.livetrackState = $$v
                },
                expression: "livetrackState"
              }
            },
            [_vm._v(" Enable Live Tracking ")]
          ),
          _c(
            "b-tooltip",
            {
              attrs: {
                target: "live-track-switch",
                noninteractive: "",
                variant: "success",
                placement: "left",
                delay: {
                  show: _vm.$config.general.tooltipShowDelay,
                  hide: _vm.$config.general.tooltipHideDelay
                }
              }
            },
            [_vm._v(" Toggle Livetracking Mode ")]
          )
        ],
        1
      ),
      _vm.livetrackState
        ? _c("div", { staticClass: "livetrack_window" }, [
            _c(
              "div",
              { staticClass: "row justify-content-center" },
              [
                _c("b-spinner", {
                  attrs: { variant: "success", type: "grow", label: "Spinning" }
                }),
                _c("div", { staticClass: "livetrack_heading" }, [
                  _vm._v("Livetrack Mode Enabled")
                ]),
                _c("b-spinner", {
                  attrs: { variant: "success", type: "grow", label: "Spinning" }
                })
              ],
              1
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }