<template>
  <div class="forgotten-password">
    <div class="d-flex justify-content-center">
      <img class="logo" src="../assets/Pro-tekt-logo.png"/>
    </div>

    <div class="login-container justify-content-center mt-5">
      <forgotten-password-box class="login-item" :code="resetCode" :email="email"></forgotten-password-box>
    </div>

    <div class="footer"><router-link to="terms">Terms and Conditions</router-link></div>
  </div>
</template>

<script>
import ForgottenPasswordBox from '../components/auth/ForgottenPasswordBox'

export default {
  name: 'forgotten-password',
  components: {
    ForgottenPasswordBox
  },
  data: function () {
    return {
      resetCode: null,
      email: null
    }
  },
  created () {
    if (this.$route.query.hasOwnProperty('reset-code')) {
      this.resetCode = this.$route.query['reset-code']
    }

    if (this.$route.query.hasOwnProperty('email')) {
      this.email = this.$route.query.email
    }
  },
  methods: {}
}
</script>

<style scoped lang="scss">
  @import '@/variables';

  .forgotten-password {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    background: $default-sidebar-background;
    color: $text-color-invert;
  }

  .logo {
    max-width: 530px;
    max-height: 40vh;
  }

  .login-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  .login-item {
    width: 60%;
  }

  .footer {
    position: absolute;
    left: 75%;
    bottom: 20px;
    transform: translate(-50%, 0);
    margin-top: 5vh;
  }
</style>
