<template>
<div v-if="this.event && this.config" class="event-avatar"
     :class="{'clickable': enable_link, 'selected': selected === true}">
  <i :class="eventIcon" class="event-icon"></i>
  <div @click="onClick" class="flex-grow-1">
    <div class="event-heading">{{eventTitle}}</div>
    <div class="event-timestamp">{{eventTimestamp}}</div>
  </div>
</div>
</template>

<script>
import { DateTimeHelper as dt } from '@/components/helpers/DateTimeHelper'

export default {
  name: 'event-avatar',
  props: {
    event: Object,
    device_imei: String,
    enable_link: Boolean,
    selected: Boolean
  },
  data: function () {
    return {
      config: null
    }
  },
  async mounted() {
    if (this.event) {
      await this.loadConfig(this.event.event_type)
    }
  },
  methods: {
    async loadConfig(eventType) {
      this.config = await this.$eventService.getEventTypeConfig(eventType)
    },
    onClick(e) {
      if (this.enable_link) {
        let routeData = this.$router.resolve({path: `/device/${this.device_imei}/`, query: { event_id: this.event.event_id }})
        window.open(routeData.href, '_blank')
      }
      this.$emit('click', e)
    }
  },
  computed: {
    eventIcon () {
      if (this.config) {
        return this.config.icon
      } else {
        return this.$config.icons.events.default
      }
    },
    eventTitle () {
      if (this.config) {
        return this.config.name
      } else {
        return `Event #${this.event.event_type}-${this.event.event_subtype}`
      }
    },
    eventTimestamp () {
      return dt.timestampToLocalDateTime(this.event.start_time)
    }
  },
  watch: {
    event (newEventData) {
      if (newEventData) {
        this.loadConfig(newEventData.event_type)
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/variables';
.event-avatar {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  text-align: center;
  justify-content: space-between;
  background: $theme-color-background-4;
  color: $theme-color-primary-3;
  border-radius: 5px;
  padding: 5px 5px;
  margin: 2px 0px;
  z-index: 0;
  user-select: none;
}

.event-icon {
  color: $text-color-invert;
  font-size: 2.1em;
  line-height: inherit;
  width: 1.2em;
  overflow: hidden;
}

.event-heading {
  flex-grow: 2;
  font-size: 1.3em
}

.event-timestamp {
  font-size: 0.8rem;
  font-style: italic;
}

.event-title-none {
  font-size: 1rem;
  font-style: italic;
  color:$theme-color-primary-3;
  margin-bottom: 10em;
}

.event-avatar:hover {
  color: white;
  background-color: rgba(0,0,0,0.15);
  box-shadow: 0 0 0 0.2rem rgba(56, 212, 41, 0.4);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.event-avatar.selected {
  background-color: rgba(50, 50, 50,0.25);
  box-shadow: 0 0 0 0.2rem rgba(45, 231, 28, 0.75);
}

</style>
